<div class="row">
  <div class="col-xl-6 col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>{{ "registro.titulo_registros_edicion" | translate }}</h3>
      </div>
      <div class="card-body">
    <div class="form-group">
      <label>{{ "registro.transcripcion" | translate }}</label>
      <div class="caja">
        <!--Titulo-->
        <kendo-textbox
          [clearButton]="true"
          [(ngModel)]="myRegistro.transcripcion"
        >
          <ng-template kendoTextBoxPrefixTemplate> </ng-template>
        </kendo-textbox>
      </div>
    </div>

    <div class="form-group">
      <label>{{ "registro.detectadas" | translate }}</label>
      <div class="caja">
        <!--Titulo-->
        <kendo-textbox [clearButton]="true" [(ngModel)]="myRegistro.detectadas">
          <ng-template kendoTextBoxPrefixTemplate> </ng-template>
        </kendo-textbox>
      </div>
    </div>

    <h5>Fiabilidad: {{ myRegistro.fiable }}</h5>

    <!--AUIDO BASE64-->
    <audio controls autoplay [src]="getSantizeUrl(audio)">
    </audio>

    <div class="form-group">
      <!--Boton Publicar-->

      <button
        type="button"
        class="btn btn-info btn-sm mr-1"
        (click)="btnAprobar()"
      >
        {{ "botones.aprobar" | translate }}
      </button>

      <button
        type="button"
        class="btn btn-success btn-sm mr-1"
        (click)="btnActualizar()"
      >
        {{ "botones.actualizar" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger btn-sm mr-1"
        (click)="btnCancelar()"
      >
        {{ "botones.cancelar" | translate }}
      </button>
    </div>
  </div>
  </div>
  </div>
</div>
