import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idioma.service';
import {  SpeechrecognizerService } from "@app/_services/speechrecognizer.service";
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Palabra,Derivada, Categoria } from "@app/_models/palabras";


@Component({
  selector: 'app-palabras-crear',
  templateUrl: './palabras-crear.component.html',
})
export class PalabrasCrearComponent implements OnInit {

  myWord:Palabra=new Palabra();
  word_es:string='';
  word_eu:string='';
  filePath: string;

  listaCategorias:Categoria[]=[];
  selectedCategoria:Categoria;
  public seleccionados: number[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private speechRecognizerService: SpeechrecognizerService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('palabras.cabecera_crear');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.speechRecognizerService.getCategorias().subscribe((result)=>{
        this.listaCategorias = result.data;
        if(this.listaCategorias.length>0){
          this.selectedCategoria=this.listaCategorias[0];
        }
    });
  }

  btnCrearPalabra(){
    if((this.word_es.length<=0 && this.word_eu.length<=0) ){
      this.alertService.info(
        this.translateService.instant('palabras.validacion')
      );
      return;
    }
    console.log('Creando palabra...');
    this.myWord.word_es = this.word_es;
    this.myWord.word_eu = this.word_eu;
    this.myWord.idCategoria = this.selectedCategoria.id;
    if(this.filePath!=null && this.filePath.length>0)
    this.myWord.urlFoto = this.filePath.split(',')[1];

    this.speechRecognizerService.create(this.myWord).subscribe((result)=>{
      if(!result.error){
        this.alertService.success(
          this.translateService.instant('palabras.success')
        );
        this.router.navigate(["palabras"]);
      }
      else{
        this.alertService.error(
          this.translateService.instant('palabras.error')
        );
      }
    });

  }

  btnCancelar(){
    this.router.navigate(["palabras"]);
  }

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

}
