<div class="row">
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card-header">
          <h3>{{ "palabras.titulo_categorias_editar" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>{{ "palabras.categoria_es" | translate }}</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox
                placeholder="{{ 'palabras.ph_cat_es' | translate }}"
                [clearButton]="true"
                [(ngModel)]="word_es"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
  
          <div class="form-group">
            <label>{{ "palabras.categoria_eu" | translate }}</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox
                placeholder="{{ 'palabras.ph_cat_eu' | translate }}"
                [clearButton]="true"
                [(ngModel)]="word_eu"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
  
          <div class="form-group">
            <!--Boton Publicar-->
            <button
              type="button"
              class="btn btn-success btn-sm mr-1"
              (click)="btnUpdateCategoria()"
            >
              {{ "botones.actualizar" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-danger btn-sm mr-1"
              (click)="btnCancelar()"
            >
              {{ "botones.cancelar" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  