import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idioma.service';
import { SpeechrecognizerService } from '@app/_services/speechrecognizer.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Palabra, Derivada, Categoria } from '@app/_models/palabras';
import { xCircleIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-palabras-editar',
  templateUrl: './palabras-editar.component.html',
})
export class PalabrasEditarComponent implements OnInit {
  myWord: Palabra = new Palabra();
  myDerivada:Derivada= new Derivada();
  word_es: string = '';
  word_eu: string = '';
  idPalabra: number;
  listaPalabrasDerivadas:Derivada[]=[];
  derivada_es:string='';
  derivada_eu:string='';
  editar:boolean;
  filePath:string;

  openCrearDerivada:boolean=false;

  listaCategorias: Categoria[] = [];
  selectedCategoria: Categoria;
  public seleccionados: number[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private speechRecognizerService: SpeechrecognizerService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    public route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant(
      'palabras.cabecera_editar'
    );
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idPalabra = this.route.snapshot.params['idpalabra'];
    this.speechRecognizerService
      .getPalabra(this.idPalabra)
      .subscribe((resultW) => {
        console.log(resultW);
        this.myWord = resultW.data[0];
        this.word_es = resultW.data[0].word_es;
        this.word_eu = this.myWord.word_eu;
        this.filePath= this.myWord.urlFoto;

        this.speechRecognizerService.getCategorias().subscribe((result) => {
          this.listaCategorias = result.data;
          if (this.listaCategorias.length > 0) {
            this.listaCategorias.forEach((element) => {
              if (element.id == this.myWord.idCategoria)
                this.selectedCategoria = element;
            });
          }
        });
      });

      this.speechRecognizerService.getDerivadas(this.idPalabra).subscribe((result)=>{
        console.log('DERIVADAS');
        console.log(result);
        this.listaPalabrasDerivadas = result.data;
      });
  }

  btnUpdatPalabra() {
    if (
      (this.word_es.length <= 0 && this.word_eu.length <= 0) ||
      this.selectedCategoria == null
    ) {
      this.alertService.info(
        this.translateService.instant('palabras.validacion')
      );
      return;
    }
    console.log('Actualizando palabra...');
    this.myWord.word_es = this.word_es;
    this.myWord.word_eu = this.word_eu;
    this.myWord.idCategoria = this.selectedCategoria.id;
    if(this.filePath!=this.myWord.urlFoto){
      console.log('Obteniendo en BASE64...');
      this.myWord.urlFoto = this.filePath.split(',')[1]; //Borramos cabecera data: base
      this.myWord.updateFoto=true;
    }

    this.speechRecognizerService.update(this.myWord).subscribe((result) => {
      if (!result.error) {
        this.alertService.success(
          this.translateService.instant('palabras.successupdate')
        );
        this.router.navigate(['palabras']);
      } else {
        this.alertService.error(
          this.translateService.instant('palabras.errorupdate')
        );
      }
    });
  }

  btnCancelar() {
    this.router.navigate(['palabras']);
  }

  onClickNuevo(){
    this.derivada_es = '';
    this.derivada_eu = '';
    this.editar=false;
    this.openCrearDerivada=true;
  }


  onClickEditar(){
    console.log(this.seleccionados);
    this.listaPalabrasDerivadas.forEach(element => {
      if(element.id==this.seleccionados[0]){
        this.derivada_es = element.derivado_es;
        this.derivada_eu = element.derivado_eu;
        return;
      }
    });
    this.editar=true;
    this.openCrearDerivada=true;
  }
  cellClick(){
    console.log(this.seleccionados);
    this.listaPalabrasDerivadas.forEach(element => {
      if(element.id==this.seleccionados[0]){
        this.derivada_es = element.derivado_es;
        this.derivada_eu = element.derivado_eu;
        return;
      }
    });
    this.editar=true;
    this.openCrearDerivada=true;
  }

  btnCloseDialog(){
    this.openCrearDerivada=false;
  }

  btnGestionarDerivada(){
    if(!this.editar){
      this.myDerivada.idPalabra = this.idPalabra;
      this.myDerivada.derivado_es = this.derivada_es;
      this.myDerivada.derivado_eu = this.derivada_eu;
      if(this.derivada_eu.length==0 || this.derivada_es.length==0)return;
      this.speechRecognizerService.createDerivada(this.myDerivada).subscribe((result)=>{
        this.openCrearDerivada=false;
        if(!result.error){
          this.alertService.success(
            this.translateService.instant('palabras.screarderivada')
          );
          this.speechRecognizerService.getDerivadas(this.idPalabra).subscribe((result)=>{
            console.log('DERIVADAS');
            console.log(result);
            this.listaPalabrasDerivadas = result.data;
          });
        }
        else{
          this.alertService.error(
            this.translateService.instant('palabras.ecrearderivada')
          );
        }
      });
    }
    else{
      this.myDerivada.id = this.seleccionados[0];
      this.myDerivada.derivado_es = this.derivada_es;
      this.myDerivada.derivado_eu = this.derivada_eu;
      if(this.derivada_eu.length==0 || this.derivada_es.length==0)return;
      this.speechRecognizerService.updateDerivada(this.myDerivada).subscribe((result)=>{
        this.openCrearDerivada=false;
        if(!result.error){
          this.alertService.success(
            this.translateService.instant('palabras.supdatederivada')
          );
          this.speechRecognizerService.getDerivadas(this.idPalabra).subscribe((result)=>{
            console.log('DERIVADAS');
            console.log(result);
            this.listaPalabrasDerivadas = result.data;
          });
        }
        else{
          this.alertService.error(
            this.translateService.instant('palabras.eupdatederivada')
          );
        }
      });
    }
  }

  onClickEliminar() {
    console.log('Eliminar registro');
    this.speechRecognizerService
      .deleteDerivadas({ codigos: this.seleccionados })
      .subscribe((data) => {
        if(!data.error){
          this.seleccionados.forEach((res)=>{
            this.listaPalabrasDerivadas = this.listaPalabrasDerivadas.filter((x)=>x.id!=res);
          });
        }
      });
  }

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

}
