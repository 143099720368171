export class Palabra {
    id: number;
    word_es: string;
    word_eu: string;
    idCategoria: number;
    created:Date;
    updated:Date;
    puntuacion:number;
    urlFoto:string;
    updateFoto:boolean;
}
export class Derivada {
    id: number;
    derivado_es: string;
    derivado_eu: string;
    idPalabra: number;
    created:Date;
    updated:Date;
}
export class Categoria {
    id: number;
    nombre_es: string;
    nombre_eu: string;
    created:Date;
    updated:Date;
}