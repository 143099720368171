import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { Idioma } from '@app/_models/idioma';
import { MenuService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';


@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    dataUsuarios: Usuario[];
    interval: number;
    selectedIdioma:Idioma;
    selectedNumber:number;
    inputTextBox:string;
    listaUsuarios:Usuario[];

    constructor(private usuariosService: UsuariosService, 
        private menuService: MenuService, public router: Router, private translateService: TranslateService) {    
            this.refreshData();
      }

      refreshData(){
        this.usuariosService.getAll()
        .pipe(first())
        .subscribe(users =>
          this.dataUsuarios = users
        );
    }
    
      ngOnInit(): void {
        this.menuService.titulo = this.translateService.instant('menu.inicio');
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
          // TODO This as a workaround.
          this.menuService.titulo = this.translateService.instant('menu.inicio');
        });
        this.pruebas();
      }

      getIdiomaFromReus(idioma) {
        console.log('Emitter');
        console.log(idioma);
        this.selectedIdioma = idioma;
        console.log(this.selectedIdioma.nombre);
    
      }

      getNumberFromReus(valor){
        this.selectedNumber=valor;
      }

      getText(valor){
        this.inputTextBox=valor;
      }

      getUsuarios(lista){
        this.listaUsuarios = lista;
      }

    pruebas(){
      console.log('Iniciando Pruebas');
      type casa={
        id:number
        nombre:string
      }
      type Persona={
        edad:number
        nombre:string
      }
      let myModel = new Map<number,casa>();
      myModel.set(1,{id:1,nombre:"Casa 1"});
      myModel.set(2,{id:2,nombre:"Casa 2"});
      myModel.set(3,{id:3,nombre:"Casa 2"});
      myModel.set(4,{id:4,nombre:"Casa 1"});

      console.log(myModel.has(7));
      console.log(myModel.get(7));

      myModel.forEach((element)=>console.log(element.nombre))

      let listaNumeros:number[]= [7,7,1,9,45,23,98,56,34,15,74,11,12,13];
      let listaObjetos:Persona[]=[
        {edad:24,nombre:"Asier"},
        {edad:12,nombre:"Mikel"},
        {edad:35,nombre:"John"},
        {edad:65,nombre:"Maria"},
        {edad:45,nombre:"Anais"},
      ];
      let listaOrdenada = listaNumeros.sort((prev,elem)=>{return prev-elem});
      console.log(listaOrdenada);
      let tmp = Array.from(new Set([...listaOrdenada]));
      console.log(tmp);
      let listaObjetosOrdenada = listaObjetos.sort((prev,elem)=>{
          if(prev.edad>elem.edad)
          return 1;
          else if(prev.edad<elem.edad)
          return -1;
          return 0;
      });
      console.log(listaObjetosOrdenada);
      let listaNombres = listaObjetosOrdenada.map((elem)=>elem.nombre).sort();
      console.log(listaNombres);
      let listaNombresCopia = [...listaNombres];
      listaNombres = listaNombres.filter((x)=>x!="Asier")
      console.log(listaNombres);
      console.log(listaNombresCopia);

      let listaObjetosCopia = [...listaObjetos];
      listaObjetos = listaObjetos.filter((x)=>x.edad!=24)
      console.log(listaObjetos);
      console.log(listaObjetosCopia);
    }
}