<div class="pantalla-secundaria"></div>

<!--<div style="position: absolute; top: 0; height: 830px; width: 30px; background-color: transparent"></div>-->
<!--<img class="gipuzkoa-argitu-logo" src="../../../assets/img/gipuzkoa-argitu-logo-01.png" />


<div class="gipuzkoaargitu"><span>#GIPUZKOAARGITU</span></div>-->




<!--<div class="mezua-2">
  <span>
    {{ "mensajes.comienza_hablar" | translate }}
  </span>
</div>


<div class="ecualizador">
  <div class="peak grad1 p1 peak1"></div>
  <div class="peak grad1 s1"></div>
  <div class="peak grad2 p2 peak1"></div>
  <div class="peak grad2 s2"></div>
  <div class="peak grad3 p3 peak1"></div>
  <div class="peak grad3 s3"></div>
  <div class="peak grad4 p4 peak1"></div>
  <div class="peak grad4 s4"></div>
  <div class="peak grad5 p5 peak1"></div>
  <div class="peak grad5 s5"></div>
  <div class="peak grad6 p6 peak1"></div>
  <div class="peak grad6 s6"></div>
  <div class="peak grad7 p7 peak1"></div>
  <div class="peak grad7 s7"></div>
  <div class="peak grad8 p8 peak1"></div>
  <div class="peak grad8 s8"></div>
  <div class="peak grad9 p9 peak1"></div>
  <div class="peak grad9 s9"></div>
  <div class="peak grad10 p10 peak1"></div>
  <div class="peak grad10 s10"></div>
</div>

<div class="barraprogreso-cont">

  <span class="tiemporestante">Tiempo restante</span>
  <div class="barraprogreso">
    <div class="barraprogresoinner">

    </div>
  </div>
</div>

<div class="cont-cambiardidioma">

  <button class="btn">Cambiar Idioma</button>
  <span>ES</span>
</div>-->


<!--<div class="mezua-3">

  <span>
    Eskerrik asko gipuzkoarron nahien mapan zure ekarpena egiteagatik. <br>Gracias por tu aportación al mapa de deseos de los guipuzcoanos.
  </span>

  <div class="transcripcion">hitza hitza</div>

</div>-->


