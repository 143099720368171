import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/* SERVICES */
import {
  UsuariosService,
  MenuService,
  UsuariosTiposService,
} from '@app/_services';
import { IdiomasService } from "@app/_services/idioma.service";
import { SpeechrecognizerService } from "@app/_services/speechrecognizer.service";

//Angular
import '@progress/kendo-angular-intl/locales/es/all';
import {
  DropDownsModule,
  ComboBoxModule,
} from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';

import {FaceDetectModule} from '@haseeamarathunga/face-detect';
import {NgOpenCVModule, OpenCVOptions} from 'ng-open-cv';
import {WebcamModule} from 'ngx-webcam';
import { TagCloudModule } from 'angular-tag-cloud-module';

//Paginas
import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { ZituDropdownComponent } from './zitu-components/zitu-dropdown/zitu-dropdown.component';
import { ZituNumericComponent } from './zitu-components/zitu-numeric/zitu-numeric.component';
import { ZituTextboxComponent } from './zitu-components/zitu-textbox/zitu-textbox.component';
import { ZituSelectorUsuariosComponent } from './zitu-components/zitu-selector-usuarios/zitu-selector-usuarios.component';
import { PalabrasComponent } from "@app/palabras/palabras/palabras.component";
import { PalabrasCrearComponent } from "@app/palabras/palabras-crear/palabras-crear.component";
import { PalabrasEditarComponent } from "@app/palabras/palabras-editar/palabras-editar.component";
import { CategoriasComponent } from "@app/palabras/categorias/categorias.component";
import { CategoriasCrearComponent } from "@app/palabras/categorias-crear/categorias-crear.component";
import { CategoriasEditarComponent } from "@app/palabras/categorias-editar/categorias-editar.component";
import { DetectorComponent } from './detection/detector/detector.component';
import { Detector2Component } from './detection/detector2/detector2.component';
import {  PantallaInicialComponent } from "@app/pantalla/pantalla-inicial/pantalla-inicial.component";
import { PantallaSecundariaComponent } from './pantalla/pantalla-secundaria/pantalla-secundaria.component';
import { GestionRegistrosComponent } from "./gestion/gestion-registros/gestion-registros.component";
import { GestionEditarComponent } from './gestion/gestion-editar/gestion-editar.component';
import { Tagcanvas2Component } from './tagcanvas2/tagcanvas2.component';
import { PantallaNocturnaComponent } from './pantalla/pantalla-nocturna/pantalla-nocturna.component';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    //IDIOMAS
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GridModule,
    ComboBoxModule,
    DateInputsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    DropDownsModule,
    InputsModule,
    IconsModule,
    ButtonsModule,
    FormsModule,
    LabelModule,
    DialogsModule,
    NgOpenCVModule,
    WebcamModule,
    FaceDetectModule,
    TagCloudModule,
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    UsuariosComponent,
    EditarCrearUsuarioComponent,
    UsuariosTiposComponent,
    EditarCrearUsuarioTipoComponent,
    DetailsComponent,
    UpdateComponent,
    ZituDropdownComponent,
    ZituNumericComponent,
    ZituTextboxComponent,
    ZituSelectorUsuariosComponent,
    PalabrasComponent,
    PalabrasCrearComponent,
    PalabrasEditarComponent,
    CategoriasComponent,
    CategoriasCrearComponent,
    CategoriasEditarComponent,
    DetectorComponent,
    Detector2Component,
    PantallaInicialComponent,
    PantallaSecundariaComponent,
    GestionRegistrosComponent,
    GestionEditarComponent,
    Tagcanvas2Component,
    PantallaNocturnaComponent,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [UsuariosService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MenuService,
    UsuariosTiposService,
    IdiomasService,
    SpeechrecognizerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
