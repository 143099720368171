
import { timer,of, Observable } from 'rxjs';
import {
  Component,
  VERSION,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
//Video Face detection
import * as mobilenet from '@tensorflow-models/mobilenet';
import * as blazeface from '@tensorflow-models/blazeface';

import '@tensorflow/tfjs-backend-webgl';
import * as tf from '@tensorflow/tfjs-core';
import * as filesystem from 'file-system';
//AUDIO Recording
import { DomSanitizer } from '@angular/platform-browser';
import * as RecordRTC from 'recordrtc';
import { SpeechrecognizerService } from '@app/_services/speechrecognizer.service';
import { delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

declare function openFullscreen():any;
declare function closeFullscreen():any;


@Component({
  selector: 'app-detector2',
  templateUrl: './detector2.component.html',
})
export class Detector2Component implements OnInit {
  fullscreen:boolean=false;
  public model: any;
  public loading = true;
  public predictions: Prediction[];
  @ViewChild('video') public video: ElementRef;
  //Audio
  record;
  recording = false;
  url;
  error;
  stream: MediaStream;
  recordingAudio: boolean = false;
  RECORD_SECONDS: number = 10;
  cuentaAtrasRec: boolean = false;
  secondsCuentaAtras: number = 5;
  message: string = this.translateService.instant('mensajes.esperando');
  transcripcion: string = '';
  LANG: string = 'ES';
  timerFaceRecognition: number;
  //Fotos
  fotoHablar: string = '';

  //Estados
  STATE_LOADING_NEWRAL_NETWORK: number = 0;
  STATE_WAITING_HUMANS: number = 1;
  STATE_HUMAN_DETECTED: number = 2;
  STATE_SPEAK_COUNTDOWN: number = 3;
  STATE_SPEAKING: number = 4;
  STATE_ANALYZING_AUDIO: number = 5;
  STATE_THANKS_FOR_COMMING: number = 6;

  STATE: number = 0;

  constructor(
    //public selectedVoice: SpeechSynthesisVoice ,
    public speechRecognizer: SpeechrecognizerService,
    private translateService: TranslateService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.translateService.instant('palabras.welcome');
    this.model = await blazeface.load();
    this.STATE = this.STATE_WAITING_HUMANS;
    this.loading = false;
    this.timerFaceRecognition = setInterval(async () => {
      if (this.STATE == this.STATE_WAITING_HUMANS) {
        console.log('Detectando caras...');
        const predictions = await this.model.estimateFaces(
          this.video.nativeElement,
          false
        );
        if (
          predictions.length > 0 
          // &&
          // !this.cuentaAtrasRec &&
          // !this.recordingAudio
        ) {
          this.STATE = this.STATE_HUMAN_DETECTED;
          this.transcripcion = '';
          console.log(predictions);
          this.message = this.translateService.instant('mensajes.hola');
          await this.delay(4);
          this.startCuentaAtras();
        } else {
          console.log('SIN CARAS');
          this.STATE = this.STATE_WAITING_HUMANS;
        }
      }
    }, 5000);
  }

  setFullScreen(){
    console.log('FULLSCREEN');
    if(!this.fullscreen){
      openFullscreen();
      this.fullscreen=true;
    }
    else{
      closeFullscreen();
      this.fullscreen=false;
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    const vid = this.video.nativeElement;
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          vid.srcObject = stream;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async startCuentaAtras() {
    console.log('[startCuentaAtras]');
    this.cuentaAtrasRec = true;
    var downloadTimer = setInterval(() => {
      console.log('TIMER CUENTA ATRAS');
      this.STATE = this.STATE_SPEAK_COUNTDOWN;
      if (this.secondsCuentaAtras <= 1) {
          console.log('llamamos a startrecording');

          this.startRecording();
          this.recordingAudio = true;
          this.cuentaAtrasRec = false;
        clearInterval(downloadTimer);
      } else {
        this.secondsCuentaAtras -= 1;
        // this.message =
        //   this.translateService.instant('mensajes.hola') +
        //   ' ' +
        //   this.secondsCuentaAtras +
        //   ' ' +
        //   this.translateService.instant('mensajes.segundos');
      }
    }, 1000);
  }

  startRecording() {
    console.log('Comienza la grabación: ');
    this.initiateRecording();
    console.log('Esperando blob rellenado base64');
    setTimeout(() => {
      console.log('Parando grabacion');
      this.stopRecording();
    }, this.RECORD_SECONDS * 1000);
  }

  initiateRecording() {
    console.log('Empieza audio...');
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  successCallback(stream) {
    this.STATE = this.STATE_SPEAKING;
    this.secondsCuentaAtras = 5;
    var options = {
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      sampleRate: 48000,
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
    this.message = this.translateService.instant('mensajes.comienza_hablar');
  }
  /**
   * Stop recording.
   */
  stopRecording() {
    this.STATE = this.STATE_ANALYZING_AUDIO;
    console.log('Grabación Finalizada');
    this.message = 'Gracias,es suficiente!!';
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
  }
  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */
  processRecording(blob) {
    this.message = this.translateService.instant('mensajes.analizando');
    this.url = URL.createObjectURL(blob);
    console.log('blob', blob);
    console.log('url', this.url);
    console.log('Guardando...');
    // var tracks = this.stream.getAudioTracks().forEach(track => track.stop());
    // console.log('STREAM:');
    // console.log(this.stream);
    // console.log('TRACKS');
    // console.log(tracks);
    //  this.record.getDataURL(function (dataURL) {
    //   console.log(dataURL);
    //  });
    //this.record.save('audio.wav');

    //FileREader
    // this.recordingAudio=false;
    // var base64;
    // var reader = new window.FileReader();
    // reader.readAsDataURL(blob);
    // reader.onloadend = function() {
    //   base64 = reader.result;
    //   base64 = base64.split(',')[1];
    //   console.log(base64);
    //   reader.onload = () => {
    //     console.log('Cargado!');

    //   };
    // }

    var base64;
    var reader = new window.FileReader();
    reader.onloadend = () => {
      base64 = reader.result;
      base64 = base64.split(',')[1];
      console.log(base64);

      this.uploadAudio(base64);
    };
    reader.readAsDataURL(blob);

    // console.log('Esperando blob rellenado base64');
    // setTimeout(()=>{
    //   console.log('Subiendo');
    //   console.log(this.loading);
    //   this.uploadAudio(base64);

    // }, 10000);
  }
  async uploadAudio(base64) {
    console.log('Subiendo AUDIO:' + base64.length);
    if (this.LANG == 'ES') {
      this.speechRecognizer.uploadAudio(base64).subscribe(async (result) => {
        console.log('RESULTADO SUBIDA');
        console.log(result);
        if (!result.error) {
          this.STATE = this.STATE_THANKS_FOR_COMMING;
          this.transcripcion = result.palabras;

          this.message = this.translateService.instant('mensajes.gracias');
        } else {
          console.log(result);
          this.STATE = this.STATE_WAITING_HUMANS;
        }
        await this.delay(6);
        this.STATE = this.STATE_WAITING_HUMANS;
        // this.esperarYMensajeEstado(
        //   this.translateService.instant('mensajes.esperando_humanos'),
        //   10
        // );
      });
    } else if (this.LANG == 'EUS') {
      this.speechRecognizer.uploadAudioEUS(base64).subscribe(async (result) => {
        console.log('RESULTADO SUBIDA EUS');
        console.log(result);
        if (!result.error) {
          this.STATE = this.STATE_THANKS_FOR_COMMING;
          this.transcripcion = result.palabras;

          this.message = this.translateService.instant('mensajes.gracias');
        } else {
          this.STATE = this.STATE_WAITING_HUMANS;
          return;
        }
        await this.delay(7);
        this.STATE = this.STATE_WAITING_HUMANS;
        // this.esperarYMensajeEstado(
        //   this.translateService.instant('mensajes.esperando_humanos'),
        //   10
        // );
      });
    }
  }
  /**
   * Process Error.
   */
  errorCallback(error) {
    this.error = 'Can not play audio in your browser';
  }

  // esperarYMensaje(mensaje: string, segundos: number) {
  //   let seconds: number = segundos;
  //   var messageTimer = setInterval(() => {
  //     if (this.secondsCuentaAtras <= 0) {
  //       this.message = mensaje;
  //       clearInterval(messageTimer);
  //     } else {
  //       this.secondsCuentaAtras -= 1;
  //     }
  //   }, 1000);
  // }

  // esperarYMensajeEstado(mensaje: string, segundos: number) {
  //   let seconds: number = segundos;
  //   var messageTimer = setInterval(() => {
  //     if (this.secondsCuentaAtras <= 0) {
  //       this.message = mensaje;
  //       this.STATE = this.STATE_WAITING_HUMANS;
  //       //this.transcripcion = '';
  //       this.recordingAudio = false;
  //       clearInterval(messageTimer);
  //     } else {
  //       this.secondsCuentaAtras -= 1;
  //     }
  //   }, 1000);
  // }

  ngOnDestroy() {
    console.log('Saliendo de el detector');
    if (this.timerFaceRecognition) {
      clearInterval(this.timerFaceRecognition);
    }
  }
  //End

  changeLANG() {
    if (this.LANG == 'ES') {
      this.LANG = 'EUS';
    } else {
      this.LANG = 'ES';
    }
  }


  delay(seconds: number) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  }
}
export interface Prediction {
  className: string;
  probability: number;
}
