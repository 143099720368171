import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idioma.service';
import {  SpeechrecognizerService } from "@app/_services/speechrecognizer.service";
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Palabra,Derivada, Categoria } from "@app/_models/palabras";

@Component({
  selector: 'app-categorias-crear',
  templateUrl: './categorias-crear.component.html',
})
export class CategoriasCrearComponent implements OnInit {

  myWord:Palabra=new Palabra();
  word_es:string='';
  word_eu:string='';

  public seleccionados: number[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private speechRecognizerService: SpeechrecognizerService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.translate = translate;
    //this.menuService.titulo = this.translate.instant('palabras.cabecera');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
  }

  btnCrearCategoria(){
    if((this.word_es.length<=0 && this.word_eu.length<=0)){
      this.alertService.info(
        this.translateService.instant('palabras.validacion')
      );
      return;
    }
    console.log('Creando categoria...');
    this.myWord.word_es = this.word_es;
    this.myWord.word_eu = this.word_eu;

    this.speechRecognizerService.createCategoria(this.myWord).subscribe((result)=>{
      if(!result.error){
        this.alertService.success(
          this.translateService.instant('palabras.success')
        );
        this.router.navigate(["categorias"]);
      }
      else{
        this.alertService.error(
          this.translateService.instant('palabras.error')
        );
      }
    });

  }

  btnCancelar(){
    this.router.navigate(["categorias"]);
  }

}
