import { CompileNgModuleMetadata } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Palabra } from '@app/_models/palabras';
import { Registro } from '@app/_models/registro';
import { SpeechrecognizerService } from '@app/_services/speechrecognizer.service';
import {
  CloudData,
  CloudOptions,
  ZoomOnHoverOptions,
  TagCloudComponent,
} from 'angular-tag-cloud-module';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-pantalla-secundaria',
  templateUrl: './pantalla-secundaria.component.html',
})
export class PantallaSecundariaComponent implements OnInit {
  listaPalabras: Palabra[] = [];
  listaRegistrosAprobados: Registro[] = [];
  SECONDS_RELOAD_WORDS:number=30;//numwords*2 + 10
  SECONDS_PER_WORD:number=2;

  @ViewChild(TagCloudComponent) tagCloudComponent: TagCloudComponent;
  options: CloudOptions = {
    // if width is between 0 and 1 it will be set to the width of the upper element multiplied by the value
    width: 1000,
    // if height is between 0 and 1 it will be set to the height of the upper element multiplied by the value
    height: 400,
    overflow: false,
    delay: this.SECONDS_PER_WORD *1000,
    randomizeAngle: true,
  };
  zoomOnHoverOptions: ZoomOnHoverOptions = {
    scale: 1.3, // Elements will become 130 % of current zize on hover
    transitionTime: 1.2, // it will take 1.2 seconds until the zoom level defined in scale property has been reached
    delay: 0.8, // Zoom will take affect after 0.8 seconds
  };

  data: CloudData[] = [
    // {text: 'Sostenibilidad', weight: 18, link: 'https://google.com', color: '#ffaaee',rotate:10},
    // {text: 'Loteria', weight: 8, link: 'https://google.com', tooltip: 'display a tooltip',rotate:-20},
    // {text: 'PRUEBA1', weight: 12, link: 'https://google.com', tooltip: 'display a tooltip',rotate:-20},
    // {text: 'Navidad', weight: 13, link: 'https://google.com', tooltip: 'display a tooltip'},
    // {text: 'Navidad', weight: 14, link: 'https://google.com', tooltip: 'display a tooltip'},
    // {text: 'Navidad', weight: 15, link: 'https://google.com', tooltip: 'display a tooltip'},
    // {text: 'Navidad', weight: 16, link: 'https://google.com', tooltip: 'display a tooltip'},
    // {text: 'Navidad', weight: 17, link: 'https://google.com', tooltip: 'display a tooltip'},
    // {text: 'Navidad', weight: 18, link: 'https://google.com', tooltip: 'display a tooltip'},
    // {text: 'Navidad', weight: 19, link: 'https://google.com', tooltip: 'display a tooltip'},
    // {text: 'Navidad', weight: 10, link: 'https://google.com', tooltip: 'display a tooltip'},
    // {text: 'Navidad', weight: 11, link: 'https://google.com',color: '#f34343', tooltip: 'display a tooltip'},
    // {text: 'Casa', weight: 12, color: "#286d00", tooltip: ''},
    // ...
  ];

  constructor(private speechRecognizerService: SpeechrecognizerService) {}

  ngOnInit(): void {
    this.speechRecognizerService.getTopPalabras(50).subscribe((result) => {
      console.log(result);
      this.listaPalabras = result.data;

      this.listaPalabras.forEach((palabra) => {
        this.data.push({ text: palabra.word_es });
      });
    });

    //Recharging service
    setInterval(() => {
      this.getNewData();
    }, this.SECONDS_RELOAD_WORDS * 1000);
    //

    this.speechRecognizerService.getAprobados().subscribe((result) => {
      console.log(result);
      this.listaRegistrosAprobados = result.data;
      console.log(
        'Número de aprobados = ' + this.listaRegistrosAprobados.length
      );
      if (this.listaRegistrosAprobados.length <= 0) {
        let myRegistro: Registro = new Registro();
        myRegistro.transcripcion = 'Aún no hay audios aprobados';
        this.listaRegistrosAprobados.push(myRegistro);
      }
    });
  }

  getNewData() {
    console.log('Recharging Data...');
    //this.data=[];
    let datos: CloudData[] = [];
    this.speechRecognizerService.getTopPalabras(50).subscribe((result) => {
      this.listaPalabras = result.data;
      this.listaPalabras.forEach((palabra) => {
        datos.push({ text: palabra.word_es, weight: palabra.puntuacion });
      });
    });
    const changedData$: Observable<CloudData[]> = of(datos);

    changedData$.subscribe((res) => (datos = res));
    this.tagCloudComponent.reDraw();
  }
}
