<div class="card">
  <div class="card-header">
    <h3>{{ "registro.titulo_registros" | translate }}</h3>
  </div>
  <div class="card-body">
  <kendo-grid
  [kendoGridBinding]="listaAudios"
  [sortable]="true"
  kendoGridSelectBy="id"
  [navigable]="true"
  [filterable]="true"
  (cellClick)="cellClick()"
  [selectedKeys]="seleccionados"
  [pageSize]="15"
  [pageable]="true"
>
  <ng-template kendoGridToolbarTemplate position="top">
    <button
      kendoButton
      (click)="onClickEditar()"
      class="btn mr-1 btn-success btn-sm mr-1"
    >
      {{ "botones.editar" | translate }}
    </button>
    <!-- <button
      kendoButton
      (click)="onClickNuevo()"
      class="btn mr-1 btn-primary btn-sm mr-1"
    >
      {{ "botones.nuevo" | translate }}
    </button> -->
    <button
      kendoButton
      (click)="onClickEliminar()"
      class="btn mr-1 btn-danger btn-sm mr-1"
      [disabled]="isDeleting"
    >
      {{ "botones.eliminar" | translate }}
    </button>
  </ng-template>
  <kendo-grid-checkbox-column
    width="2%"
    showSelectAll="true"
  ></kendo-grid-checkbox-column>
  <kendo-grid-column
    width="35%"
    [style]="{ 'text-align': 'left' }"
    field="transcripcion"
    title="{{ 'registro.transcripcion' | translate }}"
  >
  </kendo-grid-column>
  <kendo-grid-column
    width="20%"
    [style]="{ 'text-align': 'left' }"
    field="detectadas"
    title="{{ 'registro.detectadas' | translate }}"
  >
  </kendo-grid-column>
  <kendo-grid-column
  width="4%"
  [style]="{ 'text-align': 'left' }"
  field="fiable"
  title="{{ 'registro.fiabilidad' | translate }}"
>
</kendo-grid-column>

<kendo-grid-column field="aprobado" title="Aprobado" width="5%">
  <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.aprobado" disabled/>
  </ng-template>
</kendo-grid-column>



  <kendo-grid-column
    field="updated"
    title="{{ 'registro.fecha' | translate }}"
    width="15%"
  >
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-date-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.updated | date: "dd/MM/yyyy" }}
    </ng-template>
  </kendo-grid-column>

  <!-- <kendo-grid-column
    width="20%"
    [style]="{ 'text-align': 'left' }"
    field="activa"
    title="{{ 'organizaciones.activo' | translate }}"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <input
        type="checkbox"
        [checked]="dataItem.organizacionActiva"
        disabled
      />
    </ng-template>
  </kendo-grid-column> -->
</kendo-grid>
  </div>
</div>