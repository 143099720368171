<!--<div *ngFor="let video of videos">
  <video controls [src]="video" controlsList="nodownload" style="width: 720px;"></video>
</div>-->

<style>
    body {
      background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.85));
      background-repeat: no-repeat;
      min-height: 100%;
      background-position: 100%;
      background-size: cover;
    }
  
    #parallelogram {
      width: 1200px;
     height: 500px;
      background: #cdcdcd;
    }
  
    #resCanvas {
      text-align: center;
      margin: auto;
    }
  
    .estilo-1 {
      color: #e91e63;
      font-size: 56px;
      font-family: 'Montaga', serif;
    }
  
    .estilo-2 {
      font-size: 80px;
      color: #9c27b0;
      font-family: 'MonteCarlo', cursive;
    }
  
    .estilo-3 {
      font-size: 30px;
      color: #3f51b5;
      font-family: 'Montserrat', sans-serif;
    }
  
    .estilo-4 {
      font-size: 40px;
      color: blue;
      font-family: 'Open Sans', sans-serif;
    }
  
    .estilo-5 {
      font-size: 25px;
      color: #00bcd4;
      font-family: 'Open Sans', sans-serif;
    }
  
    .estilo-6 {
      color: #ffc107;
      font-size: 25px;
      font-family: 'Montaga', serif;
      transform: skew(200deg);
    }
  
    .estilo-7 {
      font-size: 30px;
      color: #009688;
      font-family: 'Open Sans', sans-serif;
    }
  
    .estilo-8 {
      font-size: 30px;
      color: #3f51b5;
      font-family: 'Open Sans', sans-serif;
    }
  
    .estilo-9 {
      font-size: 20px;
      color: #cddc39;
      font-family: 'Montserrat', sans-serif;
    }
  
    .estilo-10 {
      font-size: 40px;
      color: #00bcd4;
      font-family: 'MonteCarlo', cursive;
      background-color: red;
      display: block;
    }
  
    .destacado {
      font-size: 120px;
      color: black;
    }
  
    li a {
    }
  
    marquee.letrero {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
     height: 60px;
      background-color: #e0e0e0;
      font-size: 50px;
    }
  
    /* make keyframes that tell the start state and the end state of our object */
    @-webkit-keyframes fadeIn {
      from {
        opacity: 0;
        -webkit-transform: scale(0.1);
      }
      to {
        opacity: 1;
        -webkit-transform: scale(1.0);
      }
    }
  
    @-moz-keyframes fadeIn {
      from {
        opacity: 0;
        -webkit-transform: scale(0.1);
      }
      to {
        opacity: 1;
        -webkit-transform: scale(1.0);
      }
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        -webkit-transform: scale(0.1);
      }
      to {
        opacity: 1;
        -webkit-transform: scale(1.0);
      }
    }
  
    .fade-in {
      opacity: 0; /* make things invisible upon start */
      -webkit-animation: fadeIn cubic-bezier(0.585, 0.070, 0.455, 1) 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
      -moz-animation: fadeIn cubic-bezier(0.585, 0.070, 0.455, 1) 1;
      animation: fadeIn cubic-bezier(0.585, 0.070, 0.455, 1) 1;
      -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-duration: 1.3s;
      -moz-animation-duration: 1.3s;
      animation-duration: 1.3s;
      -webkit-animation-delay: 1.3s;
      -moz-animation-delay: 1.3s;
      animation-delay: 1.3s;
    }
  </style>
  
  <div id="parallelogram">
    <canvas id=resCanvas width="2000" height="600" style="height: 100%; width: 100%" class="fade-in"></canvas>
  </div>
  
  <ul id="demoTags">
    <li><a id="palabra1" href="javascript:void(0);" class="estilo-1 " data-weight="0">Copyright</a></li>
    <li><a id="palabra2" href="javascript:void(0);" class="estilo-2" data-weight="0">Trademark</a></li>
    <li><a id="palabra3" href="javascript:void(0);" class="estilo-3" data-weight="43">Patent</a></li>
    <li><a id="palabra4" href="javascript:void(0);" class="estilo-4" data-weight="20">Tort</a></li>
    <li><a id="palabra5" href="javascript:void(0);" class="estilo-5" data-weight="0">Antitrust</a></li>
    <li><a id="palabra6" href="javascript:void(0);" class="estilo-6" data-weight="0">Year</a></li>
    <li><a id="palabra7" href="javascript:void(0);" class="estilo-7" data-weight="0">Month</a></li>
    <li><a id="palabra8" href="javascript:void(0);" class="estilo-8" data-weight="43">Day</a></li>
    <li><a id="palabra9" href="javascript:void(0);" class="estilo-9" data-weight="20">Night</a></li>
    <li><a id="palabra10" href="javascript:void(0);" class="estilo-10" data-weight="0">Sky</a></li>
    <li><a id="palabra11" href="javascript:void(0);" class="estilo-1" data-weight="0">Bankruptcy</a></li>
    <li><a id="palabra12" href="javascript:void(0);" class="estilo-2" data-weight="0">River</a></li>
    <li><a id="palabra13" href="javascript:void(0);" class="estilo-3" data-weight="0">Country</a></li>
    <li><a id="palabra14" href="javascript:void(0);" class="estilo-4" data-weight="43">Mountain</a></li>
    <li><a id="palabra15" href="javascript:void(0);" class="estilo-5" data-weight="20">Mouse</a></li>
    <li><a id="palabra16" href="javascript:void(0);" class="estilo-6" data-weight="0">Road</a></li>
    <li><a id="palabra17" href="javascript:void(0);" class="estilo-7" data-weight="0">Dog</a></li>
    <li><a id="palabra18" href="javascript:void(0);" class="estilo-8" data-weight="0">Bike</a></li>
    <li><a id="palabra19" href="javascript:void(0);" class="estilo-9" data-weight="0">Car</a></li>
    <li><a id="palabra20" href="javascript:void(0);" class="estilo-10" data-weight="23">Cat</a></li>
    <li><a id="palabra21" href="javascript:void(0);" class="estilo-1 " data-weight="0">Spider</a></li>
    <li><a id="palabra22" href="javascript:void(0);" class="estilo-2" data-weight="0">Cow</a></li>
    <li><a id="palabra23" href="javascript:void(0);" class="estilo-3" data-weight="43">Elephant</a></li>
    <li><a id="palabra24" href="javascript:void(0);" class="estilo-4" data-weight="20">Lion</a></li>
    <li><a id="palabra25" href="javascript:void(0);" class="estilo-5" data-weight="0">Tiger</a></li>
    <li><a id="palabra26" href="javascript:void(0);" class="estilo-6" data-weight="0">Bird</a></li>
    <li><a id="palabra27" href="javascript:void(0);" class="estilo-7" data-weight="0">Cloud</a></li>
    <li><a id="palabra28" href="javascript:void(0);" class="estilo-8" data-weight="43">Tree</a></li>
    <li><a id="palabra29" href="javascript:void(0);" class="estilo-9" data-weight="20">Flower</a></li>
    <li><a id="palabra30" href="javascript:void(0);" class="estilo-10" data-weight="0">Space</a></li>
    <li><a id="palabra31" href="javascript:void(0);" class="estilo-1" data-weight="0">Moon</a></li>
    <li><a id="palabra32" href="javascript:void(0);" class="estilo-2" data-weight="0">Earth</a></li>
    <li><a id="palabra33" href="javascript:void(0);" class="estilo-3" data-weight="0">Planet</a></li>
    <li><a id="palabra34" href="javascript:void(0);" class="estilo-4" data-weight="43">Asteroid</a></li>
    <li><a id="palabra35" href="javascript:void(0);" class="estilo-5" data-weight="20">Star</a></li>
    <li><a id="palabra36" href="javascript:void(0);" class="estilo-6" data-weight="0">Sun</a></li>
    <li><a id="palabra37" href="javascript:void(0);" class="estilo-7" data-weight="0">Galaxy</a></li>
    <li><a id="palabra38" href="javascript:void(0);" class="estilo-8" data-weight="0">Eyes</a></li>
    <li><a id="palabra39" href="javascript:void(0);" class="estilo-9" data-weight="0">Finger</a></li>
    <li><a id="palabra40" href="javascript:void(0);" class="estilo-10" data-weight="23">Foot</a></li>
  </ul>
  