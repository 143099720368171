<div class="pantalla-nocturna"></div>
<img class="gipuzkoa-argitu-logo" src="../../../assets/img/gipuzkoa-argitu-logo-02.png" />


<div class="frase-nocturna"><span>{{fraseActual}}</span></div>

<img class="logo-gipuzkoaondas-nocturna " src="../../../assets/img/gipuzkoaondaslogoizq.png" />
<marquee class="letrero"
         scrolldelay="100"
         scrollamount="20"
         loop="infinite"
         direction="left"
         width=""
         bgcolor="#FFFFFF">
  <span>
    <span class="frase1">
      Hauek dira gipuzkoarron etorkizuneko nahiak. Zatoz bihar eta partekatu zurea.
    </span>
      <span class="frase2">
        Estos son los deseos de las y los guipuzcoanos. Anímate y comparte el tuyo mañana.
      </span>

  </span>
</marquee>



