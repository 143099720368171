<!-- <div style="position: absolute; top: 0; height: 830px; width: 30px; background-color: transparent"></div> -->

<img (click)="setFullScreen()" class="gipuzkoa-argitu-logo" src="../../../assets/img/gipuzkoa-argitu-logo-01.png" />
<div class="webcam">
  <div [ngClass]="STATE!=STATE_WAITING_HUMANS ? 'webcam-transparente' : '' " >
  <video
         autoplay
         playsinline
         muted
         id="webcam"
         width="300"
         
         #video></video>
</div>
</div>

<div  (click)="changeLANG()" class="gipuzkoaargitu"><span>#GIPUZKOAARGITU</span>  <span class="idioma-mini">{{LANG}}</span></div>


<!--<div class="mezua"><span>{{ message }}</span></div>-->


<!--<h2>{{ transcripcion }}</h2>-->

<ng-template [ngIf]="STATE == STATE_LOADING_NEWRAL_NETWORK">
  {{"mensajes.esperando" | translate}}

  <!--<div class="pantalla-tablet-1"></div>-->

  <img class="img-tablet-1" src="../../assets/img/ikono-tablet-01.png" alt="Alternate Text" />

    <!-- <button class="btn" (click)="changeLANG()">Cambiar Idioma</button>
  <span>{{LANG}}</span> -->

</ng-template>

<ng-template [ngIf]="STATE == STATE_WAITING_HUMANS">

  <!--<div class="pantalla-secundaria"></div>-->
  <!--<div class="mezua">
    <span>
      {{ "mensajes.esperando_humanos" | translate }}
    </span>
  </div>-->

  <img class="img-tablet-1" src="../../assets/img/ikono-tablet-01.png" alt="Alternate Text" />
</ng-template>

<ng-template [ngIf]="STATE == STATE_HUMAN_DETECTED">


  <div class="mezua">
    <span>
      <!-- {{ "mensajes.hola" | translate }} -->
      <strong> Kaixo, zein da zure nahia? </strong> <br> Hola!, ¿cuál es tu deseo? 
    </span>
  </div>

</ng-template>

<ng-template [ngIf]="STATE == STATE_SPEAK_COUNTDOWN">
  <div class="cuenta-atras">

    <div>
      {{ secondsCuentaAtras }}
    </div>

  </div>


</ng-template>

<ng-template [ngIf]="STATE == STATE_SPEAKING">


  <div class="mezua-2">
    <span>
      <!-- {{ "mensajes.comienza_hablar" | translate }} -->
      <strong>Esan zure mezua </strong><br> Di tu mensaje
    </span>
  </div>


  <div class="ecualizador">
    <div class="peak grad1 p1 peak1"></div>
    <div class="peak grad1 s1"></div>
    <div class="peak grad2 p2 peak1"></div>
    <div class="peak grad2 s2"></div>
    <div class="peak grad3 p3 peak1"></div>
    <div class="peak grad3 s3"></div>
    <div class="peak grad4 p4 peak1"></div>
    <div class="peak grad4 s4"></div>
    <div class="peak grad5 p5 peak1"></div>
    <div class="peak grad5 s5"></div>
    <div class="peak grad6 p6 peak1"></div>
    <div class="peak grad6 s6"></div>
    <div class="peak grad7 p7 peak1"></div>
    <div class="peak grad7 s7"></div>
    <div class="peak grad8 p8 peak1"></div>
    <div class="peak grad8 s8"></div>
    <div class="peak grad9 p9 peak1"></div>
    <div class="peak grad9 s9"></div>
    <div class="peak grad10 p10 peak1"></div>
    <div class="peak grad10 s10"></div>
    <!--<div class="shadow"></div>-->
  </div>

  <div class="barraprogreso-cont">

    <span class="tiemporestante">Gainerako denbora / Tiempo restante</span>
    <div class="barraprogreso">
      <div class="barraprogresoinner">

      </div>
    </div>
  </div>



</ng-template>
<ng-template [ngIf]="STATE == STATE_ANALYZING_AUDIO">
  <div class="mezua">

    <div>
      <!-- {{ "mensajes.analizando" | translate }} -->
      <strong>Zure nahia aztertzen</strong> <br> Analizando tu petición  
    </div>

  </div>
</ng-template>
<ng-template [ngIf]="STATE == STATE_THANKS_FOR_COMMING">


  <div class="mezua-3">

    <span>
     <strong>Eskerrik asko gipuzkoarron nahien mapan zure ekarpena egiteagatik. </strong> <br>Gracias por tu aportación al mapa de deseos de los guipuzcoanos.
    </span>

    <div class="transcripcion">{{ transcripcion }}</div>

  </div>



</ng-template>

<div class="cont-cambiardidioma">



  <!-- <button (click)="stopRecording()">Parar grabar audio</button> -->
  <!-- <button class="btn" (click)="changeLANG()">Cambiar Idioma</button>
  <span>{{LANG}}</span> -->
</div>
