<div class="bgprincipal"></div>

<!--<video autoplay [muted]="true"  loop id="myVideo">
  <source src="https://media.istockphoto.com/videos/beautiful-abstract-wave-technology-background-with-blue-light-digital-video-id1165470274" type="video/mp4">
  Your browser does not support HTML5 video.
</video>-->

<video autoplay [muted]="true" loop id="myVideo">
  <source
    src="https://forualdundia.zitu.net/HD_bg_video.mp4"
    type="video/mp4"
  />
  Your browser does not support HTML5 video.
</video>

<div class="mapa">
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="1011.189px"
    height="806.2px"
    viewBox="0 0 1011.189 806.2"
    enable-background="new 0 0 1011.189 806.2"
    xml:space="preserve"
  >
    <g opacity="0.5">
      <g>
        <image
          overflow="visible"
          opacity="0.25"
          width="1039"
          height="831"
          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABBAAAANACAYAAACSTJ6JAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAABC+1JREFUeNrsvYt24sqybRsS4Kq5
z/9/6l2rbEC6p852zgpCIyITG7vA7r01NfzkKaUyu+JhBgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAADw95l4CwAAAAAAAOARWNdVrmGnaVp5dz4eBAIAAAAAAADcJZkw6C50EQofAgIBAAAA
AAAA7gohDkbXrhfiAJFwWxAIAAAAAAAAcBcEcTAlt+m/J7eIhBuBQAAAAAAAAIC/SiEOJvG9vAu3
qe+RCDcAgQAAAAAAAAB/DScPlDho22xbmRBFgd8WIRSQCAgEAAAAAAAAeGBxUEmDWXwd17FNGCxO
HMSvkQg3YM9bAAAAAAAAAHciD7ww2IVbJRGiPDi/bu33y+v/LLzrCAQAAAAAAAB4fHHQNi8N2rZ3
X0eBEMXB6fVvTu5xFi8cfj8HohAQCAAAAAAAAPB48mAO8mDvxMHh9VZJhFbjoImD39vxdVOpDgsS
AYEAAAAAAAAA9y8NojjwAsFHHBycPPi9Pbnvo0Bo0QdNHPjfm10WVvQ/AwQCAAAAAAAA3KE0iLcx
6mAXpEETB35rYiEKhCYPngt5EEUCUQgIBAAAAAAAALgjadC+zjos7IQ8aMLgh9vaz1oqQxMIZycP
moww2xZXXIJEAAQCAAAAAAAA/CVxoKSBJeKgkgdNGvwMt0ogtLoHKjKhFVZc3G3s4AAIBAAAAAAA
APgEaWBCFERpEMXBiDxo4sBvLY2hCYTlVSC82DYq4eS2s3vcxf0dIBAAAAAAAADgE6VBJgyqqIMm
D/ZODFTyoP1cCYQWfeDlwTFssf3jv6+LOggIBAAAAAAAALidMDDr1zXw4kBFHMxBHPSiD34MCoRW
98DLg5fXbW+XnRn8hjhAIAAAAAAAAMANhIGSBmZ5eoLvqhClgY86iK0aY7eFKBF8IcX2f00g7F6f
k09nOLjH2YXnFF8PEgGBAAAAAAAAAG+QBpkwyMSBkgZq29tl9EEUCP72h+kWjm1rj+frGZzC37TH
mYM82KQxAAIBAAAAAAAAbiMMzMZSFLwo8LJApS3E20Px/U5ICv/czuH+4/NAHCAQAAAAAAAA4A3i
YOoIg0waVOIgEwaHsLjfJ7/bJQJgEs/TP7fVtqkKMWVhTl4r6QsIBAAAAAAAAKTBgDQY7aAQb2Nt
A7/oPxSSIG7tf2bbRhnM/uW8bov72oRMiIIjvhYlIgCBAAAAAAAAgDiwOrpgtn4HBdWGMcqD/RXS
ICtumNUoaOJgCQKhkghqAwQCAAAAAAAACHkwEmGQ1TOIsiDrqNCLPsikwc76hQ1/C4JzkB07JxJm
8T/xfqbid4BAAAAAAAAAQByEhbdqt5hFFcR0gl3xvequoIoY9sSBiirwr2M2HYVg4X+oaYBAAAAA
AAAAgAFxYNbvltCLKLjVFiMYvDjwC361eflhdhl9oGTDyNcIBgQCAAAAAADAt5UGShxMhTCIi/sY
JaC6IcS/y1IblCjwkQZmunaBEgjtf1oXhqr2wVrcjxX/BwgEAAAAAACALy0MKnEwWV3scJeIAZV+
oNIR/P2qIoxZLYMs3SBb9PsuDPOAAFg7GyAQAAAAAAAAvo04mDryoC3m2wJfyYCeJBhptThbXpBw
fcfmX1sTHO1rE6+3etwFkYBAAAAAAAAA+E7iYBq49dEAMR3Bt1Ucaa2Y/UwVP2wL8iUs2luhw7Nd
Fj5Uf6daMe7cOjXKg9nytAj1OFXBRUAgAAAAAAAAfClxkKUqqHoHmTg4FBKhSmuIdRNiAcTF/tQo
+L2dXsVBu/WbkglreG3tcVf7k7oQRUkvCiETB+prQCAAAAAAAAA8tDyYOsKgJw+8MHhKBELWNSH7
uWq5uDhh8Hs7hlsvFHxUQiyY2F6DlweLEAyxSOMq5AGpCwgEAAAAAACALy0PVFpCDNtXX8e0BSUP
noQ88GIg66JgboFuHXGgtlMQCSo6wMuDFtGwEwJBpS+YXVdjAZmAQAAAAAAAAHhYcWBWpyWoVomx
jWKTAk0SRHGg5EF7HLNtTYFFCIP4fZQHL6/b0d16edA2FX2wd4+xC6LBhGCJdRgymWBCggACAQAA
AAAA4CHFgdm2GGKWTpClHIzUPchqGZjpTgiq1oCvMRDlQZQISiBk0QdnJxT2yd+a9bs/AAIBAAAA
AADgS8oDFXWQdVLYma5fEH93CLd7q4sgqtoBVizUffrC0XQEQi99wUuTnf2pe7CzbdHFLCVhYo9C
IAAAAAAAAHwHcWCWRx2oaAIlBVTXhL0QB7N7/LYozzokVCkA19Y/iKkLS3gfZrtMXdjbdR0crok6
mJAOCAQAAAAAAIBHEQd+IevlQVYIUdUyOFjealG1XYy1DaIA6BU5XMX/+5aNqvNCdZ9reA+aSIld
G7xIUPdnplMclCxAHCAQAAAAAAAAHkYeVEUSfRFELw/UpqIQZtPFEf3CfzWdenAUC/Ssg4GKXsgW
/SqywYJA8IUUoyyoOjrMQSCsiSxAHCAQAAAAAAAAHlIcxHSF2bYFEJso+OFuf9g2CsHLA5+m0B7f
L/Z90cMX29YrOFodMRA3H8mgtqWQB/59sXA/WWeHth3ca27y4ZqUBgotIhAAAAAAAADuSh700hVi
1EFsvfgjbCoCwcuDqjhiW5i3RfhzuB0VCOq+vUhQ0kDJAxPPVRVm9M/Xp27MQiBkEsE/Nm0dEQgA
AAAAAAB3Iw68NFDpClmtgyry4EciD3a2rXHQIg7awvzk5MGz2NoifaQOQvx6SYSC/322eM8Egpcd
+yAPojhZ3W2VegEIBAAAAAAAgLuSByrqQNU5iB0WYo0DFXUQax/4yAO/WFZX85ss+PW6RYHg6wss
1i+kaFanN4ws4pWMaM95Z38iEPamu0q01717vZ9eNwn1WgCBAAAAAAAA8KniwEuDLOpAFUk8CHGg
pIG6+j6LBbivc9AW4FEe/LLrog+ydIBMJqjf2zRNa/K+tefe3rcmEWbbtqSMRSPX159NNt6SMn2O
gEAAAAAAAAD4THmg0hVUxMEhEQZKGrSFc2zHqORBLJT4y/Log1j74NxZdJv4fi1+diEO/M9e3z//
u0UIhBY9sTMdhdD+b7bLbhD+dfRqMAACAQAAAAAA4FPlQQur9y0VozjwBRJV1IGSBn6x3HL9LZEH
ZycFfKHEKA68PDheKQ+GfqbEQUci+EiEk10WnHwxHYXgBcJqY5EU1EZAIAAAAAAAAHyqPPC3WdRB
lqbwI5EHKs9/Mt1C0RcOzNofeokQax4cxYK7ql3QXXT3pEHvrTWdynAUMiZGZHiB0KuDAAgEAAAA
AACAT5MH5hauk227K6iIA7XFqAMlDmI7Rt+twG9RIPg0Br9laQvLNQvtd8qCf+8jSWVoYsYXVPSv
6fB62yTCLgiVa17LhFhAIAAAAAAAAHwUseZBFXXQZMHPcOsjD2JLxqy1oRcF5+T2aNtIhKPpqIMy
8uAWkuANEkF1ZdgLidAiEvz/xteTfWa+9eX/Puj/fQ6f8XoRCAAAAAAAAF+ckLrg0xaiPFDiIMqD
H0EezLYtkJhFFZzEpgTDKfn7s+lCg+2x7bMX0kEi+FSGWN9BvXYfseGjDzJpkEmEFYmAQAAAAAAA
APhMefBDyAO/xZoHbQEcow5UGkL7vpICJ9NRC6rF4aZDwR0soFe7rPugIjH85tta+ueedcVoXy/u
FomAQAAAAAAAALg5cWGq5IEXBv+YjjyI8qAtlk+WF0D0KQlRDixBEsSvozTYhPr/zYWzi0IwIQ+y
ug/+dcXPKNal2DvhULV2RCIgEAAAAAAAAK5HRB9EeRAFwlvkQRMHvnvCL9u2X8yKIKrIgqUjDe5G
HsS3PEiESiS073fJ5+OjQ1othbNtayRECfGvzEAkIBAAAAAAAACuIVuc7qxf96Btqv2gXxS/CHkQ
JcJLRx6siSxQXQnuJWXBvMQQnS56XSjaa/e1EGLbx9iqMmMRIgMQCAAAAAAAADVhMZtd2W5b1qox
tmg0u7yq3nL5X5ws+K8QCD76ICuEuBab2f1GHci33/IIhMW0PDH7UwuhyZ2sVaX/XP3tRVFJ0hkQ
CAAAAAAAAKPEqv1KIjzZZRRC+7pdBW9Xx2NUgC+W2KIMmjT4r23TF7IWjEshCmSu/4MsilchElSh
yL37m/YZ7S2PvlDi4Oxu6c6AQAAAAAAAABhcuW6jD7LaB1EgeHkQ0xXarV8I+y4Lv2xb/8B3YKjk
QSYLHlUcVJ0YfDcG/7PZfU4mpIH/LGMrR5U6Id9DBAIAAAAAAABYsdisWjf6TaUsnN2itC12j3YZ
fRA7Lih5cLJtYcQy0uCBpMG/z/VV3lQS4WS6naN/z+dkravkgQXhsIlYIAoBgQAAAAAAALAhiT6Y
hDyIEQixUGLM3/cL3iYGXsIWxYGKPMjkwUNKg+qjsG36ghcIR/sTDeIFQrudOltPHqTvLwIBAAAA
AAAAeZC1bfTyIKYvHEx3WVjDovcYpIH6+hg230HgbPffhvGW8kBJGB/BsU8Ego9C8GkNc5AHqkij
32IkBCAQAAAAAAAANsTUhaz7gpcHvmBiW5j6iAMfZXAM4uAUpIFq1XjRIeCLioNKIjQR02TBUYiD
XfjMlDxQAiHWUpiDRCCNAYEAAAAAAADwupq8jD6IEmEk+qAtPNvC9OwEgapvEIVBJg6+lTxwdRDi
It9LBB8VErc5fB0lggkp4WspnE2nOQACAQAAAAAA4HINa7plYxV94Av4KXngOyt4gZBJg7LTwje5
Er52JMJRyIP2Ofn3bHafq/98Tq+f31EIh7iRwoBAAAAAAAAAkNEHsetCTF/Iah/EK9uZQKgKJEZx
8NXrHWwoohB8ikGUCL4mwsG9Z14I2ev9HF7/P6uhEKMPkAgIBAAAAAAAgM1CMYtA6MmDSciDqj1j
1V3huxRL7BGjEFYnEVrKQZX+YUEeZCkPWeQBIBAAAAAAAACkPDDLax/EFAafuhCjD2LHhbgdB+TB
vwtoivddRCFMtu3KcLa61eUUZIKvjVBJAyQCAgEAAAAAAOB1ZfonVD4uMH0Kg+q4oGof+M4LRyEN
Yt0DJQ/MvnfUwb+v+/Wz8d0YYj2EdeA9JPUAgQAAAAAAAHC79arV6Qt7Jw9U4URL5MExbEoeLIiD
Lk0iqJ/7r9fi//3XWZ2JVOQAAgEAAAAAAECJBB99sBcSwdc+aPKghdB7eRC7LWTyAHHw9s8qSp9Y
ENOLAP++x89hKeQBnwkCAQAAAAAAvjMifSFLYVBpC77FX6x9ECMQmjxIF6yIg3dJg1343LxIiAUY
vcgZKWQJCAQAAAAAAIDuonQv5EFWONGnL2RpC2exSEUeXPf5xM8oSp74GZmTA6fwOWXdGxb3+Hw2
CAQAAAAAAPjOdKIPRhemWfTBSMFEQx68SST0okOUPPDRB6o+Ra+oJZ/TKzNvAQAAAAAAfPNFqUpd
iIvSfbI4VdEHL6brHhAeP0gieGJ9ChUlsjcteeLn47tjZJ8TBIhAAAAAAAAAFqf9xanPs/cL07Nt
Uxd8iHyWY89V7TEmuyyMWHXI8MLHt35sgsBLg1ijwssDGYUARCAAAAAAAMD3lAfXXtWuog+y9IVU
HsCwPLAgD6bwWalUk0l8TjH6YDjNBBAIAAAAAACAPKgKJ6qr2n79tJhOYegVTST64G0iodclI3Zi
MKujRCp5cPngfFYIBAAAAAAA+PbywLcAVJEHBycSfHtAn1c/WtWfgnxvlwfxc4tRI/5rH7XgJc85
fF6qtSZpCwgEAAAAAABAHkh5oMTBb2Hw9LrFKIRJLEy9PKgK8rEwvY1M8J+f/xyjYFASwcuEWO9g
ZP9BIPAWAAAAAADAVxUHA/Jgl4gDv/n6B00EqCva7WdLtkAl+uBd4sCEKJgSebAm28Uu0nms9j0S
AYEAAAAAAABfWR50xEFMU3hy2w+7jEDw9Q98Xn0rzhclgirIhzj4hI89EQKZeJjFFiMc1D71baGN
IwAAAAAAfEVxYDZWLNFLgx9OHkSBMNm2KJ/a0mr+RB+8Swys4essumAVn39WeNGnMkSW179f/P2+
RyJ8hc8fgQAAAAAAAF9NHvjb2erogygPvETw6QurbYsnxtoHmTxAHNxWJKzh84jpIqvYB6I0ysSB
/7/FyQQrPstp4HlfyIdHlQkIBAAAAAAA+CrioC3mqnoHO7usb+ClwU/T6QvmFpKxon/WunETgQBv
lgaWiAP/Xvuv5yAP9qZTStR+cnz933MiL66VB+1v1std9n/32UcTCQgEAAAAAAD4SuLATEcd+GKJ
vu6Bkgc++kClLhzdNiQPSF94t0RQ8uBcvP8720Yf+M+gqn/gt7N4DkogTIX8yATEw4kEBAIAAAAA
ADyyPBjpshDlQSyYGGsf+MKJquvCsZAItG68rTjwXRWWIA/iZ+I/Ny+RrLOPVCKh/V97bBMCQUmE
Vdxm+8XDiAQEAgAAAAAAPKI4iAtCX++gLQr3hTz4EW59+8ZdWLj6yIMXt3mB4K9+07rxthJB1TyI
8uDwersT+8HOarm0C/+nohCUQFDyyhJxsCRfX4iG3/v3Pe8vCAQAAAAAAHhUcWC2TVeIBfN6rRp9
1EGMPFg68uDFaN34IfxeRLvPXKUvxMiDl44I2JnuxrCzbdRClFKnQiBMiUAwIT3aNgWRsFzu6vcr
ERAIAAAAAADwSOLAbBtx4EPRd0IcRHngNx914GsetMiDKA6iRMjSF/5dCPMpvm8XCAvuWP/gmIgD
v7/sbBuhUskDLxra/Z/DZxv3wSgRVMREvPWdHlYvF+5VIiAQAAAAAADg0cRBXOT1og4OhTjYh8Wn
v8rt5cGz29rPojwg+uDzJMLJxmoXmFjwm1j8Z9EHu/AZW/j/Ofy/CYEQazac7U9kQ/uf1j3iriUC
AgEAAAAAAO5RGlTiwC/udjaWsuBFQvsbH3VgbsHXxEAUB14gqPoHRB/ckCSNwV+9PyUCIcoHv/5t
n3dV8yCmOagUBpU6M9llBMsq5IGPWmmvwYREuEsQCAAAAAAAcG/yoCcO5o44UJEH/ncx5N3CYq/J
gSgOftk2fUEVT0Qc3Hj3cAvstl+cra49YJZ3PfBFFXdhn7Pwu737nKs2kLHjQ4yW8OkWR9tGLHiJ
MP05LO4rCgGBAAAAAAAA9yQOqjoHvaiDp+RWpSvMYZHprxC31AUVefAc5IFq3Uj0wY0IUQh+YR4l
gglpoMRO23a2jWY5FAJh6QiEGAGhCj6+2DZlJhMfJr5GIAAAAAAAAOLgSnHgWzOqlAX/vZcMc1i4
xZaAPvLgRdxWrRsRBh+8y7ivl87fZd0P/Bbbdc5ufewFwin8X9xHewJhsctuEc+WRx+s4X/vrrUj
AgEAAAAA4PEW3P+7innAq9xXFEf0eeoq6sDLgygNfMTB3t1Htrg72WVrRi8MRrsuEH3wQbgohHhl
fhELcN8a8TwoEXxhxV34em/bDgxmW8EVCzTGGghRYkXhsYTnHmXEXYBAAAAAAAB4AFlwzd/d2wL2
iuKII+Ig1jrItp1bsPnFWBZ14EXBcxAKMeogrXuAPPg0iaCiEKrog3Pyddun4r5o7uu9XbZbVEUX
s1aO7fF8wU5/PCzi+c522XnibqIQEAgAAAAAAPe/0B6+i3g/n73oKOSHSllQXRVigcSdEAeZRKjS
FWJLvWMiD2LEQYw6OIdFH/Lg70uE1fqpC+fw+bevD69fx7oEft9c7TJqQO3HUyERVM2DtXhebfNd
He4CBAIAAAAAwH2JgyzEPy5cVKE1//M3y4TRCIjeei/5/triiCplIYqEvZMNsT1fDCU/DciDKA6q
bgvIg7902DiJoFo9jtRB8C0WvXiaxLGXLeTXYr+vukTEx2/72e71+zk5rhEIAAAAAADfXBqoBXa2
AFF50attW9dtJMMbxMBbRMJIusJoccQs6sD/jU9ViPnlcaFYiQOfrhDFwdnyav7Ig0+kvdeiJkIm
EkbkQYtEaKkGMXpFCQMlDqrCirtEHvgWj63jQ0yJuJs0BgQCAAAAAMDfEwdTZ5GdhUWrxUy1vVcC
jMqEkdc2Wx110Is08PUNVMSBhcXjSNSB/52XBz7E/KIyPvLg74uEIBG8NKs2ldLw+7P2HTsqiRCP
OSUQdsm+6espHJy4OIr9ebY7LKaIQAAAAAAA+LviQOVcq+rumUCIi6MYwh1FwjogAKZCHIzWOMiE
SKx10CuOWKUpqIr2KuogdlnICiQORx0gD+5SIjSWjkBQgulk284dcf8yq+Xc7CTBTgiJKdnv92L/
PtsdRiEgEAAAAAAA/p44yIoJzmGhrSTCSOE4JROURJgGb3tCoYo6UGkLvRoHlThQ70UWGv6S3B7d
37T/IergwSSCO85iNIJZHqWjBMLRdBSC2WVKkDqmpiAQDmHficeDOgaUHNtEIfzNIqkIBAAAAACA
j5cHVR2ALKx/7iyaTYiDs23b1C0diTBSe2EqJMJoGkavWOJHiINTIg2y7gpEHTywSBioi2Cmo1Ta
flAV4xwVCDv7k56wCnEQRUMvCmEOQsQf+5/ecQWBAAAAAADw8fIgW0xn9QBijr/KyV6TxVBcDJ8L
iWCdRf+UyIRrxMFI3YMYgRBfu2qNF8XJKciDWPNApSpk6QpEHTyoRHDHXhQJVT0ELxCy+gUWjjsl
EPzCfwliYbZcqEV5sLc/BR1VgdSySOpH7qsIBAAAAACAz5MHlTBQedFRJMSroWoRfTZ9Vf3s/sds
G4GgFjgjMiH+LLufmJaxL16zf729GgdnIQ5OptMUKnFA1MEXEglFbQQTx87O7Ud+Hx0RCBYEwt7t
S5PVBRWrWggnJxAsOX6VDPxQkYBAAAAAAAD4oHWMXXcFPrsSr0Kqs8X0KUiEaqEcn+vcuR0VC6oY
pL/dmS6kGH8+DYqDo7jNuipU7wdRB19bImRdGto+FcVB3M/9vmhi8T47ERGFgt/M8joILX2nFXSM
KRBVgdT4fNaPKLiIQAAAAAAAuCGiaGJ2xTErGljVAVD5/+pKfBQJMQphFYuTrCaDCsEekQ2zjReL
jP9v4jUu4vXFqAMVcXAW4uBcLMYMefBlJULDSyO//52t3/lEyQN/fK/u+0PY75Rw8ONBaycZ6yf0
pNcShMiHSQQEAgAAAADA7eWBEge7IAyewnZwt0ogxAXNkgiE84BAWOJaa2BhP/rzTBzEx1O3foGm
oiuyaAOVouBf/2J1ukJ7TMTB15UIZttoBH9VP5Necf9cw9f+OPf3E489Je3WMC54UWDhvpREUNvm
Od5SIiAQAAAAAAA+aO1iurVbEwY/3Pbkbr1AUIUETQiERUiEc7GAjosZMx1NkBV7jLe7RCJEKZBt
8QqqijjopSgck9efFUjctLREHnxdifC6kp4KATDZZcvETG7FBbo/zs3+1C6oOnu0/9mZbsHqoxj2
piNoVOFULxftIyQCAgEAAAAA4AZ0Cie2RfbByYKfYfPRCFXkgYXFdlus7F6/39t4S0cLi6SeQOh1
iqi6RWSLniWIgxhFcUy2GHWQdaCo8sYRB4gELxKsIw7k3YaF+1Icd4v4P194URVfVJE06jiZX2/b
fZ+F7Hg3CAQAAAAAgNuIg0wexFxnLxD+ed1+2mUEQluYVz3oW/52Y3Y/U/nR5SK6IxJ2lleNV6kW
s207Rai6Baq+wbEjDo5CHJyKBZt6vaQrIBKsKLJ4rUCYLU8piKIsRiGY2D991JKKKIqCzcsDL0UW
/xi3iEJAIAAAAAAAXC8KrFh4q64LXh4ogfDP689a6sJseftCv+BQhdmyivFmdeV2JRNiBMXOCYOY
bqHEx2qX9Qte7E+o+Mm9hqP7/Uv4fiTqYLFtNEP5GpEHEERCPAauEQmL26+zKAQlEvzxFYXEznRk
jT+ejqa7llh4DIooAgAAAAD8JWEwdQRCFvLviyf6FIYWgeCjD2KrxlUsQtQVTb/wV7ncmUhQRQWV
EMleS0y9mN3C6mx/rpKa/al0b04k/P798+v2IkRCViARcQA3EwnJsT+yv0ziePLiIIoAJRHMtrUP
oiDzAuHFHWuq5eksxgZqIAAAAAAAfLA4mAa/Hum8EDsw+MX3U1h8Z+H/PhdatZLrdUawZKETw/7N
8p73fpETi0PuhSDwUQdKHvxeDP0SEiFLV1gKkYI4gJsKhYGxQgm5rLVqixqYwrHS6h74r9Wx2e5n
/3qsxG4ScbtIgXpvGgMCAQAAAABAy4N4Rd86t6rrwj4RB751Ywz/9/Ig6zpQhUJ7cRGfe0yLUO3t
okSYbFsZPntsXwvBV7Vf7PJqqb8y++LEwS8hELJ0BSIO4C6GDbuMQFDRB16UxZSDnV3KyCgUlJg4
hv+L0mIOAsLsRqkMCAQAAAAAYAWgCyFGMZD93C/OVdcFJQ6egjiIXQvOdlkT4Nkur8ifxSI/Rjyo
bgtmeRTCWSzUzS1yfMTD7F5fjISY7bIOwhIWTWox9BJEQvteyYMsXQFxAJ/K733MFWJU+3ZPHvhj
Y07GIAv3fQ5/rx6vHTOxCOu7JQICAQAAAACQB5cL6xhNUH2vIg+yto0/gjzYi4W170jgw/p/2faq
/CIW9E0enJxE8AvunRAVvihbTJNQOdmLeMyjEyKxyryK4FBh3i+2LaSYyYORIpAAnzqUmBZyp0Ie
LHbZ/lSNNbHGgepyEtMjYjeU6VYvEoEAAAAAAF9FAAzhrhpe/LiYvMc6AtXtLBbzBycQYqvGuBBY
7TKs/7+v8sDXBzi6RbUlAqGJCl94bZ8IhNha0QuExS6jKtp9ZqkLXoqo9zcuuFRxOFV1XskDf9+b
q6u3aFkHcIU8mBKJcAzjR9z392LR78cRs21LVy8c2zH/UsgIM1IYAAAAAABhsFmkVhN89f+q/eIU
JvPZ5D7bep0XokAw+3NV33ck+PUqENrm0xhUhECMQPDbQSzuM4Hgr/z7CId9eGwzXXvBF4jzixYV
kRA/o1XIgms+54vPu33WiAT4JInQjuXJtikEUR7449Ifm7GOyV5IBHXM7cU4VR1vCAQAAAAA+FbS
oNcdQV2lzu4jtiqc3YR8Lybrs+VXDKNA2Dtp0CIQVPTBYpcdCX5Lg/8kAuFcCISjkALXCIRTIhBi
BIK5381CILRFzyruR/19lR7Sfq/SFWIaQ7waTDQCfBgioslHF2TywKcN7cUY034Wi5WquitqHLq5
OEAgAAAAAMAjyYJMEmS3Fib0liw+q9aLe7u8QrgfkAm7ZCFwEPfXwpN9ezYvD+LWBILvwmBhYd4e
00ceRIGgroR6iRBrIPj7j2JhEq81SgQlaOJ7Gt9n/9hnu+xpb0IYZHUR3G6FRICPHboSiWBhX/Wp
Df6Y2YsxI4rCdizG8SuTb3ZriYBAAHjcSdT/jgicCAEA4Oue56bk+5GOCFYsNM22YfVxAR5bLx7E
IldJhJ3locWquNka5EFLXWgSIdY/eLHL+gT+ymS7f19PILaIzFq/xert50QgKHmwF+/VXrzWWKPB
14WIqRnt/k+2bWVn4evYlUF1aUAiwIcRujH4Y8sSgXAyHXWwE/LAwvgWU4NMjIMfIg8QCAAPKg2q
v+WkCAAAD3h+G01PqELbK4HQu0KtCh8+uUVuu20/j1cNK3ngoxNiATTVytALhF9BIMRFdoxAOAuB
4MOkYxX4qoXj2S5Dp2e3kPddGZ5f35dnIQLOtq0lEetB+NaU7XVNQVicC4HgX8PiFl3nZCGHRICP
lgj2us/Nbt+L++tsf+SYSpmKEUCquOtqeX2RyfIULwQCwBedWPUmU5vQvHgfnBwBAOABhEGVlmCm
Iw2y7gcq39js8or0IhaVs5vAx6vjP+2y+KG/wr4fkAazeE6+feJil33in51E8FEHfmEeFxfmFt5+
Mb1zYiA+n/hc4uI7Lk5mJw92tq2ZoAowxqune9vWXYj1FnykQpQlUSBEceDFyRSew1LMoQBuOvS5
YzFKhMX9/GyXcq0dpwfL05N2lkdYfQoIBID7FQdTZ5K1ioFqRSYAAMAdioNrahdkqQkx4iDb4pU3
LwzOYdG5dgTCb2nwz+vtT7vsoHCwbeVz1a0hPpclLIpV9MFzkAfHsNhehEAwu7wq2e57V7w/2VX8
eN9zWBQtpq/6L8lifwqLH/V4/u/aZ5AJhPj/UUbMr//bUiBo8QifQkhlaMdL+94fn0qAtvEkFkdt
svLsjum3SISbRCEgEADuZ5LVy+u0zmCx2rby8EYmcLIEAIBPEgfXCoJKGGTiQC3c1QI5XqH2YfF+
4RrTF5o0+B/3te+ioPq3V8+jXR23IA9i9EEWdXAuFvntivtsl2HSi+kCa34OsYT3ysJ7M7r4yHKy
Z7vstBDnLeZ+197/mAoRaxtEeeDfR5XCEp8f8yH4DIng8TLBy4XZHatxTIppRT6CIe7fnwICAeD+
5MFsOq9TnbzXge1CJhCVAAAAnygORsVAVUV8LuSBSh2Yi4X7WSzG17B4bZtPYWiRCP/Yn1oIB9um
LKh6DBYERlX3oIo8UPJAnccXu6yzsFieF22WF5lUn6EVMif7DKNYWU3nZ7crrS36Q9V7UCkLUcBk
ERZEIcDfkAjxGFNj4hLGkCYPsmM+60ZiHZlwk30dgQBwH/JguuKEHAeCquLwkEzg5AkAAO+UBtaR
BXPn65FbJQ/24WslEGLPdbUo9wvY2H3BpzM0qeAjENT52sIC1mx7xfzFtlEHvlhivAKvFhHZ4mC1
sWhGKxYfqg5FJW9UzQf1f2bbiyM+fLtFfsTCiudEHrT3ah/uNwqH2S6vAAN8uERwY6U6XlUEVjaf
z47xTCJ8WGQCAgHgfuRBNjmKRlKFZaotkwpxEEMkAADALaSBWb/I4XzFz7JaB1nbs5jG0M6V6kp1
XJyaXeYa+00JhSfTXQ2UPIjdEGKhRNVpIYs+uFgUxPO2WKjE836VhrAm4iB+rvH9z1I5sigDn8YQ
BUKL/Mhef+wu0d6rX3aZuuH/JpMazHngHkSCiUV/9jfq2OxJg5sXWUQgANzBuBImRvtkUjSHE27M
6/Qn1p5QUIMMIgEAAN4iDia3AFdSfJd8rb4f2UYWsJNYwDdpsH9ddO5ev/fV/9X9VZs6N5vlV8qb
NPiv2365W5W+MJq6kC1U1JXKkbmJCXmQvf+9FJIYDdlCtC187wWClwdeHHgZ9GyXhRn9+x0FjxIa
zHfgb4mE3vGoxtIqDWktRMRN93MEAsDfm4TF6AN/Mj6E25Hezap/c1ZxWgkFRAIAAFTioCr6O1Lg
MGtzuLtSKFQL2J1Y0HuB8CLuy0ch7DrPQeX5x4l770q5Fwj/CSKhkgerWozcYKFiyaI6a585F++T
Sh1p78Ps3uPZSae9+9p3YIjzmpN4P326wjGRGdXCC+CviIROi9u5M6bOlqcirTYWzYBAAHhQeRAH
BRUmGQs1VSdnb+tHZMKESAAAgCvEQa87QpQGKsWganu4szq1YWd5GoPqfuDrDuyK+z2716giC8z0
Fb52Lo3F/WLKQkxbiBEIMfrgZNuowqsXBOr8XSxc2mt7a22LODdprz+2btyL//MFKPe27ZYRIw/8
53uyug4DwL1LhF5qc5bWvHbkQfwdAgHgUccMqyMPWrGmH+7rQ5hwtcFAyYNjRyQoodAVCUgEAIBv
Jw9GFo4jtQniVkUjVPJgKibU2cLRF9CrWkbGYooxikG1D4znz1hrQckDVfcg1j7w53QpD95zTs7+
tyMWKuGgXv+LXaaSnF/nNCfL61VEkbBz9znbn/aXq/2JaMjaUn5I6DbAJ64Pep1mYlHQarspCASA
z5+Qmem6B75t1M9w2yIRKoHgC0IdLY9IqITCZEnVV6IRAAC+3XlqVBpkEQcH2xYjVBEDI6kCWVHG
Xqh6L1+4/U0spjhStPgUFsgn27YUzLosZB0XunUPPuo8XIRVr4kwUBEXURwsdhmJcTCdnrmzvOji
anldBVX/SUVtmH1gVXqAd4y3VURX1fEk6+xQyQPaOAI8+CDh5cHetj2n/7E/vaef3Ak3VpaOkQeZ
RIgyQckFf+Jd3WTKvEhAIgAAfElpYFZ3U+jVNVDdC1Q9n73pfN7Z6jSJKnReLXRNLEbb48fftzSG
1bbtGFXNIV8EsP382BEGcVPi4KqiiZ+xi4T3YBXi4GjbCxyLEwo+HfOQ7Beq1pNZnqp5FO9frB2h
3keAe8aPPSOFW6uiilEq3AwEAsDnDgoqNMmnLjw5aeAFwg8hEKL1P9ifAkLt+1OyRbmwcyfc2fKI
hH8lwv97QYgEAIBHlgWVOKjaC+8LaVAJhCwMtxc1ULVIXN25akr+1v9Pm5jHK3QtRN5/Hx/PL5z9
1b+zWyxnrRljtIFf+B7dfSxOZHTbNn6gNFDFIVXkY4wc8OJACYNqUxIhigr1/o52sPjQyvQAb1wb
ZOuEKpVhDsfqp4JAAPjcCZuSB6r2QZQIPoUhnlRVASoVbXB2J9SDbVMe/P9NboLki0OFl0Y0AgDA
g4kCtbiu5EEvyiBLVajqHmQRBNlVtGt6opvldQ78hHt2IsGfn89OIKir6l4gLG5RfTRdJPFXsrhV
qYZLsdj9bGnvJYJPFWiixX9+7W98ZEAllVSx6Kewr6hoyygQWgeL/7ivVS2Js+l0BoB7Fgm9TjRt
f1a1Xz5UKiAQAD5vYBiJPvghtid3Yt2FE7uFE2Iszni2P9WMfVTC0T22lwdH9/x8COBkRCMAADya
MJjeIA4yeRAjClQYukpViFfNVMhtO38s4rymFn0j55usZoN/zTvx97sgGVRIfax/oNo0/hICQRVJ
rDotfGjNg0GJEDtNnN1rtyAXDk4eZJ03lECIKQ6q3tMpSJr/uvf4P+H7X9aPQrC//N4CZGuFbN0Q
08gsCIReKpiZrpuAQAC440ldr/PCkxAHMaxv6gw6s5sQ+e+XIBK8PGj3fXTP62iXVxm8SCAaAQDg
PqXB1Pm6uo0L7hhxcBCLvygT4sLRF8dTKQHZtthYVfFKKGRFyKIUaLWI2nP0ufKzbUPq1/A3i21r
H2RdFmLkgao7dPGaPvu86goprkIinBO5cA7zCdVhI857DnYZffAkBIIqUPlSvMe9NIYPrUwPcEOR
YFYXVmwCYWe6M82ciISLc8hbxxcEAsDHT+560Qc+feFJiAN/EjXb9liOnRRiASh1NWnvRELbXsSJ
/+hkgkprIBoBYHxxV07aedfgjfvWqBzIvs4mqOqKcVz0qSJ4Mdpgji/BdEeDWKBvFbcjMsG/Nv98
VOX/GI3gq/5HMR+viPvF7UjxxBfLCyXKhe2djAu+oLISQG0uktW3UBLHR6+o/SkKhFik8qV4j32U
x9EopAhfRyrEcXpy6/lM3mUi4V3HAQIB4PMP+MrAK3FgdhnWuVjehlGZ9cl0aoMKL40iwaczqLSG
GI2wtgkuCyJ41MX8eybuxeKumpwb8g2u3LdGJEG2mdXtGdVCL7tSrBblKuw/a/0Xu/+cB2TCattU
B3Xem8PrOCXPuZpYq8m6f3xfMPC5WMz6YomxUOLdyQMRheDFSbyYMdqKU0W1qMiWfRA2ZttCil4i
+Pc4ioPqvWa8hbsb5pNbta6IUQhVzZmsRsKb938EAsDnTPRmq0NCn4qTp59AeWGQ5U72JpAmRIKS
CT708Ghbg3lOJlRIBLhXYTAiElZ1H719ubO4iz+PCxSieOAt+9YUzjFVm8ORn6nzVFbwLi7EZ9tW
41+FOFDnr1MiEFR0gopGMMtrAc12eaW7PdaT/Unty2ozqHNnlAdZ9IFq01jVO7A7X9T6Ogjt+/b+
LmI/qqJbsroa+7A/+QgE1bZabSchDpSAYoyFR5AHPUE6u/X83nKxuxPH67vm6ggEgI+b7FXRB/ti
UtYO9DVMNGLrpDgZySZPyj7GcM2sp3cWCuU7NZhtIyT+XQxxkoa/JAymjjjIescrK78ORjFkizv1
HPwkYXOlj2OHfbqzb2UCIF4xz64EZ7eq20KU3E/J5NRsW7XfFxz0C8DYKegsFtcqtWEkhSFG3O2T
xfuT+//sPBnF3zmc63zxxEoc9NIWhkTlZ5JEIVhYgCymO2pM1k+RiRcvVKcO1QUia089Ig4YU+Ee
xcFUSIOlIxHaMZK1zt13xjdqIADc0YQvO1nGCVkmDywIBC8OVBGmRQwqPRFglld2zf4vioSjEAmz
cUUV/q40qBbvU+dErm4tkQ0ji7upeMxyMYRE+Lb7cSbBqqgBNY5XIeXZ15lAqCalU1hQ+nNXFAfH
cHvqCAQ1gVbpC5G4SK0e4xzOv3GivQjh6IW+ysePrRrLFo33epw7iRDHxTi2LdavwVFVlK/Crf17
Fferc2ffuefaEgC9eUEVieWPLRUxFqOaW9TxTaIQEAgAt50ExpDSKA6yokEHu7TuUR74nD+f53cK
A4p63Pj4yvBnIay9SITJSQSzTpcGTt7wAQuu0VzwTCgoeVC1kFNX5FQouQrnVZMEVSzOkAjfVn71
9uu42M/Er/r9iEjIJII6h8QFpEqzO9k27LwSCMvAIrAqnhjfq/Zcl2I72DadYWd5u0mVjx8LJyqB
cH4kedCRCH48tGJ8rfbj2S1+ZssLvpnpVJhlcJ8xI20B7vvYivtr3KdjvZgoEbII5zhuq+OLCASA
O5AHk421a/RdF/xBHidjvu+xKhiUpS9UhYra90sxoFQiQZ3kj6+Pr1o8xQmHIRPgnYuu3hWukQJy
lizoRxYrI4WNYvs3VYl+ERNiQyJ8K/E1uuCqrtzurV/LRqWzVYXuKpGsxMGSiIMoDY4deXC2t3Vd
WBOZ1xMI/vke3Huoio6tQZIcLe8KMNRC8FGO6/Y8i2iE+Hn0UnAWu6ynlNVPiJ9vVlxz6e0jjKHw
KKcLy+vGKBmpUrZixJgfv1tRdD9mUgMB4C8uaLI2WFEe/Agi4cm27RqjPPBXN3oCIUuZ8JOkaCZX
q68GjPaX9RMCdUKP7SipPg/XSjqzfgG5KgpASYReL/oyHPaVLAxc9Z2P4bhn2xYmRSJ8fWkwGvJ9
TYRAryBudWxUaRHzG8SBkga91IW1sxis0oviez1bHXmgnnes65CF0fvX+WJ5V4Cs0PFDnvP8c06i
EjKhsxb7+GK17F2TxdWQXGLchAcVB9kYFcdOs/zCpYpA2NllS/Y3pTEgEABut6CJC3B/4DZxEDdf
jMoLhFjZ+ZfbfI9jn8IQOyz4QUQNPk9ukIphqX7iVPV2Vlexmt1UE0KzIiqBEz0MSroqF3yyfjux
LJ0gnsBVDmLcn71AUCfveOXWkoXX7I5NUxKBPeJh99tMGrynvWKcHB4KiZAV053tuigeE8dFLO57
tDxd4ZiIg7RSvvUjDjJ5MHXEoJqcH01XLTdx3MYIi5dwO1Q08ZHJztdh/1/FWDuFOctkdQHaeF9D
Yon5BDywRMjGmjb/jxceVeTVvpAIcxB3RCAAfLI86HVaaPKgCYSfbvP1D6I8iNEHmUBQuVDKQrb7
e7JtxWLfzmoVomCxOgrBby9BIoy04Pp3okc0AgzIg95V0irve0QgxCurKs1gEQuBrP1dPHGb6auY
czJZXpBsD7e/mvVrcoy0WVRpBW1fiiGqKt9VRcBkIeJV69M1mdiebSza4Gh5CO5VonlQpk3Fc6/k
h+oskXUCyApDHi2v6/AtWghWry10dchkgvose/sC4gAeXRxk48w5jK3twqKfU+xM10TopSDHcR6B
APDJ8mBneRGTH0Eg+OiD2HlhsW1uZWwN9Wzb0Mh4wvWLqZN7TtUkzre02rv78WJhZ/3KyUf7EyKl
JlC9K7mEa3OcZR1NJhvrGJIVkZvEidMsr3p8Nl3AKC4IVCXkrMPKZJdXFl7sMjrBhGB7V7sl+CvS
QJ0nRlouZpJMdUaoimXtkv1+9OquCaE2GnUQ0xSyiIN4LlCPb8nzHGWxuqr5OXnffDSehfdDFYo8
W52W8S3kwahcKGopXPPerKPiAuCBJUIs1ppdlDA3jiqJEOdC7yqkiEAAeJ88qCZ3PvJAbW3S5xcV
i+m8Sr9VLRzjgussFvO99kfRYvbaQsaw2hfTfa/PYmEWW3/R/pHjrOpoMif7XNVLPAvftnDirBZK
1fHSaI/jxaGvd7IXAuHFPd+qD/S72i3Bh0oDJQzMdDRBJsF66Tdvaa2YVbNXCze13/XEwcneVuOg
qphvNljjQB0DA50C1GvcudekrtLF9ywbH06FZFw4cvLPr1NL4ar7AnjEYyEcAzFKKqYwKIHQxrJ4
4UFJhDQa85r5BQIB4MpjXSxqdnYZUvpUyAN/VdIvHLw8yASCCo9UodS+MNFsdSuY7EqJWrhNA/Kg
TWarSaR63ClMtkhrQB6YjeV/x6uvVRX6SSwKzMYiEE5ioeCFmxcI/pj36Uo793jHIBAseQ6z5ZXO
4T6kgVm/NkeVYlMVrq2KYsV9v2o/aIUwyOp9ZAvlrMtCTFeoFtQj7RivGvfFotT/78V5xf4UDm7P
b+6MFZlozNoJqpQMzmNXCIB43PHewVc/1Yh5gI9AUPOaKDf9XDrrqJYJZlIYAD54AhkXNVWnBRV1
ECMP4kDxUsiDLGJACYRpcFKorgSpCAt/RSabzB7sMkriaNvwzpNYkC1CJoSPgNaPX2jxNSLplDyI
+1rcqvzvuVhYZSHblUA4C4GwdwLB1zvxEUdtEdJqnOydJMhqL/jFD1EIf2/fvTZFYSTdpqrb0RMI
WX9vS+TYqDTIJHNWDfxYHB9ZtMGHFr4TV/XMdIiw/7zO1m8lWL1/lRzhvHUjsQDwDUSCnwufLK9D
5seoOJcwq4tK9zqeIBAAbjSJjJPEWDCtSlfwYcx7u8xb8nUPslZQKiWgquq8CpFQyQQVfTCF1xiF
wiIWdE+JQFCVuI922bFhCoulmGO+Gq0fH1kYjIqE7DjzET5e1j0VAqEXxq0WWmZ5pfkRgeDrnfzz
uvmOK14g/LI/0QeLWKD5E/5bcoTh/fvwiDS4tkZHr81iL2XHp+vEcHsbEARrRxpU6Tsq53+0q8Kn
ttoL+fbx/pdwrhxtJdgTMrIIJOcqAKjGKlFg1Ld4Plkdudb+ZxfGq2lQPhCBAPDB8mAekAc/7TJ0
2V993Al5cAri4DlIBB99kF3JiQuhSiKsVl8VMsuv/E5hQbMEgdA6PShZELe9XYa/zlZfubJMJNxq
ogk3FQcjFd4tWaj5/UxJqijmYlrQzuqIg+7Lsbonc1wkTUIg/OO2n04gTHZZ/0B1X/HHxfm9J3p4
tziYOuKgt+iP0uAagdCrPxOLbK7Fvqrq0PQKhmYSoVcbJFtUf7g4KCbn6vx4LqRBHLt6dSMQBwBw
k9ORkAi+01kWYbm6+blZ3bHqXXUQEAgAYwsbdVU0kwe+00KUB34Sc3YLidhtQcmDtm0mLfFgHwjd
XAtxkKVnqLwqLxHa38WIg7go8qkZ7X1p+V2q8OIsREKc0FmxeO0N0kZ6xIcsurJJ+DR4rKnjTIm6
GIFQhXLHfWa1vG2YOpHHxdYqBMIPJxD+RwgEc8f9zn2fHRuxZzNpDJ8jvkaiDGKLxdhqcaTIZ9Yp
pFcPYbTFoKrdkYmEquvIOZELvRoHm0X13xhnk2iEqo3g1DtvWKfwI8cnAFwzRoXxyXdUaGO473QW
zzFzMp+ZrRbVUZ5SRBHgxgujKvrgSQgEVX3dy4NY7+BXIhB8x4XN5ExNUgZCN9fO4jtGHvge2X7B
ZGIga6/tKREIPme9LZRi2oMfLL2BVbUa1mIBOHUmgHEySXrE7RZdahHW+1xia9S2z8VjTAkEJQ/8
wqq376vnbXYZMZSFK7fn28aDnwMC4dlJuNiy1Uf0tGPoXS2X4GrxNdJWcSTiYG91hxDVhaGKboiT
xMUuhXRW3DDriJBFIox+X+X/392iumgjqM4NPYFgiAMA+ECJ4Osh+Hmxmp+fTNc36HX+eUuUJgIB
oHc8i0VNJQ9+dOSBr3cQFw1V9IG66nntZCledVETpix1QVW4n8Vk19dG8BPagxAI8X59VXofkdCi
EFSV6+oK8ki/c0tkBN0f+tLAbOxq7VSIhOx4uybKp+1PO9sWD12ThU9WeFRdYe49b/98fQFFJRB8
xEH7+uTGgV/h9dwsXxFKeaBaL1Y1DXppBaOtRSerC1plRfyW8DM/1sZor1jk8NwRAVWdhPjzdUQc
3OMY2unYkImEdeT+AABudbqyy+iy2fJitn5M34VzmjpneZFwdYQjAgFgfIIZFzWHK+XB2XSospIH
MfpgKHWhmtwkba3MPTcvSV7Ca1UVv2O1fEuEQlU9/GBbi+pvVbHFGI1gxWIgq7jfK4hl310kdIRB
Jg+yfvaTJfl2yWI8HmuxLaI/1pQ8WMXCSrUrXS3PZ1fhfpXVVwUUm0CIRRR9dE3rxpC1eY1XnZEH
Hy8ORjoo7K/8/torP2sYm32xP/83i5AHL+H2ZHV3hEVIN3VbFUV8CHEwsvgP+8mKMACAzxyPxEW/
kZpMWWplJrqzeQ0pDADvnGRe03Xhybbh1LswyTvZZdTBSyIO/FUjddX9TROYJBphEYu3dvU/9qn3
Rbva64qFIdWEXC3MduL9fLHLNpAv4bmcgkhYbNvuMbv6HRdeo5PjjUj46pPHgdZ1Zv1iclVRuF6O
sb8PVThRyYOsq8kpWUBVAmE3sE3hNao0i9jG0UcgxOiDa7pJXHxWLGTeNK6rsSLbf6+pZxA/s143
kEpsVmLUbBtdc0wEgj+XqA4Ji+XpOb2aOQ8tDkaFAgDAX5yDWUckqHlrdpEja3V9dYQjAgEgmUN0
DsDYRi5ePfStVLJOCypl4cUuQ5JU6sK7JjeF3fQhUkfTtnISC7VYDTwr4mKW15LIohBiocVZSATr
LAhUlfJem7NUJMQBvjfZfEuniM+ewA72ux8RB7PlV2BHFlG9FIYncZzNToZlYdwqhDvrlazy1339
jybO/H6WjQtx29mfKsmLXbakVPIgu0JwVbEjJmOp+Bptmbgvvu91U6g+t7WQmVVtlxhlc+rs9yr6
oJd+kEUWyDGRhTcAwIecr7LvLRmT41y4nSdUOvKb0xgQCAD1wTtSODGmLexs25rNi4OsWOLIZO+q
1IVBiRCLtMxuMjon8qC9rpHFjpIJe9ORHa1Gwou77xexCPUh4NnnpZ5PFQam8oLjBDstuniFmEp3
v0o6fMTk/A3FEGerow52yUkq20ey9ygKu7jQjl1NFtOFSV9MdzO5RiAc7DJt6eD+b7a637KSKf78
q1KERsQL3RjeJw960iv7bA6JPFBdFKrJXq8+R6/WTdZ1IbYAPQ6cTypRoG434or9DwDgpucqS85X
IzWm/HnNX/SoBPjZrrxAgUAA0AuYKvpARR08Wd6q0act/AoCIdY7OFteLPAm8iCRCCYkwtF0VXuf
jpGFWldRAO093oX3eV8sQGPF82NYCJrVhWJG+qRnrc5iUb635KBPV/zNqs8vt0mheEcxxKq4nJIH
qljmzuq2QZP4LLP9IStMWqUFncR+4193bMN3cFLL//9TkAbVfWadHXoL2KrYkdkd1UK4NsrmMxac
ScrCPCC9Dok4ulbwxMV3lb+aFTXMUhqycUyl7Jw68mBEEqx/4zMEAPhG4sBsLD20qmkQ0+f8XOEc
zmsv1olCQCAAvM0AqiuhPic7hlP7ugfxiuhzIhCONn6V6KMmbbEuwFksHpU8eOks8nuF6Cws2mbb
9lBXg2PbTmFR7/9f1W2YbHvVL060Vbuz9pnM1kltsDwsf0QmrMlnUsqEbL8YWNS9pXVdVVwuLrp8
WP5BLP7nAYmX7UPZ/qiifFQ7VNXFYxbi4iiO+3Mir3ZONGRFjVbLry70JMLOyb3VHafr34pCGCi0
Kffxjy5MmtSxiftTjDJRkSYHG6tLUUmDNQgCJSzPHXlsg0LilNxvJQ+6sgBpAADwKeLAxPxrZ9tU
yaybmf+bOREI2flsEzVXzS0QCABWFk70E/ssvzlWTI+FrV6sTl1Qudmbq0+fMHlbE4kQ5UIWfVC1
N6uiFNr7vrfxInb7RCCoK9b+sVTYr79qN9I3PU7s184iaip+lgkDtQgxe1saxVsiDnoiSBWZi/JA
5ferYpxT8l5Wef9rcYx5SZdF+KyJQMi6h1Ttknwhxp14L17sMq3JjxEWJgzz4HHjhZb9DYlQhFxe
cRe3FQmDKQtKHBwS6VXVpYhFLf1xWlXKVtEBKvJppA7BtelYPXmALAAAuA9xUMkDlUIdaynFos1n
qyNDKyGOQADoLLSy1IWDOHCjQKiujMYCir287ItJ3kdN5gYKKmYLtkoejObDK/NZFTXzn8dLWAyq
xVscHM1N7jN5cLS6en91ddCKBdU0sMhSi4VqIbF2BvnpiudVRRdUheL2xTGSndS81MkkSvZ6FyGy
Xpww+K/pFCGVvrAOHPsnIRCqtklmeS0Ocwv/NfxvFBDZVYVzuI8pLsj/YnpAJsp6kTXvFgmFzJgt
T02J0iDbXw9Wd8PoRRqoCKeTkAnXRgtUFbmzegpSeiILAAA+TRxMxbmql2LXzk++rfVPy4tMm5sn
n01Hhcb53zLymhAIwIE9Vjhxb/3UhcnqtlpRHByTSeO/E7y/MLFbk8FjDRPirOJ4Lyc+WxjFyfku
WdT6+1IRCOqxVJu/xXTlcr/t7bIFWpVL3FugZ10NskWBqpKeFT9TAqFKm+gV3ckKylVt63oCQUUh
qNoU2WIoHiftc4mpQb0UoUr+TMmiU0mEKuUoE0OtdoI/qVdRNPEqeFa/YbnlYvxKeZBFiUxikW3F
/nvVc7+ijkdWS0Ptoz9sW6gzC/FcE6mVRRlkWxV9kNVAUNEII+OFGR0TAADuQRyM1DlQNaVUW+vY
NtrXZGtRj7EGQnV+uyjUjEAAGJ8MV4UTszChOUzg2gRRVYLv1Tz41AmeiELYLErCJHlOFviVRFAF
ybI8rp31K9qfisVXvL+5IxDac+mJhKwN4Dp4gpg6AuHaK4pLsqAYacnYO2ntEumTSYRejRBfaHQv
BIJ1FmL+in2sefDfRCDEziaV+PHvy+JuZ9PFNtWWXfVV+9vkfubbkU5CIDy517EUksIXP7pZ0c1B
eaC6D0yd/bsrEkaGr0KMZeIxdtHxt1nKQpZCo2oPjG5eIGUpbFURRSukjIreQRwAAHyuNHiLONgV
4kCdu34GoeCjoqNAGCkEvHk96ryBQAC4PLCzto0jhRNVnYBjIQ6kPPgbE7xCIsTJsuquoG57C0w1
KPrFUdbm8WDbK8qxkF1W7Cx+RrEgYxY6frA6/706UcwDEmEN77e6+l5VaX9LGsVsdbRNVn0+K3DZ
u7obc8tVBIJfSB2DyFLi4JeTByp1oRJ1NvDZLXYZ0rdcIRNUwbw2BvjiRu3nZ9tG0mTyQH3mS7XQ
vEVqQyFbJ+u3TrVCjK3F63qLPPARJD6tScmDH8WYvhMTqqpoYayfcrQ64uBcHNOLWvwXAqF7izgA
APhwWdCbf412smrnoifLI+bUrY8+8KmOi+lU1DiXNxuMQkAgAAe+XvSNygNfnG8t5IFagK73IA86
EsHn2vurnL3+s9eEYvmJtCX30ybv7f/9BDy2cZwTeWB2mW8+Ejp2sH4/dUsWMr1evSbe56WzWF2s
n0bRkwfZ56Ta2B068iCLNjkUsmgfjh2zy64C5r6Ox1SrdzAqD1Tdg+qq7hT2kdUuCxb2Pp+sNaiv
hfLkBNkq9v9dEAhZ7YYpyI6zGFM2EQk3GmOqKydxUhILC44U+btW/KpOC++RB1nr11g/RQmDo+UR
B9mx3Kt3kp7GBr5GHgAAvE0EXHMuskIavGeOrMRBFAvZ/GqxOlq4Fx1rCASA/iQ0iz5QB6oKjY+F
+eJ2d5EHgxIhigSzPOdZvZdxgXq0bV73OSyM/BXAXZAIcRGyBomQhVKvySJotTzioX2eB6tTGFQx
nF5od5X/78P1e+3eshSGaeBEFiv8RoHgi8hlBXeihMiuRGdyxy+eYqcMX+ugyYIoDtr20pEHm6v0
7Zhzk4c1kU2jNRpU+oUfD17sssBRu+/2XNtn1d73H9Yv1NjqNMxBvCjB9aZCi51UL1UroxdhkhWi
XKzuEFD1zK6EmC889STkQUyfmmxbGLHXueWW4mCorWLvbxAHAABXCYNrRcI1XRUmyy+8jEQdZBGd
WRrwyOu5ptA3AgGgOHhi9IHqaa8K8/nJZa/ewWaifE8TvY5EmK6wrW1B4xc57b1TCzzVitHCQjcu
RharowHUxFyFCptdhm+1596eSyl/xD5UyYOswFy2IK3Cn9XCuCd5VPjc3uqCl3FRqORAlpKRXU1V
URhLOI58gcT/hi0rmHi0K+uL+O+TKJzevnTuiISjEyE/7DLM0IREme1PpM0iJJnazu52ESJhfYtE
EPIgtkTMUl7mZIw8W16MsreArrotZG0aozzICnv6fTgeh1W0wTH8PKY2VOlHvWNkfcv4zWkdAGBI
GkwDX48KhGsiDq5J71VtsbNi5JOYU/VSB3uvb0UgAOiBpNd5IWt7Eq+WHp08iO3jRir4349Vubwy
my2m1ETeD6JLeG/PblEeFw3+c2jvuyosF9NFsvaKWdHBkTDhWQilXu2BXkG52fpFFBfbXvGsWsCp
1zPSBULlre8tb7tZFdmJsiCiWkP6VAVf3CercfB7+8/rpuRBjDx4V3HSYt9XUqQXiXASr+un6Q4u
Zts0hqfw2cb388X+RPVkLQF9dxjzEqH3fnSKJsYUgWxRriTr0XSkyOjYmLVqHKldo2TwlIwtKqIs
EwdVV4Wq5kM3XQEpAABwM3EwXXk7IhWqloxZ62uVMqpaDPv0hINtC1pPYh5pptOqqznSSNocAgHA
+lW7D4VA8BN+1bKxF4HwEFWxxZVZNbhEqTBSjE6FZLfFyNHyAol+QeIXtacw+Y+VzXuRA2qx7Rcm
q9WdF94jD8zqGghV2zcVfVFJhF7rxl0hDuJzNfG4vhhllbMf95ssVeG3NPj/gjz4r23rHdy8s0ki
EkaiEdTnd7RtHYS42PaLWH88mNURI1EixKvgZyGq/v3sYjRCMtlS4sCPkb4CdFYjpqoPM1roMtuv
s+elckRV/2vriIMXIRCy97qSmtlEjZoFAAAfJw56daGqQtfXpAGoCzRKHPS6k6l0UlWjZwlzk1j0
9+zmVvE8drJ+UW6KKAIMiIReQbgszDX2pM+uio5Mju/3TRKT2k7ueK8YXZQH2VVJE4txPymPMqe6
IpgtaqviMv7EMCKhRtIWpmQBoaIQVCrDuTPoT52TZmXI1Xuges6r6AcfRbJPpFn731gD4MXJgyYO
mjxom6p34BdxaUrLWxdlRUrPUrwfi23bN/rFqCrgtwuSbFeMTzFCykc+tRShljbU/t8fTzIaobNQ
V4/vCxK2HtReIlQCQcmf0U4Z8diLtTyyqzhVrQMVMaJuqxoH1xZGpL0iAMDHy4NeC+Lqgs9o5MFI
W8Z9RxBkRatVTaG1eH5RiLe1iY/cfFd0NAIBkAd13/B9Yv5Wu7xy+iK2kUrwj/3mhUnvFcXo2nvf
FjrHwfdLGeEoDl6Syf5ZLCyzwT5bSPc6UGTywIpB3n89u9e7CyJB1WLo7U+9WhWzOOlVPe9jzn9W
3K9dPVfRNz6VZXKL7Be7TFf4j11GIMS0hezq9c2jfEQ0wmo6oqLqpHFyr9NLhB+Wh9XvLC8M2MTB
s/t6794b/5kenUSw8HyvmRTF2gK+D/U/r9tP04Ui4zF6MB2FsF753CqB0Lt6sxbjx4sQVVWqSBZx
YFaEhyIOAAA+RB5UbbWzuc9s45GjvXVEr75UVWtKRYD6OZQl80nV9SjWlHoOEkFGR/fWKQgE+O6D
S9bPPB788epgO4jjhHMk+uDLXnUaLEZnYbEZF1lVwcKY0+2vGGYRIMdk0a0WRvEz39s2faJ3Nb9q
15iJA0sEiTopvadqfbYAyxbDsWDl2eqQ8ygQ1DHQ7sMvLn36wv8Xtpa+MCIP1o88xlw0Qnyv1ZXm
xXTrP78Y/eF+/iO8lz4FwHcFUelVXiQ0iRAnIMewDyzFQn205kGUB/9jf6IQokBY3Ot+srEorZ4Q
U8VvYxFcP57HiKjFdIrJcyIQquKIw2kKSAMAgE+b32fCoOoqNTKX66XTjRSo3gdpEB/Tzyfi+aTa
4tzDn9+yudRVcygEAnxX1EQ0y1nKrlqpAmlZ9MHZ8jZdX/MNrgsxVle1s7Zn57BgO4aFZ6zK/2w6
RFotdrMQs7MQCauTCS0lYw6Cowork4sK04UVo2yJCyCzft7ayJVc65yAqm4QKqpjLwSCv58nu4xA
aMdQjECIRROzBefmxPdRi7RONEL2PmYdCE6dfTPW4agKvB5e9/nYB3oWE51zkAhWLMyz6AOfthAj
EH64Y8hHIPiog30imVbrd17InmdVx8PC+K0mVs/JWJ4VfKwKI26OPcQBAMCnyoM5kQY701GnVbep
TCJcIxCq7lZZB4Wsg1iWPqnmHscwZ36LwEcgACSLKNWnXoURqc4LWfpCWuzuu0wmw4LLrF94Tl21
PbnFupkuuudv/QDZW6SpvPKj9U1xFvoWw96UMTar6xYomRDTOOZCFoy0hFuSx1itbmF3KgRCFYEQ
W53u3eP6z1LJgyryYP0seRD3axGNsFg/pUFF2ayJPFASoe2LJyG8VPpNloKzdASCkgftsWLdg3+C
TMgiEE6m68xkMqrXozqTCFk6zlKM3c9BIBzd+KFagw6JA6QBAMBflQdZoeh4O5K+mp2b1IXIffF4
MeLAz8uqi2pZdx8lEXoFnbMLbEMXOhEIgDyoq9OrYnJVwa1jssj6VtEH1TgvFqtZC0M/4Pkr/+39
90X3svZ+MYVBFVCMAuFYSITdFYu03mteC5E1mnunxEAV1pZJhizqIBalvFYgqOr0LYQ91kB4Fp9n
FtWjpNynyYNEjsWUhinss2pSUHWqiAt4X8gzq9mRtd1U96tSesx0gU1V++Cn2FR3icY5mWidBt6L
aydx6kqOTzuLUWNRHvj9rScPDHEAAHAXc/qRWgRZfZx4Lq0uDI2uH3ad+1biIGvffU3tnXjei/d7
LOZyQ+cxBAIw4NThTlVoUTzAsyJbf+UK6d290ZdXbDOJsCRyxoddr+7nmUCoIkHiZz93JMJIFEIV
5tZbzGctgKZwkqmuwq7J4430nc8EzsktuKqTWFZEUZ2c/JVfVUTRS4RnsZgr2w79reMqdGqIBRZX
IRJWy8P1q3zMXbjNxq4simEOn4+64p+lcymB8EOIg1isMIoUnwpk7vXEiJhraotMiRRb3D6q0hWe
rY48qIp0mhLCiAMAgM8j6bgQ6/b481fWnj3WvKo6ak2WX/ipzsMxpTDW41G1krILkz2BoOZ2VSRk
1dkLgQBwhUjIistlIfaxwJYsdMcE82Kiryb7MTe5LTzaeNVy5tvEPwqEkXB3M22MfVj4ybYFFUfC
3GxQIGQLt6oycK+7QxXZMVJwp6p5kBnwLPx+bzol5WiXnQZir+LnRB6kJ7l7OKaKAouZrFGpA3Oy
cPb3FeXBLllMZ90Kdu49XUy34VTFRdtEy3df8O0os/aIPmprSY4/E6JM5a1GoRbf37gfx+KNz8mm
CiaeC2G1MqYDANzlHF6l3j0l28HylolzZ03QEwxWzNdG2j1n0c2VQKjmeCoS8s0XZBAIAP22fFOy
2I0LLhWe/OVaN36ASFADqbpK3RYO/ip2izgYrX1QhYu3gom+S8B7cuTU4mYtFo9VFMycnMwqgbAM
SoQqX07dVrbaP/8o0XwKgy/y51OCrqklcnfRPEWBxcW2bUyzIpmq+OFql/UPoljwNRIqKeojbfzi
OBNqqj6I78Lg2zXGz9O3m1qKictIBESWmqHqdpjpWgdeGPwq5MGpIw/MvnEkGQDA3Uwgx6IPovT+
UQiETApkEQbVfMzE+T6bbx2LOVBPIKzJOWq9Yl64WF6fC4EAkMiDbMJtVoekL8W2MuEclgcqJcSn
LrQriD4E/BgWA35B4AfbeCXSTBcjXNzt2S1WTnZdlV4rTh69FIbZ+iHsI/UWMjFQyYOs/kHWEWMp
3tPZfVbqvo9iwam6mlRtUO/6eEqiEVbLQ++ziUo8PrIUAXP70E5MAPx+tA/v7Zr8XVZ06hBkws4u
a5OYXaYjrJ1xUk3+pmSfUq9bpRXE3tdZp5asTWNZpJOxHADgrubx6iJMLPyr0u68lJ+trm9QpQZW
kaBZwfCsuKE/L6mouEweKHHQm4e+ea2CQIDvbiurr6u2etViTP0tXL4fVdXZo1iUxHZw6qqiH3RV
KsmaCAR/lXgOQuFs13Vd6ImSyvBO1s+bu0YgVNEHy8AJLouoyd7TGJqX1bbIQt2zlKC7qndwjURw
Y04ssNibAEVR2SYYfsIT90l/X7uwn/kaCq0DxrkjELJWVzFfdLJtwaZqv1LHQ7xypFIdsvcoSorY
WveX2Hotd5dMkiEOAADuViJkbYefgkTwrYZ31o8grbr/ZIWzexdkspoHWfpCrxOQddYgmVCwt8yt
EAjwnQebty5+14G/QRyMvY8qAsEviHzrNwsLzVhJXQ20PbEz2WX+9+rkgT8ZnayubD8N7AvZIF5d
hc6kxWxjNReWjjSoFvwjBXqyk9ScPBf1GZv1ox+uKu7zACLBpw2cOwIh7u+x6FMW3j878RZrIBxs
rAaC6kjjowDM3cdkdV2N7DOsqmYfkolSfPwq4uC/pqMP/ISt2teIOgAAuLdJZN66URX+/WHbFAYf
QafmpVH8z8lccrZcZletsKNAUBGYo5FxvYuWa+/ra89vCAT4zgvY3sG1DsqHkVZiYDK0Wy0u42Le
L1jiVcY42J6KQba3H0SR4HPXR4oYjkSsZPtVTyJMA88jOwGOdGEYiVzota6LUSWZJBrJY48Lz4df
yIVODX6iYdaPPnixy3zNg21bUsU8TrNtZIIXC5lAUAJLSSufsqA+w7P4uleHJNZcaMf0wd3G16hq
HUR5oGqkxM4epCwAADy2PFAS2kchxNoHszjn+tTXLJWu1cuK0QuqaLS6HREJsf7UMiAPqrVL+ru3
nN8QCIBE2P4sW2DFhd5IaPnFhPv3oMdEdPN+t0X6WQzE57BYiPnyftCtDG06QIoQ81UIgsXq2hjT
wD5WGeJKQvW2a/bhXkTENdJhRMb4v+9FT6iiPsuAfHlkgdYEwjn5DM9u4fwixIHaVI2ENulpUqFF
9ajWpmY6F9TE867qZ/RaIMb9PbaNVK23vCi5RiCMyAMZrcR4DQBw9/Jg6siDeI5UKXiqvWGU3aq4
r5q3noMMyFoyxiiFcyIO4jnqXenStzivIRDgWxEm8L3FU9VuTQ1Y0UzKK8RIBPm+L2ERFa9YT+Ln
arC/Sh743xUiYU0WUlkkSk9Qlbuo1e1/qhaO6r21zuJ/vfJ/yp73Sb5/1pGgEh+pwPgix86aLMj9
++YjbfZh8y0V/dWVs22LG/p9y7dEbcdWVpNBfTZmY72lT1cKBFVz4ZCIkr2Qiu3xqqKJqtMCKQsA
AI8vD3aJPOgJ9lh/KabCqhS/rDPQWtxf1U2hV7h6GZAHN5UDCASAty1mq8Jf2SCiWv35yfKCRJAS
Z00WMKtdhrurxZUKkX5zpf5EJHiJkC3Yp8H9a/gtKm57aRM9KZA9p14Kzzpygkq6D5g4fkYWqV+y
i4lIZVjE+5AVnoy5na2wopqQHEwXq4z72ZyIpZFxURWE6gkEJcxi68YoS2Lqhh8XVBvQZ8sLrFZp
TkQdAAA8jjyILX/jeeMpkQfx3HG0bVceH4VQCYRrIhDUuTorVJ0V870LyY1AAOSBLvilDuadG7xU
pdfj622cnBoSobuwjgOk74hQhbqX7TPf8v4GkZAtpKc3CoJ1QByoxxiJgOgJgezxh352jYgp3rup
89xT6fGVjpVCIqhaAjvbthPduUlR7FjhJyZ7q+tNjNTGWDriQEUeqAnSYmPdR9SVpL3pft3qCpKX
CL6atX9uKiICeQAAcP/yIM7DZ9OpfZk8iO2jfVtw30ZRpTHEaLms7XJW2+BkdVvsZeD8fBfnKQQC
fHd5YNa/uhYlwiTkwcn+hBCfi8nyRiJ8x0lrsoBSBeSy1jprZ6B993uq/j9ZGH+EUIkL7rUjDiYb
KxQ5KjPedZIq0kJGH3u9xfN4sHFoEZOQ2S4Lefor9FkKz8mNR3HSFB+vShcZEQcqX1PlbmYFTbMe
21EixCKRUSD4OgixkvXRdFvQL1GcEwDgm8mD2fKOCz6lL0t986kGsXaOlwgnq+sgxNpnJs6Vp+Qc
OdLh6l1tFhEIAB8/ec/61atJpx+49smkurrSZiZ6i3/zaIQsZ/7NRQI/6r38rM+oIypWu029hQ9/
XZ1ojrt5v/8Gg+keS5igtLQeFf54FhMX1ZnBkuNGFbDsyYOqaGK5WB+QCLEgVhY2qtpmHa2OhiBl
AQDg8ZitrnnwJDZVN6edJ3z0gZcIxzD/t+QcpToojbalrlpr330UJgIBvrM4mKyuIh5Fws62uVCx
V7mSB21gObtFskWJ8N24Qai7DHf/KnnybxALd7sQT4otfithMCBY1mKMmoJQWIrt5OSBL6aoohBG
BMLZclGa/aw3QbpGIqhCte1vzI2lUQBXxaiQBwAA9z5R77dr9FEGP8Lmu/fswlzcR6rFors+AkF1
KlKd1+LcNDuHVh2mZLrpvZ6jEAiARND9y9vgcgiT0ZjCYJaH58ZB7yiew/JnnPx+UQhCIsRF00j4
/bcJQf4qr49FW3ocrIk88GNJDHeMi34ffbBPBIK9USBUt1nhp5E2nNkkUUUkTOK1ZCloWfsr5AEA
wGPJg1h7rG1PThh4eaCiD5Ywx/e1D/ztsSMQqnba2bk0TblV58d7Pz8hEOA7T9bNtC2MhU+OdhlC
O7uBpBnQbFI8JT87IREuB0lxdV2F6j/kQAtwg+PA385CAPiFcxXyX0UhVCkMo5Wih/I4E4Hgx8hF
iIRTkAdqHB8pRsWYAQDwIKdH010QYspCFn2gWjb6rgteHmRdGFbxXMzqOl1XnxMf6byEQIDvjCqi
GFMXvECIBcma1dx3BrzMUp7EIvnbdmcQC6hqwYE4gC99HCQyoZFNSvzVlazQUy+NoScSRqtFV21E
1Vg8ha/9FSNfTLLXBjSbuBnyAADgASbnedcF1c5YyYMYfRDPjy9ii+0WY80cE9Kgl2b7cLUNEAgA
nUm6iEKIA8zxdfB5MX1FLxZUnAbFgYkBabFvWgsBKQAwLBPWZMEdIxB8xwY1Fk3JAl8tvhfrdz0Z
lgZFPYyqBoRK5VBjqnUmbYwvAAAPdBo0ncrmow968qCdB3zXhRZ18Gzbjj1Z3RwT0uDaltRf5lyE
QIDvjq92HqucN4mwfx1gVDhwLOxlYmCpKuavYoK8fvOuDAAgFt6i2KJqfzq5MWwkVzNbfF+zqXHN
RiZKiVDo1YKwwUnbl5qwAQB8M3kQJYKKPlBdF3zqgm/zG1MXfPRB1e43m8OPpNl+yZRbBALA5aTT
RyCoCuAqHDh2ZRiZrGeT8G9dDwEAhiSCWd3+dCnEwdSZ5JQtUm8hDXqvcUAmjI7piAMAgEeakF+m
sVYFFA8D8iCre6AiDzJ50Eu/u/rchkAA+BqT8TjpjnnEx448MKsrnVuYeMfwYP/9cP4/ACARwuJa
hflfc+XerB96OZyecIvXGiaUD9W+FAAA3n4KsLyAopcIbfO1yrw88KkLPm0hRh+k7X7V+SRrR/1d
zj8IBIDLiWlMZTjatq3YVEzG5+TYqgqVpfUQiEIAgM7COi7+/c+uydXsCYVPkQYjE7Fs4sZYCQDw
ZeSB/zrWQNgHaXCwsboHqvZBjD7YRB2oc8t3P98gEODbT8RFmGyTCCcbK4gYJ9a+PoKZbrWmKptT
DwEA3isSYoFF60iEq8Iz72EsYjwEAPg2IkGlMcRIhF0iD1ThxF70wYVE4HyDQADoSYQoEJREUBPs
aCt3brD7zcF067M4UEXzST0EALhWJFRSYH3rfQMAAHyyPDDLOzHEzmix/a8vnHg03bIxdl1YkQcI
BIBriWkEcRDriQNLJMLOSQRfqFFFImTPB4kAAG9e7BP2DwAADygRpkIixPpkZtuC6CchDqqWjYBA
ABifeIcw4KUjGtI+40IixFSGkRQG/7dIBAD4MLkAAADwICLBC4MYeeDn2D6F4dSRB5uOQ5wzEQgA
b5UImwV8IgvWZLDzEqG1eKzqIMhBzLCiAAAAAIBI6NUmUxLhFG5j5AF1DxAIADdjeV34q2gEJROy
gc1HIqyJNMiiENpjUVQRAAAAAL6bNDDTrYqjQFgTgXAeEAfIAwQCwBtHqcsohMn+FFTsiYM4oPkQ
Kx9mpeohjKQ1mBMZDG4AAAAA8J0kQpxzK0bm11kbdUAgANxEIlhYvPcGuFlsUSrs7W31EP59TkQh
AAAAAMA3FwqZTIjRvb1IX6IPEAgAN5MIZttaBFnaQhQHvrVMFAmqHsJ5QCj02rQBAAAAAHw1UdD7
/SrmyKv4+Zr8LSAQAN4vEf7fyFJHI/hQqrmzTeF2nwiDsxAKcxAJRCEAAAAAwJfAXbhTxRFjzYNp
QCIMCwPm0wgEgJuLhEQiTK+L/N+3J+v3p51ej7mdEwJ76xd72bm/ie1qAAAAAAC+5DTcxlIWUi+R
fM08GoEA8Nckgh/crk1jaL/f27bdTNv27uezkwhEIQAAAADAVxMG8evJxlo4ejFQRSAwb0YgAPw1
idAGobNtuy1UaQwx7aFJhN/b4XX7LQ+Orz87vf5NkwgXBRUBAAAAAL6gSBiRCFm3NGodIBAA7k4i
tCKIZpepDL16CG2w27nbxYmDw6s8aLc+HWIJgykDIgAAAAB8RXkwFfPoEXmgOjAgFBAIAH8NPwgt
to1EGIlGmJ1E2Al5sA8CAXkAAAAAAF9dIsStiuo1IQ2WAXnAXBqBAPAJI9o2lcHXQjiLwW7nbneF
EGi/24e/9f+zyfmiDgIAAAAAPCquA0MmEeJFuVF5kEkEQCAA/HWJsNq2qOLvAe7FyYMXu6x3cBYD
opIIKmKhbQyEAAAAAPAlptjWjzyIUQgW5uLVtiITEAgA90JWD+H34Ha0yyKJvsPC8ioJzMYjFmKF
WgZAAAAAAHh0eRC/nq1OYfDz8JHog404IIr3embeAoA3jnJ/BhxVrOUctlPY/KDm5UGUCDHiQA2y
AAAAAABfSSQocZB1YOilMCxGEUUEAsCdSwQlEtRApo5Jle+lBkskAgAAAAB8RYnQa+EYGZEHSAQE
AsBdSYRMJEQjmoVRZQMlsgAAAAAAvoM4sDfMjasIBGoeIBAAHgIMJwAAAADA21CioCcPrtkAgQBw
d4NeL/QqC8EyI8wKAAAAAMCKubJdIRK2k3UKKL4JujAAvHf0uuxd22s9U3VU8KFXI4Mfgx4AAAAA
fGVBsBY/i7XIevfH3PkGEIEAcBt5oMTBLmx7q9syxrwtX3iRsCsAAAAA+A7SoFr8V9G568B9wTsh
AgHgY+TBHMSB37xE8AOcFwdxQyIAAAAAwHcTCiMRCApVkJGi5DeACASA28kDJQ0ObosSYXYDWRQH
J6vbP1IbAQAAAAC+izioUnuZD38iRCAAvE0cKHkw2zbiwAuEJycSdrZNXWjiIG4qCoHBEgAAAAC+
ojzoiQO/qS4N2feAQAD4K/LA37YoAhV58PS6/XjdfCRCFn1wdFuUCLKXLRVkAQAAAOALiINp8O+q
75EGCASAu5MHVeRBkwQ/3Pbktpi+4KMPjmLz8sCnMKgBEwAAAADgqwiF+L2qgaCEQWynDggEgE8X
B2Z5e0YfeRCjDqJEONhlAcVKHniBcEYeAAAAAMA34a0LfwqOIxAA7koezKYLJvo6B00g/HTbDyEP
WvRBS1No0uAlkQexBgLpCwAAAADwHYTBSFTBOvgzQCAAfJo8mDvyoEUb/HQSQRVPXO1P0cQmDV6c
PPA1EGjjCAAAAADfTSRMxc+iJFjD18yXEQgAdyUPVLFElbrgiye26IPYdUEJBNWBwacwEH0AAAAA
AF9VIqji5ZPlkQhKGKwIBQQCwN8YvFTUQdZpwdc8aPLAF060IA9ehDyIKQwxfYHBDwAAAAC+4tw7
m4v35EG77UXtMo9GIADcDtFtYTRlQQmEmLbQkwdtO/XkAdEHAAAAAPBFJYLa5iASlDxYhUQw4wIc
AgHgL8uDKl3hKciDOcgDlbLwbNv6B7HzAqkLAAAAAPBV59+ZRJgLqWBCHiiJoNo/AgIB4EPkwdyR
Bz9N1zvw8kB1W2jiwG+xdSOpCwAAAADw1amiDuK8PP7NWkiEFWmAQAD4zMGrkgc/hUCIxRKjPIji
4NfrFosnUjgRAAAAAL40SeFydTFPbVU3hqp4InNpBALAzQevqttCkwaxTeOTkwdt4FtMpy00eUD0
AQAAAAAgDnJpsHNbFolg1o88ICUYgQBw0wFMRR706h40iXAI8sAsb9Oo5EEWfUDhRAAAAAD46vIg
EwbZ1v5WPoSRvoBAAPgEVOFE1XHBi4OnK+TBs9hi4UQVfWDIAwAAAAD4QvLA32YRB/uwKYGwmu7I
EGUCIBAAPmwQi7UP9raNPngKAkHJgxh1oMSBjzwgdQEAAAAAvsO8W6UtxNRhte2DRGi1xpQ0WBEJ
CASAj6TXtjG2a/SDWNWmMYs8iFEHqTwg+gAAAAAAvqg88JEFPvLXb14g7N2cfXH3a9apewAIBIBb
iQNlQaMBVYNYJQ+yWgdZygJ1DwAAAADgO8iDOcy5lTzwF+7i/HsX7jMrmrh5OnwiCASAW0uELAJB
hU+1CrAxbeGX1Z0WjvanRsJiRB4AAAAAwNeTBlbIgzjfVi3Tfd2xpzAHj1EGq5tPkw6MQAD4lIGt
GtBU6FQznr5g4rOTB14gqE4LKuoAeQAAAAAAjzyv9vNrP89WtcayyAMvEGL68MXDD8gD5tUIBICb
MVLIZZ/IA7PriiaqegdLHNyQBwAAAADwgOJgSuSBulDX5tYqZWG09tji5uLZHJtIBAQCwE3lgXUG
t51tK7761AVf++DYkQfdTgvIAwAAAAB4IHEwFbcjacKqVbrvehbTh9cgDfwc+2T5hbp/nz6fIAIB
4BYiIYZWRYngBYKv9OoHL1/jIBZLRB4AAAAAwFcUB1NHHMQIX5+2EMVBLJwY2zauydxbzblXIwoB
gQDwAfJAiQQ/0PlbH33gDegpGcC68gBxAAAAAAAPIg8yUeAvxlUX5qI8iNEHT0Ie+E4LJ9u2Tfed
ztrv0ygE5t4IBIC3DoAmBsBZSITZtnbVbJt3deqIA+QBAAAAADyyPMgiDCYxd44X43z0QZMFMfrA
t2v0Rct93bGYNhwFQlasHBAIAO9i6gyEcyIS/h1Pw4B2VREX5AEAAAAAPKA8UIIgi9yNdcVU7YOn
RB60+fbZ8qLlv+yy45mXCIvRjQGBAHBDeaBkwog8UFEIaxAGyAMAAAAA+IryIKsVltUP25uuf/Bk
l/UOmjyI9cZ8ysKzu40t07OuZ0QgIBAAbioSRiIQVAqDt5lVuxiKJQIAAADAV5AHURD4luf+++zv
okRoX8dOC00e+JQF1S49pjC0CATqHyAQAD50MFShVzurUxhMSIKq2isDFgAAAAA8CpU8OAQZ4EXA
iETYh7+fgzxo9cQyaeDFQYs8OFpe/4B5OAIB4F3ywEyHYkV5ELsvTEIKVN8DAAAAADzinNlMX2yL
aQi+c8IhEQhzEAmqTXoslqhqHWSFE3sFzIk+QCAAvIkq8iCGXe3FwObbyKjoAy8SNr9j4AIAAACA
B5sz+wgEXwjRd0/wIsHPp+eBzRJ58PIqDn6ZrndwLMQBtQ8QCABvpzCpVVhVHPgmIQWqgYoBCwAA
AAAeec6s5MHBiYOf9qcVYyyGuLPLrg2+nli8MNfkwUnIgxiBcCzkwarkARfxEAgAbyEzqXFAVOFX
KvpAhUhhOwEAAAC+xwK7P/l8zIVr1nUhtmH8GbYnuyyIOJlOATbbXoxT3RaebRt9cHRb1m0BeYBA
ALjZQD8qD2I1WB99sLgByw9c2QAGAAAAAF9LGvREwhr/994XssmcebJttK5PX1ARCLGugYX58VLI
g6Nd1jpo8qBKW/D3acgDBALALRiNPPBFYPaWF3Y5iS2TBwxcAAAAAI8vDqZEHkxizjfFuWC7v3tc
1A50KvOdE55sWwPhh5s/z0GkLEEW+K/9vNrXPvD1Dp7d705WRx4gDhAIADcb+FXhxKwXrR8AfepC
NKRV5VciEAAAAAC+xhxySr6W/+5u1ygT7ikqoYg6UK0be/NmdeHNX3w7BwHgv/dz6xex+Tl3u09S
FhAIAB9CVUU2mlQVfWBuADyJAS6GUp2NKAQAAACAR5cHUzKXjFuUB1m3rruQCUVKRtapbG95yq+a
N69u3lxtMar32JlnR3mw+Hk24gCBAHDLwX/qDITRoiqDqkKrvBVV4VTIAwAAAIDHnT/6eaS6rQTC
knwfiwpeleLQK+So/r9Tx8Hf9jqVHYo5s9ll1MFRbCMS4WQ6yjfWPEAeIBAAPmTwNzEYqkFQVY5t
A35PHhytk8LAwAYAAADwcPJgFtvOdGtCJQt8/v9sRbeATCRc2/mh8/e96Ap1wS3rVrYLc2YVsevr
F6j2i14OZD9HHiAQAD518PemeCSHK0Yf+J60z2GLeVnnzokBAAAAAN65EL71orEjD3bJNtu2W1dW
NLDX/jsVCYkAUN+PvCdResyWF02Mab7+Ypu/4GZuzny2bStGX8tARRWozmbn4j1DHiAQAD508Ffy
oDKpI6kLfkCMRV1iCgODGwAAAMA7hEHvb2801+oVEIxbDN8323YY6LX+vqYFuCre6BfwPZlQpWVk
hcafEokQ58yrmw/HFozPQiBEQaDeEyVbkAcIBIAPkQdm2hxnaQtV6sKpkAcx+uB8xUkAAAAAgDlc
3i5RfR/nVu9ukVh07arqZsWr8FnnARWWfyqEQnxtWfeHSQiE+B6t4j2spEFWaLy1aYzzZlU08cXJ
g19OIqjC40oS+Hl0GtWLPEAgANxaHmRpC9GmKnmgUheyyIOq9gF2FAAAAGB84a5u/e/XZMF8IRLe
OPeKV+f9/PHJLaR7V+KVPMgKA7Ztev2fKcwlewUdlVxZC4HgX1uVmqHmzF4ixPSFGLH7HCRCFAhn
q1M51uq1MLdGIAB8tDzIxIGyqGrw7/WjVYVdGNgAAAAAxuVBtliW/54smF/velwiJM9DhfH/Xjz/
dNuTW0zHLgR+DqnaErbbdvX/6CSCidfkIwbi15YIhDURCOriWpaicTDd7vzgnkPsuqAuvD279yCL
2FWtLxEHCASAT5cHu2QA/OG+j71r1SCoxIHK37oY2BjoAAAAALrzN3V1XYmEuMDMQtzXKyMRqrpZ
bb74Wxr8z//d/nnd2hV5dTU+XoRq3QjaXHL/ert7vZ1e/8bcfazhufmoAZ960P6mah9pxWvzt3vx
/UH8XUz59REXat48FLUrPmPm1AgEgE+VByryIKskq3K4jolEIHUBAAAA4LbzN5WXryIRVKeDWKH/
VhKhzSNb9ME/rxLhf5xEaHPJSiDEK/J720YuxOdSLfx9F4gpLOZVYUazbVpGrAmmREKMUFCFE7OI
C9W28TQoDxAHCASAvyoPYgEYn7+mws5G5MEZeQAAAADwJrKCfn5hHBfI5uZqq21b/i3u90MSISne
GAsotjmjFwj/5/X2R5hP9gTCL/tTN2GXyBF7/f/VvUcxxeAQ3iv//6o4YRQIPpVX1QXzkmInhM4a
pM1ZyINY5yF7XkQaIBAA7koeRHEQq8juOgN+r9sC8gAAAADgujmcmr/5q9zqKrvZZch8m7O135+F
ZLhGaGQRCHu7rIHgoxB+2p80hjks4qNA8Ivz2bbRr3Gxv7jnorpAqBpe/r05JwIhzpF9ZG5sT5ml
SZzF64ypCsdEHMTICObOCAT4wgP9+Cj8QQPBO+TBTzfIj1aQ7dU+2NQ9YAAEAAAA6M7hsoV6zMvf
hblavOK9s8vaAb57wfTnoctUhqyFY3tuMZr1pxMJ/4Q5ZRQIJ9NX9ichD2L3hcUt+q/phnAWEsHC
fR3cffgLazG1wqdS+GKJ8TVWaQtpm3PmzQgE+NrSYFQkrNl9vHeQSE48vqhMJQ989MHhxvIg5m8B
AAAAgJ5PZvJAdcuKha5XJw9aIUKVArCGxe/oczPTxRQPVqfFHkzX0zpZHkmxhL/z80vfsnJnugvE
D6ujaePCXaVlxLTeveURH6t4jKPVdcLO2XwZeYBAgK8pD1R/2V47nbQ3b5QK1wwchbXOKuVGefDT
toZ1FoN8zFVrX3flAQMhAAAAwND8chYLdL9I9h2zYn0BP19Ti93VzfGyeWlPIszFPNNve9umEngJ
oGo4qLlnDPef3OtoC34f9fCPe5+y+gunsICfhEBQRcWnIGvO4ftqvhwvui3GRTcEAnw7caC+NtO2
13+9CrFw8TP/mIPFbTJ5oMz1iDyIg+FLMhiqgR2LCgAAADBONo/zIfVtYfwzLJB3YeHdOhlkqQCb
BeuVaQxZV4hdsvlaAXMQGrvX57N/nUf6SIajm7se3aLbC4T23rQ5bay/cCgEwikIhDnMmb0IiZG5
S7hPVRDyl112l8hqhjFvRiDAF5cHvS0TCGvxtf9ZDClbO7UW/ONWRjiGlMWaB5U88EUTVeHErHos
AyAAAADA9fNMP497Glwgt4VsC9338sAvWP2V/GuiENTFMyUSfA2urM1kvF81d41RDUogxAKOrYhj
ky0xfUJ1P/AdHWKtCVWXwaxuRfkrSIRfdkWrc0AgwNdBDZRTZ5CMg2W2WfGzKkdtCgNvVfPgyXTN
g1F5UAkEUhcAAAAAbjvP9PO42CbRL5B3blH7Yn+iD6I8aNvO3h+FoJ63uqgW57WLEBmxG0JWPLIJ
hJaCsRNzXC8RvEBo6RM+NcLPYS28777TxVTMj5+FOFDfZ2m/RB8gEOArIWoLKMOq+r9OiUCIg/VS
/M5vyg5fE3mgog+eOvJADYxV4cTYDodBEAAAAGBMHliY06koUh+B8H/cfK4JhJa+kLXgbsUV/bxN
dmQY6DRWRd+q+a+fY8YuCKojQozCaO/F4l7fznRLcp/mEVMYYlqFf1xz773vsrAKeRBFQZQH6qIb
0QcIBPjG8qCX46VCtbwkWAa+XsOCfE1ONlXkwSEZWH/YtkeuWW5W/cDYM6gMggAAAADXSwTV4cAX
9fvpJEKLQogCoS2YVyEPfOh+jEIwLxHeKECyi2h+nhu7ICiJsNo2EuFgly0Sm0BQc1x/kSymeFiY
c/vnGaVB/PuePPhlea2woxF9gECAbzeYZyFVftsVAiFKAr+dk59n6Q5RHmRVcH0Lmjiotuccw7KO
YnB8DgOiMqgXAyGDIAAAAECOWKhnxbBjFIIP029zutWJAbO8BffB9BXwxd53AaiKQDDTBQxPyZzY
z3ejQGgiYBICQXVO2Nll28fsOfpIiZiCEQsl9uRB+7uT5RG7RB8gEOALD+hZVwNvNlWhlaryrcr/
OhcyQQmEzFLH1IXYxzarJhu7LaiQrGMyEFL3AAAAAODti++pM7/z3Rh8mP4PJxDOdhl9cAwCoTeH
u7atY5QHWVrDatsIhKPpVoZeZESJ0gonLm6tpiJs1UW9WA/ilLwXUS7E56yKJVZFxpU4oGYYAgG+
yWCuQsn84lwNWDFvajFdCTfLB4sDjQ2IjYMQCL1WNFVOlyr+QusZAAAAgM+Zf/qCiqrGVVuznN38
M7YWVHn4Z7FoX97wPC0RIKoDQ1zE++dgQWa0139wz6+lXkxuTh5bksd57mqXqRzP4f1QMsXP36vC
ic/i/mKqwlkIEuQBAgG+Ckk7nSyUTLVCzIzniDjIRMIqBvQq+sD3r/VyY///s3cuym0rS5ZNgKTs
7vn/P53pa4skMO17VUfJjZ0F0JYlPtaKqCD1pCQ7qhIL+TByo1e24ObW0vwFAAAA4O9LhDGWfbj2
JuY7JIHQLppPIg+2jt/WLIS1seVuOpmuuRAJenEd8to7iTHH9DsO6W+QBULu7TXFZS+I3kQx97eY
CoGgfQ4qObNalow8QCDAY27eQ/Tr0NrSiQaDbEBT1A1j1iSCa3IzFmIj/4z5fdpRdjLy4NcG6LIO
tGmia5zIJggAAADw9+JRd2OrxYDtbn0WCLmE4SjrHHVG6bTy+m6MeZ5Mpk3G2/eubrDpGMdIEiD/
bjqpocW5Wr6Qyzi0d4HGudrXyzV91GaUx0IcuDjZ3WwjbkYgwINu1hH9MgFXg/YSvoTBdZjdIhF6
GQhj8bNlaaBZB3nD1lSutYaJyAMAAACAv4+7Gz8X8are7e8JhKo+v13Eu2wBJy56I813KwKhKm2Y
w2c2zOl7uAkNB1m79D3PEuf+K3x/LycQtG/ZOX2uPq5ldSz+DYmbEQjwKLu1L1/QUgGtO/uWBEK7
cHd3+s9xaU+n4rmTDJVAGGSDzo0c97EcKznFsitvJQ+2WGrkAQAAAMDHywO9Ez5HfUc7Zx/EW4yq
afc9kaAxXsj33jLKXGPRHJO279Uuwp1EiLjsfZCFSCVONPat5MEvYfA/bwKhrS2TxVwG8SkumzC6
rOEJcYBAgOdjy5SDqlFhHhfjLG7I924bzS6WzRbXpjD0NvSxEBnXyIPVngdshAAAAAB/LA30grMq
g51MXNZoPRLmWKbdu34IOd7bFQJBey/s5HFfXMirQJiLOLUnB/LnjZ34PH9MMwZa5sH/pPWvuMxA
cGXDLhtha9PzRX8w4mUEAjy+PHAX6a7PgE42aJtY70DQ18kb4ywbeLURbUknq+bX9uSBNpQ5Iw8A
AAAA/kLA+b9xlGS/hhEHa6MH2+dkiZAzZr+9xXbf3+K97yn2a3HsMS4zEIZYTkKomnW70tl9IRDO
5oK/utE2iqzYdeJdnbjQsm1z9sGv9f+SQPgRyx5fk5E37t/DZQnbrANiZQQCPDAr0xdcs5q8oW2Z
vKCG0gmFvGH3BIRusNrQJuK68TOuWWLVkZYNEQAAAOCDQ1Fz4erS50+xrL/PPQxyeWuWCHlqmI73
3sd7FsJcXMjr96rGhev33cnPt4vlzS6NcdvrZmGh5bku7tYJY605uMtA+BGXpbq9aQlVGUk5XYFY
GYEAz4XrMrvryAPtMRBxmTqm9VFz+EwEzXyIJBTWftb8ubmDritbeC3kQTV+hpEzAAAAAJ8vEZw8
0DLTtvaxLGfQzAF3sZ+zBSaJZ6sR5rqclMgX/K20YBd17wPNfNDXzt/XyYOjkQctA6FJBO2B4Pof
uFKS6IgFsg4AgYA8WFycux4DYyynLaj5VEPs0sw0c6A9j2KDrQ4bt7GpPMib6Wv4GbjV+Bk2RQAA
AIC/Jw40g1RvBJ2MRFCR0OJTjTG1rMDJg73EfC4LoDUO/6//Xf/99pinkX2LZfbBmGSEli1o+n+e
BNF+By0h3qfvqV/f/lZZHjRh8CP8BIZqlOWWR5okAgIBLjZcN9s2ywO3AbqmNZpili1r1cNAyxFU
bMyFRJiMPFhroOPqv5yFZVMEAAAA+HsSoVdv7+RBXvu4HMM4GZGwK2RCez7L92jvbxkG30Ug/B8R
CS4LQfsp9H7XMZaNId3NvKGIe/PIxh9GHDR5kDNv7YSx8KM0y+fEyIBAeLYde9nApurwqrVbYTa/
k7lYz4ZzLl5Dv7ea2mHlsMnZDz2BoON7XPdZ5AEAAADAX8Y0UtxSxqCxXR4jnksBziISxkIkHNLn
tQyGsZAH39+kwX8nifBfb+tbXDZXzOUL7nd0kwyG8GW/g/kePXHwL5EIKg6qkl2VBFHIA+JjQCDA
xeak2QdOJGSL2jY516gwX6xPHYHQkwi5rCGiP+ZnWjloXN3cFIWBZWMEAAAA+BS2jHF0maWHdOGf
ywD0AjnC9xd4icvMhVY6oH0P/isJhJyF8H/ish9C7hVWlWdorDrF5dQHd3Mri4g8aUHFwb9EIriY
3E0a68kDpAEgEMDKAycRXIaAjoxpj9mAts1MN6utAqE35iZvcGtzak/FOgdjGgEAAABuRR4MciE7
he+HkEdzN5GQpcEoF9n5JpZOVji8fU3IBX/LPsh9D3T9tzzmPggtjp2MPHAC4RyXI873sSzP0Li3
3bBrUxb+X1pt4sKP8CMbq8aJSAJAIMAfSYSxIxF0M9Tsg7xhvcblmB0nEKpShp5A6GUg6EFzlrfV
6CIPAAAAAG5DJlRZCFkKtLgzly+oQNDYL+IyA+El3u/s79PHc/aBEwf5/bm8Ifc/yLH12mSJc1yW
YOjks5Yd0URJa5b4I8mD//u2mkT4V1zezKuaJhL7AgIBrtih+/0PqqUZCLkGy5UwvMYyAyHCl0lU
r7VFIMyxrCU7h68xq+bdsoECAAAAfBKmD4KL96o79y37tcWkOqVAywRajJdLGOa3t7V8wU1dcNKg
ZR7k/gdDXNfL4RTv/Q9CYuH8uw1xWb5QCQTNPtCpaPT6AgQC/Pn+bZYb4Zh7EuTN2U1fOEadgRDh
ey24SQxbBIIbhVMtlQ6BPAAAAAD4UrSRXy/Wy3Fn7n9wjGWjb9drIDdJbNkHc4p7W/mCK1lw4qD1
PlCBMXfEh/bnaiJDY9EmDHImQhMnrXwhlzD8T7z3QHANzcvSBQAEAlwjD/Sivrqwzxf1Ov3AWVVt
VjjJa4/hGze6CQy9JoouG0EzE6rPD+QBAAAAwM3LBXfjSvsf9JoW5l4ILdMg917IYkHHNubsgywO
DiIPhrhuBGW7wG+vrze4zm+f00RJnrzwIwmE/xF50LKBXenCP/E08S8gEOBPRYLLQOiVMKw1hOl1
es3dbntLJUdPIkQhDCI6M27ZPAEAAABuRhaoOND4Ll+cN4FQ3eRymalZIuRYszd9QcVBlgf5++ho
c83KfS3ebj/TWb5HmzQxFgKhCYNctpDlgZZwkHkACAT4MHmQn4+x3gchjETYsmZ5TTcBImJZujBs
OFiikAaBOAAAAAC4W6nQ64cwxbJ0oBoLOUssmkt0W1+EJgo006AtN5lsrbn4T1kqENpNNZ0c9hqX
TcyzQMgSoRrZeApfykscDAgE+BCJsJaFsNaPoNekcCou6IeVx55AiJ4gKN7HhgkAAABw+9Jgi0QY
wvcdsCWrReybJzPsk0jIkxDG8GW1ruQgNzn8UVzk/0yCoQmEeHutXPagGQ7nuMxg+GGkhDZNRB4A
AgH+qkSIQiIMHYngLtyr8gJ3IAxGKETxdqyIhOptNksAAACA+5EHa9kETSDki+M8fcvFne4mWY41
XazrXjcLhEbOPmhNDl1zQ5ctcE4/317kwZhiZc1wyBLiNS5Lic9GqBAPAwIB/oo8UIng1rxygf87
B0UWCqUIWBMFSAMAAACAh5EJvWzXLBEmuXjWEeKacTCma54xfOltLpUY08+QJ5RFXGYftMyA1pvg
/xmRkPsVHNPPOsb7dIn8GioQqmkOmnnAuHJAIMAH7caXM3fdXf9hRSZE8b6IOkth9cL+7ee6enNj
QwQAAAB4OHHgJIIKhUgX++5Cepb4NF+c6+dUWbfttfbpa/No8/z6KhD+79tSgaAZCPlnyJJDfwY3
/axqXI48AAQCfDpVZkLEcuTiGH4M4+YsBTY3AAAAgKcXB1tGcrfGg3phne/I6/jGfHHeeh3kbAX3
Gu1iPY9r3El8q6UFWzIQmkA4iUDQuDrk98y/61nEgfs9iK8BgQA3JRPUklaNF9XiAgAAAABEER/2
yhdcBkK+2FeJMEvsmgVCG92YR5CfRAjkhop5ckOE703QShT+x6wmEF6TQMi/09CJn/Xv4BolTvnv
iTwABAJ8xYbtutg6eZBTwiqJ8O/v8atUgQ0NAAAAAFZi0GklLtVsAScRtEwhT104xLKfgI5u3JsY
d+gIhDxiUVduoKgND3OcnR+jkAguSwN5AAgE+Hh+bSjSB6GqM5vMRq0ZCHkT3qW3T+nj2aq+OQQk
AgAAAABcxogbJUKEnwKWMxHyZIIcx+Z49ZAu/Js4eInLUY77WN4gCxEI+TV/xnKUY56+UJVZhJEI
a4LF3uwjxgYEAnzW5l3VnenGPYpA0FSwVivWNsUxllkMSAQAAAAAcDHplgvmiH42wimWpQy9+PU1
LrMOKnngGizm18sjFnO/g9dYjltscXZskAhVc8lAHgACAW5BHuRUMK2virgsY8ibbDO5WSC4jrlI
BAAAAIAnJmXFLm40Rb+ctopl3QjGY4pJI5ZZtEeJYzWz1gkEfV1t4piFwTE99sYtXitXEAeAQIAv
kwe68Wpn17bZZQvrGtG8vH3ey8rGuJAIbHwAAAAAxKUb49W176GNFXMvhDzS8RSX2QgqDnq9vZxA
yOMktbfCeUUezL/7dyKGBgQCfMVm7Tq79ubLjuHryF6inkXrNvd/NkGyEQAAAADgD+PZ3k2xnEl7
jsum4CoNtGmili+sCYscQ6s4cH3Gro6BiZsBgQBfsdEOnc02j7Fp6V072UBbCcNkVs8ST/KIRAAA
AAB4pkD0sql3hG8emN8/dGLaiHr045QEwixx7CgywY0ld9kHKi7yaMWzWVWMvDnuJUYGBALcmkRw
9rSJAycQdh2J0KtXG96+dziJAAAAAABPxSCSoFrRuYB3Yw5dc/Acb6ogqB57P0PVR6x3c42pCYBA
gDvbpS9HOeaNrBlYFQiv4Wfg5kaKW5rdDG/fM95eayERyEIAAAAAeFqRECsi4RpxMBcX8fl7DG/v
HzYsJzAi1ieZ2awD4l1AIMA9kjeyKW2irQnMLupmMnlDHVf+7wydDTeCEY8AAAAAzywN8qMrI3AX
8/NGYVDd5HJZD9GRBio4tkyOIOsAEAjwILv1tiyENYmQm8w4SbBWL6YCg80UAAAA4HklwlhIhLGQ
B9rnYIs8cM29h47QCPM8zPcIE98ychEQCPBwuCyEJhGO4TvU6mauEqESB7GymQ+6yQIAAADAgwWf
voFiTx60pTFlblqo8mDt5pQrZ5gLWTBcEVMH4gAQCPCQmCyELBByeYITB64XwpZaNdfYJteeMZEB
AAAA4AlCUbM0yzXHoINc5Gv2QTVKfJF9kGNMiYVVKDg5cI1IQBwAAgEekryxqUT4tWG/Rm2Cq6yE
nkDIG/1ONnmyEAAAAACeUyT0xinm2NKJgy1jExcX9Pp2IRSu/6UQB4BAgIfcsd+zEKpShlPURniM
OjNhFBngxkSe3r7+nF4TAAAAAB4UKV9YyzzYFXHl3JEIW8aK/9GFv5ZgIAsAgQDPKhHyxX5vY+9t
8lHIg7ap/+qrcBCJkD9/CLIPAAAAAB46/Iy6fKFq4K2ZrZU86JUxfFjszD8hAAIBlrVfbRPeUpvW
kwg58+Dwtl7D17W9/wD0QQAAAAB4BpGg073WMhC2lC8spAFxJQACAT5q916WMkxpY6/6IbSNfZ/W
9Pa+diDoxw/peZYHWt/GBg8AAADwQKSM117mwT76GQi98oU/LmEAAAQC/L5EmEUknMxGvzdLxUB1
IFSzfQOJAAAAAPDQ8qCX1epiyi0CwY1xRCIAIBDgEySCCoRqtGPOLGi9DfbxPlGhSk3riQMAAAAA
eFx54GJKd2NKMxByA8WzSIQpltMXLmJc/iUAEAjwF/d6ed426PFNEhzf/r8c09unuBzPmA+LLAzG
8M1zkAcAAAAAj011Q+oQlzelNAMhx6S98oWccYA0APjLjPwJIBlaHZOTN+uTkQen6DevQRoAAAAA
PBGdvgcu60DlQc5cXRMIUxGDIhEAEAjwBRJBRUKerLDWwAYAAAAAkAeu50EWBy/hMxAa7oZWJQ/o
fQDwCVDCAOUZUEiELbVnbOIAAAAAz4nrezCKOHDyoAmEwciDk5EIroThPz8A/Q8A/hpkIIDbbOdC
JGiHW5UHVRdczDAAAADAA5OyDyL6U7yaPHgRiVD1P8jiwMmDiRgTAIEAN3AOhM8omKNOGdsqD5AJ
AAAAAI9Hb+rCXsRBFggu+yBLg2MhEChhAEAgwJfu+v2Ur7mQDNeu3vcEAAAAgDvCZB9UUxfWsg9a
bJgFwpYm3ltjWQBAIMDfPhOu+LxeA8Y1mcBmDwAAAHDfuN4HuYTB9T/Q0oUpCYO8ehPAiCMBPgma
KMKWg+AagRBxfRYCAAAAADxOzKjyoNc8MWcfuNIFlQhnJALA10EGAnw0W3slkH0AAAAA8HgSwZUw
7EQiOHnQYscmD15l5SwEjTH/8+KULwAgEOAmD4S8woiAtaaLKhvY9AEAAADukNT/YJCYsSph2Is8
cI0Tj9HPPtARjsSQAAgEuNdzJH6vmSIAAAAA3Cc9eaBjHPfp/W5kYxMIOfvgFGQfACAQ4K4Ohd5y
AmHLOEcAAAAAeNyYUcsY2holjuxlH+QJDOdgdCMAAgFuh2IMT08kqDxQibCpfAEAAAAA7l4eRCyz
EKpVZR84iaCNEy/GN5J9AIBAgNs5BCpxMBSS4dryBTZ8AAAAgMeJH10WgpMHvfKFXLLQ3l+NBwcA
BALciDwYOgdBfoxCIEydzZ4NHwAAAOCxRcJoJIKTB00gnOMyEyFnHtjGiWQfAHwue/4EsFEi9Ozx
IPLAiQNXyqCPAAAAAPBYMWRV/prjwPPKslkHyAOAz4cMBHjfxS/7H6zJA61f6wmEKXxzRQAAAAB4
THkQRiSEiR3zzSaVBr1sVgBAIMCNbPxVuULrpFuloUXa3PPGf466jIEDAAAAAOCxY0pFY8Gps1z2
KtkHAAgEuOFNX2f57kQkaAlDrlXr1q1xCAAAAAA8TOzonuv73FSuKfpTvKxEAAAEAnwRxfjGShz0
BIJrhFON3eEAAAAAAHhckdB7n8qDLdmqxI4AXwxNFMFt8FuyD9paa6LYK1/gIAAAAAC4U9INqOHa
LzWPvTiR5okANwIZCKDyIKJunvg7GQhbJAIAAAAAPLBr6MR98wa5AAAIBLgjiZClwf5t9SYx9Jrg
TIsXxCIDAAAAPJtM0EwDbi4BIBDgLnbxbeMbNesgv+3KGFwt2ySfAwAAAACPIQXc897nbfl8AEAg
wA0zFKsnEtx4njnWa9kAAAAA4J4Dx8ss0t7zrbFgjit/t78CACAQ4EZkQpYGo/l4bDhEkAgAAAAA
j88c6/0NqpjTSYR/3m+yZwEAgQA3KhKiIw704/r+6m0AAAAAeAxpoI+9/gbDxkUcCYBAgDsXCm7j
33oQXGz+WGQAAACAp5EIcxFbjmb1RAIAIBDgRg+BeePmP66sUiIAAAAAwMPFj70R3sOKPKjiyP+8
ADegABAIcNPiwI1kdAeBNlvUposjEgEAAADgKeLHtZtQvdjRTfwiGwEAgQA3vulnaXA2q8mEwRwC
+7fVEwn/eWEsMgAAAMC9x5EuruzdgGrxY44VNX4kCwEAgQB3cgi0jT4Lg5PIg3wYDEYe7IuDYGGR
OQQAAAAA7j5+7GWwTtHPQNgbibAPP0KcuBEAgQBfgZnhm1cTBU0e/FrH9Lx9PEQg5M3/ELVN5gAA
AAAAuH9xoLHktEEiuNgxx42rsSM3oAAQCPDZO/5y41VrfBaBkCXC2RwE+QDIqzoI2tdxCAAAAADc
r0TYIg+2SoQcP1YSgbgRAIEAXywP3CGgJQwuA2FO/6dy9sFLcQhwAAAAAADcOZLJGrGtj1YugW0S
QUtgt2QirMWzAPDB7PkTIA3Sxq0X8q4PwkmWNlLMAuHw9vEmD9rX7NPXjen7D7FswAMAAAAAdxJm
GomgMWQWCbsUh2oMeZL4sX3N+PZ9c9xK/AiAQIBPkgb66EbkVCUN1SSGnRwaL3FZ+rBPB0F+rbn9
rMZmAwAAAMB9SIQqAyHfhDrE+82k8QqBYCc6ED8CIBDg74iDYaM8cOMWezVtuYShMckBkDMRWhpa
OwiwyAAAAAD3LQ4ilr0QXAbrKcWDOdZ0vRDazSctYSB+BEAgwF8SBz1ZEEYc6Egd1/VW5/i679vM
chYITGIAAAAAeCB+3fmX2FPLF3SaV7uhtI/liG/XCyFnsLYbUIssVgBAIMCfyYNKGGjzwkHeN8Yy
nWxnNvgh6syFtpnvi1U1UiQNDQAAAOCOQ9GoeyA0EXA0MeEulr0QevFjy0K4iIGJHwEQCPD78mDo
iIJx4/O2Ua8JAJUO7ec4R909l+wDAAAAgMeSB5HkwRDv2ai7WPbE0gyEsSMR1m5oIQ4AEAjwQfJg
LNau8zH3uTmV7NCRAm2ywhyXVrmSBxwAAAAAAHeMlDHoOPDRyIPcILHFiXmso2bBrpXAEkMCIBDg
D+WBkwW7zma8llWwE4GQJYLLPnDlEdUCAAAAgAcIS9NjzkLQZoqtF0KertCLY12cShwJgECAD5YH
WRJoGlivtKBKJVOJoKlnvYMEAAAAAB6UDVkI1USGPNJxLYvWSQOyDwAQCPC7e3f4sgMtPziErytz
Vlc38SwgsngIOTCmqMc9AgAAAMBjolkIreHh2awpPboJX2SyAiAQ4MN25zr7wGULvLytQ3qsBIKr
K3OZDTnzYE6bf2uYc4p323w2MmFGKgAAAAA8BkUWQo4PpyImnIw8CBPnIg0AEAjwEft1+OyDLA6+
va0XkQm5zMFtyrNs5JpGNpvNv0mD1/hPjdtJhIKTCIgEAAAAgMdkNkszVNv7twgCJAIAAgH+QB6o
RFCB8P1NHnxPIiGXMmgDxHnDxX3b+CNt9nnmbxMIP41IOIcva0AiAAAAADyGMNC4Mjox35Z4cO58
PwBAIEB3V35PD4vw5QW5dOGXMPivN4HwPd4zEFoJwxB1KYJmC+QNfIpLA9yyD85vwqAJhCYRXo1E
WBwCv9Lf+BcGAAAAeAiq0tj8fpcB625mESMCIBDgAzZk1+hwH5elC9/TahkILftgEAEQb48uxayy
yHMhELJEOL6tsxEIHAgAAAAAjxOjuph1rSliJRCq8lfiRwAEAvymRFjLQMgSIfc/GOTiP+Kyyc3Z
XPBPnc09j+jJEqGtVspAQ0UAAACA54hTdSyjjhGv5MEWcUD8CIBAgB5SvqASIY9v1AkML7HsfRBy
MX9OF/+53MBJBLfBn41EyOsUPgMBAAAAAB4nRnXiYBRxoFPAIvojwWnCDYBAgD/AlTDsComQV96s
VRy0LIHXQiK4kTtTISF6MsJmH9D/AAAAAOCh4lONUVucuovldK8wsaXGju4GFPEjAAIBNmzOEf20
sCYQ9majzlMTcrlBbniYJUJv854KiXCOZQbDubP5AwAAAMBjiQQXo+blJoG5UlpGgQMgEOAvSQS1
uyoPIm3AWR78iPeGh7np4TH6GQi9NLOzee5KIMg+AAAAALhTNpQvVPLAZR9slQfvL0gcCYBAgM0S
QVPEXL1Zlgdtg84lBk0a/EgioZeBoP0P9PlkZEPZBIdNHwAAAOAhY9OhIxB2Ufc+cJmsNOAGQCDA
X9isRxEJ2tk2b8w6bnGLQJg7a1p5ezHLF3kAAAAA8DDxqMamrnGia54Y4bMP1hpwE0cCIBDgA4VC
yAZ7zchFbaS4Nkpny/svNnrkAQAAAMDDxqFuAsOW5ok5RnV9uOilBYBAgA9mLt7X3p97EuRNem30
Ys4qiEIMrD0iDwAAAAAeWx7k51V5rWYfrPU/sOULxJQACAT4GIEwdzbc3ghGnaJwinp6gpMD3bQy
NnkAAACApxEJPXmg/Q+0ieJa1gExJQACAT5IGFSTEXSj7n0v19+gEgjdjRxpAAAAAPB0AkFFgi6N
IXvTvKpSWQBAIMBviAPXudalf7kN29nhqn9CrAgEhAEAAADAMwWjlyMct8qDYSWenVekwdDe/vX6
xJ4ACATYJg+iIw9Osexe20TBLNKgGq0zykZv08bYtAEAAACenupG1drHqvg2wt+sGlIsi0QAQCDA
lRJBU750usLh7fkuluN1mjzYm9W+5lxs+sgDAAAAALhWMjgZoBIiZ8jm51VG7dwyIYhNARAI4MVB
FgjVaMa29uFn7maBcEiywY3OmdOmzcYMAAAAAFtj1urtiPWGiy0zdnp7O0wcjEgAQCDAFRuz9j1Y
Ewi78BkIL4VAOKdNfI7LZowAAAAAAFVzw7XGh4MRBjkrNt/MinjPjnW9EhAJAAgEuNhh/3cTTM1q
qgyE49vm+5rkgfY1yDKhZSCcjURopQw5ZYyaMwAAAADQ2DSibrztLvZdVmyLS09GHgxJHlTjyhEJ
AAgEMOQNuV3cn9MFf8s8eI1+Y0QtYzhFnYmgtWdsxAAAAADg4lTXq8uNBXfxaIs9VRy0LIUmFnTU
42REAje9ABAIz02RhaBNFKvpCpoilrMQzkkiHJKEyA0Vm0QIJAIAAAAAdORBJRJmIwd28nkqGMZ4
z7J148q1tGGSnwmJAIBAYIOWjbptnCfZZHdJEqhIGEQi6Ko+H3kAAAAAAL3YVMeMa+ZAlgQ7+R6a
ddBiVc2SbY85M2GKy1KHf34+JAIAAuEpMVkIeaOsshD2RiRUDRXzcvIg78RsxAAAAACQ5YFr9J0v
+NvFvt7Qugh5JY5VgeBW+976M03ErgAIBLjMAsilBU0KHNOm++t5K1HYm017NNJhjDoDgSwEAAAA
AGgxqd7c0gyEfKHfZIA2+G5oXJr7dR3NY4tXTylOPacYmSliAAiE5yZlIbh0MbdRH5NEyKljQyER
KnkAAAAAAOAkwtyJSVt/rb2JMbM0iLjMStDyBder67ghZm032yhlAEAgPL1EiLgsZRgKiaBpZDvZ
qPPG6zIOdEMmCwEAAACAeHStwbf25tIL/b0RB70eCPskEqqeXVlshMSsxK8ACISnRksZcj8Et3TM
jQqCsSMNAAAAAABcPDpEPwPBjRXPMelOPqb9ulQkuGljITJDx58zlQEAgfC8SCmDbtq6zuHH6DhB
4LIN3HMAAAAAgEgX6/mG1ikuR4nnGDNf1E/hG3jn7FgVCVVGgwoEzdTNPysAIBCeetMeig2zmsv7
OxsoGy4AAAAA/BspY8hiII8Zry7w242t1qdrL1KgZRU4kaB9FCLqG2mjxMpkIQAgENi0zcX9XDw6
KTAXb8/F17HhAgAAAICLDVUiVLFn7pVwiMtGi1kSaOlDNWa8Eggu+5ZYFgCBAB+w8VfZCWyyAAAA
ALCgmBDWygbOJt7sjXjcG5HQVtXg2/VeaN97F++lvIwjB0AgQG8/X3kMIwg+stwBAAAAAJ5LIuSY
cTLxppvU0BotHkQg5Ldbo0UtXXDZDFlItOljk/48lDEAIBDASwRnaocVeTAVEgEAAAAAoEInhEUR
Z2oWwv5NImRx0EobDvHeK2Efl6UMu45AaMuVQQTxLQACAVnQFwdaMxbFpl6Jg4v3YWwBAAAA4J/g
c1nK0CTC3BEITR606QpNJBzePqZjyBvVmMdDEhE6qWGM91IG4lgABAKsiIMxfDbCmkAgAwEAAAAA
fkciaOPCOV3Itwv8JhFaz4ImD1QgaKyrEkH7J6yNewQABALEZZlCtULkwZpECCQCAAAAAPyGRIi4
vPOvfQt26bE934s4CJEFo6zcbDFLhGqSw79/FvogACAQkAfr8qBXwqCjbpAIAAAAAPC7EkHjxxxb
Din2HGPZ8HA24iDLAf24ioQsG1wMTCkDAALhqcVBJQ+qxjFDRxZMiAMAAAAA+BOJ8O8g8j0bIZcz
nONyrGKTCCoYWhzbJipoSUOYmHcMn6nQm0gGAAiExyeZ3Z5YcFJBa9O2lC4gEQAAAADgapFQZCM0
JnNRP4gwaI+aLdsYzepl4ZJ9AIBAgI4wiPC2dYtEmIuvAQAAAADYJBH+HUQueyNojHlNPy4X6/am
j5F5AHAlI3+Cp5MIUWyc88pmHUEJAwAAAAD8BZFg4tBKDFRCYNwgENbiYgBAIIBskj1+e2wj3WoB
AAAA4INiVte7q9cUcRf95uAR3PwCQCDAb8mCawyry0pw7wcAAAAAuJrUC6ESB3kU48GsvQiFQeLU
LRPFiGcBNkIPhCfcp83bbhOtNlU2WAAAAAD4W/IgZxvskzh4SY8vRiBkeaDjyHVtyrAFgCVkIDyX
OOg1SOwxFM/d2wAAAAAAfyIPdkYe/FrfZGWJoAKhTWrQVUkEZAIAAuGpZcHax1xmwdyRB92OtRvG
RwIAAAAAbJUHThx8T8/bx3IGQiRxcEqPp/R2TyIAwAqUMDyHTNA1Fc/zGJ1hqzwAAAAAAPhDeZCb
I+YeB9+SRFCBcBB5MItAOKbVkwg5bgYABAJ7tZEJlURQhs7Kn8OGCwAAAADXyAOdrtDKFl468uAl
ltkHKg9+CYPXtFQiVI0VAQCB8NTSYF6RB1NHHuRNvZqvSzYCAAAAAHyUPGhZB67fQZYHue9BJQ9+
vq3X9NgkwrmKgxlNDoBAeCp+bXqmH0GvC63bPKsxOlUmAhstAAAAAGyVB1q6sNYsUeVBnrqgZQtZ
HvwQeXCMyxIGmigCIBDAyAPNPuiNsunZ4VFkwuJwwNoCAAAAwIo8yD0Prm2YuI/Lm1hTXPY7+GlW
K2XIfRB6mbgAgEB4WnkQhTzQBjJTLLMMevLAlTKw+QIAAABAxZZpC9/D9zzQaQtzXDZLbJKgkge9
8oV/x7DcCANAIDy7PBhiWwbCOZY9E3SD36VNe0wfn/hTAwAAAMAiGL3MPsjxo8s+UHnwvZAHc5IH
xyvlgU5hQBgAIBBAJEIlD87RH2OzloWgmQhzOyiwtwAAAADIA4kpc1y5j37pQpYHredBznhtMmCL
OOj1PiD7AACBAKaRYk8e5JXTwtxMXpeFQDNFAAAAALBhafiGibvoN02s5MEk8qDX7yD3PDjFZdbt
hDwAQCCA55o+CLmRopriLA+cRAgkAgAAAADIjSw3snHLuMZ9IQ9yycIPIxCOsWyYSN8DAAQCbJQH
g5EHLvsgb6pto3Y9EHaxLGWYgjIGAAAAALgUB1tKF1QiHDbIAx3TmLMPev0OkAcACATYIBHmKyVC
NWbHiQTKGAAAAADgP4HnMvtA48lD+PKFF5EHY4pdzyIPfhiB4DIPJuQBwMcy8id4TMymuFUiaCNF
zULYR90LIdIjAAAAADxpKBq+HFYzEHK/Ay1bUHnQhEFevVGNOq4ceQDwAZCB8BysTWGoyhiGQh44
iUAZAwAAAMAzB5x17wM3tjGvQ5ILY4pfNfPgZyEPtGzB9jtAHgAgEGCbPBgKiTAVEmFe2fhdL4Rs
miMoZQAAAAB4RrS81fU/OBQCocWULUY9fZQ8QBwAfAyUMDzy7v2+UbpJDFX2gQqEoZAHeQ2xLGUA
AAAAgOeWCHojKsuDQ1qudGGtaWKTB0fkAQACAT4e1wNhCp99sJaBsNYH4T8veJnGBgAAAACPGmgu
4z4th9UMBJUH4waB8GrkQVWOizwAQCDAH8iDNZHgmsysTWFwAoEsBAAAAIDnxJUv5LhxLyJB5UGL
VXP5wqtIhGMsMw/OKbYN5AEAAgE+RiJcIw/yQTCuyAOXgYBEAAAAAHhekTCGz0DIK8eUEZfZBzkD
4bUQBxPyAACBAB+5e/fHOWaZoF1qq/E7lTygBwIAAADAE2KmL2gMuVYO274+l9cezTrFZcPEs4ll
kQcACAT4W/t9LBssxoYDoJd5EEgEAAAAgKeTB9X0BS1hcBmtuXwhS4STWdrrIDcARx4A/GUY4/h8
wqDH0HnfcMUCAAAAgOcjy4MhCQLXB6EnD04deaACIZAHAAgE+Hubutvkq83f9TUYOrIBAAAAAJ4A
U7aQy1pdyYKKg1HiSZd9UE1YYFQjAAIBPmFzDyMArskk0JIH93EAAAAAeI74sj1qw0Rd2vegyj7Q
EeM9iYA8AEAgwCeh6WWur0HIpu6aLrrmi2zgAAAAAM8hD6qRjXlUY1uahZBvWDl5oOJAY85AHgAg
EODvS4OqwY02RVR5kDf2CYkAAAAA8JTiIDqxZJYEL2/i4EVEQi5hiFiWLmjmwdrIcQBAIMBfkAcR
/YkKrh6tbc4qD6pUMuQBAAAAwHPIA1e2sE/SwK0mF9rXzuGbJ7bnOf50o8gBAIEAnygRtA7NCYQ5
rqtFC0QCAAAAwMPJA9fvoCcPvv3v+v72mLMQWtwZEmeuTVxg6gIAAgE+acMPIw+qVXXDXZvDW9am
AQAAAMBdx5HX9Dto8iALhCYR9rG8WZXjzGNHIhBnAiAQ4JPQDd9Z49wN183jPRcbermpY4UBAAAA
HkoejB15kEsVnDw4JIEQEmMezWoxJ5muAAgE+GR5oAdAb/pCVb7Qy0CgiSIAAADAY8mD/Dh25EGT
A9/iMvugkget98EU71kHv9aryIOqhIE4EwCBAJ8kEnrTF1z/Ay1fOEadgcCmDgAAAPA48sCVLORJ
C65k4ZuIhIORByHy4NUsJxHIdAVAIMANyQPNPoi4nL5wiv5YHTZ1AAAAgMeSBzqicZeEwMtGeZDL
ZPMNqmMhELR8wU1gAAAEAnyBTBjl7YhLM9ybyctIHQAAAIDHlgeadeAmLfRKFvbp+2nZwi9Z8FNW
LmOoRoZzowoAgQBfIA8qiZBFgJMIZB8AAAAAPIc8aOsQlyULW+RBHteYM1q3yIO1flsAgECATzgM
eiJBRz7OIgnO8ujkAZs6AAAAwP2xNqIxN0qsxIE2Sxzj8qZUkwdZGvx4W00gNIlQ9tjiRhUAAgG+
5nBw8kAnMKhEmAp58M/GzqYOAAAAcB+YcY150kLVKPF7euw1S8wTvXKvg59JGvyIZQZCzj6gTBYA
gQCfLAy2yAPNQHACYQ56HgAAAAA8mjzoZR8cRBx8F4nQMg9yyYL2OzjFsmQhC4TcQDGXLdD7AACB
ADciEiK2lTBUmQgqD9jMAQAAAO5XHuRpC1kevIg8+K8kD3LmQfvaiPV+Bz/C9z7QUeH0PgBAIMAX
S4St8iCMPKgyD5AIAAAAAPcrDzT7QOXBtyQPskDImQc566DX70AbJx5jOXXBygOyDwAQCPB54sCJ
hKGQCioF5g3yAAAAAADuSx5E+L4Ha9kHrffBQeSBa5b4I3zJQpYHOnEBeQCAQIAbkAhb+x9EIQ+m
qHsfsLkDAAAA3Jc8GKPue/BiBELugdAaJubMg1a2kBsl5lWNazwjDwAQCHD7ImH8DYngyhbY1AEA
AADuUx6oOFB5oJMXdFRjxGXZQpYH/9ooDybkAQACAW7jkHDiYEsmwhyULwAAAAA8ujzojWzMGQcq
D3ZJHuQxjSoP/hXXN0tEHgAgEOCL0B4HKhPGuC77gE64AAAAAI8jD7I4yCMbdTVxoJkHvbKFJhEq
eeBGNSIPABAIcCMiIaJfvvA7EoHNHQAAAOB+5UE1cSGXLOTMg0HkwdHIAy1deH1bTR60r9UeW4E8
AEAgwG1Ig4jfL2GYVqQBmzwAAADA7YkDF//t0joU8iBnHuioxiYPpvBjGtfkAc0SARAIcCcywc36
3Zp94CQCmzwAAADA7UmDCJ99Om6QB5p9cCjkwdaGicgDAAQC3Jk4WBMJ1zZQrKYxAAAAAMDXCINK
HGSB0JolatnC9/BNE5s80GkLrXRhy7QFbZhIs0QABALcgUSo1hjX9z4IpAEAAADATQmDnjzY2vNA
5UFumDgnCdD6HuSeB9owMQuELA+mHEciDwAQCHCfIqEnEabwZQwRlDIAAAAAfKY4qIRBFevlsoVr
5EEuXXDyoGUaZHGgZQs6phF5AIBAgDuSB1tEQsT2EoaLTZ9DAAAAAOCviINhgzCIWGaYtr4HrQli
kwcvG+RBzlKt5MEPWT+j3/MAeQCAQIA7kgeVMBh651fQPBEAAADgq8VBJQtcc+wxLpsmbildaGKh
yYYWB7aeB69x2TTxZ/T7Hmj2AT0PABAIcOMHjytN2FrGUDVLjOJtAAAAAPi74mAs3h6NOHClC9o4
sZd50OTBKcmDH0YevBbywI4BRx4AIBDgPhhimzzY2lARiQAAAADwsfLAiQOVA04UuKyDKvsgZyC0
lTMPsjw4J4HgyhV6DRN1XCPyAACBAH/p8LjODPjNeGvPgzGuK2kAAAAAgI+P/ao4zYmB3vOceVAJ
hJckDl5i2TBR5UEuXdB+B9rzAHkAgECAT5YGWy/g5w3ioTqYRiMPeqUPAAAAAPD35UElDXbFGotH
JxAOSRhoz4MxXfBX8iA//lrHt9U+f0IeACAQ4O9Lg2HDcycPmiWO8OUEveaJ1SGVv/famEcAAAAA
+P1YMD9W/Qt26SJ/n56viYSeRGgry4MQeXDsyIPXJA902sKFPAAABAL8uTBwoqCqfavkwSzPB9mo
1+YBV6t9zypDAZkAAAAA8HHyQHsYOGGwTxf8lURwGQu976fioJUt6KjGvF6j3/NgMbGL7AMABAL8
njCI2DbDd60fgYqDOW3YWSJsyThw9loFAmUOAAAAAH9fHuwKaeCyBvaFPKiWkw0unmxZBVkaaL+D
Jg6QBwAIBPhgadAbxxPRn93rJEKWB1N6HNymHfU84F4dXfv+7jDqZiH8+htwUAAAAABsosVWLeZS
cfBiHnMmQs4wcCMdqwkOIXGkyz6opi1skgfEgwAIBLheGmzNMqjG8rTnKhCyJXYrS4RKFuyLtUtf
e45LS12JjSGW5RMAAAAA4ONGFwNWkxLy0gyE0YiB6oZVSPyosaX2PaimLbieB8gDAAQCXCkO1jbt
XqbB2hrMJj+ljfscfmROe+0t4iAfRA3XkKeXgYBEAAAAAOijMaAbs/gtPbaVMxD0Bo8NV6PfaFtv
Sp1i2TjRNU1EHgAAAuEP5MGW7IIqhaw3osddtLcNeRJxUM3dzQKh14BH6+jaIaASY1MZAwAAAACs
xo/a9+Ag0uC7EQgthsuNr7MQ0Av6LUtjyiYQ8vjGTQ0TkQcACASoN/+t2QVbsgzWxvBoBkITBCez
8qYe6fUPnaUZCFP0+x6oOEAiAAAAAKzjShfajZ4sEL6n1bIRnDxwZa2aHaCZAlPxNVkg5FXJg5zx
ijwAQCDAijzY0sNAMwt6mQY643e3IhBy5sFRHrMVzhkI7QBytXTt9XolGNUhCAAAAADrMWREnX3Q
Ewi5aWJV1pov8M9GJjhx4ATCOS4bJeb48hQ+ywEAEAiwQR7o2B3NKHCSoHru5EH+nrEiEI5vX58P
jzkuMxBeRCIc5DVVCMwrB8QshyEHCAAAAICniiFd/4O8WsyW5UG+8M8X+6eNIqEnEVRGuMyDi6bd
ZB8AIBBgmzwYN0qAfdTzd3crSzvr9gRCW04g7OVQUoGQ+yxUdlpr6yL6zXkAAAAAwIuEUWJFnb6Q
b/q0mFDlwauJA11Z63kltjvHsgTivPJ1yAMABAJs2OwHIw56Uw16YqASCa7sIdIFvvZA0AaJTiC0
9LgsEDQVrh0Ia70VSFkDAAAA2EBRvlD1QMilDNo0MSQG1EaHKhHORgzMUZczrI0IJwYEAATCb2z6
buSObvqHQiSMHUFQ9UvIaW4RlxkCLtPhbATCEMv0OFdHl1PhXsObbDeyh0MEAAAAoE+OKaubUS6e
bHFgHreYRy3+EJlQTeeqZMCW5zbuI/sAAIEA9Yavxlhtce4r8CISoSpJWHvU5yGPo/l5XGfc9po6
xlFttjuUdHSPNmnk4AAAAADYJg+2ZrRqM+0Wr+VJCU0e/EjxWhYIbiLDNaMeo3gbeQAACATHSsfc
fDHeSgK0QeEh6okKlRTQZoRT53Mj3rMI2s+1GKtTHFIqD+a4zD7IB5Kb/zvFepNFAAAAAGLJpTyo
JEIVN2rTxFy68CP8DZ8tfazc29F5RBwAAAJhhd4mnzMP2pgdbVC4N+Lg4mwRWaCvHSvSQQWBbv7u
oNKyhWy0X0Ug/OgIBLIQAAAAAPryQOO3cUUcqDzQBtqaKfpzY6y2RRDMRYyKOAAABMIfSATtltvE
wXcjENwYxry23sl3Y3+GYjkBodIh2+hTcSC5DAQdC8ThAgAAAAiDy2yDKoZ0fa+qvljVBK5jXGYh
uP4HW+RBVxgQ2wEAAuHPDgE3bqea2dsEQpYHuoGvjdOpsgh6DRfHqHsmhBxCeTyPHkRqs19jWwkD
AAAAwDMLg4i6b1Uv88D1PdDx2jrCOz/q5CyXKboqCxAGAIBA+DN6dWrVvN5v8d77QOVBvmjP3XGr
ETtVH4PRHDa78BMcwvwMkxw0aq9/ijR4lc93VpsDBwAAAJ5NGgwb5cEQ/fHf7f1V9oEKBCcPXJbo
3BMGxG4AgED4+yKhGuGo83p3xcavxjjLhCn83f12iPS69brDp0p/66XA/YzrxjgCAAAAPKs0qBpi
qzxwE7wOJnbUyQsh8du5EAabRm0jDAAAgfD58kAv4PPGnzf/PHKn3fF/LS7O3QHQLvj159iFL6NQ
kfC79XNHWefY0IyHAwkAAAAeWBwMK8KgGtM4FLFjzmBViaDx22wkQhU3LqYtEKMBAALha0WCKyFw
F+8qD3KDQu0pcIqlPV40KOwIjKOIhCwxVCBo6cLRPLo6OldawcEEAAAAzyIOqulYKgxUHrgR4Ln0
9aUQCFvEgcaNxGgAgED4woOjN6/XNcHRiQtuPKI2J6wEQq+EIR9ERyMStgqEo1lrQoODCQAAAB5Z
GsSKLKhEQY4Tc+bBFoHQJMIu6v4HVdkrja0BAIFwg/RKGaqmhVoykMcjVgJhDt9jwL32KQmDY9Ql
DFUTx6qDbyUOLn4m5AEAAAA8kDjolSeoINi6egIhN+HODbiH8JMXXO+sKeh3AAAIhJuWBz37nO/4
67QDNypR5/VmgRCxNMhuEsMp/EQGHefY6+KrUyHIOgAAAIBnFweVNNitPLobTa4Bd27C3eK4tdGN
W/pTAQAgEG5QJETneTUy8VisqvfBbA63fJid08F0MgdYNcZxSy0dWQcAAADwLOKgulGkgmBtVWLB
NcHOK8dt2kPL9amqxjUGEgEAEAhfJwuu/bjerZ/lolzH7+S7/1PnENBRPtmGT0km6PjGntTIP5OK
AxolAgAAwCNKgy3ioOp15Rpo6/s1E2EXdd8szRrNMVhueK0NuPMULzIQAACBcA9nkdmgZ/Nxt7Zc
wLvvF+lwyYeMa94zdASCjvlxI3/IOgAAAIBnEQduYoJOvcriwI3PrgSCSgnXaHEIX2qaJ3ipRHAZ
CMRuAIBA+GJJMHTEQU8SXCMhqq+fOz/TnA699nwKb9JVQsyFTKDXAQAAADyaOPid/gZVtsGhEAg5
E2EsBEHEsvQ1YnljJ2eqNmHwI/wEr0kkAgAAAuGGZELvDr4bu6ipcNXF/WCEwcIgp8PQlTf0Dib3
u0QhDTDXAAAA8GjiYK1UwfUy2BtxUEmEqon1NTdzck+qqvH2z/AlDIxwBAAEwp1IhCl8+UE+vKqR
j04slON38vO3A7IqcejJA3eABeIAAAAAnkAcVGMWXbZBTxgcwpcuuLHeazFkFgduclcWCa8iD84u
BiWWAwAEwu1Ig0ocuOaHa+lwVbPD1c1fshLmjiDY8vttek0AAACAO5AHv9PfoCcO3Ns6PltjOhdH
OmHgmmsf47L/gU7uyk24yT4AAATCLfDrQto03okrJEI7RHYrh9EpiiyEX6+/dkG/Ut6w+XflXxwA
AADuWB7kR+09UE1B2G+I0/YdabB2M0j7GjhhcDbiQCXCMZbTu1z2Af2rAACBcEtnVPxe/4PcrVdT
4PIB1A4BnZrwRxLASRAOFgAAAHgwedDrbTAaYbDbKA0qYeBKFaqM1SwNKmFw6rz/vEEcENsBAALh
xuTBVonQGEUEtAPqJf6TjpYPpXwQNYmwOQvhd8QCAAAAwIPKgxZb5Yv/jypP0PiuGuGdpyOcC1Hg
hIFmJ6g0OMdy/DZxHwAgEG5YIrhDIn/M2e94O5hO8Z6JcDCH06IPAgAAAACsyoMcd62NXtwyinEn
0iBSjDYVseCW5oi9lT93imW2gYoDpmcBAALhTkTCXEiFiGWTnkiHzSnqsT/VyB8OAwAAAIB1eZCz
DbIkaBmgehNnTRxoPDZFnYU6GXmQsw/OhTBwmQbnqHtszcgDAEAg3AdVqlyE7/K7MwLBHVJu7A9Z
CAAAAABLcdCTB3uRBk0cvKwIhKoZ4lpmwbTxUUWBygP3dXNHHDShgTgAAATCHcqEapawZiDsOuKg
khIcCgAAAIA48PHXEMupCi8iDr4ZiVD1obp46be1RQScV8SCPu9lGkwiLSKK7FfkAQAgEO5DGAyF
OHAiYUwioPo8N/YHAAAAAHlwGYttyTzI4uCbCISWfaA3clQcXNPDoBICW55v6W2AOAAABMIdHlq9
g8vNFdZDyWUZqJQIJAIAAADAotdBxLJUdKs8UIHQ4jS9edMu3tsFfjVy8dgRCFkS9HokzLGttwET
FgAAgXDn9Ox3lWEwF3LAHQIcDAAAAIA8WI+72sp9Db5dIQ+yONCMAx2xWMkDV8IwR51VUD0n2wAA
EAgPKg0itpUvDIUw6C0AAAAA5MEy/nIZn1syD5pAaJ87xnKqQlWioOJgTR6slSM4WeCeIw4AAIFw
5/Kgkglu8oKTB2qaeyN5AAAAAJAH61kHuWHit0IgaM+DRhYHRyMNjtHPPrhm3KKTBe4RcQAACIQn
kwlhpIGO/lnrtlvWvQEAAAA8Wdylo7HziMY8WUEzDr7F5bSFMcVWUyEM2nqNOutARy/2YrmeKEAa
AAAC4UmZzYGgo3ecMDh3Dh3kAQAAADxXQLWefbClUWJ7bPJgl+TBlORBlgV5HWO9XOF8pTgo4zrE
AQAgEB5fFjhRMIdvwDPGsr6uOnicRAAAAAB4Rlz2QZYH399kwXcjEVyzxCwPsjD4WQiEreJgivVs
A4QBACAQnkwaDIU4mAo5kAXCEOsZB73DhoMGAAAAnk0caAZCbpjYZMF/iUDImQe5WeLZyINf4uBH
IRD+pM8BwgAAEAhgU9OqcT/HeDff7fBbm/WLKAAAAAC4xE1dyBkIeX0z8mAWedBKFn6IQPjZkQdX
iQOEAQAgEMCJhJ48aKvV2+3kANNUt4E/KwAAADx1gPXe/6DFRpqFUPVAyBkIreeByoM2aaFJgx8i
D37GZelC/roZcQAAgEDYxK/DIB1o+bDIAiHLg9e3g+01Lu13pMNHD0c9KAEAAACePgwL30RRJUJb
LftzjMsbPucUo/0UiZDLF7I8cDd9EAcAAAiEq+nJg9zNNzfuaT0UskBQqz5EPRJyCMobAAAA4HlF
wliIhLxa7BUiD05GHuTMAy1d2DQhC3EAAIBAWBMHEb6EIWcf6EHWRMAo30cPw7xUJPz7a35lQnBY
AQAAwBMIg5yh2RMJeeXMz9nEaK9GHOSpC1XmAeIAAACBcMUptixjyAfTGMsSBjXhozkYe4efkwgX
hxcAAADAAzMXUmFtta/V7AM3rnFr2QLiAAAAgfBHh1k7mIZ04BzjPaWumewmEsZY2nQnD/JqcqKV
P5CFAAAAAM8Sbw3mfXPUN1NybKYZolpmqiULp1hOW/gn7iPuAgDoM/InWCKHx9ZJDHoozVE3A9Ks
hSobQbsUAwAAADyyTHDx1xzL8YpaXlr1quqJgxl5AACAQPjog2yOZXfffEDp83Y4/eMj4nKe8SHe
5xYfYln+oCIBiQAAAAAPhblY1xs27uZNfq7rVDyeY1mysChbQB4AAGyDEobOwfZ24Z4PtCF8JsIv
efAS/QyEvQiEFznQXLreP2l1lDMAAADAg6LNq508UCkQcVleOpnHaiEPAAAQCJ92sDmB4Ex37hC8
e/uaw9vHXsIbce0APCIRAAAA4EliLc34VHnQ1igCQbMWXDaDhbgKAACB8GFIFsIQ6/0QNAMhZyHs
RSKsCYR4+1guX0AiAAAAwKOJgzAx1mRirLba6Owh6izOnqCIYNoVAAAC4RMkgjvYemvXvo1IhGlF
HCiTOWgBAAAAHiHGCiMRquyDLBDGFXmQp2Ll9xFLAQAgED6FXm2eHm5NFoxxOcrxEHWqXZhDcE4S
gfGOAAAA8Mgx1rQxxhoLWeAaUg+FTAAAAATCXz3YBnPIVel17dAb5VBrEkFr8+ZCHszu9ZEIAAAA
8EAxlpMIbkRjzvJs8dUsEiHHXZU8IBMBAACB8CkHnJtFnKcxHON94oI2UmyPThi45+7zaKoIAAAA
D8FKGYNKBH0+ijgYi0UWAgAAAuHLDreqS3ATCK9vkmAnh1akg24XdTOfeWUN8vkAAAAAj0DvRk3V
gPpaidA+lzgKAACB8CkH2xDejO/iPfugEggRlxkJThBMxaNmIfz75yALAQAAAB4kxnIS4WwkghvP
OAR9EAAAEAh3JhHGJA700Pr1+YdYljW4xozOvutoSDIRAAAA4O7ZWMZwFpmQMxByZoHKgxF5AACA
QPjqw01LGMY3gaAHlR6EmSwRIpZZB+dCIowiMZjKAAAAAI9ClZ05hc9AcA0UK4mgn0cmJwAAAuFT
DjYnEa6x2/Pb374dar84pO95jtq6ayZCBFkIAAAA8BgxVo5tps5y/aGGDQsAABAIn4PJQmgTEc4r
h6FrlhgiEXZvb+fsAx1ZdI7LLAWyEAAAAODRJEKvR1Q1+nqLQAgkAgAAAuGrDrcwImHtc0MOrzze
cXz798hZDS+xHFvkShmq1wEAAAC4eUwfhJ5QmK6UCFHIA6YxAAAgED7tgMsTEWLlwIuVAy7X6LVM
hEMSBy4TYQyyEAAAAOAx6Y20ruIqfdwqEgAAAIHwaYdblgiz+ZiTBlV3YCcQXkQitJGRZCEAAADA
s0qFKOKsiLrfAeIAAACB8PmYXghNIuiUhvzxqjvwznwsS4TT2+Px7X37eM9C0DGRZCEAAADAw4Zg
cV02gRMJSAQAAATCzUiEJhJ6B14TBHmpTNglUdBEQpYIp/Q1k0oEAAAAgAeSBZUIcI0REQQAAAiE
25UI/7YHlz0R1iRCFgj7tLJIyBIhZyG05+3rWy+EnOWARAAAAIC7omigqCJhlFhJMzE1DppX3gYA
AATC14gEkQiusU/LGjgmOfBqBIIrZdgnmZCzEFovhIvyCcoYAAAA4B5Dqs4azap6HQSiAADg7zDy
J/g4iSAH1pwu7nWawulNBOTVRjTO4XshHEQmuKyFCFL3AAAA4I6Q7AMnDXaFQNBMhByDVdMbkAsA
AAiEm5QIOq84iwSVB3lMYy5HcBLhkORBzlrIEqGXCggAAABwc2FURx7sJPbRrM0QObC2EAkAAAiE
m5UIU3p0mQh5TVFnIexjmYXgDtFeOh8AAADAzVBkH6g80N5R2oR6iOVNm0niKvofAAAgEO5KIuTl
REIbzTjJv087JA+xLGfomniyEAAAAOAO5MFa5kGvhDNSzDV1ls1EoGcUAAAC4dYkgoqEc1pOIrTP
rTIQDsUhWo0zAgAAALjZkGmDPDiYGCjHP65kNN+YyQIBAAAQCDeN64ugh1s+4Kao+yBoKYNrKoRE
AAAAgNsOjpalC2P0+z8dTPwzpBirJw8oZQAA+CAY4/h5EqFXzpAfVR40puhnIFSdiAEAAABuEe3f
tBN5kCXCi4l9sjzQzM5zekQeAAB8EGQg/M1TcVlX15vSoAdbTudzTYSq/gcXmQf0QQAAAIBbYqVx
Yr5J8pJWvonisg+q0tBeHwQAALgSMhC+4NwsJEIUh2k7IJ086M1CJgsBAAAAbhXtfaA3StbKF1os
peJAx2NrH4T3H4AGigAACISbMgX+7v9gHl0XYhUIo0iDXSEPAAAAAO4hNhoklsm9nl5ktY/l2Chn
HhxlaRYCmQcAAB8AJQyfgwqC0Tzq+1xX4qFY7rUCoQAAAAA3KA9cnJPHVrvMgy3y4DXqDATKFwAA
EAh3c0hGIQRcJsFaVoEb10j2AQAAANwTW8c2tqVjGyt5kDMQqilXlC8AACAQblYeuNnGeURRPjB1
osLFt43LKQ5zYNMBAADg/sSByz5Ykwe7JA80++A1rUogEC8BACAQ7uagdIb9II9ZIuSMAjf60XUU
zqIBAAAA4Gbo9D5Ya5yY5UGLc7bKg7OLlcg+AAD4fWii+PcOSNfjoJptnDsLa3dh12HYdRaejDzg
gAQAAIBbodf7YN+JjarSBZUHr0H2AQAAAuFO5UGVmld1F86zjVUeuM7Cx+g3B+KQBAAAgFuKjyK2
9z7QzMxYEQivsWF8I9kHAAAIhFuVB1Vq3ktHHuRDsh14+ZD8GT5NrypnAAAAALgFtmYf5BsuLZbK
8sDdWMnLyQPiIwAABMJdHo6HJBG+vS3NPtAGQSoPfsYyRe9cSQRMOwAAAHwVH5h9kBsnagbCWkw0
ExMBACAQ7ulwVHHQyz7Qvge/DsYfb+LghwgErfPDsAMAAMCtxUcfkX2Qb6xUYxtd74NAHgAAIBBu
/XBcK13Q7AM17FO82/UsD9rzJhFWbTsAAADAF8VGEXVpp5MHW3ofnApxQONEAAAEwt0wFAekli1o
BoLLPmiG/TXJgn8ZeZAPTTuFAdsOAAAAXygOIvplC73SBZd9UMkDShcAABAId3lQjhsEgpu6kEsX
tGniWvmCOyw5KAEAAOCrxYHLzNwZcXAoYqMmEHSc9VEe6QUFAIBAuBuquj43sjFnH6hh16aJP2S5
7IOygSIHJgAAAHyBPHBx0ViIA3djpcrMPIs0yHFQNdIaAAAQCDd9YG7JPjhskAc/08qZB5U8IFUP
AAAAvioWcqOsXeZBNY3qJX1sl77PFJe9oU6FRKB0AQAAgXAXXJN9kA/HJhAiLlPztHSh1/cgy4OJ
fwoAAAD4InngMg7GWM/KrEZaj0kETEYcnGPlZgoAACAQbvHQjKizD1x9n6vtc9kHKg62yANsOwAA
AHwmQycW2kX/psr38FOpXPNE7YGgmQf0PgAAQCDc3cFZpelp1kE7HKumiT8LeXCMZZoe8gAAAAA+
lc6NlEocuIlULvuglS9MsZy+4ATCOZZ9D4iFAAAQCHchD1wGwtaZxk4gVD0PzsgDAAAA+GJ5UGUd
qDh4iWX2wTd5O/eGmsNPXiD7AAAAgXDXB2c+PIfO4akzjbWr8CnJg9egbAEAAADug6qMs0kDFQUv
RhzkxokRPvOAyQsAAAiEhzg0q8NzF5clC7l0IR+OOfsgy4NjWqdYWnbkAQAAAHy1OHBNpA9JHmip
ghvZqE0TNfNAY6JTFFMXiIsAABAI9yoPNBPBmXV3MOoh2bPsHJIAAADw1RLBjbBuZQrfRSJoX6gx
lqWdOTuzysjUnlARZB8AACAQ7ujwrBoI5d4ILjWvyQJtlpgFArONAQAA4MuRMs6Ieoz1i5EIrlli
Vdb5agTCamwEAAAIhFuXB3p4ZgufH4eoDXu26pp5gDwAAACAW4yBqixMnbigpQvt8yPFOXpTpWoq
rQ2lKV8AAEAg3O0BqhJhLORBOyxPhURAHgAAAMAtxz4aB7lJVCoSWrNElQenQh78iMveUG4CAxkI
AAAIhNulk7o3JFmgj1ketAMzZyBkkaDyYEIeAAAAwA2LhN40qtwLKmdlqjx4jWXmwc9YNpfWqVRk
HwAAIBDu5tDMB6eTByoQmkTIcuAcy0aJNAUCAACAe5MHWsqp/aC050GWBz9/Ux6QfQAAgEC4m0Mz
H5yujKHKQsjNgibz3B6MmHUAAAC40bioV8rpGknn3k+5XOFH+LHWp6hvtBAjAQAgEG4PKV/oiYM1
eaBZCBPyAAAAAO5UHPRiIxcHtazLqueB63twissbLmQfAAAgEO5OHIxR23Y3fSGMHKikAQciAAAA
PIJImI1A0DHWbuoCzaUBABAIdycM8mHoDsVerZ+r+Ysr5AEHIgAAANyrSIgi9jmLRFCZkLMPeiWe
yAMAAATCTUoDJw52nbUP3zQo5BB1IiGQBwAAAPBIYVYsJ1Hl0oTc5yCvnHlAeScAAALh5qTBYJ67
zAOdc6zzjveFRJivWIgEAAAAeCSJ0B7dNCoVB5p1MOXvgzwAAEAg3Jo02FKy0KTBy9s6dCSCHqLd
pokAAAAADyANXHnmWjNp5AEAAALhJqVBL8vAiYOcfbBLsuBF1kHkget/oCUMZSYCByUAAADckTTI
j5VQiLguK5OYCAAAgfCl4qCXYeCkwZCEQC5f+CULvqWVMxFUIGTjXtX1AQAAADyKNHBxWO/ruaEC
AIBA+FJxsCXLYIz1sYw6bSH3PHgRgfASlxkITh6454gEAAAAuGeJsCYSqkzPCD/FAQAAEAifKg/y
YVRlF6wJA33Uxom98oWIy7FFp7hsFnRGIgAAAMCdSoO5eNvFMsOGFUgEAAAEwmfLAzXZvQyDXSEL
es+r6QuudGGK5cii44o8yAcxAAAAwL2IBNu7IHxDal1ViSnxEAAAAuGviAM9cKpDSoXArvO+3tqb
tZMDL8uDoywyEAAAAOBepYGTCE4ehInT1jJANbYjLgIAQCD8VXmwJgz2K2/31mg+P79eOzSzOHhN
y0kEKw9oGAQAAAA3LBF6Ixr1hkgvPnOZCGQgAAAgEP6KPBiKg8mVHWjmgH6sJw2qgy6Lg0gHp5MH
WSKcRCBMHJIAAABwB+JgMCJBb4aoRMg9qTROqyTCRdzHjRUAAATCR8gDzTzQLIHWp2BvnqtIqKSB
S60biwPUZR78+N/1823lLIRqnCMHJAAAANyDTMiyYIp+WabGa7uORBjfvs+QXgsAABAIHyIP1Gar
LDiYVcmDNWHgxgy5ZolNHjRx8CMJhNf0eXrY/ueXw7IDAADADfErNnmLw2azJlmux9MQPvvASYT2
uf9ICLIQAAAQCB8pD3ZGHryYx70RCLuouwE7aZDr/VQetOyC1yQMfhbyoMpAAAAAALj50KyQCGcj
EXYphmux1r5YOT7KMdiMRAAAQCBcIw6cPNjJIXRIsiAvFQiV7VZZ0J5PcmC2i/5zWlkevIpE0AaK
5fQFDkYAAAC4A3kQscw+OJulEsE1t3Y9qRhzDQCAQPgQedArWcjy4JuRCPmA0nFBLsvA9SeYzSF5
KgTCUeRBlXkAAAAAcG8SoRcX5bd3cXmzpsVwubz0GMsMBO2zQCkDAAACYVUe5M69g5EHB5EH35I8
+BaX2Qf78F1+q87BVXOgSQ7JU1w2TzzGcmSjygOyDwAAAOAexUGE73+Q5YGLe0YRCBrH9WIlShkA
AB5dIEgGwQW68RefW01ayJkHLbvgmxEIL0YeOHFQSYJqnTsCwT3mw/AcZuoCByEAAADcMqmRYpg4
ypV1HosYrJWfnuPyJtA5Vm62BJmbAACPIRB6suDKz+9lHlTNEr8VAqEdSjl1Tg+8yVzgn8N3Ep7M
56lEyKs6CKd8CCIPAAAA4M6oGihWMdEultmk+WbQWeInjcHGt7eHIAsBAOB+BYIRAUNHCujBsyYP
tkxa+CbrpZAHjSwDTlHX6/VEgpMI7vvkz72o4UMeAAAAwB3Lg0HiKpeVmTMQ9GZOa5R4WInFchbC
GHITBgAA7kQgmAaH7rEnEHTz39ow0TVLdPJgL/JAU+y2ZA04A17NOq4+3/VVCOQBAAAA3LlEqDIQ
mjh4jeWEhSHeJzLsJT57KURCy0AgbgIAuEeBYBocDub5sCIPKgYjEKrMg5cVeVDZcW1weFwRCJMR
AtWaw9ft0e8AAAAA7hrpg5Djm/NbzHYSieCyEHIpwz7qKQ4nE4vRTBEA4J4EgsiDrUtFwrxBHgzp
oHGjGl2zxFay0ORBPtRUHLwakdA+b1qRB675YjXFgZIFAAAAeDRyfNMu7HMTxXbzJ2ch7FOclMsZ
WozX4rBDXGaH5ozU9vVDkJEAAHDbAqEjD9Qm5+dVJsK8IhB6fQ9ewmcd5C6/OSVOxcFrRyD0ehdc
uy5+T+QBAAAA3DtFFkJVxuAkQovvXMz2Essshhaj5TiRmAoA4JYFQiEPxlj2KRhj2Wk3f21vw3cC
IR84LyIRDrHsd9BeQ0cJ/Tq8fhYCYW1iQiUGqueIAwAAAHh0ZiMRcilDlgjHeM8s2Kev1T5Xp7i8
MaSyYdD4lDgLAOAGBUK6wI+oGxzuzGqfm79+NoePK1/YxbJ54iF81kF7jTxhIWcc/JTnWR7oyKCq
8WE1h9g+50ADAACAR8RkIeQyhqoXgmZ75qaK7ebP3iztnTAUMSUAANyCQDATF3KWwL6zdMMPc6E9
F993NAJhH0srnb+3ps5lcfAzPXelC9rroCpFmIvfIxAHAAAA8GTkmKeSCFkm5Lgrx4Au8zTHkU4e
AADALQqE4gJf+xPkkgK9yM9ZCL2L8Z5A0FS2XVzWwWm/gywNqtKF3DTRyQP9GUsxgDQAAACAZyJl
IWhDxXxDpxqZXQkEt1QiRNAHAQDgNgWCyT7Q0TtNHuhyvQm04Y5elFcCIR8gLutgSofSq5EHP0Ue
6AG2RR4gCwAAAAC8RMjxXU8iuH5T1U2qqnSBDAQAgFsVCHJxH+EzEFpfgu9xOR1BJyNELPsJ9ASC
9lnImQz5kHL9Dn5XHizEAcIAAAAAoMtcSIQsEs4iETQOHFeWFQg0UgQAuD2BMMjG7uTBt7eVJUIW
CHrItMOlvT0UEmGIOoPB9Tu4Vh64fgdIAwAAAIAtQaJvqDgmeXCW5xqHRScOrOQB4xwBAG5NIKw0
T9TpCCoRmkDYxbL/QXXhrlkOQyEOss1ucmCLPMgH1ywH14w4AAAAAPhtZhOzzUYkuAxQ10x7CH8z
SeNTYjcAgFsQCB15oJ1yX0QgvIhAcGN+XM+BCD+toX1cU+HWyhaqUY32tZEHAAAAANcjDRVVHmg5
QyUQqphzTSIAAMANCQSXUpab2+QMhIOsnH2goiAKgTAbkeAyD9y0hddCHpx78gBxAAAAAPBhzCvL
9Z+K6PfCco0U/8k+oA8CAMAXC4SifEGbGu5l5RGOeVpCL41tLuSCkwd52kIWCFkcaL+DU3rNGXkA
AAAA8OkSoSpdUIGgseYWkUAsBwDw1QLByAOXgaACYRfLMYuaOZAfKwPtDp/cvyCXL7hHNypImzYi
DwAAAAA+XhwMHZEwR7+EtRrlvSskQiARAABuRyBUHXF3hUSosg7OscwKOMWliY5YljKsCYRcpuBK
FpAHAAAAAF8nE7ZKhJxNoHGmSgQrDyhjAAD4eoGQN3RNK3NZB73MgypDwAmEapZwFgjVcl1+aZYI
AAAA8BcxjRSdPKgyT1Ug9LJc85qDDAQAgK8VCKn/QTV9oVraMLFlHrQxi26sos4B7h04Z7NO5n1T
dUghDwAAAAA+hV5mqcs+aHHkFNtKZRnnCABwCwIhbcRhNnZXwlD1PTgleZBXkwrXCgQdA6TZBmcj
DihZAAAAAPg6iTAX8Vk1gaGSB5VEQB4AANyAQIhYz0CoGie60oVf4uBHvGciaB8EJxDCHDpTZ1mz
jTwAAAAAuAmJUI1xzBkIs0iDXtnscPFC9EEAAPhcgSDjGyPqEY676Pc/cALhZxIIxyQQ1ET3shFc
eUL5PThEAAAAAL5cGlTyoDeJYb8SczLOEQDgqwWCEQeVPNBNfZADQ7MQmkhoMmEtAyE6EqFXR0e/
AwAAAIDbEwlrWaQR/ZtWTh4AAMANCATXRHGQjXwMP5PXCYRq7GKVgbBFJATiAAAAAOAmpcEQ23pZ
aRw4iEgYos44oJEiAMCNCAQnEXRsTjWT1x0WOjXBjXHsyYPofBxxAAAAAHDbQqGXhaAiwcWhPXEA
AAA3IBDcpj2uLJUI04Z1jvXsg+h9DHEAAAAAcLPSwMWFbprWZL5+LS4EAIAbEghrIqFngtfGMK6N
8+n/QEgDAAAAgJviV3wmDbnX4kGVCY0qRuxJBWJDAIAbEAjXpJANxWbe2+QX8gA5AAAAAHD3zIVI
cOUL7bFlsub3V1O3rrr5BAAAnyMQ/oSra9WQBwAAAAAPJxLWShqaLIi4bMadH10GKwAA3LBA6I1P
7ImDXPqgQoGOuQAAAADPKxS0B8Kv97Vm265XgpvI9e+3uREFAPD1AsFt0LqB6xSFLY0XXXYCmz4A
AADAvZqBy/4HWgar73fxZXtfnty1JhEAAOATGK8QCFkSVDN8VSK4kY9jeswioTp4AAAAAOD+cD20
1m4iuaaKKg905CMiAQDgRgRC3pDbRt3b0LNAaAfCL1mwf1u79JiFgpY2AAAAAMCdkW4CqTxYy0aN
FYlwCj/yEXkAAHBDAiFLBLehn8yG3j43HxZNHBxEJuyqA4QsBAAAAIC7lQdOHOyKNUpMqhMY1koY
yEIAALgBgZA34akjD04iEib5/jkD4ZBWTyIgDwAAAADul6EjDvaxvKGU479eGYOTB+8vSgNFAIAv
EwgRdYdcFQhHEQg5A8HJg0oikIUAAAAAcGesZB9kcdCLBQeJP8+xrXQWaQAA8EnYKQy/7G06CKry
hXOSBwcRCLmvwe7t41rD5mrZxiQfggMBAAAA4O7kgStbUHHwkoRCvpGU5cFUiANXtkDMCADwVQIh
nwmymVcZCK4PgnbVfXn7+Mvb578YkTC+fX17zX8fSqSjAQAAANyFPMgSQbMOXtLKEsEJBI03N5cx
AADA1wkElQhro3VyD4Q8WaF9H+2f0LIX8teShQAAAABwn/KgV7bwYiRCEwg6ylFvMp1iZfoCN5wA
AL5QIBRlDNoPQeVB3syzQGi9FiYjEE7pkDnLIUQWAgAAAMBtCYOLkDF85kElD769LRUIEXW5rCt7
JfsAAOCWBELv/FhZIQKhrSYdTnFZA3eMy0kMrYwBAAAAAG5TGqhA0L4HKg+yOMjywPU+0BtNvRKG
QCIAANyAQPjDCQhqoXPWQu66mzvv6iQG7aEAAAAAAF8jDobO45bMg5yB0D7WYr+IZanrsZAIWr5A
jAgAcAsCoRACvZXLFbKBzqMip1jOAs7ZByoOAAAAAOBrxEElCyL6fQ/WyhZ08kIuXTim9RqXo8LL
8Y2UugIA3IZA6B0UTgLsjDzIZnk0ayjkARIBAAAAYPsFfz+oKy6yV3obaAzo4rYW07XsUi1bcKUL
rXFiLnHN4iDLgyoDgR4IAAC3IBCKtLVKHOR0NZdVMMayNwKiAAAAAOCD5MAHfb9KGLhsU5d1qtkH
Kg+aQNCpC7ls4VUEwlHkgfY/IPsAAOCrBYI5TNwBkS1z7mmg83wvzq7oN14EAAAAQBj4C/trPxYm
DrtWHowbV44NtXShSYQcI0ZcTudSeaBZCDRPBAC4cYGwNpanHRC5xu1g5MEcl8a46qKLTAAAAIBn
FwZD5+3hD+TBmkRwTRHdDaSxeHQC4SWWIxtHiQ8reZCzD6ryBQAA+GqBYJrnuAY52l3XTVZwqWm6
zkkqUNMGAAAAzygO1hoXuseeQBg6cVRPILgbRzn2262sffgJDG5ko8qDn2mtZR/8EydSvgAA8MUC
oTjAXHrawSyVB9pVd62urR0oAAAAAM8gDbZOOnAf60mENXkwFz+L63mlZar5UTMQdikmdPHhWuaB
a6B4llgxkAcAALclENZG9DiJsI9LuxyxHMnTEwhVFgKHAwAAADyiOOhNOtAYzH2OEwhDIQny23MR
+7mRjDnm2xuZoKUNOyMa1pomavbBWukCsSEAwI0JBHegOImwi2XjxLXatp8rEoGDAQAAAB5NHmwd
izh23tcTCGuZCPOKRMivU8kDzSjIGQjaL2FM4iDLgyYGqqyDtckLlC4AANywQLimoU6WBxE+++Bn
1J11XX1bcEgAAADAA4mDShbkNRRvDxtEwu/IA431skBQeZAFws78nPqzalyY5UGv78Ep6HsAAHB3
AsFJBJUJ7qCY43LiwjGWZQxHc0hQvgAAAACPIA/cTZi1sYi7lY+7mEsv1IeNAqHKQFgb2X0w8mAw
P0eYmPB3miZOxIMAAPclEHpSQZ/nC/98WJyMMHCZBxPiAAAAAO5YHETUN116YxF7YxK3ZiF8lEAY
Y9kQUXsf5J8riliwkgdb+h7YsY1kHwAA3L5AmIsDYW1lKXDuLJue9mSBxgIOSAAAgLuXB5U00AaD
bkTiVoEQ8TFlDGsTGPRnGwtxMJkYMN9M+ikCoSprnZEHAAD3JxAqeaAHQ35bDxH3ta5MYe4dzI9w
aKxJg97ncmgCAADcjTyoxIG7k79fuUj/3fKFoYivejdrxkIiuJKF/P3yKO5zXN48ypmnuQ/W2tQF
RjYCANyRQJjl4FFxoFkEp3jvxJsPk3xwXTOuaCEx7lkimOAiOm/P1e/PAQoAAHCzOHmgU6sOxaOO
PXTyYEvWwbWlDBrzVb+DigO9KXTuxIfnJAiaLHDTFxjZCABwxwIhos46yIeC9jQYiwOsV/N3Dj/B
4e4lQiEO1tIN3aE+v39LRAIAAMCNnfNu3HVbOsXgJZZNCV32QZVxUMmLLW/PRbwRUTd9HIq40ImD
k3muvbDcYmQjAMC9CoRfm7Rc+GoTHE1HO8a7PW8SYEwHk1r43NF3Ellwltedlmf1fUiEThfmXuph
PtDzAT3ooY9IAAAAuGl50OKdlyQOXkQgaAbClqkGsUECVBIhxxM9GTEUsZiLCTU2dCLhZGLHqqk2
fQ8AAO5JIJiDSXseOJOcBYLLPsjSQJvjuIvrdjANsZzMcPMSwQQVrhvzsEEgrI22RCQAAAB8HdW0
Bc04+JbkwTcjEJp4cLGB9ovqTVJQebBW0pBfo+qZEBKXTIU4cPKgkgnn6E/jAgCAOxQIs5EIWr7w
GuvmvB2Kh+Jw0BKHk8iDfGjFrUuEjjzY0gxJ/96z/M0mRAIAAMDNnPUulsk3TZowyCtnIVRTDarp
VtXkqy0SYfidXzXqm0lOCKg8qHojTOaRqQsAAPcqEKSMoR0aQywNcpYIWre3i8sMBJdxoHWCxyQR
2mN73bh1iVDIA9eIaGdkghMIbspFNfby7ptNAgAA3Bm98oUsEL6LQMh9D1xzwrk4+6foT7TqyYOt
DRYj/E2ka8oWzkUMc976uxDLAADckUCQA2SIZRZCkwftkHwN3/xnlx6rC2s3JqiVRgxvr5WZisPu
1oKJ6nfMnZazRAgRNj1rX4mEOchGAAAA+KzzXiVClgeagfA9ltkHg8RZc9R37vVu/aLE8w8FghMH
PYFwKp67n7MSBvZ3IH4BALhDgZCyEOZ04d6yAUaRCL05wVrTp5kHubHia/i784ORCDd1x91MXKia
R7qOyzqBopcimNcgB7L8SIgEAACAv3zeVxkIuWnit/SojadVHPTu6p+Li/F88T90JIeTB4tGzdHP
hDh31lp2QZVBOef4k/9lAAB3KBDkIIm4vDOeywx6AsGJgLEjEFwpRPUzDflC+SsPnE7pgqYyuvFN
+rvmA7rXrVhHHrmGkzfx9+kEXqsQSAAAwI1S3RzRHgh5CoM2ndYG1blJdTWloOwZUPyMPYFQjXd0
F/2ub4ErVeiVWLiMCbIOAAAeRSCYXgj5AGmH5XFFIFRNBF1Kv2vEGMWh1i62v/QiuTPGKTePzEFE
bqCUpckQl6Y/BxIHCShar4heRsJicsVnH9ArsmDTaCr9HgQYAABwI/IgP9ezP0uE6qbBnM77dsa/
pkcdfVhdqEf8XiPFXh+ESiD01po4cI+c7QAAjyQQOgdKvkM+dlY1wrDqDVA1FozwXYfXRg59ZiDR
uwvhujB/i8vxTZqBkDMP8rSLJmzyx0cRCl9W1lBIg7WAxf07upTKoCwDAABuSCLkiUpVZuVeYpx2
vuWeUj/fzvq8chZCr3xh3vBzbjmLNd6KQiL0HitxYGM1znIAgAcUCJ2JDMOKRHCzjPPd9pytsCu+
3skDPTDbz/PpWQgb+x7kzIPvsezE7ARCCxTaHYl9XN6d2CISVssaPurwXpEG1979iPB3KrJQoL8D
AADcmkRw5ZmuN1S+EdPkQV4qELTHwFoDxSjO2d89f39nIQ0AAJ5VIBSHQG6oOKwIBMcu6vKG/HWu
iY+z21/ZD0GzD6q+B1kgNIlwiH4JQ3UXw2Uk5DsVORthiL809rGYg62Prj9EdP5/9IKW4W+KEAAA
gI3n3hDbsyxVHsxJHjRZ8Esc/EgS4SgCwZUKRFyXgbD2vp5EiLiypwHCAADgiQWCmcgwi0Q4mUN0
CzkbYa1kYVoRCZ/aD6HThdk1UMplCzkDIZcwZDkzhS/xyHc09O7GLi6zErJI+NCxj+Z3j0IUbFmx
8u9edW62IoFgBQAAPpk1cZCzDLXvQZYHTSA0qVCVLvTKBLYKBPexuRAJa1IBaQAAgEDoSgQVCLFy
Yega7+SDLx+we3MhOXW+vqyz+6Q702tTF16MQMiNFHNDpfY7VHfte+mR+fkxfc9eNsLV/RE64mBI
/5b6M7vH3v+VNWnk0jfzXR36JAAAwN8WBi726fWDymdc7n2QsxBek0A4xmXpwjVlCx/NvOE54gAA
AIGw6SBpF6HnqDMIqm69L28f36eDuF0gV193LiTESV5vcVH8kYdbp/fBGMvyBZUGWRxkeeAClDH9
jVRS7AqB4Moato59tBfgK+KgEga9QGrcIBB6//ZTXJZ85NnXiAQAAPhMmbAmD/Tmi45p1saJuSRR
b57EFwiE6L0eZywAAAKhPiWXpQzTykGzdezPHO9lDHn84WwuInsWvlcTaMcC9n7XjUHDlgyEaga0
BgJVjaNedG/NRshNFscUtORRnPm17AV453fujejU5pi7QiTohf9ciANtItUex+LvdfF7EOAAAMBf
EAdbshBiRSAcjThQ6b/anPDTfnHOUwAABMIfSoR88XlaEQjn8N2EVSIM6WL4Jeo5w3qY95oGbh35
aC+eO3fjq+kLefZzEwi5YWKET9M/h6/zj7jsF+EyErZkI4zyd9KJDfkCXF9bZYYTBrvi7aomdDAS
yP1/OZn/P72shAuRQDYCAAD8CZ2JQ2vNpPXGy1kkwqk466qeT1zMAwDA/QgEw5QuSqu76VNxweea
5W2VCNUh7i7Ce42B9H2LTv8dceAyEPZJFhzSc9fvwF0oT3GZ2bG1VMDNnn6Vt48iEk7yN8vyZTDy
pXpdV1KRH51EyBkIUfy/ycHUqXheiQT3/+RuRcLWzBkHASYAwIdyTfmeK9NzN1RcxsFCHrCfAwDA
XQoEyUJwJQ2uGV5VvjB1JEJrrNjrwq/2/xyXae1b5hL3ugoPK8LCXUjv4jIDYS8XzjquMa8sEFRO
aErkEO/lEHrX30kEndYwpqBlCFPGIL/vmMTOGMuyCbdUJFSBVU+qnIp1Dt/j4RzLHglXj6/8yov2
zmtf8zMt5BfBJwDAH8uDSiL0MhAi6qzMtRJP5AEAANy3QDASIV9s9wRClYHg2L09ZomwJg92UdcN
2mkN8vNGrM85DhM8uEwAdwd+kL+Jq308x7L3gfterilhEyjt77ATiZC/z1H+Zu7vtiZLsig4FI87
ESg7+ZnD/N3z/5VTIRG0VjT/2+fxlWWfhysEwR9dtG+9eO+kx649j0KILbJpKOUAAPhwibDWOFjP
ubUmwTPyAAAAHk4gFBIh4nK8YzXCsZcdkMkXxvviojYf3u5OftVwsRIG1SjBSiCMUfcj0JT9/DfR
GdB5bFMWCO5iXUsi8sQGJ1Q0I0FFws7IFxU1Th5oqYaWbGjmQ3VXJqLfRNEJhEOSCMeoMxSqPg/u
gttdmP/pRXtXKmwITrc8RiHFKvmFSAAAuIK0fw/mXHSZiL0pDFsaS1/EWvwLAADAQwgEkQhRiITq
wnztwq39fCoR3AV8bhiYZyf3shDm4sLVNTassh/GlSBCSw7cDOifab0m+aECQS/WJ3NxvlZWUfUr
OCaRkGdO6++qMuNQLCc51uRB79/i/Pa9zoVE2ItMyEKk1+dh7vzf+9OL9jWpsEUeVKmyYf6GsfJ/
25byMKECAP4/e+ei3DiSs0uQlLzz/k+7Y4nkf3qPaxoCP6BKbttjS5kRDN9kXUmJyMIFxk95is//
qmmwl9e71aUK/9aIRgAAgK8RCE0ivEUjqqRh6gQxUxE4eYkwiw9yLxZeXVBblTFUwdVmY6sD/vaV
LKi6MEeB8BoEwsVuGxnOLkD2j221Y4NG36QxO7mpGh3G7s+76YkP50IgxPuTlSxUJ2i+94S//5sQ
CWe7LQPxQsT3xfAiYbI6+yUL3kcEQiWqrHNiqG53tnxc2CQExd6RYof7SDYCAECOaKI8mnk4Wqo3
UnIJAADwOALBiwRR0rDfEThmwZpfYV9EgOVX0n0Jw2r5tAezfLW71+Bod0F6TxpkYwqvVpcx7OLx
qUkEL04mbCJoV2UNmVCI5R9eliwdgdB+VqUbIyvl6mRpCqLGCwQvErKSCTW+0mdY9CZ6TOIxvHfV
f/Sxmulskkl8rQTCSIrsdnuOTDYCAMDgOYr/PK3K9qJAGC5dAAAAeHiB0CTCWzSieiOoD2T1way6
85+CRIgf4tcQVN4rEFTdfRwTGMcsxhWIqnmS2XF8k2oM2ALeKClO4bIvTiac7TYjYRcBfFXeEOVL
lYGgGidmJ02TCJrV6vjIfmF221hzCcJETX44uX3DN6lci5M2JQ5me9+qf08mZCeo88BW9ZFQzbmU
DDvID7IRAADcm6rOPsgkvGp2rPofVIsTNE8EAIDnEggdkbAlAVOVfeAD0JiibyK4VR/MexYw2bHf
gRohqGSCX52vVhysCDL97XmJEAP4JZEaV3EfY38EtYKtGi2ebLyE4dQ5WVLTOFRfiV4AnwXM7bZ2
u82e8PfLT5642HHaxFrcvuptMYV9zjryQK32Z9kIvaZc6mu8PzHDZevsw7EfBNkIAABaHlQiXsn0
U/jcUucZvR4IAAAAzyUQvEjoSIRKJsQV3pPphoE+wO3OUu5IhDUJvlTwaU5sZF2XR6VFvE0fwK9C
IlyFQKj6I8whAFdSIKb4qyaKsx3HMsbHvFl/hGeWATAHSVCNrjTLO2AvQiZ4QaNGVmYniFnpgBXi
YLN+M84oEObO4xkZhdmbXtF+N4t9+ZCNgEQAgCenGtecZeTFnkRm/QxHJAIAACAQBiRCC46r0gUL
gZj/cM46IquVXxXIm/XHB45IhHlAIpiN18pvLohvjy878VgLkdBKG/yKSJaNsBaBfRVUK3GQpc/H
cpC48q2yHXqpoVGIZCd4rTwka7KZCYzq8Y4IhEwomOnMh6zE5E8Egp9Q0ZpLXt1j8dkIh0yEdgzz
dgkAz4IoXRidRhSl/T4gD9RnEe+5AADwvAIhkQh7EAlVwO0/fM9CIqha+fa/s9UN7CqRMCIRfHnF
yerGgdmJQSUSdhfczVb3aoj9EV7sduzjIuRLJl6UQMiyRDL5ojIjqh4EKjXUN23MxkP2Rmr5E73r
nQJBjaE009NFVEaJkgfZNJIq8+Ak7o9q1BlfgyZN/HhLP6Hi6iRCFBH/PMZwMo1QAPj8oJVj7d8n
a5xYjTI+hc/YSiBQxgAAAAiEQYkQBcJaBNqq8aAPKH1wZyJwn5KTAuvc5mg6uJcganV85PanAZmg
7ttiuo9Cr6xhS+TLlIiWSdxXVfqRPU/xvqiTpuok7WLHaQ+ZSFDXERtsqlKN2O9h7ggEtY/unygQ
spIR1XNiC6/5xY5jO7OJIauTB1uQJS7OQSgAfKQoQCp829dnJPvgxXT2gdlx8lL8XMz68fA6AwAA
AiEEXeY+XKsgfk+C07MIiKqg3V+3OjmoAnkVpMcP/3Y96gTCEokwKjJ8L4R2f2ar0yIzkRDHLaqS
i6l4zfZC7MSJEkogbFY3MMwmPvjXfS1EQmy0qBpsVmmj1RSEKktGNYjcBgVCNhpMiYPZ6qklSnzF
5pKqr0TLRpjDPt1bFfPNUjnhBbhPFkzvu8rf18kx92monjixcWLMPPCfRWZ5o2QlDxjlCAAACAT5
iXwsZfBBjwpOYpD6Yr9XpFXDopESArM8JT8GaLG7/UkIBJ9B0ctCmIr7MCIRVGB3j0iIfRFGsyaq
6RGrHevs1YnSPSMU40rPJZysvdh4n4eRBpvqPkyWr/Znr9HW+ZoF4yMjHEcmlpgQPEtynKgtSoTe
GNSbsiREAiAMUmGQZZ5lv1elbqlM4Jj7EolQZR+c7dg4cU/kQVZKRwkDAAAgEAYlQiUPVBmBT8vO
mutlJQ3xxC0GULvdlkT469tDYKt6IYw0VMyCv0p8xKBtsn4Dv6o/Qk/AmB0nYVQlHUoc+G0rTpay
INqPmDyJ6/SPxfd5UCKhCvb98zsVgskKsWJWN8bsnRwqgTV39pUqGNmtPw4yZmlcnDy4Wj97YhcS
4Sao8cc8b7fwZNJg6nw/cmxnTX8tO+Y41j78tawa+2afo77xsfp8zLIQAAAAEAjvkAjZau7qgkkv
D5RA6KXkV+P6lkJITE4ixNGKW3Kyoe7LVJxI9gJUCyLBf63KGnzgnaVdLtYfVRivs5d5UPU9iFLk
Xomgmlo2keAfk4XHtRcn51aIJxOXs/A6jF6/FQHFNBBIbMn/qkyFubPFff7q9u1eGcbWEQp7EWBZ
TzLcUys+cn0AXxBs9r5Og8e8ic/EnpBk/OoHnaLYWAPFKBHi5IU1kQhRHhyy4XgNAQAAgTAuEeIK
qu+BsASRkDWaG1m5zcbmeTGxuwB0SYJb1c1fNeDrnWDedd6aiIRqJOU5kQm9LASzsYaSKuug12k6
C9r9c1yNr8waR56DTPASwcLroQL03qpfTwioE1Hr7ItqX+iVR1giXaryi6pZoy8XyV6/0d4OWXZC
KmIGZMF0z/HBiix8oTwYEQSj2+h7QJb2TjbCx4uE7DxBNXS2cN5yEVvWQJHyBQAAQCC8QyJ4keBH
MfoA1q+SZiUDcxGcVYGUGs8UO/T7sYeLEAgqmGuPqwqEpt5zFU5e44nGlpxoLnZsqteCfpXFocY0
7u5k52r5tIV4YrRa3jywSuX3zSKzzIpsysM1SAQvg7JpCjb4XI7ID7O6V8FoD4MobtTzWcmwuA8u
ljcFq8ZdruI+qGkT8bkycUKcZXn0TpqngePEPx8EU/AV8qASBXPx81xczmxMCiuJd5Bo7Pd/JIUq
eZBl7/nXKwr2TM6SfQAAAAiEP5QIWZ1/CyhX0yP25uJkzJITvSgQWsBZ1SYuLiAz63fV95eZkqCn
DKLiCUX7OXnefPbGbDqd0p/8nIKMyQSCCuDjV3Vyu9n4yv0uTqLj1ImqYWTrjeB/7xstVhKh6ieh
pjb0+ibMljdlnIvHv4uAPTvpNHGSG1/XJbl91bCy9RdRIy9XIRSqLIVKGI00iLOOoFH/v2fXTTAF
nygP1HE1i597f5sG3w/UsWhKJLDfv+t1rSYwLOJ9NmYfqGy9rIEi2QcAAIBA+CCJEANiH0xOQSRM
d8gDszyd23f7r+YzT9Yf1RhPBjc7Nousgp4/ed7M6iwOXwrSK/8wG5v20BMH96T9e4mwhtd+K06m
YzbCi932fchGPqrHWK24V1MUsiCiV16QPc/ZOM5MIPiVMdXjwo+5VAKhPVeVPBiRC6rcohIJ2f7R
6x2ijqPd9GQXgin4aHmQHetq/OrIpjIQVPlYfL+b7LYXj/+sZL9/nzyoxIGS7/49qr1m95T1/fOZ
zisCAAAIhDuCYfdhXomE1uE4q/HuNVA0q1dhr4VAiJkFkzjxMyEPTASKI+P93vO87aazCGJwnJ3g
ZgJhFwF1r9GeFCTZSZI7kYsZG5kQ8eUZqs9Dkwh+9KfKQuit9MXHaKYzTjJxsHQCBnUf1oET0Ewg
nN3jb00lfRmHFfv/qDi4FjJhRCKN9pXo1Ypnqd6TOH4JpuCjiMdPPI5Uf56leF9Q+7Yam9vez3zT
06uTCJN7f0ci/Jk8UOKgKl8w09l66v2T7AMAAEAgfKFIiEFl1rjKEnkQRcAUTsZWy0sY4glGXNFV
wY16HL1O9vsHPW8qi2O3YxbH/I4ArdfQ6+6VlfAYzOqyFn9/FsszEmK37J5AqNKEs4yUSiAsA7Jm
DwFD1mNCnXxGCaB6GPjpFCfLU66VQOmVjKw23v/CrB59aQPP7ZQIOZU5YkH4TJyowwcHmiql/Wy6
lKiXmTAVUtN382/Zchfx3t32+4NE4FWUr6dZP/NA9UaKzRMn8bkexUGZoYfgAQAABMLni4Td+mOz
MokQMxHaydbJ6lT1LGV1Mr2qvIn7vlvefO7edH/5vCVlDbE7/mZ5t34r/nfvCIOb+/+ek6LB1973
R1DBvx/72E64VbNIC8F7FUTHBoFfIRDWQiBYkFhLIhxiacEpEUdz8nz2xoPG75VwGd0sHNtZ/4Yo
5GKTzylIhBZM0Q8B/lQeqADzLILMs+XZCH8iEH5tr1aXnkWJ8CX7vZqm8t2OtaIJZixpVK9nrxwu
K4Ej8wAAABAI/7JIiKuJI13aLTlp8IF0Veue1bcvdpzUEIOgXkCW1Yt/1HOm+grEx9+bRW4jwsA+
sJ6zEAlmdVlDDHDVKuBk9bjKavpBNX7ynjKG7PY30+UCWRNFvxraq7ltW3YCvJguE9k6ImG1+8Z3
jpTxZCMqYzaKvy+z/U7pVvvL2y6FRID735LEPumDzBe3ZRJhtCeKek+6BnlQvZ9ZeL//lP1+YPxq
eZmvPAaLrINKCp07r6nKZMt6w2SLBWQfAAAAAuELREIMsmOwPiIQzPKJA6qZ29wJEH1AW/UeGFmt
tY8SCUlfgd3qxnTWkQhW3dePPhm6o89DfM59w8is5jgL4EcmC1Qno1UjxayJ4iZOQLckKDchrhbL
exGsd0gECzJsNj2lojelYTQDQY1/tOI5jOM+fXBV9ZeYPuK4guehU7rgV6l/BZj/cdtLEmxmJWNZ
BljMQog1+JU88O9V20dKBCEFpnuvYlRAfPB9zbIOVB+ZF/dattc1EwjZAkE1kYj3IgAAQCB8pUgQ
JwejH8QxkFBBaCSe9GUrSPF24iptSz/Nei18eF3kgHhRJ39TcYKTnvx89iqKECJmeVlD1SyyWu2L
fR168mBEIkyDt1/V81f3w5fiLB0JsopgpBIr/oTbZyfs4raUPFANNTPhkz2uSiC0x3Jxj2Xq3B6N
5eBP5cEiAs2/gkRowabKFMjS6K0jEZaBYzb7PPxjiVAE49nnyD7wGZwKhlHJMPLxkbyWc/Gaxte1
/S7KA7VQUJWQUb4AAAAIhO8kFAZPgPbihMbePvxjEDOSTn2y21XUFti8vm2XsFVdmT/teSp6C9jo
Sd1XSYOOEIm3vdrtavlsdbNISwL4rNGldQRCJRJ6/Sb8SmGvWaWJQNv3gxjJFvANJpdCsljx/ZxI
gJEJDOp+ZiUMKvXbH2NNzv1t+WSL2T6gxwg8HVWqu8o++OttewkCoTrmq9s1d6wtA8eqFcfadPtR
eJ9EKHoHZA2MK2ldjXU1G8sW6j1/I/Jgsbxs4T+ms0q8qIy9KmJfmJ78pXwBAAAQCD9NOPy/kyL/
4xaC0ektMMkCQguBzNluVyVWF9i07TUIhKvVjRs/9Tl4RwbHtzjhKcoaNjumuI+mCiuRMNrccuqI
hN7t9yRGr8RmKoL5bJ78xW5XSFVgkn2dhEywzn3dk8c6WsKgao7bY3kNf8vKN2KZEUAVLJvVDfbi
SvVfIthUdfLvKYfabWwUbHUb23v2+yQbY2RKSvb+asX7apXivyeSYETEzIU8aK/Vy4BAWJL3IJ9l
eCkkgvHeAwAACIQfLhTCavZWnIRkaeebO4FQAuE1kQhZc7wvS2/86asfnbKGaqXMkgC+OskdEQg9
mWAdgWDvEBiqFEcF6VEg+OkUJ6tHzGWz6+eBxxOflxFZ40/6ewLhYsd04qx/xM3zSRkD3BEsV2nu
vlY+prqrz4usLl6tkI80XFTHUyYgW4nXUBZCUcpRlfP17pt6D7COULind0CVEVaNaHwJm389z+71
MLtteHsxnWX4JRmGAAAACITvIxHaKLhs9dgHKyq10a+O/hIH/7XbcoYsxfEhgvuvfP3ciW7V4+Ee
gWB3nrT62+rd7nubVfb2h93G+ip4gaBGzC2mx1D2xtFlkmbkeR+dwqBKGGLmQXyMV/vdYHITxzCA
CkAzeZAFnP9xv4v9OLL6+GyqSkyt9+Nnsyy4qq9I/NvQZIZCHvTG1I4IhJ4o3Yv3xN57XyYP2v1s
z6nKPHgJP5+T13MP8uBVfL5XZQy89wAAAALhwSTCHkRCNaPb15PHlVB/gpFlIHBi8bkiIRMK2d9L
YaBOtjvTQT61WaV4vFsRUPjguhICi/h6Sr7G7IA5HDdmY+UblgiEqRAIWUlDOyabRPANJslCAOsE
zH4fHClfiD0PFiG1rmJb7VhWEG9re7te//c5CdB7E2S67y8D8kC9J4yMyc16y4yIhCwjTL1nxPeP
2W57qUR5cBbCwIuDSga1TIOqTNGPa+bzHQAAEAgPKhGiQFiTgCeudCqB4E8y/EpFtjoBHysShsVA
7/ruuczAuNHqftzVrLIQJ5lIWIU0yEoX4kl3HCN3sn5zt2psnTrpzwK4KtPBZwK9OHHng7U4cvUm
lXv0tYbneBuxPOVdpbrHlWozPWb0VeyX/jiNYyJfCskwh9vpyYMtCeAPEm1gAkWUKSc7ZkqoCUdZ
f5Z7sxN67+OZfDzZsWFiti2JDIqf635x4L+WLxIcPuN5vwEAAATC4xCDMPX3eCLhV2MtnGzEBkvt
+7Q+khOLjxMJ4qT4j2TBe6VDkRnxIfdFZNEokaAmU6g65mzEWZQIp45EqJowzkECTIloicfgLIIE
db/O7tg8iWNtsz/sTA8PKw+URMjKF852m4Fmbh9r7/1Vivtmt9lu7ZhrGTT/CfttzHZrl+2JBBWo
35QyDAbhSxF0xxGHk+WTYTKhca9IqF4/lX1wEq/lWYiQTNJc7Vi68LcdsxAoXwAAAATCswSdSS39
Gk4M/clEXKlQqxW+9rWqgYUvEgqPePtJKU6cSpFlB6hgX6UsnyzPSOg1YZxMZyiY5SUeWVO5OVx2
FkHBi+XjUi0c0zeBFCLhuRCTF3oZCHG1WvXiUOVrMQutEggvlpchnML9PLnLx7GtauVfNQ7eC3mw
WN1w8CzkhpoOsxX35yMkwj29D3oZFF4e+EyS2PfgbyGIygwE3l8AAACB8NgSYTPd/2Az3Sk7nnhk
XeFXdVLEiQV8kgTzJ/Tt+830it3UkQiL5aUMWVO1Sij0mi+q0WtRIswi+Lra7zTw1XSjxs2OWQ6I
hCc+hDryIEt1nwt58F+7XaH2XfozgTBS4rbYsezhbOOZCCYkQnbcxf4PccRh1nDQOvejavY4Kg92
y/sfqPeOKnsq3u/VvZ4qm8RLg1e7LZ+ixxEAACAQnjwI24RE8OngVWfskZRSghX4sP33LQqeihNt
1eRwtXxcWzvBvpruEJ/Jg5EyiazBokojj/JiChIhBjpV9sEaZMohQKGs4enkgZlewVbp77F0LZu+
81871sdf7NjfwN9eL0ttdwLDl/KcrZ+F0K7r2nlviI89jq78K4gE1USyJxCULDDxN0uC8H3g9VMS
oWr8GKdmqKwDLw18xoHqb7TxOQ8AAAiE55YIWTp4Nf+6l6bJSQV8pUiIAYMlYmEOciFmI1wG5ME0
8LussaJq4HYuArhYylCNypvttrzBS4SbtG4kwmOTlC9kjQNjvb+auHAZEAhrIhAmux07OrJqvbjj
1UuEkeyD1W6nxZjlpQteIPzlNj+Jwj8nlUTviQOzj2mimPV16WUNql4HmTi4FOJg9DUEAABAIDyo
RNjt2GHakuAnOwmqRlIBfKVI2MX+6vfjWO4QGzBW2QZVXwV1GSXhYiDXGiP62vC4CtsEgjp5V0HF
1T2+1Y6ZRkiE55AHVdBZpb2rcY2x90HsgRD7H8QMhCrwzJrBzk4m7DaQ8eauL06CUNKuyYO/hDxo
AkE1E+7JA/VZuHe+VgIhez2zqTBmejTzNcgCJRGiOFh7zzfvIQAAgEB4TokQA6+pc2JXngxxQgFf
LRJC4JRlJqiU5i2chK+mmyPOxUl7JRiyAKBJgYvdrq76YymOpTyLE/gYHF2ESPDZCIdMBI7ZhxMH
lTzISnXitIG2D/ryhapWXtXH+2B+tnylPQuQe5MZsmMh9geJx1zW9yD2QPDZB/GzTwnC2cYE+j74
u/gZnGVXxeuJvYqqkgWVdVCViSAPAAAAgfDsgVcnFXwfOAFiDjR8O5lQSIUoE2Ijxs3ylb2RFcAs
+yAGSX4lNKZ9qyDCSwSzuhZ6LiSCGSUNjyoN1H42Or5UyQNfvqBS3+OKddbbYLK66d5UBOUnqyXa
Lo4HH/yaC+69QHhJBEJsnDgXwflmWvyNBvvZ73oiIctsiPfN9y+oyhauQh5UmRV8zgMAAAKBoCtN
BVcnOPto8Abw3aTCYBPGrAFjL5V4GpQHXiAoeRD/16/CVqvKcYvZCK3BXFrSwLH8o6WB2jezbv3n
AYHgx/VeLK+PX5NgOt6/vbifmXybxf5v4nr8sXBNBIKXdj4D4cWOWQdzOEZicF5NI1DHuxXvC2b5
yFcrPn/3EOT7+6Xkgfra/k7WAQAAIBDg/YFWOEHdR4MzgJ+ynyd9QKoynmngd5U4yARC1hAxS+Vu
q6hVTfur5Y3VqpKGG5HAcf5jxEG2H6qRjdnUhSgQ1Cp23HoTETyb1en4lRCb7ThaMmtK+nqHQPB9
EFS/gxigr4PCxAbESE8mVNl/WRaEFz7XRPwoCaTGa6qsCt4LAAAAgQCIAUAkhJPkWL4zWoNciYP4
u5ZmvdixTjtrvuhHPC42loXwt+VNHv2kBh8wRLFiCIVvJw962TBxVGms+29bnL4w2225QQxKsxr5
qjzBTI8OzgLtan9ulzvbsU+JFyWxH0O8nJ964rfYQFI9B9Vjnwp5MHdkQu99Iz7HPttgFffRv16X
8L2SQGQdAAAAAgEAYFQkJDLBTE91uEcsWCIaFuundKs05mwKRDYLXk2VuNhtE8nNdJ26LGui1OFb
iYOsmediemThf4RE8BkulgTOMXjOuvOPlg1tdlsipEotvPyIWRJz8r+XRCAooaJKOMx+N2CsMjBU
BkL22szJ96MSIT5veyEPrnbMQojyJxMh9DoAAAAEAgDAe2RCIRTuEQvZzzFYryRCvE2fiaBGSWYC
QTVYXJNgKBvRepOd4INbAo0vkweT1WNEZyEP/Gr7L4HwV5AHPoBW2QdZ0DkUcIqpP5t4XL9u+1Xc
74v7fi+kif+/q91OYTDrl0j4AN0/9pjyfzGdgaBep3sFwmT96QrqPqrsg6v4XXz91iAOyDoAAAAE
AgDARwmFd4gFJRf2QiqoQCIGDu37xfRoySqYjAHUxX43ndsGRELsFUEDxs+XB6qZZvb6LlY3TYyT
B7Kmgb3sg2yMYnksJRKhZSFc7Xd2TOvjobIE2kjFOREJcX/29y0r51HyIGs+qDIQbEAg9MbCqlIo
s2NJUS8DYS22rSMOyDoAAAAEAgDANxELUS6oVVizsZrnzQVVcxFMVYHm4oKtxfSq5FYIDFnigEj4
cHmQTd1QYxlV+r+v+X8JIsGXL/h9VK1o92rl3yMR/H49u32xSQS/+u8f4xS+WpAEcbrJ3hF0Zsem
kb2Rlf44yQTCvX0QqjKGahqEkgnx/SLKwZg9gTwAAAAEAgDAdxALhVzwq7BWCISt2LxIUMGJEgl+
ZbpqqBYDjs3ylUtEwufKg0waZPX86u+xaWDsfaAyD6qRjfvo/i8kggk5NrvbjFkIalKEn8gwJzLB
jyztBeSZPIgCYbXbEomRXiYj0xiq/gfx+VJScVQY0CgRAAAAgQAAP0EuiIZyWxEo9Gqezy4wiJ3q
F6tH+qlxbqMrmL3g5CASCFDet9sEeeClQJwkMCoR4t98Cr9P3R8Z9TeUeVAEw0oi+CwE1URxcp/3
amqCf+7UVJGY/aAmTURx8CqOky3ICQvB/3THZjbeRDG+J+wDx+RWPAcclwAAgEAAAPjOIqHoSm8i
qFLiQNU1n51I8NLgZMf097gqnWUhKJmg6qmnnkhw8kSKFThkH8xCHqgsApVVEMsaeo0DM3mgVt3f
Vb5QHANtv5ns2H9geQveY+ZB+x+/r1fZOvuAlPOPPYqD+DxUEmUqRILZ/eMbM+miBEEvyyB+5fgD
AAAEAk8BAPxAibCJE/ws2OmN1DvZcdydma6jP4nryyRC1qwt659Q9UpIpcKzBjRJ6cIs5EHsY/AS
BELMLlBbDMQ3u119//tt86vwcfV9/6DXTfVD8AG9us8+U2FJxFtWdqOaEF4LedK2amLJ4RBPhEIv
26Ca4LJ33iOGpQHiAAAAAIEAAI8hEbL+B+vA1i73YseUbzX6rgWaUQz0pMG1EBmz6VRqFcioLAx7
8t4JUyF64hQF/zVmHyzi9TYhDvz+1QLlv8OWpe7/SflC3P8nt/+3iQxxn/X3+2q6h4NZnuKvshP8
/uzHNMbeDyoDQ5UvjIoE63yvfq4kgiXSAHEAAACAQACAByT2RKgaKKoSglhm4Gvi44SGJUiE6nqq
GfNqUxkRs/VXRZ96LGTIwojlCzH74D9hexHyIKbIWyIO4gr8a5AHMQMhKwn4qP2/SYQmoy4hmG73
N+7fvbGnm+nGgyP79mp1+YY6hqfid/cIg5H3C6QBAAAAAgEAnoGkseJu45MYsmyE61tgudqxpCGO
k2vXfUquf0uCrGyl9p6Rf1Ug+s9z8yQBkJq64JtexgyEtqmJCtUo0BhEZ5MH/g7yQAXRfxSgFmMd
WxaCBbEWsw+W5DGb5T0Cto5EyMZWqsyLfSDAN3FcZ5cZlQh77/aQBgAAAAgEAHhgkZBMZ5hEELR3
RIKXB7EuPmYjTEnAmQVcmUBQMqEKvvbkccVmkm8O4XElgsg+GClfUNkHMZV/K6STSt/3Wza68KMm
MFSBsd8HVK+GNkFkSQRCT5xsHZGwDe67w4F6MsJ1VDz0pAPSAAAAAIEAAE8sEXyg4FP75yIIqkTC
1Y4j/JRI8MHKHAI31eDubMfGc2c7rljHwLNXlz6ZWOl98EyEXu+D2DTxxY6lC1E2rYNbJoSyEY4f
kn1QyDMvkK5Cml3t2CDS779Vk8Ft4BiqxiH+c2ze87hHL6umlFTSAGEAAACAQACAJ5cILpCIwcFq
x34CVcB4dYG+T3OPjfayjIRMJmzi+qsshHUgUIsrv5P7mz2qREiyD3x/ikwixNdyEkF2DP6vdl9P
iyyV/8OzDzoSIe57aqJEr+FglsVTfU1HVX7WPogQAAAAQCAAAHyESIid6i0ER1WpgV9hVhJBrebO
Iqg1JzDadaxBImQCYbWxVPrZXd5EMPmomQh+BT3Kg1OQQKrnwR6ey4uQOpVEyH6Or9tNMP2Jr0Oc
chAFQuzlYfbnIw+7oxAJ8AEAABAIAAA/SST4oHrvCIRVyAMvERbT2QhRKMxOHkwuYG0THE5BIpzt
dgVbZRhkjRnb1m5nfQKJEOVBVsIQRzVGeeAnKbT+Ba9WjyNUY0C3jjj4lGB6oA9IHPWoJk2ozJ1q
5GFvDOKnZx0AAAAAAgEA4MNFQjKpIStnaEFgC+5PQSIogZBtLZi1IBMsBLv+9tYiOM0aMl6sTkl/
KIlwR/mC2hY7jjaMYxhfhUBQmQVZw8B0esZnpvAXfUCm8FyZ6eyDKA4skQKpLEAcAAAAIBAAAB5N
IvjAWvVDWEKg3oL7xXQZg5IJJ8uzFfyK+R4kwkjZgk+3V80dVfC32cd3/v/XX9oQGM+FRIhTB3z2
gZcHXiI0OVM1texKA/uiNP5EIniRsAtpMHXkgVWSwBiDCAAAgEAAAHhEifC/aKfORmh14luQCVfr
Zx2cLF/9zpowtmB2cbe7dILUKBBeg0DIAr/d3cYjlDL05MFSyBsvD/z4xV/i4L9BIFyDvOk1C1Tp
/F8WVIf93MS+nkmCycYE017dLgAAACAQAAAeSiQU2QiZTIgiYUmCVdXAz/c5aNsuJIJvbDcXgWnM
jIjXE4M9H0D6RpKP0g9hGpAI/jky040TX+22fKEJhGqSQiYM/vU0fn+7SVbCkBgYuX4AAABAIAAA
PLREcIGVqhf3mQlNJKx2bNQXv88mAMQ6et/QbzHd3G5PZEArc2i9GaKEiJdXderbg0iETL7Mlo/b
tCBifAbCJciDS0ceWPL8fqsAu7ovoZ8EYgAAAAAQCAAAWWAlshGUSNhCgBq7/quVb9988df28iYR
2tcoEZQIUM3vTNxuDI57afaj6erfjhjwhudHvQ6xN4QvC/GNKH05g5q6sPWkwU8MwBEGAAAAgEAA
ALgzgOqIBLPf6f9V2YGSCH4MYJQIqi/CLK67fW/i9xbuczZdIkqFn17KoLI25mSbTJcvxIkWfjSm
/7vKPCAIBwAAAAQCAAAiIe1gHzvZK4mw2u14xhignu02AyGTCL1guDVftBAYx5GUaszgbp1O+j9A
HkSRoDJDJtMZCHFkZzU6s132n+cJYQAAAACAQAAAGJnWoMoLNjuugscV7igSqukMsb/CIv7ebr/9
fLbjOMrN8hGErQ+Atcf7Q7MQvERQGQmq5CFmIWx2HJ156HWAOAAAAABAIAAApCKhIxN88OplQgv+
WzDashFOQSBcnDw42bH5n5rs4EcT+kC5/T5bVY8Bckzr/7EvVXj+s2yNKA9GszT2H/78AAAAACAQ
AAC+WiYU4/CiTIhjIFVGgBcBKsNgDpdp2Qrnt+tRIxwXdxmVAbE6meHHVv7EpopTIhKqTfWF2Eek
AdkHAAAAAAgEAIC7RcI/1uDYL8HsOMWhbU0mLC6QvxTiII6FPAcx8PL2sx8FOQuJ0KYLtAyG69tl
tiAR7AePdFSZBup3Jl6rvZAHSAMAAAAABAIAwMcKhU7zxZiRsNqx10E2FrIF/pc3aeCb++1vvzO7
bRp4st8lE00meFmxml6d/6nioPe7UWmgGksiEQAAAAAQCAAAHy8TkqwEv9K/uWB/NT0tIDZQPDkp
sFnef8H/70lsatLD5v7/kYPlrOxEZR0gDQAAAAAQCAAAXy4SfEmDX+2P0xv81IAoEXzvgz0RBn46
Q/zfRciDkRT/R5UIe0cqAAAAAAACAQDg60SCaLoYx0BOlo8i9OMg/XSAOHkhyoHsbz2J8KjBc1aa
AAAAAAAIBACA7yMR/hex5mMgvTywIBRagL+EINj3OYilDa1JoyUiQYkDf9v7D2ukqMoNej0NEAkA
AAAACAQAgB8hEiyRCVEkxCaM5oRCm6oQxzUqeZBJhLm4vZ/IPvB7GiMCAAAAIBAAAH6OSEhkQhQK
aizhr6D/ar+zDq5vW5zMEEshsjKG9vfthz+1qiFi1hgReQAAAACAQAAA+JkyoRAK5oL7Nv4xbpvp
6QxmxywENSry0Afhh5QxVCUJlUgoXxb2TAAAAAAEAgDAjxEKYhTk7gTBFqTBGv4WeyVUGQjZRIaf
tEqvxIH6+z2PCZEAAAAA0GHmKQAA+FYyIa6iZxLBT2nYQyDsBUJrutjrieAzIn6aRIjPW5QITGQA
AAAAQCAAADykRIhB8Ra2TCKoDIRT2MrJDD9AIuwdaZCVLyAOAAAAAP4QShgAAL53oFxJhNgHwew2
A+GUbKvlGQz7N34+pkGxkD0OJAIAAADAH0AGAgDA95YIsZxBNVRUfRC8QDi/bVU5w08tZRiVBwAA
AACAQAAAeEhxYNbPPogiYXfv7U0inMM2VMrwQ+XBbnWJAwAAAAAgEAAAnkIieGFwdZsa6agyEF6C
SFAS4f/f6M/qhfCevwMAAAAAAgEA4OcSGil6ieCzD65BIsQshFjG4OXBi+mmipOTDwAAAAAACAQA
gB9CVsbQZMHFbrMQrtbPQqgyEJAIAAAAAIBAAAD4wRJhd2JAlS9c3jafhdAkwGy3JQxnIRB8BsJP
b6YIAAAAAJ8IYxwBAL4Zv8oYXPCeZSA0eXA23QvBy4BTsV3tdwbCZre9EPZMIoRSCwAAAABAIAAA
wL9IVsZwdcH/xW0tu+DXZRbTvRDOduyBsLzJh9nd5pbcl2YWyuwEBAMAAAAAAgEAAL4AkYXQsgNa
oH8VW8tOaMLBlyZ4aeBLGJqIWOx39sLuZILvqZAKhfj7KBgQCgAAAAAIBAAA+FyqZopRHniJcHKB
fytRUKMdL28/+/4J/vb2RBhkP0/hbwgFAAAAAAQCAAB8JkUWgu+FkI1zVCMdvUDwIiE2X5zsdqLD
noiDfeDrpGSDKoHoSAUaOgIAAAAgEAAAoEMvCyGTCD4rQPVCOBeX3Z1U2DvyIH6/F383uyNLoei1
gFAAAAAAQCAAAEASREeBECczqAyElgUw2W0Wwi958OIua04yrOH/LfmayYPtDpmwjwoFxAEAAAAA
AgEAAPr4QHtP5EHsibDZbSPF2W7LFlYXuDd5cBUCIZMHJoTB1hEJI2JBlT1MiTzwv0cuAAAAACAQ
AABABN9ZKYOXCk0ceEnQJILve+CzD0YFgrov1ff3SgX/dSo2K74CAAAAAAIBAOA56JQxZBkIbWvj
GSe7Heno+x40eXB++5/N6jIEE0JgKzYlFdTXTCZYEB1tG5UIyAQAAAAABAIAwFMRyxj2IBGaNLg4
GbDY7/4HUSL4oFyVL2xBFtwrENZ3iIWtkAj+vvqvXijMlpc7AAAAAAACAQDgqSRCVsLQ5MGv7dUF
2C2YjqUMFn4+iQDf7L4SBiUPeiJhHRAJXiD4sZRLEAmqvMGQCgAAAAAIBACAp0CMNIxBe5MHpzd5
cBICYbHbtP6WidC+X02XFHh5kImETUiNTCCsg5eLWRAxg+LkHucpkQkmRAIAAAAAIBAAAJ6CqoTh
4gJpv+q+uyDbB9axvGHvyAN1P8x09kCWeaCkwTogEUwIhPPbdhISYQriAQAAAAAQCAAATyUPzHQJ
w/ImEGI6v7nLLkEi+OucTfce8CUE1f2pMhJ65QuZQNgsL2No8uBFSIT2+Dcba6a4s2sBAAAAIBAA
AB6CYhpDG9d4Md0DwGcp+AB7HgikdxF4ZwH5iEzYbawXQlZO4bMmFicQXoJImMP9nRAHAAAAAAgE
AIBnI2YhTG8Bt5IHMVNBpfn30vtVM0JVHuB/b4lMGM1SUM0U/X3wjR/PYVvsthxjQhoAAAAAIBAA
AJ6KThaCkgetF8HVdJr/LCTAiDzw2yykQvyfTID0shX89/E6FycR/DYHeVLdNgAAAAAgEAAAHhrf
vDD7u5cLbcyjz0BYhABQ0sA64mAW16OyIaZBqZDJhSg2qnGOTZxM4jmprhupAAAAAIBAAAB4DFwW
gipluJqe0nCy340WY7A9F8H+iDyYxXVVUmFOrtdMN3dU0yCmIBHi6MYtXFcsh1CTJnb/HLOnAQAA
ACAQAAAejRbsrkIq+BGPUR4sSZBvlmcNTIU46G2VcMjuh3qcVgiNdrnJ+r0WsuwGAAAAAEAgAAA8
BiILobG5INoHzXMQB72yA3unQFgGfhfLDvbiNtKnQPyPBXniBUrcVDYCAAAAACAQAACeQiL4Boa7
Ewft62rHTIBeGUGvB0IlC9T3Xhyc3NcWxC9vtzfbccpDJhHMbntCxL4PbfPyQE14QCIAAAAAIBAA
AJ5GIrQg2k9F2KwuFRiRB/dIhJ5A8OKgjWFc3c+b6b4GlUjwAqHJg4vYvEzIxkQCAAAAAAIBAOCh
JYLZMRuhbZuNT0iwd0qEXlZCzD5o29WJBC8XsgaPmTzwfR+aMPj7bXt1AkHJA7IQAAAAABAIAADP
IRH+F/0esxF2q8coqoyD3s+jIx0zmRAFwjl89aMmR7MQ1NhKLxCURPC9EJAHAAAAAAgEAICnFwl+
KoH/fU8YVCKhaq7YG/UYyxiiQPAZCIvVzR17AsFLhIsdsxAOTRQZ4QgAAACAQAAAeFaRYJY3WuzJ
guzvI6UNVa8ELxEudpuR0H4fRz1aIRHi1Imrkwiv4WuvjAEAAAAAEAgAAM8pEv6JsvXoRwuBeCUV
9iKQz0YrZtkIVztmI/g+CVUPhCm579kUhph9EDMQAAAAAKB3bslTAAAAQTC893Mkfl+VOVQNFn3p
wr0CQUkELxPWsO1OJFC+AAAAAIBAAACALxINPaHQK2uIZQvTOwRClAhbkAabJeULCAQAAAAABAIA
APw7QsF/1sSMhGxyg/+7DcqDSiJsQhogDwAAAAAQCAAA8I2lwsgoSFX+cM/n1i5EgpIGyAMAAAAA
BAIAAHxzoaAEgZIH6rOq99m1JyIhTltAHgAAAAAgEAAA4LtSiIT4uXSvOLi5GfE1/g55AAAAAHAH
CAQAAPhXECUO7xUGasyk+vnmb8gDAAAAgPtAIAAAwL/K4OjI93xeSUGAOAAAAAB4HwgEAAD4dgxK
hfEPO6QBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMBz8H8CDAC6
YUwX9FcenQAAAABJRU5ErkJggg=="
          transform="matrix(1 0 0 1 -13.8467 -9.9121)"
        ></image>
        <g>
          <path
            fill="none"
            stroke="#0b49dc"
            stroke-width="3.9766"
            stroke-linecap="round"
            stroke-miterlimit="10"
            d="M192.877,166.28
				c0,0,1.472,0.648,3.118-0.589c1.651-1.235,1.828-2.591,1.415-3.828c-0.412-1.237-1.767-1-1.767-1.707
				c0-0.707,1.591-1.12,2.415-1.768c0.825-0.647,1.06-1.883,1.65-3.238c0.589-1.354,1.47-2.356,2.886-3.476
				c1.411-1.119,2-2.53,3.297-2.707c1.296-0.176,1.708,0,2.413-0.944c0.709-0.941,0.885-1.177,2.063-1.235
				c0,0,0.825,0.353,1.528-0.411c0.709-0.768,0.592-1.179,1.182-1.769c0.587-0.589,0.762-1.293,1.117-2.475
				c0.354-1.177,0.826-1.885,1.179-3.06c0.352-1.178,0.412-1.356,0.822-1.887c0.413-0.528,0.061-1.177,0.413-1.528
				c0.356-0.355,0-0.355,1.179-1.06c1.179-0.709,1.237-0.827,1.824-1.299c0.59-0.47,0.356-0.058,1.356-0.293
				c1-0.237,1.117-0.059,1.648-1.059c0.532-1,1.063-1.355,0-1.944c-1.059-0.589-1.293-0.766-1.352-1.414
				c-0.06-0.648,0.293-1.12,1.233-0.294c0.946,0.822,1.064,1.766,1.887,1.827c0.824,0.059,1.178,0.233,1.354-0.413
				c0.176-0.648,0.472,0.06,0.882,0.59c0.412,0.528,0.709,0.411,1.648,0.823c0.944,0.414,1.06,0.177,2.475,0.531
				c1.411,0.351,1.001-0.061,2.118,0.118c1.119,0.175,0.885-0.235,1.884,0.645c1.002,0.883,0.827,1.002,1.944,1.355
				c1.123,0.354,0.827,0.768,1.887,0.768c1.058,0,1.412-0.24,2.177,0.528c0.769,0.766,0.591,1.355,1.412,1.531
				c0.828,0.176,0.769-0.176,1.356,0.587c0.591,0.768,0.53,0.472,1.178,1.002c0.648,0.53,0.412,0.766,1.355,1.059
				c0.941,0.298,0.646-0.116,1.236,0.533c0.589,0.648,0.532,0.411,1.237,1.116c0.709,0.707,0.826,0.535,1.828,1.002
				c1,0.473,0.882,0.235,2.119,1.003c1.236,0.765,1.178,0.293,1.827,1.414c0.645,1.117,0.41,1.061,1.234,1.294
				c0.825,0.237,0.648,0.411,1.236,0.883c0.59,0.472,0.471,0.295,0.471,1.299c0,1,0.237,1.059-0.471,2
				c-0.706,0.941-1.528,0.882-1.647,1.824c-0.119,0.942-0.061,0.294,1.001,0.825c1.061,0.533,0.234,0.65,1.647,0.826
				c1.413,0.176,1.473,0.587,2.416-0.059c0.941-0.65,0.941-1.534,1.825-0.768c0.882,0.768,0.941,1.061,1.707,0.883
				c0.769-0.177,0.235-0.35,0.944-1.294c0.707-0.942,0.767-1.355,1.412-1.237c0.648,0.118,1.118,0.354,1.647,0.235
				c0.532-0.117,1.181-0.175,1.181,0.589c0,0.766-0.592,0.531-0.709,1.592c-0.117,1.059-0.292,0.766,0.709,1.118
				c1.001,0.352,0.529,0.058,1.704,1c1.181,0.941,0.709,1.237,2.122,1.826c1.414,0.588,1.826,1.118,2.533,0.531
				c0.706-0.589,0.233-1.473,0.882-1.298c0.65,0.178,0.883,0.414,1.825,1.003c0.945,0.589,1.354,0.472,2.651,1.354
				c1.296,0.883,1,1.296,2.18,1.296c1.175,0,1.53-0.296,1.706,0.472c0.178,0.763,0,1.175-0.292,1.942
				c-0.297,0.766-1.003,1.355-0.356,1.827c0.648,0.469,0.826,1,1.648,1.059c0.824,0.059,0.532,0.766,0.412,1.531
				c-0.117,0.766-0.06,2.062-0.707,2.237c-0.645,0.177-0.528,1.118-0.353,1.769c0.177,0.646,0.412,1.058,0.177,2.296
				c-0.234,1.236-1.77,2.825-0.943,3.415c0.826,0.589,1.591,0.117,1.708-1.236c0.117-1.354,0.119-2.709,0.269-3.595
				c0.143-0.88,0.085-1.883,0.438-2.353c0.354-0.472,0.12-0.531,1.059-0.178c0.944,0.352,1.649,0.293,2.65,0.236
				c1.004-0.058,1.239,0.177,1.239-0.767c0-0.942-0.177-1.12,0.705-1.235c0.886-0.12,1.061,0,2.356-0.412
				c1.297-0.414,1.474-0.295,2.062-1.06c0.591-0.766,0.707-0.648,0.826-1.827c0.115-1.177-0.296-1.118,0.293-1.942
				c0.59-0.827-0.412-1.648,0.59-1.475c1.001,0.178,1.001,1.12,1.59,0.414c0.589-0.708,0.646-1.001,1.178-0.057
				c0.531,0.941,1.413,1.766,1.708,1.118c0.292-0.648,0.705-1.475,1.292-0.533c0.589,0.944,0.354,1.591,1.119,1.944
				c0.764,0.356,0.764,0.65,1.591,0.293c0.827-0.353,0.59-0.705,1.236-0.176c0.649,0.533,1.296,0.768,2.18,1.181
				c0.882,0.411,1.117,0.293,2.001,0.705c0.884,0.412,1.826,0.768,2.531,1.357c0.709,0.585,1.532,1.646,2.536,2.176
				c1,0.529,1,0.529,2.352,0.941c1.358,0.412,1.002,0.47,2.182,0.531c1.176,0.059,1.119,0.118,2,0
				c0.883-0.119,1.002,0.059,1.769-0.531c0.764-0.589,1.059-0.235,0.88-0.941c-0.176-0.706-0.233-1.353,0.532-1
				c0.764,0.353,1.295,0.059,1.238,0.825c-0.058,0.764-0.766,1.116,0.292,1.528c1.062,0.415,1.417,0.944,1.883,0.357
				c0.472-0.59,0-1.415,0.769-1.592c0.765-0.177,1.06-0.353,1.531,0.234c0.472,0.589,0.116,0.648,1.294,0.648
				c1.179,0,1.237,0,2.121-0.352c0.883-0.353,1.118-0.296,1.234-0.883c0.12-0.589-0.646-0.529-0.646-1.179
				c0-0.647-0.389-1.12,0.66-0.706c1.046,0.412-0.072,0.175,1.046,0.412c1.121,0.235,1.062,0.411,1.829,0.587
				c0.764,0.178,0.528,0.119,1.237-0.057c0.704-0.176,0.883-0.176,1.528,0.646c0.648,0.825,0.648,1.119,1.475,1.65
				c0.822,0.528,0.882,0.942,1.469,0.528c0.59-0.411,0.59-0.353,0.295-1.176c-0.295-0.825-0.825-1.354-0.295-1.354
				c0.532,0,0.77,0.292,1.117,1.059c0.357,0.765,0.357,1.119,1.181,1.648c0.824,0.531,0.765,0.354,1.591,0.473
				c0.824,0.116,0.472,0.058,1.766,0.175c1.295,0.117,1.239,0.117,2.238,0.176c1.004,0.061,0.826-0.115,1.589,0.413
				c0.766,0.531,1.18,1.062,1.649,0.531c0.472-0.531,0.352-0.825,0.943-0.353c0.592,0.473,0.649,0.942,1.412,0.116
				c0.768-0.822,0.531-1.235,1.356-1.059c0.826,0.175,0.942,0.058,1.71,0c0.762-0.057,1.175-0.057,2.233-0.297
				c1.061-0.232,1.118-0.176,2.002-0.352c0.882-0.176,1.179-0.528,2.123-0.237c0.939,0.299,0.587,0.061,1.764,0.357
				c1.178,0.294,1.062,0.472,2.182,0.472c1.12,0,1.234-0.12,1.941-0.709c0.706-0.59,0.236-0.942,1.062-1.121
				c0.823-0.173,0.589-0.234,1.414-0.94c0.822-0.705,0.762-1.061,1.413-1.531c0.649-0.472,0.471-0.705,1.414-0.531
				c0.942,0.178,1,0.531,1.766,0.295c0.765-0.237,0.942,0,1.12-0.648c0.177-0.649,0.237-0.53,0.766-0.824
				c0.527-0.293-0.299-0.118,0.527-0.293c0.827-0.177,1.589,0.117,1.297-0.532c-0.292-0.649-0.292-0.53,0.589-0.53
				c0.884,0,0.764-0.354,1.766-0.057c1.004,0.294,0.941,0.35,1.531,0.528c0.589,0.174,0.236,0.235,1.12,0.353
				c0.883,0.12,0.941,0.12,1.352-0.294c0.412-0.412,0.531-0.471,1.238-0.292c0.709,0.176,0.589,0.528,1.53,0.292
				c0.941-0.236,1-0.175,1.062-0.943c0.059-0.763,0-0.944,0.587-0.944c0.589,0,0.414,0.356,0.651-0.644
				c0.233-1.003,0.058-0.769-0.472-1.298c-0.529-0.53-0.944-1.117-0.061-1.061c0.885,0.06,0.709,0.828,1.356,0.647
				c0.648-0.174,0.119-0.47,0.706-0.881c0.589-0.413,0.53-0.53,1.179-0.236c0.645,0.295,0.824,0.295,1.471,0.53
				c0.649,0.237,0.412,0.414,1.356,0.528c0.94,0.12,0.94-0.232,1.648,0.239c0.707,0.47,0.589,1.175,1.294,0.647
				c0.709-0.531,0.417-0.827,1.299-0.354c0.881,0.469,1.354,0.176,1.296,0.707c-0.063,0.531,0.118,0.352-0.884,1.119
				c-1.001,0.767-0.944,0.767-1.946,1.53c-1.001,0.768-1.176,0.827-1.943,1.648c-0.765,0.825-0.707,0.709-1.296,1.709
				c-0.587,1.001-0.058,1.177-0.705,2c-0.649,0.827-1.122,0.827-0.709,1.592c0.414,0.766,0.649,1,1.473,1.531
				c0.827,0.531,1.885,1.412,2.238,0.587c0.353-0.822-0.177-1-0.826-1.822c-0.647-0.829-1.001-1.651-0.585-2.831
				c0.41-1.174,0.352-1.174,1.176-2c0.824-0.822,0.882-1.118,1.708-1.766c0.822-0.648,0.47-0.236,1.234-1.062
				c0.77-0.825,0.944-1.061,1.471-1.117c0.532-0.06,0.532,0.056,1.24,0.175c0.706,0.117,0.941,0.707,1.707,0.178
				c0.768-0.531,0.94-1.298,1.471-1.71c0.532-0.411,0.943-0.173,0.885-0.943c-0.059-0.763-0.815-1.057,0.005-1.117
				c0.818-0.058,1.054-0.116,1.704-0.47c0.648-0.354,0.471-0.589,1.175-0.765c0.708-0.179,0.415-0.294,1.178-0.648
				c0.768-0.355,1.059,0,1.238-0.944c0.174-0.939,0.294-1.589,0.294-2.473c0-0.883-0.12-1.235,0.295-1.824
				c0.414-0.59,0.529-1.062,1.176-1.236c0.649-0.178,1.179-0.354,2.121-0.354c0.944,0,1.004,0.235,2.061,0
				c1.058-0.234,1.298,0.057,2.06-0.588c0.767-0.648,0.944-0.825,1.591-1.355c0.649-0.531,0.766-0.414,1.533-0.414
				c0.762,0,0.881,0,1.53-0.469c0.648-0.47,0.589-0.707,1.413-0.707c0.824,0,0.706,0,1.709,0.237
				c1.001,0.236,0.765,0.176,1.529,0.528c0.766,0.352,0.59,0.411,1.237,0.12c0.649-0.296,1.004-0.412,1.59,0.115
				c0.588,0.528,0.413,0.648,1.649,0.885c1.236,0.234,1.059,0.117,1.886,0.586c0.824,0.473,0.647,0.059,1.411,0.237
				c0.769,0.176,0.827,0.118,1.119,0.411c0.295,0.294,0.295-0.411,1.001-0.293c0.709,0.118,1.001,0.293,1.294,0.941
				c0.296,0.646,0.415,1.342,0.769,1.614c0.353,0.272,0.41,0.152,1.176,0.389c0.767,0.238,1.178,0.118,1.591,0.472
				c0.411,0.353,0.176,0.411,0.825,1.003c0.646,0.585,0.412,0.763,1.176,1c0.767,0.234,0.237-0.296,1.18-0.179
				c0.941,0.117,1.061-0.118,1.412,0.706c0.353,0.825,0.292,0.825,0.648,1.649c0.354,0.825,0.058,0.941,0.825,1
				c0.764,0.059,0.883,0,1.413-0.235c0.529-0.235,0.707-0.354,0.767-1.237c0.057-0.881,0.352-1.12,0.764-1.649
				c0.412-0.527,0.649-0.588,0.941-1.237c0.294-0.645-0.177-0.706,0-1.353c0.18-0.648,0.022-1.003,0.1-2.179
				c0.08-1.178,0.194-1.472,0.489-1.885c0.294-0.411-0.647-1.059,0-1.059c0.646,0,0.704,0.528,0.704-0.413
				c0-0.942-0.881-1.883,0-1.59c0.886,0.296,0.709-0.53,1.474,0.296c0.766,0.822,1.001,0.531,1.473,1.531
				c0.47,1,0.237,1.707,0.705,1.294c0.472-0.411,0.884,0.179,0.651,0.708c-0.236,0.53-0.414,0.412-0.588,1.412
				c-0.18,1-0.354,0.632-0.354,1.555c0,0.919-0.648,0.389,0,0.919c0.646,0.529,3.06,1.825,3.06,1.825s0.589,0.472,0,1.177
				c-0.588,0.706-1.292,1.945-1.292,1.945l-0.296-0.706l0.586-1.944c0,0-1.174-0.709-1.943-0.941
				c-0.762-0.238-0.999-0.355-1.234,0.174c-0.237,0.53,0.117,0.53-0.412,1.061c-0.529,0.528,0.295-2.003-0.414-2.003
				c-0.705,0-0.35,0.589-0.822,1.12c-0.472,0.531-1.413,0.823-0.764,1.412c0.644,0.589,1.174-0.232,1.354,0.237
				c0.177,0.472,0.409,0.707,0.117,1.177c-0.297,0.472-0.354,0.766,0.292,0.826c0.649,0.058,0.945,0.058,0.945,0.058l0.766-1
				l0.587,0.529l-1.001,1.175l-2.238-0.175c0,0-0.469,0.061-0.529,0.943c-0.058,0.882-0.058,0.882,0.415,1.589
				c0.467,0.707,0.647,0.707,1.823,1.59c1.177,0.883,1.003,0.883,1.945,1.531c0.944,0.651,0.705,0.472,1.587,0.472
				c0.886,0,1.3,0.059,2.181-0.118c0.883-0.177,0.883-0.413,1.705-0.354c0.827,0.061,0.769,0.238,1.182,0.706
				c0.412,0.472,1.059-0.116,1.118,0.532c0.06,0.647,0.824,0.824,0.352,1.176c-0.47,0.355-0.587,0.472-0.587,1.475
				c0,1-0.119,0.706,0.471,1.648c0.587,0.941,1.001,0.763,1.062,1.59c0.057,0.823,0,0.885,0.29,1.765
				c0.297,0.883,0.474,1.414,1.003,1.825c0.529,0.414,0.415,0.709,1.06,0.12c0.649-0.589,0.472-1.412,1.122-1.12
				c0.647,0.294,0.178,1.237,0.765,1.12c0.587-0.12,0.529-0.353,1.413-1.062c0.885-0.707,1.06-0.234,1.529-0.942
				c0.47-0.706-0.057-1.352,0.649-1.12c0.707,0.237,0.824,0.592,1.297,1.062c0.473,0.469,0.646,0.531,1.353,1
				c0.704,0.472,0.944,0.12,0.944,1.062c0,0.944,0.059,0.944,0.588,0.646c0.528-0.292,0-0.445,0.824,0.487
				c0.824,0.928,1.061,0.278,1.355,1.16c0.294,0.885,0.354,0.181,0.294,1.297c-0.058,1.119-0.471,1.297-0.471,2.178
				c0,0.883-0.416,1.064,0.234,1.414c0.648,0.355,0.411,0.472,1.472,0.472c1.06,0,1.591,0.179,2.416,0
				c0.823-0.174,1.292-0.293,1.884-0.293c0.588,0,1.296-0.293,2.12-0.238c0.822,0.059,1.589,0.059,2.593,0.118
				c0.999,0.061,1.057-0.291,2.473-0.469c1.414-0.178,1.826-0.414,3.119-0.766c1.294-0.354,1.531-0.589,2.593-1
				c1.059-0.413,2.12-0.708,3.357-0.885c1.23-0.174,1.588-0.646,2.532-0.766c0.94-0.117,1.355-0.234,1.941-0.411
				c0.587-0.178,0.823-0.709,1.475-0.765c0.645-0.061,0.998-0.176,0.998-0.176s-0.233-0.411,0-1.003
				c0.234-0.589-0.591-1.296,0-1.707c0.588-0.413,0.885-0.649,1.235-0.885c0.356-0.233,0.356-0.647,0.824-0.763
				c0.474-0.12,0.884-0.589,1.119-0.059c0.236,0.528,0.236,0.353,0.826,0.353c0.589,0,0.354-0.178,0.589-0.59
				c0.236-0.411,0.297-0.704,0-1.176c-0.297-0.47-0.999-0.827-0.766-1.59c0.234-0.768,0.412-0.648,0-1.295
				c-0.413-0.649-1.117-0.886-0.827-1.178c0.298-0.295,0.534-0.178,0.884,0.353c0.355,0.529,1.164,0.647,0.91,1.411
				c-0.26,0.769-0.175,0.709,0.047,1.414c0.222,0.709,0.402,1.003,0.871,1.531c0.469,0.53,0.588,0.706,1.232,1.178
				c0.652,0.473,0.652,0.528,1.357,0.942c0.706,0.411,0.475,0.766,1.298,0.885c0.823,0.12,1.062,0.47,1.588,0.056
				c0.528-0.411,0.06-0.763,0.766-1.12c0.708-0.353,0.532-0.705,1.593-0.353c1.059,0.353,1.232,0.65,2.174,1.12
				c0.945,0.472,1.179,0.649,2.063,0.827c0.881,0.174,0.881,0,1.765,0.292c0.889,0.297,0.712,0.119,1.534,0.532
				c0.824,0.414,1.059,0.468,1.297,0.414c0.23-0.062,0-1.769,0-1.769s0.058-0.413,0.645-1.12c0.587-0.708,0.766-0.825,1.239-0.883
				c0.466-0.061,0.763,0.411,0.41,0.823c-0.352,0.414-0.766,0.116-1.12,0.767c-0.352,0.65-0.471,0.944-0.293,1.413
				c0.177,0.472,0.233,0.176,0.884,0.355c0.646,0.176,0.704,0.587,1.352,1c0.651,0.413,0.416,0.824,0.947,1.472
				c0.525,0.646,0.646,0.882,1.174,1.531c0.533,0.648,0.297,0.411,1.061,0.118c0.765-0.294,0.882,0.059,1.178-1.059
				c0.299-1.119,0.181-1.062,0.707-1.414c0.531-0.352,0.414-0.293,1.178-1.18c0.765-0.881,0.765-0.764,1.356-1.47
				c0.588-0.708,0.646-0.177,0.883-1.238c0.237-1.059-0.883-1.59,0-1.825c0.883-0.237,0.823-0.354,1.06,0.235
				c0.234,0.59,0.351,0.531,1.122,0.531c0.764,0,0.764,0.353,1.349-0.355c0.593-0.706,0.825-1.352,1.063-2
				c0.238-0.651-0.238-1.121,0.702-1.296c0.944-0.177,1.417-0.237,2.299-0.355c0.884-0.115,0.474,0.118,1.417-0.469
				c0.941-0.588,0.881-0.176,1.469-0.941c0.587-0.765,0.178-1.119,0.705-1.591c0.532-0.47,0.651-0.235,1.179-1.002
				c0.533-0.764,0-1.53,0.884-1.765c0.882-0.238,1.061-0.238,2.002-0.238c0.94,0,1.296,0.177,1.535-0.704
				c0.231-0.883-0.239-1.531,0.469-1.886c0.71-0.354,0.649-0.295,1.414-0.237c0.764,0.061,1.062,0.061,1.589,0.295
				c0.533,0.234,0.883,0.531,1.473,0.589c0.587,0.058,0.943-0.117,1.354-0.884c0.412-0.764,0.591-1.235,0.882-1.824
				c0.297-0.589-0.173-0.648,0.769-0.766s1.175-0.057,2-0.057c0.826,0,0.709-0.236,1.294-0.471c0.593-0.237,0.477-0.709,1.478-0.709
				c0.999,0,1.06,0,2.003-0.232c0.94-0.24,1.173-0.298,1.883-0.648c0.703-0.354,0.526,0.521,1.292-0.388
				c0.769-0.908,0.649-0.969,1.356-1.146c0.704-0.176,0.764-0.176,1.296-0.706c0.529-0.529,0.529-0.588,1.59-0.705
				c1.059-0.117,1.118,0.233,2.24-0.237c1.117-0.472,1.117-0.587,2.12-1c1-0.414,1.173-0.355,2.002-0.649
				c0.821-0.295,0.939-0.589,1.704-0.768c0.765-0.174,1.589-0.646,2.413-0.646c0.827,0,1.416-0.296,2.237-0.059
				c0.824,0.234,0.766,0.293,1.767,0.472c1,0.175,0.825-0.237,1.712,0.233c0.884,0.472,0.408,0.415,1.47,0.589
				c1.062,0.178,1.298,0.237,2.003-0.235c0.705-0.47,0.823-0.941,1.358-1.708c0.523-0.765,0.882-1.061,1-1.882
				c0.118-0.824-0.888-1.299,0-1.414c0.882-0.118,0.882-0.531,1.884-0.472c1.001,0.061,1.001,0,1.943-0.237
				c0.941-0.235,1.118-0.116,1.705-0.763c0.585-0.648,0.354-0.883,1.298-0.648c0.94,0.235,0.706-0.061,1.35,0.528
				c0.651,0.589,0.297,0.767,1.12,0.767c0.824,0,0.592,0,1.356-0.298c0.767-0.291,0.767-0.349,1.122-0.704
				c0.347-0.354-1.122-0.707,0-0.943c1.113-0.233,1.056-0.585,1.883-0.527c0.822,0.055,0.822,0.115,1.352,0.412
				c0.532,0.293,0.77,0.293,1.534,0.176c0.765-0.118,0.408-0.061,1.586-0.414c1.181-0.352,1.002-0.531,2.122-0.531
				s1.063,0.059,2.062-0.47c1.001-0.53,1.413-0.53,2.179-0.768c0.766-0.237,0.646-0.237,1.706-0.648
				c1.062-0.411,0.942-0.292,1.945-0.88c0.999-0.589,1.06-0.413,1.178-1.179c0.118-0.763-0.414-2.06,0.35-2.355
				c0.771-0.294,0.475-0.235,1.298-0.412c0.822-0.178,0.475-0.296,1.527-0.354c1.063-0.059,1.063-0.354,1.654,0.119
				c0.587,0.472,0.289,0.472,1.297,0.588c0.996,0.118,0.767-0.116,1.825-0.235c1.06-0.118,0.883,0.059,1.231-1
				c0.357-1.062-0.23-0.709,0.532-1.534c0.767-0.822,0.239-1.059,1.354-1.179c1.119-0.118,0.945,0.061,1.885-0.174
				c0.945-0.237,1.059-0.591,1.709-1.12c0.646-0.531,0.586-0.824,1.41-0.883c0.824-0.061,0.711-0.65,1.355-0.237
				c0.647,0.411,0.238,0.589,1.121,0.767c0.882,0.174,1.178,0.353,1.942,0.174c0.765-0.174-0.059-0.412,0.765-0.53
				c0.824-0.116,1.062-0.353,1.237,0.06c0.18,0.414,0.47,0.766,1.001,0.176c0.527-0.589,0.647-0.352,1.18-1.12
				c0.529-0.763,0.293-1.293,0.943-1.472c0.646-0.174,1.231-0.232,1.231,0c0,0.237,0.178,0.414,0.532,0.709
				c0.354,0.293,0.236,0.646,1.062,0.234c0.824-0.411,0.468-0.352,1.235-0.472c0.77-0.117,0.883-0.058,1.65,0
				c0.765,0.061,0.646-0.117,1.646,0.296c1.002,0.414,0.704,0.588,1.475,0.704c0.765,0.12,0.349,0.12,1.35-0.174
				c1.003-0.295,0.65-0.178,1.181-0.65c0.53-0.47,0.587,0.159,0.704-0.802c0.12-0.964-0.408-0.902-0.348-1.788
				c0.059-0.883-0.854-0.763,0.365-1c1.221-0.235,1.399-0.235,2.222-0.472c0.825-0.235,0.415-0.589,1.476-0.353
				c1.055,0.235,0.939,0.293,1.826,0.764c0.881,0.472,1.057,0.708,1.528,1.472c0.472,0.768,0.883-0.118,1.002,0.884
				c0.12,1.002,0.702,0.767,0.702,1.531c0,0.765,0.357,0.944,0.357,1.591c0,0.648,0.118,1.06,0,1.828
				c-0.12,0.765,0.118,0.704,1.002,1.413c0.88,0.705,1.296,0.763,2.646,1.705c1.356,0.945,1.766,1.182,3.064,1.769
				c1.295,0.589,1.295,0.768,2.648,0.943c1.356,0.174,3.714,0.294,6.36-1.472c2.649-1.768,3.064-1.884,3.77-3.359
				c0.704-1.47,0.94-2.708,0.765-3.708c-0.174-1,0.057-1.709-0.586-2.003c-0.648-0.293-0.237-0.645-1.358-0.764
				c-1.118-0.119-1.351-0.119-2.352-0.236c-1.001-0.12-1.475,0.293-2.239-0.592c-0.763-0.883-0.119-0.702-0.705-1.235
				c-0.594-0.528-1.003-0.826-0.766-1.353c0.232-0.53,0.351-0.53,0.998-1.061c0.65-0.53,0.709-0.883,1.295-1.53
				c0.594-0.648,0.118-0.768,1-1.179c0.884-0.414,0.827-0.176,1.418-0.53c0.588-0.353,0.529-0.766,1.059-0.409
				c0.529,0.351,0.883,0.707,1.175,1c0.297,0.293,1.001,0.409,1.83,0.707c0.825,0.293,0.942,0.293,1.999,0.585
				c1.061,0.297,0.827,0.18,1.65,0.826c0.824,0.648,0.824,0.53,1.354,1.415c0.529,0.88,0.708,0.59,1.058,1.294
				c0.354,0.707,0.298,0.881,0.999,0.47c0.713-0.411,0.826-0.411,0.475-1.353c-0.354-0.944-0.645-1.003-1.057-2.062
				c-0.417-1.058-0.825-0.883-0.708-1.532c0.117-0.644-0.237-0.857,0.06-1.488c0.291-0.63,0.352-1.042,0.706-1.457
				c0.354-0.411,0.411-0.235,0.524,0c0.12,0.237-0.524,0.648-0.582,1.12c-0.063,0.475-0.417,0.825-0.357,1.238
				c0.061,0.411-0.06,1.178,0.475,1.237c0.526,0.06,1,0.237,2.18,0.177c1.172-0.059,1.291-0.117,2.353-0.47
				c1.059-0.356,1.712-0.533,2.417-1.12s0.884-0.883,1.527-1c0.648-0.12-0.177-0.296,0.648-1.062
				c0.828-0.765,0.65-0.531,0.708-1.592c0.062-1.058-0.058-1,0-2c0.062-0.999-0.616-0.942,0.018-1.588
				c0.629-0.65,0.688-1.002,0.57-1.709c-0.116-0.706-0.116-1.177-0.409-1.769c-0.297-0.589-0.83-0.707-0.83-1.472
				c0-0.765-0.587-1.237,0.238-1.12c0.823,0.12,0.768,0.179,1.355,0.65c0.588,0.47,0.824,0.883,1.649,1
				c0.821,0.118,0.646-0.118,1.294,0.352c0.647,0.472,0.59,0.708,0.945-0.293c0.35-1,0-0.742,0.646-1.726
				c0.649-0.981,0.355-1.101,1.237-1.101s1,0.058,2.003,0c1-0.059,1.529,0.175,1.764-0.414c0.238-0.586,0-0.941,0-1.53
				c0-0.587,0-1.646,0.823-1.235c0.826,0.411,1.002,0.53,1.943,0.882c0.943,0.353,0.65,0.473,1.596,1.062
				c0.941,0.589,0.704,0.706,1.528,0.881c0.821,0.178,0.702,0.12,1.474,0.53c0.767,0.414,0.468,0.355,1.293-0.056
				c0.826-0.416,0.354-0.354,1.237-0.178c0.883,0.178,0.94,0.178,1.476,0.473c0.526,0.292,0.233,0.233,1.175,0.764
				c0.94,0.528,0.882-0.061,1.355,0.709c0.468,0.763,0.705,0.585,0.646,1.294c-0.06,0.706-0.236,1.177-0.236,1.822
				c0,0.65,0.177,0.592,0.827,0.65c0.647,0.059,1.06-0.237,1.292,0.411c0.238,0.65,0.416,0.708,1.179,0.708
				c0.764,0,0.591,0.058,1.355-0.649c0.765-0.707,0.589-0.351,1.002-1.414c0.409-1.056,0.061-1.408,0.706-1.823
				c0.647-0.414,0.295-0.588,1.357-0.705c1.057-0.12,1.352-0.239,1.823-0.648c0.468-0.416,0.353-0.533,1.001-0.827
				c0.646-0.292,0.293-0.292,1.119-0.292s0.353-0.356,1.472,0.233c1.118,0.589,0.703,0.531,1.708,0.413
				c1.001-0.119,0.768,0,1.887-0.354c1.113-0.352,0.588-0.469,1.588-0.531c1.001-0.056,1.293-0.175,1.944,0
				c0.646,0.179,1.295-0.117,1.469,0.651c0.178,0.763,0.235,1.293,0,1.766c-0.233,0.469-0.233,0.648-0.118,1.237
				c0.118,0.588-0.115,0.646,0.65,0.764c0.768,0.117,0.768-0.292,1.12,0.355c0.349,0.647,0.116,0.765,0.823,1
				c0.707,0.237,0.587,0.411,1.411,0.296c0.829-0.12,0.533-0.296,1.297-0.354c0.765-0.061,1.298-0.531,1.476,0.234
				c0.173,0.766,0.055,1.002,0,1.652c-0.059,0.646-0.535,0.822-0.356,1.294c0.179,0.469,0.705,1,1.238,1.294
				c0.528,0.295,0.646,0.354,1.292,0.589c0.653,0.234,0.883,0.294,0.944,1c0.058,0.709-0.767,1.179,0,1.651
				c0.767,0.47,0.709,0.53,1.236,0.707c0.532,0.176,0.646,0.824,0.591,1.178c-0.059,0.352-0.119,0.352-0.65,0.824
				c-0.528,0.47-0.468,0.528-1.119,0.646c-0.646,0.118-1.472,0.059-1.766,0.293c-0.295,0.237-0.528,0.355-0.764,1.061
				c-0.237,0.707-0.298,1.002-0.705,1.59c-0.415,0.589-0.535,0.824-1.298,0.885c-0.764,0.059-1.181-0.12-2.063-0.239
				c-0.883-0.113-1.002-0.113-1.94-0.113c-0.944,0-1.829-0.592-2.006,0.113c-0.176,0.708-0.352,0.887,0.646,1.181
				c1,0.293,0.887,0.236,2.358,0.293c1.47,0.062,1.354,0.062,2.356,0.062s1.002-0.119,2.059-0.179
				c1.061-0.059,0.944,0.179,1.769-0.352c0.825-0.533,0.646-0.944,1.062-1.238c0.408-0.294,0.765-0.706,1.292-0.294
				c0.533,0.414,0.593,0.588,1.355,1.058c0.767,0.475,1.826,1.005,3.007,1.829c1.177,0.824,1.177,0.88,2.41,1.47
				c1.235,0.59,2.295,1.062,2.943,1.474c0.651,0.411,0.354,0.353,1.355,0.411c1.001,0.06,1.239,0.06,2.121-0.117
				c0.884-0.175,1.295-0.059,1.646-0.824c0.355-0.767,0.414-1.238,0.885-1.709c0.474-0.472,0.234-0.883,0.824-1.414
				c0.591-0.528,0.53-0.708,1.237-0.824c0.706-0.117,0.591-0.176,1.296-0.354c0.704-0.175,0.943-0.353,1.238-0.058
				c0.291,0.294,0.233,0.472,0.767,0.766c0.528,0.293,1.117,0.648,1.351,0.236c0.236-0.412,0.236-0.65,0.531-1.239
				c0.297-0.589,0.18-1-0.295-1.235c-0.47-0.237-0.942-1.002-1.707-0.826c-0.765,0.178-1.355,0.178-2.297,0.298
				c-0.944,0.113-0.884,0.291-2.063,0.411c-1.176,0.117-1.059,0.059-2.002,0.059c-0.944,0-1.234-0.356-1.767,0.177
				c-0.527,0.528-1.061,0.589-0.235,1.059c0.824,0.469,1.533,0.411,1.414,1.002c-0.117,0.587,0.06,1.177-0.356,1.47
				c-0.407,0.296-0.586,0.414-1.116,0.237c-0.529-0.176-0.708-0.707-0.767-1.293c-0.059-0.593-0.706-0.769-0.942-0.475
				c-0.234,0.296-0.118,1.414-0.468,1.002c-0.355-0.413-0.944-1.648-1.825-2.12c-0.885-0.47-1.476-0.648-1.77-1.238
				c-0.29-0.589-0.47-1.059-1.057-1.352c-0.591-0.295-0.945-0.768-1.651-1.179c-0.705-0.411-1.237-0.293-1.411-1.001
				c-0.177-0.708,0.174-1.355-0.474-2.238c-0.646-0.88-1.47-1.177-1.766-1.822c-0.296-0.651-0.117-1.064-0.414-1.475
				c-0.292-0.413-0.292-0.707-0.884-1c-0.586-0.295-0.704-0.179-0.88-1.061c-0.178-0.88-0.528-1.12-0.472-2.118
				c0.061-1.004-0.057-1.122,0.41-1.415c0.476-0.292,2.003-0.706,0.178-1.412c-1.824-0.708-2.239-0.178-2.945-1.354
				c-0.705-1.179-0.531-1.179-1.119-1.473c-0.585-0.296-0.941-0.768,0.06-0.824c1.002-0.061,0.704-0.061,2.003,0
				c1.296,0.056,1.414-0.177,2.824-0.116c1.418,0.055,1.535-0.181,2.708-0.181c1.18,0,1.534-0.234,2.418-0.353
				c0.882-0.117,1.415,0.118,2.354-0.826c0.945-0.941,1.59-1.472,2.412-1.823c0.826-0.354,0.475-0.474,1.533-0.531
				c1.059-0.061,1.591,0.766,1.943,0c0.354-0.765-0.057-1.06,0.589-1.413c0.647-0.355,0.588-0.648,1.18-0.944
				c0.587-0.294-0.413-0.707,0.824-0.939c1.237-0.237,1.411-0.475,2.471-0.708c1.062-0.236,1.356-0.297,2.298-0.589
				c0.941-0.297,0.705-0.058,1.825-0.649c1.121-0.587,0.766-0.587,1.649-1.059c0.885-0.472,0.531-0.411,1.473-0.824
				c0.941-0.412,1.355-0.47,1.179-0.884c-0.178-0.413-0.296-0.587,0.349-0.706c0.652-0.119,0.593,0.292,1.475,0
				c0.884-0.296,1.355,0.118,1.708-0.649c0.354-0.764,0.354-0.883,0.764-1.412c0.414-0.531-0.409-0.531,0.886-0.589
				c1.294-0.061,1.294,0.354,1.885-0.294c0.587-0.648,1.001-0.709,1.945-1.414c0.939-0.709,0.469-0.295,1.646-1.297
				c1.179-1,1.471-1.53,2.24-1.823c0.764-0.293,1-0.649,1.647-0.765c0.646-0.12,0.178-0.295,1.12-0.53
				c0.94-0.236,0.823-0.296,1.646-0.353c0.823-0.06,0.882-0.06,1.178-1.001c0.297-0.944,0.588-1.298,1.062-1.886
				c0.467-0.587,0.526-0.943,1.118-1.587c0.588-0.648,0.647-0.648,1.118-1.474c0.47-0.825,0.706-0.768,1.117-2.004
				c0.414-1.236,0.354-1.469,0.829-2.353c0.466-0.884,0.406-1,0.763-2.002c0.351-1.001,0.47-1.298,0.705-2.062
				c0.238-0.767,0.476-0.941,0.706-1.768c0.236-0.826,0.473-1.355,0.651-1.766c0.175-0.412,0.409-0.883,0.409-1.708
				c0-0.825-0.646-1.297,0-1.531c0.65-0.236,0.71-0.354,1.357-0.413c0.646-0.057,0.646-0.177,1.41-0.588
				c0.768-0.411,0.709,0.117,1.061-1.001c0.354-1.12,0.176-0.826,0.354-1.883c0.18-1.061,0.18-1.18,0.355-2.061
				c0.174-0.883,0.059-1.002,0.41-2.003c0.354-1.001-0.352-1.47,0.592-1.765c0.943-0.296,1.529-0.356,2.002-0.533
				c0.469-0.176,0.41,0,1.117-0.883s0.354-1.001,1.179-1.237c0.821-0.235,0-0.529,0.704-1.234c0.71-0.709,0.414-0.709,1.415-1.062
				c1-0.354,1.12-0.529,1.704,0c0.593,0.53,0.885,0.414,1.18,1.119c0.298,0.707,0.416,0.941,0.473,1.59
				c0.061,0.648,0.232,1.001,0.766-0.06c0.527-1.059,0.882-1.588,1.002-2.476c0.118-0.88,0.295-0.527,0.352-1.588
				c0.061-1.058,0.176-1.53,0.59-1.706c0.41-0.178,0.646-0.355,1.002-1.119c0.354-0.766,0.117-0.705,1.061-0.823
				c0.942-0.119,0.764-0.119,1.47,0c0.709,0.119,0.882-0.296,1.414,0.293c0.53,0.589,0.586,0.827,1.181,0.118
				c0.586-0.707,0.939-1.001,1.527-1.826c0.588-0.823,0.296-0.941,1.001-1.826c0.707-0.882,0.707-1.236,1.476-1.53
				c0.766-0.294,0.766-0.943,1.293-1.414c0.531-0.473,0.412-1.471,0.824-1.236c0.413,0.234-0.234,1.12,0,1.589
				c0.234,0.471-0.061,1.766,0.649,1.648c0.705-0.117,0.882-1.059,1.41-0.529c0.531,0.529,1.12,0.413,1.12,1.12
				c0,0.708-0.943,1.532,0,1.708c0.94,0.175,0.591-0.826,1.178-1.237c0.588-0.414,0.471-0.825,1.297-1
				c0.824-0.178,0.647-0.235,1.413-0.414c0.763-0.177,0.763-0.529,1.354-0.707c0.588-0.177,0.941-0.118,0.823-1.058
				c-0.117-0.943-0.542-0.943,0.022-1.769c0.566-0.823,0.334-1.06,1.215-1.355c0.884-0.292,0.589-0.057,1.352-0.88
				c0.768-0.825,0.593-1.826,1.358-1.296c0.764,0.528-0.119,0.943,0.764,0.528c0.883-0.412,0.592-0.585,1.238-1.058
				c0.646-0.471,0.528-0.767,1.296-0.767c0.767,0,0.47-0.12,1.177-0.59c0.708-0.472,0.295-0.708,1.296-0.825
				c1-0.118,0.414,0,1.473,0c1.057,0,0.529,0.015,1.648-0.052c1.12-0.066,1.296,0.112,2.001-0.477
				c0.707-0.588,0.354-1.176,1.181-1.001c0.822,0.18,0.174,0.531,1.35,0.709c1.18,0.174,0.944-0.065,1.18-0.74
				c0.237-0.675-0.177-1.382,0.588-0.911c0.768,0.471-0.351,0.12,0.768,0.471c1.119,0.354,0.944-0.588,1.47,0.236
				c0.534,0.825,0.883,0.882,0.592,1.472c-0.297,0.589-1.414,1.238-0.884,1.474c0.529,0.235,1.234-0.531,1.59-1.237
				c0.352-0.707,0.412-0.472,0.704-1.474c0.297-1,0-0.822,0.942-1.413c0.942-0.589,0.885-0.53,1.65-0.824
				c0.768-0.294,0.117-0.765,1.295-0.469c1.179,0.292,1.061-0.069,1.827-0.094c0.763-0.024,0.589,0.328,0.881-0.557
				c0.298-0.882,0.355-1.706,1.18-1.531c0.822,0.18,1.474,0.248,1.824-0.022c0.354-0.27,0.884-0.39,1.297-0.682
				c0.413-0.297,1.177-0.887,2.002-0.355c0.825,0.529,0.589,0.884,1.589,0.119c1.002-0.768,1.002-1.001,1.77-1.415
				c0.764-0.411,0.764-0.177,0.529,0.532c-0.238,0.706-0.784,1.293,0.138,1.352c0.922,0.059,0.274-0.706,1.04-1.176
				c0.765-0.473,0.705-0.413,0.943,0.235c0.236,0.649,0.41,1.353,0.764,0.295c0.355-1.059-0.117-1.29,0.764-1.44
				c0.886-0.148,0.768-0.092,1.001-0.976c0.238-0.883-0.351-1,0.533-1.293c0.883-0.296,0.764-0.708,1.707-1.297
				c0.94-0.589,1.059-0.707,2.12-1.471c1.061-0.768,0.646-0.709,1.765-1.001c1.121-0.297,0.824-0.119,1.53-0.827
				c0.711-0.706,0.591-1.295,1.18-0.882c0.59,0.411,1.059,1.141,1.118-0.138c0.059-1.276-0.119-1.628,0.706-1.982
				c0.827-0.355,1.474-0.355,1.711,0c0.232,0.354-1.062,0.646-1.356,1.354c-0.296,0.707-1.413,1.237-0.296,1.472
				c1.12,0.235,1.47-0.588,1.68,0.293c0.205,0.883,0.794,0.942,1.265,0.825c0.474-0.117,1.001-0.943,1.121-0.353
				c0.118,0.587-0.528,0.882-1.411,1.471c-0.884,0.59-1.356,0.768-1.002,1.472c0.352,0.708,0.352,0.177,1.41,0.53
				c1.061,0.352,1.032,0.236,1.017,1.002c-0.014,0.764-0.134,0.883-0.661,1.472c-0.532,0.588-0.414,0.411-0.235,1.354
				c0.174,0.943-0.238,1.06,0.413,1.825c0.646,0.769,1.117,1.18,1.117,1.533c0,0.354-0.41,0.412-1.236-0.53
				c-0.822-0.942-0.648-2.889-1.295-2.415c-0.647,0.471-0.236,0.763-0.884,1.648c-0.649,0.884-1.356,0.413-0.411,1.471
				c0.939,1.062,0.998,1.592,1.648,1.121c0.647-0.472,1.178-0.824,1.178-0.237c0,0.589-0.295,1.061-1.002,1.472
				c-0.707,0.413-1.591-0.292-1.591-0.292l-0.529-0.531c0,0-0.118-1.237-1.059-1.003c-0.944,0.238-1.182,0.295-1.593,0.943
				c-0.411,0.65-1.059,1.944-1.767,2.65c-0.705,0.708-0.646,1.179-1.235,2.063c-0.59,0.885-1.472,1.177-0.71,1.826
				c0.769,0.646,1.357,1.412,2.538,2.237c1.174,0.824,1.232,0.589,2.351,1.529c1.12,0.942,1.475,1.708,2.239,1
				c0.766-0.708,0.766-1.175,1.299-2.176c0.528-1.002,0.408-1.178,1.115-1.767c0.708-0.588,1.178-0.943,0.943-0.235
				c-0.235,0.705-0.354,1-0.885,2.06c-0.53,1.06-0.884,1.296-1.059,2.119c-0.178,0.827-0.648,1.062-0.178,1.711
				c0.471,0.646,0.706,0.704,0.412,1.705c-0.293,1.003-1.059,1.71-1.059,2.884c0,1.18-0.061,2.123,0.294,3.772
				c0.353,1.647,0.884,3.709,1.884,5.417c1,1.708,2.767,3.592,5.125,4.36c2.354,0.763,3.827,0.351,4.533,1.293
				c0.708,0.94,1.354,1.884,0.59,3.357c-0.768,1.47-1.237,2.532-2.061,3.945c-0.824,1.412,0.646,1.179-1.063,1.884
				c-1.703,0.709-2.179,0.354-2.94,1.12c-0.769,0.765-1.182,0.706-1.769,1.825c-0.591,1.12-0.883,1.415-1.061,2.296
				c-0.176,0.884-0.355,3.591,0.291,4.944c0.654,1.356,1.006,2.475,2.299,3.654c1.297,1.176,0.475,1.414,2.181,2.119
				c1.707,0.706,0.941,1.06,2.707,1.06s1.708-0.294,2.944-0.294c1.237,0,1.824-0.294,2.063,0.412
				c0.236,0.708,0.646,1.589,0.706,2.65c0.057,1.062-0.768,1.294,0,2.238c0.765,0.942,1.236,1,2.062,1.472
				c0.822,0.47,0.529,0.237,1.593,1c1.054,0.768,1.172,0.884,2.354,1.238c1.177,0.352,1.118,0.174,2.766,0.234
				c1.651,0.059,1.297-0.06,2.886-0.472c1.593-0.414,1.77-0.53,2.829-1.177c1.058-0.647,1.529-0.885,2.529-1.296
				c1.001-0.413,2.003-0.589,3.006-0.413c1,0.181,2.237,0.236,2.884,0.65c0.647,0.411,2.238,1.354,2.944,2.294
				c0.706,0.944,1.53,1.355,2.003,2.885c0.471,1.534,0.353,2.003,0.767,3.476c0.411,1.472,0.646,1.472,0.589,2.592
				c-0.06,1.118-0.06,1.235-0.589,1.942c-0.528,0.707-0.884,0.413-0.767,1.533c0.12,1.116,0.254,1.942,1.363,3.119
				c1.108,1.181,1.049,1.708,2.762,2.12c1.703,0.411,1.527,0.236,3.178-0.057c1.648-0.296,1.648-0.649,2.883-0.827
				c1.236-0.173,1.297-0.408,2.652-0.173c1.352,0.234,1.411,0.234,2.648,0.765c1.238,0.528,1.767,0.292,2.119,1.116
				c0.358,0.827,0.883,1.12,0.531,2.002c-0.353,0.884-1.176,1.473-1.117,2.59c0.059,1.123-0.472,2.123,0.117,3.475
				c0.589,1.357,0.994,1.357,1.114,2.475c0.121,1.119,0.063,1.765-0.349,3.119c-0.413,1.359-0.709,1.65-1.414,2.533
				c-0.705,0.884-1.062,1.238-1.588,2.123c-0.532,0.88-0.885,0.822-1.181,2c-0.295,1.177-0.646,1.592,0.707,2.534
				c1.356,0.941,1.532,1.649,2.358,2.118c0.82,0.473,1.234,0.708,1.059,1.357c-0.178,0.646-0.06,0.469-0.883,1.235
				c-0.828,0.766-1.414,0.707-1.712,1.531c-0.29,0.825-0.409,1,0.237,1.591c0.646,0.588,0.531,0.528,1.65,0.468
				c1.117-0.054,1.943-1.059,2.59-0.351c0.646,0.705,0.59,0.705,1.121,1.886c0.53,1.174,0.824,1.884,1.059,2.825
				s0.234,1.766,0.353,2.945c0.118,1.177,0.177,3.122,0.472,4.417c0.295,1.293,0.65,2.592,1.176,3.475
				c0.533,0.88,0.709,0.646,1.179,1.589c0.475,0.941,0.354,1.235,0.591,2.355c0.232,1.119,0.117,1.234,0.646,2.293
				c0.53,1.064,0.765,1.414,1.296,2.3c0.528,0.885,0.588,1,0.588,1.707c0,0.708,0.414,0.651-0.822,0.651
				c-1.239,0-1.648,0.469-2.591,0.057c-0.942-0.414-1.178,0-1.59-0.884c-0.411-0.883-0.411-1.414-1.239-1.118
				c-0.821,0.294-1,1-1.999,0.646c-1-0.352-0.942,0-1.414-0.824c-0.474-0.824-0.236-0.411-1.179-0.648
				c-0.943-0.233-1.588-1-1.767-0.117c-0.178,0.885,0.47,1.12,0,2.061c-0.47,0.942-0.118,1.118-0.822,1.412
				c-0.709,0.295-0.475,0.295-1.24-0.353c-0.763-0.647-1.354-1.47-1.943-0.352c-0.585,1.12,0,0.883-1.296,1.414
				c-1.291,0.53-0.765,0.292-2.234,0.411c-1.474,0.119-1.711,0.061-2.653,0.413c-0.94,0.354-1.118,0.354-1.468,1
				c-0.355,0.648,0.059,0.707-0.65,1.121c-0.705,0.411-0.529,0.235-0.825,1.178c-0.292,0.942,0.12,1.472-1.06,1.941
				c-1.174,0.473-0.588,0.825-1.768,0.649c-1.176-0.176-1.059,0.056-1.291-0.824c-0.236-0.883-0.178-1.294-1.119-1.121
				c-0.943,0.176-0.118,0.412-1.237,0.59c-1.122,0.176-1.298-0.236-1.827,0.411c-0.532,0.648-0.474,0.709-1.357,0.825
				c-0.879,0.119-1.234,0-1.293,0.706c-0.057,0.709,0.234,1.237-0.236,1.475c-0.472,0.238,0.354,0-0.881,0.176
				c-1.239,0.176-1.061-0.12-1.593,0.588c-0.528,0.708-0.061,1.06-0.351,1.532c-0.296,0.47,0.468,0.412-0.591,0.589
				c-1.062,0.176-0.767,0.469-1.588-0.062c-0.825-0.528-1.417-0.173-1.595-1.058c-0.172-0.886-0.172-1.236-0.705-1.71
				c-0.527-0.467-1.001-0.704-2.176-0.528c-1.18,0.179-1.473,0.179-2.533-0.176c-1.062-0.353-0.707-0.827-2.181-0.472
				c-1.472,0.354-2.062,0.472-3.12,0.882c-1.061,0.414-0.886,0.475-2.413,0.827c-1.53,0.349-1.417,0.291-2.358,0.53
				c-0.941,0.233-0.35,0.529-1.589,0.47c-1.236-0.06-1.704-0.178-1.587,0.234c0.115,0.412,0.528,0.942,0.528,1.651
				c0,0.704-0.472,1.923-0.824,2.904c-0.354,0.982-0.821,1.452-1.12,2.452c-0.297,1.003-0.297,1.534-0.939,2.299
				c-0.646,0.765-1.18,1.826-1.648,2.298c-0.475,0.47-0.65,0.705-2.004,1.528c-1.354,0.825-1.652,0.649-2.943,1.588
				c-1.297,0.945-2.062,1.712-2.122,3.36c-0.059,1.648-0.532,4.945-0.118,5.71c0.415,0.765,1.062,1.472,1.648,2.652
				c0.592,1.178,0.884,1.295,0.592,2.061c-0.298,0.765-0.941,1.353-1.77,2.709c-0.825,1.353-1.53,2.177-1.885,3.475
				c-0.352,1.294-0.588,3.236,0.118,5.297c0.709,2.062,0.709,4.182,1.002,5.241c0.294,1.062,0.236,2.416,0.059,3.533
				c-0.179,1.121-0.529,2.006-1.061,2.769c-0.527,0.766-1.12,0.884-1.061,1.648c0.059,0.766,0.295,0.589,0.533,2.003
				c0.236,1.414,0.236,2.591,0.236,3.768c0,1.179,0.116,2.24,0.054,3.239c-0.054,1,1.298,1.058-0.232,2.413
				c-1.534,1.357-1.65,1.237-2.709,1.709c-1.059,0.471-1.001,0.471-2.887,0.411c-1.883-0.059-3.061,0-3.947,0.648
				c-0.88,0.648-0.88,1.474-0.765,2.474c0.118,1-0.351,3.649-2.061,4.828c-1.706,1.179-1.943,1.355-3.531,1.059
				c-1.591-0.292-3.534,0.12-3.827-0.118c-0.296-0.232-0.884,1.063-1.416,1.179c-0.529,0.116-0.824,0.293-1.588,0
				c-0.766-0.294-1.357-0.384-1.473,0.159c-0.116,0.547,0.178,1.195-0.411,1.076c-0.593-0.118-0.235-0.703-1.119-1.059l-0.882-0.352
				c0,0-0.826,0.85-1.001,1.427c-0.181,0.575-1.004,1.165-1.181,1.281c-0.178,0.116-0.529-0.116-0.942,0.53
				c-0.409,0.647-0.587,0.237-0.764,1.295c-0.179,1.062-0.298,1.119,0.237,2.003c0.526,0.883,0.526,0.883,0.35,1.646
				c-0.174,0.768,0.234,0.768-0.587,0.885c-0.824,0.116-0.708,0.647-0.593,1.708c0.12,1.063,0.414,1.65-0.763,2.711
				c-1.179,1.058-2.005,1.705-2.471,2.706c-0.475,1.002,0.116,0.944-1.357,0.944c-1.472,0-1.237-0.12-2.415-0.589
				c-1.176-0.472-1.413-1.646-2.179-0.411c-0.763,1.237-0.296,1.707-1.649,1.765c-1.353,0.06-0.883-0.117-1.649-1.178
				c-0.766-1.06-1.586-1.001-1.705,0.061c-0.118,1.059,0.527,2.355,0.646,2.825c0.118,0.472-0.177,1.061,0.474,1.531
				c0.645,0.471,0.32,1.918,0.117,2.062c-0.591,0.413-1.532,1.475-1.594,0c-0.058-1.473,0.594-2.651-0.645-2.769
				c-1.237-0.117-2.123,1.061-3.299,2.002c-1.18,0.944-0.887,1.121-1.943,1.062c-1.06-0.061-1.06-0.177-2.061-0.707
				c-1.004-0.528-1.59-1.296-2.473-0.826c-0.883,0.472-1.237,0.883-1.886,1.592c-0.649,0.706-0.881,0.941-1.708,0.648
				c-0.826-0.296-2.177-1.12-2.475-2.123c-0.293-1-0.411-1.294,0.178-2c0.589-0.705,1.236-1.765,0.825-2.354
				s-1.003-1.123-1.59-0.237c-0.589,0.885-0.531,1.237-1.355,1.65c-0.822,0.411-1.177,0.176-1.177,1.589
				c0,1.414-0.469,1.883,0.532,2.592c1,0.705,1.469,0.526,1.588,1.415c0.117,0.883,0.178,1.648-0.766,2.235
				c-0.939,0.589-2.179,1.179-2.299,1.531c-0.118,0.352-0.351,0.531,0.297,1.413c0.647,0.885,0.766,1.237,0.237,1.941
				c-0.534,0.709-0.179,1.417-1.003,1.003c-0.821-0.411-1.355-1.942-2.298-1.825c-0.941,0.117-2.061,0.531-1.416,1.235
				c0.653,0.707,1.064,0.768,2.182,1.472c1.118,0.707,1.118,0.883,1.179,1.768c0.06,0.88,0.472,1.235-0.526,1.587
				c-1.004,0.357-1.945,0.357-2.479-0.411c-0.528-0.763-0.822-0.883-2.001-1c-1.18-0.117-1.825-0.176-0.767-0.707
				c1.063-0.53,1.65-0.53,1.59-1.179c-0.057-0.645-0.645,0.12-1.53,0.12c-0.881,0-1.764-0.414-2.001,0.234
				c-0.239,0.648,0.237,0.531,0.354,1.707c0.115,1.178,0.058,3.123,0.176,4.006c0.116,0.883,0.591,3.475-1.529,3.531
				c-2.122,0.06-1.414-0.413-1.826-1.059c-0.416-0.648-0.592-0.766-0.177-1.589c0.413-0.824,0.763-2.002,0.528-3.062
				c-0.233-1.061-0.59-1.709-1.53-2.179c-0.942-0.472-1.53-0.296-2.062-1.296c-0.528-1.002-0.884-1.472-0.528-2.475
				c0.349-1.001,0.409-1.412,0.941-2.177c0.529-0.768,0.766-0.826,0.766-1.768c0-0.941-0.297-1.235-0.708-1.825
				c-0.413-0.589-0.471-0.883-0.235-1.59c0.235-0.707,0.707-1.235-0.058-1.59c-0.766-0.354-1.356-0.885-1.475-1.65
				c-0.115-0.764,0.355-1.942,0.177-3.121c-0.177-1.177,0.415-2.355-0.824-2.414c-1.237-0.059-2.059-0.47-2.708,0.587
				c-0.646,1.061-0.941,1.65-1.529,2.709c-0.59,1.059-0.117,1.357-1.117,1.414c-1.002,0.06-1.179,0.117-1.239-1.238
				c-0.06-1.352,0.061-2.12-0.354-3.062c-0.41-0.941,0-1.115-0.823-1.705c-0.825-0.592-0.528-0.592-1.415-0.709
				c-0.884-0.115-0.469-1.472-1.412-1.648c-0.942-0.178-0.942,0.472-1.177,1.473c-0.238,1.002,0.175,1.354-1.063,1.475
				c-1.234,0.115-0.823,0-1.885,0.291c-1.059,0.297-1,0.648-1,1.651c0,1-0.291,1.473,1,1.766c1.3,0.293,2.299-0.293,2.36,0.411
				c0.059,0.709-1.061,1.65-1.534,2.121c-0.47,0.471-1.059,0.295-0.884,1.295c0.177,1,0.177,0.648,1.119,1.357
				c0.942,0.705,1.887,0.643,1.825,1.353c-0.059,0.705,0.062,0.296-1.002,1.238c-1.059,0.939-1.528,0.587-2.117,1.587
				c-0.589,1.002-1.178,1.711-0.352,2.004c0.821,0.293,0.883,0,2.003,0c1.118,0,0.821,0.057,2.175,0.057
				c1.354,0,1.475-0.177,2.238-0.236c0.766-0.058,1.357,0.355,1.415,0.825c0.061,0.471-0.591,2.471-0.767,2.827
				c-0.176,0.352-0.295,0.827-1.354,0.059c-1.063-0.766-1.648-1.179-2.473-0.826c-0.825,0.356-0.533,0.475-1.651,0.475
				c-1.122,0-1.766-0.236-1.766,0.413c0,0.646,0.763,1.118,1.413,1.174c0.649,0.062,0.826-0.117,0.826,0.709
				c0,0.824-1.061,2.003-1.061,2.886c0,0.883-0.535,1.12,0.528,1.12c1.06,0,1.354,0.587,1.649-1.237
				c0.293-1.83,0.589-2.062,1.061-2.769c0.471-0.709,0.353-1.589,1.058-0.648c0.712,0.941,1.474,1.059,0.771,2.294
				c-0.71,1.24-1.771,2.534-2.004,2.771c0,0-2.121,0.941-0.769,0.941c1.354,0,3.005-0.648,3.299,0.354
				c0.296,0.998,0.472,2.886,0.354,3.767c-0.117,0.887-0.178,1.827-1.177,1.178c-1.001-0.648-1.708-1.411-2.71-1.651
				c-1-0.234-1.885-0.293-1.826-0.705c0.057-0.411,0.353-1.295-0.65-0.822c-1,0.467-2.12,0.702-2.062,1.766
				c0.06,1.059,0.118,2.235,0.771,3.354c0.648,1.119,0.707,2.59,0.648,3.004c-0.061,0.411,0,1.12-0.943,0.765
				c-0.942-0.354-1.595-1.003-1.766-2.357c-0.178-1.351,0.115-2.589-0.296-4.121c-0.415-1.531-0.822-2.769-2.179-4.124
				c-1.355-1.352-1.412-2.12-2.531-2.12s-2.063-0.352-2.121,0.768c-0.06,1.118,0.352,1.824,1.002,2.766
				c0.647,0.944,0.765,1.473,0.589,2.417c-0.179,0.941-1.062,2.825-1.239,3.769c-0.176,0.944,0.178,1.707-0.469,1.824
				c-0.646,0.119-2.24-0.411-2.531-1.883c-0.296-1.472,0.529-2.475-0.591-2.592c-1.12-0.115-1.825,0.824-3.182,0.824
				c-1.353,0-1.293-0.589-2.649-1c-1.354-0.413-1.705-1.002-3.004-1.118c-1.297-0.119-3.121,0.53-3.944-0.415
				c-0.822-0.941-0.822-1.115-0.822-2.527c0-1.418,0.06-2.535-1.3-3.183c-1.351-0.648-2.883-1.473-3.121-2.12
				c-0.232-0.648-0.408-1.118,0.533-1.353c0.941-0.237,1.471-0.296,2.884-0.296c1.417,0,1.885,0.119,2.063-0.648
				c0.179-0.763,0.408-1.707-0.705-2.355c-1.122-0.648-1.712-1.12-2.653-0.824c-0.942,0.293-1.945,0.117-3.356-0.586
				c-1.415-0.709-2.002-0.59-2.118-1.771c-0.119-1.176-0.591-1.411,0.116-1.705c0.706-0.296,1.472-0.472,1.884-1.24
				c0.413-0.763,0.707-1.766,0.529-2.414c-0.175-0.646-0.469-1.766-1.06-2.414c-0.59-0.646-0.707-2.062-1.47-1.707
				c-0.766,0.352-1.24,0.176-1.708,1.295c-0.473,1.117-0.237,1.707-1.12,1.883c-0.883,0.178-1.062-0.414-1.354,0.589
				c-0.296,1,0.353,1.178-0.296,1.944c-0.648,0.764-1.354,0.528-1.474,1.294c-0.116,0.768-0.175,0.885,1.298,1.357
				c1.47,0.47,2.294,0.47,2.236,1.174c-0.059,0.709-0.646,1.061-0.646,1.885c0,0.827-0.766,1.416,0.058,2.83
				c0.825,1.411,1.298,3.118,1.298,4.474c0,1.354-0.061,2.414-0.473,4.063c-0.41,1.648-0.883,2.712-0.706,4.004
				c0.178,1.294,0,1.825,0.941,3.005c0.943,1.176,1.239,1.839,1.354,2.802c0.117,0.965-0.233,1.146,0.057,2.202
				c0.295,1.059,0.415,1.297,0.415,2.71c0,1.411,0.119,2.295-0.587,3.709c-0.712,1.412-1.416,2.529-1.945,3.886
				c-0.532,1.355-0.649,1.884-0.824,3.357c-0.178,1.472-0.414,2.473-0.531,3.945c-0.119,1.473-0.704,2.235-1.297,3.297
				c-0.587,1.062-0.941,1.179-1.352,2.062c-0.415,0.883,0.061,1.12-0.883,1.352c-0.939,0.239-1.59,0.474-2.237,1.239
				c-0.647,0.766-1.474,1.59-2.005,3.003c-0.531,1.414-1.118,2.118-0.821,3.121c0.29,1,0.351,1.65,0.586,3.003
				c0.235,1.354,0.297,0.587,0,2.062c-0.296,1.472-0.352,1.296-0.467,2.648c-0.119,1.357-0.827,2.299-0.593,3.417
				c0.236,1.12,0.414,2,1,3.416c0.594,1.413,0.534,0.705,0.65,1.942c0.117,1.237,0.059,1.354,0.293,2.651
				c0.236,1.293-0.117,1.707,0.475,2.65c0.585,0.942,1.468,1.293,2.527,2.177c1.06,0.883,1.18-0.056,1.826,1.12
				c0.648,1.176,0.592,1,0.824,2.121c0.237,1.118,0.117,1.118,0.472,2.118c0.354,1,0.415,0.651,0.415,2.123
				c0,1.47,0.172,2.177-0.415,3.414c-0.587,1.236-0.765,1.766-1.06,3.238c-0.295,1.474-0.12,1.239-0.883,2.298
				c-0.765,1.061-1.001,0.883-1.473,1.708c-0.472,0.824-0.705,0.707-0.885,2.062c-0.172,1.355-0.409,2.003-0.351,3.473
				c0.06,1.472,0,1.592,0.06,3.003c0.06,1.414-0.416,1.648-0.06,2.947c0.351,1.292,0.351,2.176,1.411,3.117
				c1.061,0.945,1.885,1.651,3.063,2.358c1.178,0.706,0.764,0.53,2.356,1.118c1.588,0.589,1.53,0.882,2.649,1.298
				c1.12,0.411,0.59,0.584,1.886,0.646c1.295,0.059,1.646-0.472,2.825,0c1.18,0.47,2.712,1.171,3.596,2.264
				c0.884,1.092,2.063,2.446,2.529,4.509c0.473,2.059,1.237,3.535,1.357,5.004c0.117,1.475,0.881,3.945-0.534,4.945
				c-1.409,1.004-6.006,3.299-7.416,4.593c-1.418,1.297-2.064,2.182-2.886,3.241c-0.826,1.062-0.942,1.886-2.357,2.179
				c-1.412,0.297-2.828,0.883-3.355,1.886c-0.532,1.001-0.885,2.589-1.828,3.53s-1.53,1.473-2.649,2.828
				c-1.121,1.354-1.884,2.709-1.354,4.416c0.53,1.708,2.002,1.708,0.887,3.24c-1.119,1.532-1.178,3.007-2.301,4.179
				c-1.118,1.181-1.764,2.239-2.705,2.946c-0.942,0.709-0.826,1.532-2.3,1.59c-1.471,0.06-1.297,0.238-2.528,0.351
				c-1.24,0.118-1.713-0.351-1.828,0.535c-0.117,0.884,0.705,2.885-0.945,3.943c-1.648,1.059-2.473,1.709-3.945,2.062
				c-1.471,0.351-2.002,0.177-2.998,0.587c-1,0.413-1.594-0.118-1.712,0.825c-0.117,0.941-0.233,1.594,0.825,2.002
				c1.061,0.413,1.178,1.121,0.828,1.768c-0.355,0.649,0.059,1.476-1.238,2.356c-1.299,0.883-1.416,1.117-2.705,1.354
				c-1.302,0.235-1.655-0.119-2.658,0.528c-0.999,0.651-0.883,0.238-1.41,1.416c-0.533,1.177-0.236,1.06-1.239,1.706
				c-0.999,0.646-1.236,0.235-1.175,1.296c0.057,1.059,0.766,1.059-0.236,1.235c-1.002,0.176-1.531-0.414-1.531,0.414
				c0,0.823,0.765,1.236,1.237,2.062c0.469,0.821,1.003,0.883-0.178,1.295c-1.178,0.409-0.705,0.708-1.647,0.708
				c-0.941,0-1.238-0.12-2.239,0c-0.999,0.117-0.999-0.708-2.119,0c-1.119,0.704-0.295,0.525-1.885,0.823
				c-1.587,0.294-1.001-0.298-2.236,0c-1.235,0.294-1.235-0.357-2.415,0.355c-1.177,0.706-0.881,0.998-2.239,1.116
				c-1.354,0.116-1.059,0.292-2.239,0.353c-1.176,0.057-0.883-0.236-2.292,0c-1.417,0.238-1.478,0.177-2.358,0.764
				c-0.885,0.595-0.646,0.533-1.765,1.475c-1.12,0.944-0.824,0.883-1.886,1.298c-1.058,0.41-0.354,0.527-1.117,1.352
				c-0.767,0.821,0.354,0.821-1.178,1.296c-1.53,0.47-1.53,0.296-2.122,0.944c-0.587,0.646-0.528,0.941-0.119,2.587
				c0.414,1.655,0.649,3.122,1.179,4.124c0.534,0.999,0.592,1.355,0.416,2.358c-0.179,1-0.356,1.058-0.826,1.942
				c-0.473,0.883,0.174,0.883-1.355,1.472s-6.419,2.53-7.95,3.3c-1.529,0.763-3.591,1.291-4.592,1.765
				c-1.002,0.468-1.593,0.94-2.651,1.47c-1.057,0.532-0.766,0.886-1.531,0.769c-0.765-0.117-0.41-1.771-1.47-1.714
				c-1.061,0.063-1.413,0.477-2.476,0.889c-1.059,0.412-6.127,2.827-7.892,3.591c-1.767,0.765-2.887,0.704-3.649,1.179
				c-0.765,0.469-1.12,0.941-1.942,1.646c-0.823,0.706,0.118,0.476-1.593,0.883c-1.707,0.416-1.055,0.299-2.176,0.769
				c-1.119,0.472-0.534,0.293-1.771,0.766c-1.232,0.469-0.465,0.353-1.707,0.647c-1.234,0.298-1.824-0.117-2.295,0
				c-0.47,0.117-0.767,0-1.473,1.237c-0.705,1.233-0.705,0.944-1.118,2.059c-0.41,1.118-0.469,1.058-0.646,2.06
				c-0.179,1.003,0.058,1.003-0.825,1.537c-0.881,0.527-1.059,0.351-1.594,0.883c-0.527,0.528-0.527,0.528-1.408,0.47
				c-0.885-0.059-1.475,0-2.416,0.295c-0.944,0.294-1.002,0.294-2.062,0.764c-1.061,0.475-1.587,0.53-1.825,0.885
				c-0.236,0.353-0.41-0.117,0.176,0.943c0.589,1.056,0.12,1.353,1.002,1.647c0.883,0.296,0.589,0.296,0.883,1.06
				c0.297,0.766,0.475,1.411,0.882,2.004c0.415,0.586,0.18,0.407,0.415,1.587c0.234,1.181-0.118,1.883,0.234,2.707
				c0.354,0.827,0.295,0.772,1.004,1.886c0.704,1.118,0.41,1.003,0.996,1.355c0.592,0.356,0.474,0.589,0.235,1.647
				c-0.235,1.062-0.177,1.883-0.058,3.003c0.119,1.121-0.119,1.417,0.237,2.537c0.356,1.117-0.118,1.351,0.356,2.176
				c0.465,0.826,0.465,1.294,0.289,2.12c-0.172,0.823-0.646,0.883-0.764,1.883c-0.119,1-0.178,1.12-0.528,2.357
				c-0.354,1.238-0.708,1.472-0.708,2.3c0,0.823,0.708,1.059-0.175,1.763c-0.886,0.707-1.12,0.766-2,1.411
				c-0.888,0.65-0.476,0-1.359,1.064c-0.88,1.057-0.354,0.589-1.179,1.531c-0.823,0.944-0.823,0.527-1.12,1.469
				c-0.289,0.941-0.229,1.298-0.347,2.063c-0.12,0.765,0.058,1.001-0.654,1.883c-0.704,0.882-0.882,1.238-1.647,2.063
				c-0.765,0.821-1.06,1.413-1.705,2.589c-0.651,1.18-0.178,0.885-0.474,2.238c-0.29,1.357-0.587,2.358-0.646,3.417
				c-0.06,1.063,0.412,1.648-0.237,2.353c-0.646,0.711,0.178,0.533-1.062,0.883c-1.233,0.355-2.179,0.712-3.472,1.062
				c-1.298,0.357-2.239,0.475-3.418,0.823c-1.176,0.355-1.941,0.415-3.061,0.827l-1.115,0.411c0,0-0.414,0.473-0.059,1.826
				c0.35,1.357,0.467,2.001,0.941,3.179c0.468,1.18,0.527,1.062,0.289,2.238c-0.229,1.182-0.466,1.769-0.645,2.71
				c-0.178,0.944-0.469,1.59-0.236,2.358c0.236,0.765,0.415,2.003,1.119,2.706c0.705,0.705,0.763,1.063,1.002,1.766
				c0.237,0.708,0.531,1.06,0.06,1.65c-0.472,0.588-1.062,0.53-1.003,1.236c0.061,0.705,0.766,2.061,1.239,3.119
				c0.468,1.062,0.763,1.884,1.764,2.651c1.001,0.767,0.883,0.942,1.766,1.826c0.884,0.886,1.002,0.118,1.178,1.356
				c0.179,1.236-0.354,0.883,0.532,1.527c0.885,0.648,0.825,0.414,1.295,1.238c0.472,0.821,1.118,0.821,0.709,1.652
				c-0.416,0.823-1.064,0.762-0.709,1.289c0.354,0.534,0.059,0.357,0.764,0.65c0.71,0.291,1.416,0.59,1.238,1.061
				c-0.179,0.471-0.117,0.412-0.471,1.236c-0.354,0.822-0.709,0.707-0.53,1.592c0.176,0.882,0.647,0.882,0.647,1.529
				s0.117,1.118-0.233,2.416c-0.357,1.29-0.651,1.41-0.593,2.709c0.06,1.295,0,1.411,0.474,2.528c0.47,1.12-0.474,1.595,0.706,1.534
				c1.179-0.057,0.823,0,1.824-0.593c1-0.586,1.298-1.35,1.652-0.821c0.351,0.531,0.293,1.177,0.113,2.237
				c-0.173,1.062-0.998,2.179-1.232,3.828c-0.235,1.646-1.355,5.534-1.826,7.476c-0.473,1.944-0.473,1.652-0.648,2.947
				c-0.18,1.296-0.062,1.176-0.411,2.117c-0.355,0.944,0,0.592-1.119,1.182c-1.12,0.584-1.827,0.352-2.825,0.941
				c-1.002,0.588-1.061,0.413-1.596,1.294c-0.526,0.886-0.644,1.297-0.941,2.118c-0.289,0.829-0.881,0.947-0.468,1.771
				c0.409,0.824,0.409,1.062,0.351,1.648s-0.351,0.704-1.117,1.472c-0.766,0.769-0.766,0.887-2.058,1.768
				c-1.298,0.883-0.942,0.944-2.121,1.061c-1.181,0.116-1.536,0.471-3.005,0.29c-1.475-0.174-1.062-0.232-2.418-0.585
				c-1.348-0.355-1.059-0.179-2.176-0.299c-1.117-0.117-1.413-0.762-1.769,0.062c-0.352,0.822-0.471,1.357-0.883,2.296
				c-0.408,0.944-0.117,1.182-0.884,1.77c-0.765,0.587,0.297,1.058-0.881,1.058c-1.182,0-2.355-0.116-3.947,0
				c-1.587,0.12-2.12,0.237-3.59,0.472c-1.473,0.233-1.768-0.057-3.3,0c-1.528,0.06-4.826-0.413-6.417-0.234
				c-1.593,0.178-2.121,0-3.771-0.117c-1.647-0.12-4.413-0.12-5.889-0.062c-1.472,0.062-4.059,0.069-5.832,0.506
				c-1.766,0.437-2.409,0.437-3.47,0.731c-1.062,0.292-0.944,0.767-2.302,0.944c-1.349,0.175-2,0.292-2.765,0.351
				c-0.765,0.062-1.237,0.473-2.06,1.237c-0.826,0.763-1.002,1.179-2.122,1.827c-1.116,0.646-0.884,0.822-1.942,0.822
				c-1.061,0-1.59,0-1.708,0.709c-0.116,0.706-0.295,7.715-0.763,8.947c-0.476,1.237-1.062,1.771-1.062,3.419
				c0,1.646-0.585,2.825-0.704,4.182c-0.12,1.351-0.12,1.588-0.356,2.473c-0.234,0.883,0.06,0.939-0.825,1.704
				c-0.881,0.771-1.764,2.119-2.942,3.184c-1.182,1.057-1.883,2.002-2.358,2.767c-0.469,0.767-0.884,1-0.764,1.94
				c0.117,0.945-0.298,1.356,0.236,2.359c0.527,1,0.824,1.179,0.942,2.237c0.119,1.064,0.061,1.827,0.061,2.474
				c0,0.648,0.584,0.882-0.593,1.236c-1.174,0.355-1,0.294-1.944,0.294c-0.941,0-1.293-0.057-1.764,0.768
				c-0.471,0.822,0,0.764-0.943,1.352c-0.942,0.594-0.705,0.77-1.765,1.119c-1.062,0.357-0.588-0.175-1.588,0.533
				c-1.003,0.704-0.885,1.351-1.535,1.646c-0.646,0.298-0.646,0.062-1.409,0.416c-0.771,0.347-0.476,0.704-1.18,1
				c-0.706,0.293-0.357-0.117-1.18,0.527c-0.824,0.647-0.824,0.882-1.356,1.648c-0.527,0.768,0.415,1.177-0.646,1.476
				c-1.06,0.292-1.06,0.116-2.121,0.353c-1.061,0.233-1.002-0.353-2.239,0c-1.233,0.349-1.293,0.529-2.352,0.705
				c-1.061,0.179-1.477,0.47-2.121,0.825c-0.646,0.354-1.001,0.587-1.823,1c-0.825,0.415-0.825,0.941-1.534,0.885
				c-0.704-0.061-1.233-0.47-1.886-1.237c-0.646-0.768-0.469-1.119-1.352-1.119s-0.824,0.234-1.766,0.413
				c-0.944,0.176-1.002,0.114-1.593-0.062c-0.586-0.176-0.527-0.117-1.297-0.062c-0.766,0.062-1.114-0.29-1.293,0.419
				c-0.176,0.704,0.527,1.53-0.236,1.295c-0.766-0.236-1.647-0.354-2.53-0.77c-0.884-0.409-0.651-0.587-1.711-0.821
				c-1.056-0.237-0.527-0.123-1.647-0.354c-1.12-0.233-0.821-0.059-1.704-0.531c-0.883-0.471-0.826-0.705-1.478-0.705
				c-0.643,0-0.348,0.178-1.061,0.468c-0.705,0.299-0.115,0.769-1.293,0.535c-1.178-0.236-1.766,0.233-2.768,0
				c-0.998-0.236-1.177-0.236-2.001-0.535c-0.827-0.29-0.354-0.349-1.651-0.646c-1.293-0.292-1.236-0.178-2.003-0.644
				c-0.761-0.473,0.533-0.236-0.761-0.473c-1.301-0.238-0.885,0.236-1.829-0.12c-0.941-0.352-0.704-0.646-1.413-0.41
				c-0.706,0.231-0.353-0.709-0.706,0.231c-0.353,0.941-0.708,1.713-0.886,2.182c-0.176,0.473-0.354,0.354-0.881,1.354
				c-0.527,1.003-0.941,1.886-1.883,2.651c-0.943,0.763-1.477,1.416-2.002,1.824c-0.533,0.413,0,0.709-1.36,1.176
				c-1.348,0.473-1.117,0.418-1.703,0.883c-0.589,0.475-0.238,0.418-1.12,0.83c-0.885,0.408,0.177,0.293-1.238,0.469
				c-1.41,0.176-2.297,0.236-3.445,0.236c-1.145,0-0.91-0.47-1.85,0c-0.944,0.472-1.122,0.413-1.828,0.413
				c-0.709,0-3.887,0.122-3.946,0.002c-0.058-0.122-4.417,0.345-5.121,0.054c-0.709-0.296-0.474-0.234-0.18-0.881
				c0.297-0.648,0.119-0.885,0.471-1.415c0.355-0.529,1.004-0.294,0.591-0.944c-0.412-0.646-1.472-2.116-2.297-2.943
				c-0.826-0.823-2.062-1.765-2.24-2.592c-0.174-0.818-0.529-1.176-1.114-2c-0.589-0.822-0.829-1-1.181-1.942
				c-0.355-0.941-0.058-1.472-0.942-1.647c-0.881-0.179-1.235-0.179-2.118-0.711c-0.884-0.526-0.947-0.644-1.534-1.174
				c-0.587-0.534-1.588-1.002-1.234-1.534c0.35-0.525,0.527-0.701,0.762-1.059c0.237-0.351-0.292-0.941-1.294-1.707
				c-1.001-0.764-0.824-0.529-1.708-1.297c-0.885-0.765-1.059-0.408-2.179-1.002c-1.118-0.585-1.532-0.351-2.237-0.941
				c-0.707-0.588-0.943-0.409-1.591-0.708c-0.648-0.295-0.472-0.41-1.531,0.24c-1.06,0.646-0.824,0.646-1.942,1.295
				c-1.12,0.646-0.884,1.234-2.001,1.116c-1.122-0.118-2.888,0-4.006,0c-1.122,0-1.474,0.06-2.533-0.173
				c-1.063-0.235-1.176-0.417-1.706-0.18c-0.531,0.234-0.47,0.234-0.649,0.766c-0.177,0.529-0.235,0.297-0.235,1.412
				c0,1.117,0.354,1.354,0.058,2.237c-0.295,0.883,0.059,1.182-0.53,1.708c-0.586,0.535,0,0.474-0.998,1
				c-1.001,0.532-0.827,0.65-1.77,1.18c-0.942,0.533-0.822,0.649-1.649,1.001c-0.824,0.354-1.059,0.117-1.295,0.882
				c-0.234,0.767-0.177,1.416-0.177,2.121c0,0.706,0.058,0.59,0.058,1.534c0,0.942-0.174,1.06,0,1.826
				c0.177,0.763-0.174,0.824,0.059,1.763c0.239,0.941,0.592,1.182,0.119,1.827c-0.471,0.646-1.412,1.707-2.061,2.299
				c-0.647,0.588-0.941,0.883-1.471,1.59c-0.532,0.702-1.237,1.764-2.358,1.646c-1.117-0.116-1.231-0.095-2.295,0.009
				c-1.059,0.109-0.999,0.584-2.121,0.584c-1.119,0-1.943,0.235-2.412,0.351c-0.471,0.116-2.533,0.77-4.066,1.295
				c-1.53,0.53-2.117,0.705-2.884,1.356c-0.765,0.647-1,0.824-1.705,2.118c-0.71,1.294-0.887,1.829-1.416,2.65
				c-0.529,0.825-0.648,1.298-0.943,2.12c-0.292,0.825-0.824,1.825-0.939,2.532c-0.118,0.704-0.18,0.763-0.062,1.941
				c0.12,1.179,0.237,1.299-0.175,2.302c-0.412,1-0.297,1.292-0.589,2.059c-0.292,0.764-0.649,0.649-0.47,1.886
				c0.177,1.234,0.647,1.234,0.647,2.469c0,1.24,0.115,1.942,0.235,2.945c0.118,1.001,0.472,2.825,0.472,3.827
				c0,1.001-0.592,2.417-0.592,3.712c0,1.297,0.354,5.89,0.297,7.064c-0.06,1.178-0.177,1.535-0.177,2.942
				c0,1.419-0.06,3.42,0,4.656c0.06,1.233-0.12,2.472-0.06,3.591c0.06,1.124-0.235,4.065-0.235,5.243c0,1.176,0.06,1.884-0.41,2.827
				c-0.472,0.941-0.472,1.232-1.415,1.232c-0.942,0-2.002,0.119-2.944-0.528c-0.944-0.647-1.647-0.47-2.766-0.999
				c-1.12-0.528-1.475-0.238-2.475-0.766c-1-0.532-1.238-0.883-2.121-0.591c-0.882,0.295-0.586,0.769-1.413,1.532
				c-0.823,0.767-0.354,1.002-1.705,0.883c-1.355-0.116-1.414,0-2.241-0.941c-0.825-0.941-0.647-1.237-1.586-1.941
				c-0.944-0.708-0.472-0.823-1.591-0.944c-1.122-0.117-0.709,0.121-2.355-0.531c-1.651-0.647-1.769-0.768-2.711-1
				c-0.943-0.233-1.532-0.116-2.768-0.175c-1.237-0.059-2.18,0.175-3.004-0.118c-0.823-0.299-0.292-0.235-1.765-0.823
				c-1.472-0.593-1.706-0.651-2.944-1.003c-1.236-0.353-1.531-0.353-2.53-0.708c-1.001-0.35-1.001-0.47-1.829-0.47
				c-0.824,0-0.473,0.35-2.357,0.294c-1.884-0.058-1.411,0.473-3.177-0.119c-1.767-0.588-1.354,0.062-2.354-0.236
				c-1-0.293-1.415-0.648-2.594-0.352s-0.94,0.352-2.532,0.177c-1.591-0.177-0.765-0.118-2.179-0.413
				c-1.413-0.292-1.355-0.176-2.417-0.587c-1.057-0.413-1.704,0.119-2.176-0.589c-0.472-0.711-0.532-0.883-1.122-1.768
				c-0.588-0.885-0.117-1.176-1.059-1.946c-0.943-0.764-1.53-1.06-1.471-1.588c0.057-0.531,0.528-0.645,0.824-1.823
				c0.294-1.181,0.118-1.358,0.646-2.003c0.532-0.648,1.412-0.297,0.827-1.122c-0.59-0.822-0.118-1.35-1.062-1.884
				c-0.941-0.526-0.881-0.411-1.648-1.12c-0.765-0.704-1.062-1.059-1.648-1.824c-0.588-0.763-1.119-1.176-1.474-2.12
				c-0.352-0.941,0.119-0.763-0.527-1.41c-0.652-0.651-1.3-0.941-1.417-1.827c-0.116-0.886-0.057-1.708,0.297-2.295
				c0.351-0.594,0.823-1.533,0.706-3.006c-0.117-1.474-0.237-1.941-0.648-2.941c-0.412-1.002-0.528-1.594-1.238-2.181
				c-0.705-0.588-0.528,0.12-1.355-0.884c-0.821-1.003-1.116-1.003-1.531-1.707c-0.411-0.709-1-1.474-1.705-2.299
				c-0.707-0.824-0.944-1.352-1.65-1.883c-0.707-0.527-0.942-1.062-1.354-1.238c-0.412-0.176-0.294-0.061-1.771,0.358
				c-1.468,0.409-1.352,0.645-2.353,0.701c-0.999,0.062-1.766,0.354-2.647,0.117c-0.887-0.234-2.063-0.117-2.889-0.117
				c-0.823,0-0.881,0.354-1.352-0.883c-0.474-1.237-0.235-1.41-1.238-2.179c-1.001-0.766-0.411-0.704-1.53-0.824
				c-1.12-0.117-1.237,0.648-2.299-0.469c-1.058-1.122-1.235-2.181-2.472-2.474c-1.236-0.296-1.179,0.114-2.238-0.646
				c-1.061-0.767-1.179-0.652-2.235-0.767c-1.064-0.119-2.24,0.407-3.006,0.407c-0.765,0-0.707-0.117-2.062-0.702
				c-1.352-0.59-2.003-0.706-2.766-1.297c-0.766-0.588-0.411-0.588-1.591-0.766c-1.18-0.177-1.18,0-1.943-0.644
				c-0.765-0.653-0.529-0.83-1.355-1.299c-0.824-0.471-0.824-0.237-1.648-0.705c-0.824-0.474-0.589-0.711-1.414-1.179
				c-0.824-0.474-0.647,0.117-1.414-0.646c-0.766-0.771-0.649-0.236-0.999-1.298c-0.353-1.059-0.472-1.415-1.416-2.064
				c-0.939-0.643-0.824-0.292-1.708-0.818c-0.881-0.535-0.706-0.594-1.647-0.766c-0.942-0.179-0.647,0.058-1.765-0.299
				c-1.123-0.354-0.767,0-2.061-0.472c-1.297-0.467-0.767-0.883-1.768-1.884c-1.001-1.002-1.765-1.589-2.119-2.353
				c-0.354-0.769-0.531-0.944-0.531-1.886c0-0.942-0.236-0.884-0.473-2.417c-0.233-1.526-0.233-1-0.47-2.296
				c-0.235-1.289,0.237-0.646-0.355-1.825c-0.587-1.178-0.352-0.766-1.059-1.587c-0.707-0.827-0.529-1.179-0.999-1.357
				c-0.475-0.176-0.827,0.591-1.769,1.179c-0.941,0.588-2.003,0.824-2.649,1.297c-0.647,0.469-0.647,0.709-1.708,1.178
				c-1.06,0.47-0.647,0.827-2.177,0.827c-1.536,0-0.591,0.35-1.885,0.289c-1.296-0.057-1.533,0.356-2.12-0.057
				c-0.59-0.414-0.708,0.176-1.356-0.292c-0.647-0.477-1-0.827-1.59-1.477c-0.589-0.646-0.883-1.118-1.532-1.292
				c-0.646-0.178-1.237-0.474-2.176-0.236c-0.944,0.236-0.708,0.41-1.883,0.763c-1.182,0.357-1.062,0.589-2.356,0.532
				c-1.298-0.059-1.241,0.409-2.183-0.059c-0.941-0.474-1.589-0.591-2.295-0.886c-0.709-0.292-1.944-0.705-2.298-0.942
				c-0.353-0.233-1.001-0.944-1.943-1.292c-0.941-0.354-2.474-0.886-3.12-1.24c-0.648-0.356-1.942-0.999-2.884-1.356
				c-0.943-0.348-1.708-0.588-2.18-0.883c-0.472-0.289-1.062-0.173-1.883-0.057c-0.828,0.114-0.592,0.53-1.829,0.53
				c-1.236,0-0.823,0.295-2.06-0.059c-1.235-0.357-0.942-0.53-2-0.883c-1.063-0.354-0.943-0.12-1.472-1.059
				c-0.531-0.944-0.768-0.708-1.356-2.182c-0.587-1.472-1.058-1.648-1.588-2.414c-0.532-0.768-0.768-0.293-1.592-0.53
				s-1.529,0.059-2.413-0.466c-0.884-0.534-1.354-0.534-1.708-1.417c-0.354-0.886-0.589-0.471-0.646-1.647
				c-0.06-1.177-0.18-0.769-0.649-0.884c-0.469-0.117,0.118-0.117-1.471-0.117c-1.593,0-2.238-0.413-3.239,0.061
				c-1,0.465-0.828,0.232-2.002,0.346c-1.178,0.119-0.765,0.711-1.941,0.595c-1.182-0.12-1.298,0.059-2.063,0.47
				c-0.765,0.412-0.411,0.764-1.472,1.178c-1.06,0.414-1.235,0.177-2.18,0.645c-0.942,0.476-0.707,0.944-1.473,1.65
				c-0.762,0.711-0.762,0.884-1.646,1.122c-0.884,0.231-1.178,0.292-2.24,0.112c-1.057-0.174-1.177-0.174-2.475-0.822
				c-1.294-0.646-1.352-0.708-2.766-1.062c-1.412-0.351-1.823-0.588-3.355-0.884c-1.534-0.291-2.002-0.291-3.476-0.467
				c-1.472-0.179-1.648,0.234-2.472-0.118c-0.825-0.354-1.235-0.354-2.06-1.063c-0.825-0.703-0.473-0.998-2.003-1.059
				c-1.533-0.061-1.237-0.116-2.355,0.237c-1.121,0.348-1.236-0.061-2.358,0.765c-1.117,0.821-1.353,0.705-2.412,1.237
				c-1.062,0.529-0.531,0.588-1.824,1.061c-1.298,0.469-1.002,0.586-2.592,0.939c-1.592,0.351-0.943,0.531-2.652,0.649
				c-1.707,0.12-1.294,0.704-2.588-0.062c-1.298-0.763-1.474-0.057-2.063-1.355c-0.589-1.291,0.295-1.705-1.179-2.173
				c-1.471-0.474-1.531-0.179-2.529-0.354c-1.002-0.179-1.063,0.057-2.181-0.36c-1.119-0.408-1.059-0.585-1.884-1.646
				c-0.823-1.059-0.648-1.117-2.12-1.941c-1.473-0.824-1.12-0.941-2.825-1.766c-1.71-0.824-1.472-0.237-2.946-0.941
				c-1.471-0.706-1.352-0.766-2.829-1.534c-1.471-0.765-1.058-0.765-2.586-1.353c-1.533-0.591-1.064-0.708-2.357-1.12
				c-1.296-0.415-0.707-0.646-2.12-0.883c-1.412-0.237-1.355-0.237-2.295,0.179c-0.945,0.409-1.531,0.941-2.474,1.471
				c-0.941,0.531-0.592,0.59-1.65,0.763c-1.062,0.179-0.473,0.415-2.121,0.117c-1.647-0.29-1.115-0.117-2.474,0.298
				c-1.352,0.413-2.059,0.942-3.117,1.471c-1.062,0.533-0.885,0.472-1.943,1.354c-1.064,0.883-1.358,1.062-2.24,1.589
				c-0.883,0.532-0.943,0.77-2.296,1.358c-1.354,0.587-0.708,0.529-2.121,1c-1.411,0.474-0.353,0.356-1.944,0.702
				c-1.588,0.358,0.414,0.358-1.588,0.358s-3.121,0.295-4.301-0.121c-1.177-0.411-2.353-1.176-4.06-2.177
				c-1.71-1.001-2.651-2.769-4.535-3.473c-1.884-0.706-4.417-2.65-6.008-3.419c-1.592-0.763-4.769-2.588-5.831-3.233
				c-1.059-0.653-1.588-1.36-2.944-2.006c-1.354-0.65-1.824-1.059-3.119-1.653c-1.297-0.584-1.354-1.173-2.769-1.645
				c-1.412-0.472-1.354-0.764-2.708-1.413c-1.357-0.648-1.416-1-2.475-1.415c-1.059-0.412-1.648-0.821-1.648-0.176
				c0,0.647-0.178,2.004-0.767,3.359c-0.588,1.351,0.12,1.173-1.294,1.942c-1.414,0.763-1.766,0.707-2.179,1.59
				s-0.528,1.354-0.942,2.237c-0.409,0.883-0.647,1.239-1.057,1.883c-0.416,0.649-1.121,0.944-0.707,1.649
				c0.41,0.709,0.94,1.474,1.235,2.24c0.295,0.763,0.233,1.354,0.176,2.236c-0.057,0.884-0.353,0.529-0.295,1.647
				c0.06,1.122-0.469,0.885,0.238,2.182c0.703,1.291,0.234,0.528,0.938,1.764c0.709,1.239,0.826,1.179,0.767,2.065
				c-0.058,0.883-0.704,2.704-1.119,4.119c-0.41,1.412-1.001,2.591-1.354,3.768c-0.353,1.18-0.415,2.531-1.943,2.769
				c-1.531,0.236-2.061,0.47-3.18-1.237c-1.118-1.705-1.296-1.824-2.473-2.529c-1.178-0.708-1.826-0.65-2.063-1.71
				c-0.233-1.06-0.647-2.65-1.237-4.005c-0.587-1.356-0.647-2.177-1.116-2.769c-0.473-0.587-1.356-1.058-2.886-1.175
				c-1.533-0.117-1.294,0.117-3.18-0.474c-1.884-0.586-2.003-0.527-2.592-1.409c-0.589-0.887-0.295-0.71-2.297-1.119
				c-2.001-0.416-2.592-0.883-3.651-0.943c-1.059-0.06-1.589-0.355-3.003-0.179c-1.414,0.179-1.177,0.589-2.297,1.005
				c-1.117,0.408-0.706,0.821-2.061,0.646c-1.354-0.182-1.468,0.176-2.475-0.59c-0.999-0.763-1.528-1.119-2.705-1.475
				c-1.181-0.351-0.592-0.704-2.533-1.292c-1.944-0.591-1.415-0.649-2.709-0.942c-1.296-0.294-1.827,0.06-2.649-0.886
				c-0.826-0.942-0.766-1.769-1.473-3.236c-0.706-1.474-0.825-1.767-1.825-2.535c-1.001-0.767-1.236-1.062-2.357-2.121
				c-1.117-1.058-1.528-0.941-1.589-2c-0.058-1.059,0.061-0.826-0.53-2.296c-0.589-1.475,0.295-1.822-1.001-2.004
				c-1.296-0.176-0.941-0.233-2.356-0.292c-1.412-0.062-0.473,0.354-1.827,0.59c-1.352,0.231-1.115,0.823-2.001,0.059
				c-0.883-0.769-1.414-1.943-2.177-2.77c-0.766-0.824-0.649-0.824-1.473-1.944c-0.825-1.118-1.942-1.118-1.825-1.886
				c0.117-0.763,1.338-1.883-0.009-2.295c-1.348-0.412-0.934-1.354-1.936-2.354c-1.002-1.004-0.587-0.767-1.414-1.529
				c-0.825-0.77-1.237-0.711-1.353-1.714c-0.119-1.001-0.766-1.174,0-2.116c0.764-0.945,0.707-1.708,1.471-2.061
				c0.766-0.354,1.059-0.413,2.297-0.942c1.237-0.532,1.531-0.941,2.768-1.236c1.237-0.296,1.647-0.533,2.768-0.765
				c1.119-0.236,1.592,0.059,1.532-0.827c-0.06-0.883-2.237-4.238-3.476-5.948c-1.236-1.707-2.236-3.356-3.121-4.473
				c-0.884-1.12-0.707-1.473-1.531-3.006c-0.826-1.527-1.296-1.824-1.708-3.006c-0.411-1.176-0.824-1.292-0.293-1.941
				c0.53-0.644,0.764-0.941,1.53-1.412c0.764-0.471,0.764-0.999,0.471-1.77c-0.296-0.763,0.354-1.289,0.474-2.411
				c0.116-1.121,0.703-3.004,1-3.534c0.293-0.531,0.53-1.769,0.767-2.707c0.235-0.944,0.528-4.533,1.531-4.357
				c1,0.176,2.767,0.291,3.532,0.353c0.766,0.056,1.517,0.104,1.944,0.413c0.824,0.586,0.884,0.704,2.003,1
				c1.117,0.297,2.353,0.12,3.06-0.12c0.708-0.234,3.24-1.354,4.241-0.939c1.001,0.413,0.824,0.354,1.943,0.764
				c1.119,0.416,1.649,0.296,2.297-0.118c0.648-0.408,2.709-1.883,3.003-2.117s-0.352,0.41,0.294-0.234
				c0.648-0.65,4.064-3.596,5.595-5.005c1.531-1.418,1.824-1.769,3.769-3.243c1.942-1.469,1.825-1.357,3.591-2.824
				c1.767-1.473,1.534-2.414,2.825-2.769c1.297-0.354,1.65-0.592,2.83-0.592c1.176,0,2.59,0.237,4.414-0.707
				c1.828-0.941,2.888-1.881,2.888-1.881c0.999-0.588,0.588-0.705,2.002-0.411c1.412,0.293,1.648,0.293,3.002,0.528
				c1.356,0.236,1.533,1.297,2.592,0.236c1.062-1.059,1.944-2.179,2.708-2.592c0.765-0.411,3.945-3.063,5.004-3.591
				c1.062-0.528,1.65,0,1.708-1.589c0.058-1.595,0.439-5.827,0.412-6.186c-0.117-1.707-0.354-2.769-0.117-4.829
				c0.237-2.06,0.412-2.414,1.121-3.358c0.706-0.938,1.057-2.411,1.941-3.651c0.884-1.237,1.237-2.41,1.944-3.118
				c0.708-0.706,1.236-1.356,2.592-2.59c1.353-1.237,2.237-1.475,2.886-2.769l0.647-1.297c0,0,0-2.12,0.647-2.647
				c0.647-0.533,2.002-1.238,2.354-1.356c0.355-0.12,0.591-0.468,1.357-0.238c0.764,0.238,1.058,0,1.586,0.299
				c0.532,0.294,0.179-2.768-0.412-3.534c-0.588-0.768-1.707-2.884-2.061-4.121c-0.352-1.237-0.88-2.357-0.88-2.357
				c-0.708-0.647-2.063-1.647-2.063-2.294v-0.65c0,0,0.413-2.062-0.767-3.651c-1.176-1.589-2.237-3.359-3.179-4.709
				c-0.942-1.355-0.765-1.887-2-2.771c-1.238-0.883-1.179-1.177-2.417-2.058c-1.234-0.884-0.588-0.884-1.943-1.12
				c-1.354-0.237-1.237-0.592-2.238-0.648c-1.001-0.06-1.001-0.115-1.059-0.887c-0.06-0.765-0.06-2.645-0.295-4.474
				c-0.237-1.824-0.174-2.825-0.237-3.826c-0.058-1,0.415-2.001-1.234-3.183c-1.651-1.179-2.414-1.41-3.414-2.704
				c-1.001-1.299-1.12-1.766-1.709-2.3c-0.59-0.527-0.472-1.002-0.059-1.765c0.409-0.767,1.237-1.417,1.707-1.77
				c0.472-0.354,0.295-0.472,1.708-0.472c1.414,0,1.65-0.234,3.064-0.234c1.411,0,2.175,0.353,1.236-0.647
				c-0.943-1.004-2.417-4.241-3.475-5.771c-1.059-1.53-1.531-2.825-2.356-4.124c-0.825-1.292-1.884-1.884-1.531-2.706
				c0.354-0.826,0.237-0.941,0.943-2.003c0.706-1.059,1.061-1.237,1.235-1.765c0.178-0.534-0.705-0.416-1.119-1.062
				c-0.411-0.646-0.175-0.941,0-2.062c0.179-1.118,0.179-1.588,0.766-2.471c0.589-0.886-0.294-1.65,1.236-1.771
				c1.531-0.116,2.12,0.236,3.474-0.062c1.357-0.288,1.65-0.35,3.182-0.823c1.531-0.468,3.064-1.059,3.767-1.291
				c0.709-0.235,0.825-0.71,2.299-0.71c1.472,0,3.002,0.177,4.299-0.527c1.295-0.705,1.354-0.88,2.531-1.648
				c1.178-0.771,1.296-1.296,2.18-0.886c0.882,0.414,2.942,1.654,3.063,1.941c0.119,0.297,1.708,1.773,1.943,2.18
				c0.235,0.415,0.12,1.119,1.237,1.119c1.12,0,2.354,0.059,2.649,0.646c0.293,0.595,0.529,1.418,1.002,1.653
				c0.47,0.233,1.588,0.411,2.531,0.646c0.943,0.236,1.177,0.704,2.236-0.118c1.061-0.824,2.123-1.707,3.124-2.648
				c1-0.943,3.474-3.478,5.121-4.063c1.653-0.588,1.653-1.179,3.122-1.472c1.473-0.294,3.123-0.473,0.589-1.058
				c-2.532-0.592-2.709-0.296-2.767-1.886c-0.059-1.595,0.709-2.003,0.884-3.654c0.175-1.647,0.47-6.121,0.412-8.241
				c-0.06-2.122,0.882-5.479,1.001-7.713c0.116-2.243,0.942-5.776,0.942-8.188c0-2.417-0.12-2.591,0.293-5.064
				c0.413-2.472-0.058-2.411,0.942-3.533c1.001-1.12,2.357-3.537,2.943-4.063c0.59-0.533,0.885-1.237,1.24-1.651
				c0.353-0.411-0.708-1.411-1.24-2.414c-0.53-1-0.762-1.059-0.94-2.59c-0.176-1.531-0.647-2.179-1.177-3.357
				c-0.53-1.178-1.297-3.181-1.826-4.712c-0.53-1.531-0.471-2.647-1.002-4.537c-0.529-1.883-0.412-1.942-0.884-3.651
				c-0.473-1.702-1.294-2.468-1.059-3.292c0.237-0.828-0.176-1.059,0.586-1.944c0.77-0.888,0.473-1.354-0.174-2.06
				c-0.648-0.71-0.233-0.829,0.236-1.414c0.469-0.588,1.235-0.648,0.469-1.415c-0.764-0.767-0.881-0.588-0.233-1.354
				c0.646-0.765,1.414,0.059,1.235-1.295c-0.177-1.353-0.472-2.004-0.589-3.417c-0.117-1.414-0.707-0.763,0-2.177
				c0.707-1.414,0.297-1.118,1.236-1.827c0.944-0.707,1.063-0.646,0.824-1.942c-0.233-1.296,0.354-1.002-0.824-1.943
				c-1.176-0.945-1.411-0.588-1.648-1.944c-0.233-1.354-0.059-1.942-0.706-1.824c-0.648,0.119-1.118,0.941-1.59-0.352
				c-0.472-1.297,0.296-1.414-0.708-1.886c-1.002-0.472-1.059-0.883-1.177-1.472c-0.118-0.59,0-0.12-1.059-0.768
				c-1.062-0.646-1.062-0.293-1.355-1.294c-0.294-1-0.233-0.237-0.705-1.589c-0.473-1.355-0.589-1.296-0.825-2.179
				c-0.237-0.883,0.116-0.415,0.531-1.179c0.41-0.763,0.35-1.002,0.94-1.824c0.587-0.827,0.412-0.942,0.942-1.531
				c0.534-0.589,0.235-0.47-0.06-1.354c-0.294-0.885-0.587-1.651-0.058-2.299c0.532-0.646,0-0.232,1.175,0.299
				c1.18,0.528,1.123,0.291,2.475,0.411c1.355,0.118,2.002-0.589,2.357,0c0.352,0.59,0.705,1.062,0.705-0.472
				c0-1.531-0.645-2.414,0.473-2.355c1.117,0.061,1.768-0.115,3.122-0.059c1.353,0.059,0.941-0.531,2.121-0.411
				c1.174,0.115,1.707,0.174,2.886,0.411c1.177,0.235,2.591,0.119,3.354-0.176c0.768-0.296,0.295-0.707,1.767-0.707
				c1.474,0,1.59-0.179,2.947-0.238c1.355-0.059,1.412,0,2.413-0.763c1.001-0.766,1.649-0.472,1.471-1.591
				c-0.175-1.118,0.297-1.414-0.705-2.651c-0.999-1.235-1.119-1.942-2.063-3.59c-0.939-1.648-1.411-1.768-1.883-2.651
				c-0.472-0.882-0.529-1.059-1.296-1.882c-0.764-0.825-0.884-1.12-0.884-1.771c0-0.644,0.12-1.585-0.882-2.174
				c-1.002-0.592-1.414-0.297-2.063-1.355c-0.645-1.062-0.351-1.531,0.237-2.299c0.589-0.765,1.062-1.883,1.646-3.003
				c0.589-1.118,0.709-1.587,0.942-2.827c0.239-1.235,0.416-1.646,1.123-3.062c0.704-1.414,0.351-1.118,0.823-2.179
				c0.471-1.059,0.648-0.531,0.529-2.062c-0.116-1.531-0.471-1.648-0.648-3.12c-0.178-1.472-1.353-0.825-0.53-2.532
				c0.825-1.709,0.53-1.768,1.237-2.59c0.709-0.824,0.999-1.002,1.355-1.416c0.355-0.411,0.235-0.411-0.473-1.531
				c-0.706-1.118-1.118-1.353-1.058-2.59c0.058-1.235,0.058-0.294,0.532-1.648c0.469-1.357,0.407-1.531,0.526-2.12
				c0.116-0.589,0.293-0.472-0.706-0.529c-1.001-0.061-2,0-2.827-0.354c-0.826-0.354-0.882,0.705-1.708-0.827
				c-0.825-1.528-0.646-2.176-1.706-2.587c-1.062-0.414-1.591-0.237-2.18-1.417c-0.587-1.176-0.587-1.059-0.944-2.648
				c-0.351-1.591-0.234-1.885-1.118-2.295c-0.883-0.414-0.943-1.12-1.943-1.593c-1.001-0.472-1.823,0.059-1.587-1.059
				c0.236-1.12,0.352-1.237,0.586-2.354c0.237-1.12,0.415-0.944-0.177-2.355c-0.587-1.414-0.823-1.118-0.468-2.356
				c0.352-1.236,0.762-2.356,1.293-3.532c0.532-1.182,0.119-1.003,0.768-2.946c0.646-1.942,0.586-1.296,0.883-3.18
				c0.293-1.883-0.118-1.707,0.646-3.18c0.768-1.472,0.177-1.356,0.826-2.592c0.649-1.238-0.059-0.764,0.943-2.002
				c1.001-1.236,0.943-2.176,1.411-1.472c0.471,0.708,1.004,1.711,2.181,2.356c1.175,0.648,0.705,0.707,2.234,0.411
				c1.534-0.293,0.768-0.116,2.238-0.469c1.471-0.354,0.769-0.885,2.24-0.472c1.471,0.411,4.888,1.884,6.359,2.592
				c1.474,0.704,2.295,0.822,3.476,1.237c1.178,0.412,1.117,0.529,2.531,0.588c1.414,0.062,1.356,0.412,2.707,0.118
				c1.354-0.294,0.649-0.352,2.593-0.531c1.943-0.175,1.179-0.411,3.004-0.528c1.826-0.117,0.589-0.413,2.647-0.413
				c2.064,0,2.416,0.296,3.004-0.294c0.591-0.589,1.119-0.354,1.001-1.237c-0.119-0.883-0.176-1.414,0.531-1.824
				c0.706-0.413,0.765-0.767,1.707-1.414c0.944-0.647,0.589-0.768,1.649-1.12c1.062-0.353,1.237-0.529,2.298-1.473
				c1.059-0.941,0.764-0.763,1.648-1.705c0.882-0.946,1.238-1.003,1.824-1.475c0.591-0.47,0.12-0.824,1.061-1.356
				c0.942-0.529,1.059-0.468,1.77-1.412c0.706-0.941,0.706-0.531,1.765-2.061c1.059-1.529,0.177-0.886,1.412-2
				c1.237-1.119,1.062-1.06,1.767-1.828c0.708-0.765,1-0.765,1.472-1.766c0.472-1-0.057-0.942,0.885-1.768
				c0.944-0.825,1.294-0.646,1.002-1.766c-0.298-1.12-0.943-1.414-0.24-2.414c0.708-1.003,0.649-1.12,1.768-1.886
				c1.118-0.766,0.354-0.707,2.178-1.65c1.826-0.939,1.294-0.703,2.475-1.529c1.179-0.822,1.294-1,1.767-1.237
				c0.469-0.234,0-1.12-0.355-2.003c-0.353-0.88-0.41-0.765-0.882-2.177c-0.47-1.414-0.53-0.826-0.824-2.238
				c-0.295-1.414-0.707-1.826-0.058-2.651c0.645-0.824,0.822-0.235,0.703-1.413c-0.117-1.179-0.94-0.942-0.235-1.886
				c0.708-0.941,0.297-1.059,1.536-1.766c1.237-0.707,0.645-0.352,1.943-1.296c1.293-0.941,0.822-0.941,1.704-1.412
				c0.885-0.472,0.532-0.824,1.179-1.061c0.647-0.235,0.176-0.058,0.883-1.413c0.707-1.352-0.118-2.118,1.354-2.118
				c1.473,0,1.414,0.177,2.71-0.416c1.292-0.584,0.586-0.469,2.176-1.353c1.592-0.883,1.297,0.353,2.123-1.293
				c0.824-1.651,1.59-2.534,2.294-3.712c0.709-1.176,0.646-1.414,1.298-2.532c0.647-1.119,0.235-1.236,1.236-2.239
				c1.001-1,0.293-0.47,1.294-1.763c1.001-1.296,0.294-0.766,1.355-1.884c1.058-1.119,0.705-1.119,0-2.004
				c-0.707-0.882-1.119-0.47-0.824-1.885c0.292-1.414-0.118-1-0.178-2.59c-0.06-1.59,0.412-1.414-0.47-2.062
				c-0.885-0.648-1.062-0.352-1.651-1.413c-0.591-1.06-0.529-0.531-1.062-2.001c-0.529-1.472-0.057-1.413-1.117-1.708
				c-1.061-0.294-1.53,0.647-2.59-0.118c-1.06-0.766-0.648-0.707-1.18-1.648c-0.53-0.941-0.821-0.648-1-1.708
				c-0.175-1.058-0.414-1.53-0.06-2.058c0.358-0.533,0.358-1.063-0.292-1.474c-0.649-0.412-1.12-1.648-1.827-2.12
				c-0.706-0.473-0.824-0.59-1.53-0.942c-0.708-0.354-0.883-0.117-1.354-1.061c-0.473-0.941-0.59-1.12-1.295-1.12
				c-0.706,0-2.417,0.415-2.886-0.412c-0.472-0.824-0.532-1.176-1.296-2.238c-0.766-1.059-0.707-1.236-1.355-2
				c-0.648-0.767-0.47-0.943-1.175-1.885c-0.708-0.941-0.649-1.062-0.884-1.883c-0.238-0.827,0.175-1.648-0.649-2.475
				c-0.822-0.824-0.941-0.237-0.646-1.528c0.294-1.298,0.528-0.886,0.824-2.298c0.294-1.414,0.294-1.179,0.294-2.59
				c0-1.414-0.412-1.475-0.235-2.298c0.175-0.825,0.119-0.588,0.528-2.003c0.414-1.414,0.119-1,0.708-2.059
				c0.587-1.062,0.115-0.648,1.001-1.414C192.698,166.751,191.813,166.116,192.877,166.28z"
          />
        </g>
      </g>
    </g>
  </svg>
</div>

<img
  class="gipuzkoa-argitu-logo-principal"
  src="../../../assets/img/gipuzkoa-argitu-logo-02.png"
/>

<angular-tag-cloud
  [config]="options"
  [data]="data"
  [zoomOnHover]="zoomOnHoverOptions"
></angular-tag-cloud>

<!--Palabras Detectadas-->
<!-- [ngClass]="{ animar-palabra-actual: showEfecto }" -->
<div [ngClass]="showEfecto ? 'animar-palabra-actual' : ''">
  <div class="cont-palabra-actual">
    <span class="palaba-actual">{{ palabrasDetectadasAudio }}</span>
  </div>
</div>

<!--End Palabras Detectadas-->

<marquee
  class="letrero"
  scrolldelay="100"
  scrollamount="20"
  loop="infinite"
  direction="left"
  width=""
  bgcolor="#FFFFFF"
>
  <span *ngFor="let registro of listaRegistrosAprobados; let i = index">
    <span *ngIf="i % 2 == 0; else impar" class="frase1">
      {{ registro.transcripcion }}
    </span>
    <ng-template #impar>
      <span class="frase2">
        {{ registro.transcripcion }}
      </span>
    </ng-template>
  </span>
</marquee>
