import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idioma.service';
import {  SpeechrecognizerService } from "@app/_services/speechrecognizer.service";
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Palabra,Derivada } from "@app/_models/palabras";

@Component({
  selector: 'app-palabras',
  templateUrl: './palabras.component.html',
})
export class PalabrasComponent implements OnInit {

  listaPalabras:Palabra[]=[];
  public seleccionados: number[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private speechRecognizerService: SpeechrecognizerService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('palabras.cabecera');
    this.speechRecognizerService.getPalabras().subscribe((result) => {
      this.listaPalabras = result.data;
    });
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
  }



  onClickNuevo() {

      this.router.navigate(['palabras-crear']);
    
  }

  // onClickEliminar(content){
  //   console.log('Eliminando...');
  //   if (this.seleccionados.length > 0) {
  //     this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  //   }
  // }

  onClickEditar(){
    this.router.navigate(['palabras-editar',{idpalabra:this.seleccionados[0] }]);
  }

  cellClick(){
    this.router.navigate(['palabras-editar',{ idpalabra:this.seleccionados[0] }]);
  }

  onClickEliminar(){
    console.log('Eliminar registro');
    this.speechRecognizerService.delete({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.speechRecognizerService.getPalabras().subscribe((result) => {
            this.listaPalabras = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    // this.modalReference.close();
    // this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

  onClickCategorias(){
    this.router.navigate(['categorias']);
  }
}
