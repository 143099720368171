import { Component, VERSION, ViewChild, ElementRef,OnInit} from '@angular/core';
import * as mobilenet from '@tensorflow-models/mobilenet';
import * as blazeface from '@tensorflow-models/blazeface';
import '@tensorflow/tfjs-backend-webgl';
import * as tf from '@tensorflow/tfjs-core';

@Component({
  selector: 'app-detector',
  templateUrl: './detector.component.html',
})
export class DetectorComponent implements OnInit {

  public model: any;
  public loading = true;
  public predictions: Prediction[];
  @ViewChild('video') public video: ElementRef;

  constructor() { }

  public async ngOnInit(): Promise<void> {
    this.model = await mobilenet.load();
    this.loading = false;
    setInterval(async () => {
      console.log('Comprobando cara....');
      this.predictions = await this.model.classify(this.video.nativeElement);
      console.log(this.predictions);
    }, 5000);

  }

  public async ngAfterViewInit(): Promise<void> {
    const vid = this.video.nativeElement;
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({video: true}).then((stream) => {
        vid.srcObject = stream;
      })
      .catch((error) => {console.log(error);});
    }
  }
}
export interface Prediction {
  className: string;
  probability: number;
}