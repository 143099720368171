import { CompileNgModuleMetadata } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Palabra } from '@app/_models/palabras';
import { Registro } from '@app/_models/registro';
import { SpeechrecognizerService } from '@app/_services/speechrecognizer.service';
import {
  CloudData,
  CloudOptions,
  ZoomOnHoverOptions,
  TagCloudComponent,
} from 'angular-tag-cloud-module';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-pantalla-nocturna',
  templateUrl: './pantalla-nocturna.component.html',
})
export class PantallaNocturnaComponent implements OnInit {

  listaRegistrosAprobados: Registro[] = [];
  timerCambioFrase:number;
  fraseActual:string;
  contador:number=3;
  constructor(private speechRecognizerService: SpeechrecognizerService) {

   }

  ngOnInit(): void {
    this.getAudiosAprobados();
    this.timerCambioFrase = setInterval(() => {
      console.log('Cambiando frase');
      console.log(this.contador);
      if(this.contador>0){
        console.log(this.listaRegistrosAprobados[this.contador-1]);
        this.fraseActual = this.listaRegistrosAprobados[this.contador-1].transcripcion;
        this.contador = this.contador -1;
      }
      else{
        this.contador = this.listaRegistrosAprobados.length;
        this.fraseActual = this.listaRegistrosAprobados[this.contador-1].transcripcion;
        this.contador = this.contador -1;
      }
    }, 10 * 1000);


    this.timerCambioFrase = setInterval(() => {
      this.getAudiosAprobados();
    }, 600 * 1000);
  }

  getAudiosAprobados(){
    console.log('Comprobando audios...');
    this.speechRecognizerService.getAprobados().subscribe((result) => {
      console.log(result);
      this.listaRegistrosAprobados = result.data;
      console.log(
        'Número de aprobados = ' + this.listaRegistrosAprobados.length
      );
      if (this.listaRegistrosAprobados.length <= 0) {
        let myRegistro: Registro = new Registro();
        myRegistro.transcripcion = 'No hay audios aprobados';
        this.listaRegistrosAprobados.push(myRegistro);
      }
      else{
        this.contador=this.listaRegistrosAprobados.length;
      }
    });
  }



  delay(seconds: number) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  }

  ngOnDestroy() {
    if (this.timerCambioFrase) {
      clearInterval(this.timerCambioFrase);
    }

    
  }

}
