import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Registro } from '@app/_models/registro';
import { AlertService, MenuService } from '@app/_services';
import { SpeechrecognizerService } from '@app/_services/speechrecognizer.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-gestion-editar',
  templateUrl: './gestion-editar.component.html',
})
export class GestionEditarComponent implements OnInit {

  myRegistro:Registro;
  idRegistro:number;
  transcripcion:string='';
  audio:string='';

  lang;
  private translate: TranslateService;

  constructor(
    private speechRecognizerService:SpeechrecognizerService,
    private _sanitizer: DomSanitizer,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('registro.cabecera');
    this.idRegistro = this.route.snapshot.params['idregistro']
   }

  ngOnInit(): void {
    this.speechRecognizerService.getRegistro(this.idRegistro).subscribe((result)=>{
      console.log(result);
      this.myRegistro = result.data[0];
      this.audio = this.myRegistro.urlAudio;
    });
  }

  public getSantizeUrl(url : string) {
    if(url!=null){
      console.log('Cargados :'+url.length);
      return this._sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    else
    return '';
}

  btnActualizar(){
    this.speechRecognizerService.updateRegistro(this.myRegistro).subscribe((result)=>{
      if(!result.error){
        this.alertService.success(
          this.translateService.instant('registro.successupdateregistro')
        );
      }
      else{
        this.alertService.error(
          this.translateService.instant('registro.errorupdateregistro')
        );

      }
    });
  }

  btnCancelar(){
    this.router.navigate(['/gestion']);
  }
  

  btnAprobar(){
    this.speechRecognizerService.aprobarRegistro(this.idRegistro).subscribe((result)=>{
      if(!result.error){
        this.alertService.success(
          this.translateService.instant('registro.successaprobar')
        );
      }
      else{
        this.alertService.error(
          this.translateService.instant('registro.erroraprobar')
        );

      }
    });
  
  }

}
