import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers';

import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { PalabrasComponent } from './palabras/palabras/palabras.component';
import { PalabrasCrearComponent } from './palabras/palabras-crear/palabras-crear.component';
import { PalabrasEditarComponent } from './palabras/palabras-editar/palabras-editar.component';
import { CategoriasComponent } from './palabras/categorias/categorias.component';
import { CategoriasCrearComponent } from './palabras/categorias-crear/categorias-crear.component';
import { CategoriasEditarComponent } from './palabras/categorias-editar/categorias-editar.component';
import { DetectorComponent } from './detection/detector/detector.component';
import { Detector2Component } from './detection/detector2/detector2.component';
import { PantallaInicialComponent } from './pantalla/pantalla-inicial/pantalla-inicial.component';
import { PantallaSecundariaComponent } from './pantalla/pantalla-secundaria/pantalla-secundaria.component';
import { GestionRegistrosComponent } from './gestion/gestion-registros/gestion-registros.component';
import { GestionEditarComponent } from './gestion/gestion-editar/gestion-editar.component';
import { Tagcanvas2Component } from './tagcanvas2/tagcanvas2.component';
import { PantallaNocturnaComponent } from './pantalla/pantalla-nocturna/pantalla-nocturna.component';

const loginModule = () =>
  import('./login/login.module').then((x) => x.LoginModule);

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    loadChildren: loginModule,
    canActivate: [AuthGuard],
  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', loadChildren: loginModule },
  {
    path: 'profile',
    component: DetailsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'update', component: UpdateComponent, canActivate: [AuthGuard] },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'usuarios-tipos',
    component: UsuariosTiposComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearUsuarioTipoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearUsuarioTipoComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },

  //My Routes
  {
    path: 'palabras',
    component: PalabrasComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'palabras-crear',
    component: PalabrasCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'palabras-editar',
    component: PalabrasEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'categorias',
    component: CategoriasComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'categorias-crear',
    component: CategoriasCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'categorias-editar',
    component: CategoriasEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'detector',
    component: DetectorComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'detector2',
    component: Detector2Component,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'inicial',
    component: PantallaInicialComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'secundaria',
    component: PantallaSecundariaComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'gestion',
    component: GestionRegistrosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'gestion-editar',
    component: GestionEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'nocturna',
    component: PantallaNocturnaComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'tagcanvas',
    component: Tagcanvas2Component,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
 
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
