<kendo-grid
[kendoGridBinding]="listaPalabras"
[sortable]="true"
kendoGridSelectBy="id"
[navigable]="true"
[filterable]="true"
(cellClick)="cellClick()"
[selectedKeys]="seleccionados"
[pageSize]="15"
[pageable]="true"
>
<ng-template kendoGridToolbarTemplate position="top">
  <button
    kendoButton
    (click)="onClickEditar()"
    class="btn mr-1 btn-success btn-sm mr-1"
  >
    {{ "botones.editar" | translate }}
  </button>
  <button
    kendoButton
    (click)="onClickNuevo()"
    class="btn mr-1 btn-primary btn-sm mr-1"
  >
    {{ "botones.nuevo" | translate }}
  </button>
  <button
    kendoButton
    (click)="onClickEliminar(content)"
    class="btn mr-1 btn-danger btn-sm mr-1"
    [disabled]="isDeleting"
  >
    {{ "botones.eliminar" | translate }}
  </button>
  <button
    kendoButton
    (click)="onClickCategorias(content)"
    class="btn mr-1 btn-primary btn-sm mr-1"
  >
    {{ "publicaciones.categorias" | translate }}
  </button>
</ng-template>
<kendo-grid-checkbox-column
  width="1%"
  showSelectAll="true"
></kendo-grid-checkbox-column>
<kendo-grid-column
  width="20%"
  [style]="{ 'text-align': 'left' }"
  field="word_es"
  title="{{ 'palabras.grid_lbl_palabra_es' | translate }}"
>
</kendo-grid-column>

<kendo-grid-column
  width="20%"
  [style]="{ 'text-align': 'left' }"
  field="word_eu"
  title="{{ 'palabras.grid_lbl_palabra_eu' | translate }}"
>
</kendo-grid-column>

<kendo-grid-column
  width="20%"
  [style]="{ 'text-align': 'left' }"
  field="puntuacion"
  title="{{ 'palabras.grid_lbl_puntuacion' | translate }}"
>
</kendo-grid-column>

<!-- <kendo-grid-column
  width="20%"
  [style]="{ 'text-align': 'left' }"
  field="activa"
  title="{{ 'organizaciones.activo' | translate }}"
>
  <ng-template kendoGridCellTemplate let-dataItem>
    <input
      type="checkbox"
      [checked]="dataItem.organizacionActiva"
      disabled
    />
  </ng-template>
</kendo-grid-column> -->
</kendo-grid>