import { Component, OnInit } from '@angular/core';
import { SpeechrecognizerService } from '@app/_services/speechrecognizer.service';
import { Registro } from "@app/_models/registro";
import { AlertService, MenuService } from '@app/_services';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gestion-registros',
  templateUrl: './gestion-registros.component.html',
})
export class GestionRegistrosComponent implements OnInit {

  listaAudios:Registro[]=[];
  seleccionados:number[]=[];

  constructor(
    private speechRecognizerService:SpeechrecognizerService,
    private menuService: MenuService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.speechRecognizerService.getRegistrosAudios().subscribe((result)=>{
      console.log(result);
      this.listaAudios=result.data;
       this.listaAudios.forEach((elem)=>{
        elem.detectadas = elem.detectadas.replace(/[ ,.]/g, ",");
        if(elem.detectadas.length>0)
        elem.detectadas = elem.detectadas.substring(1,elem.detectadas.length);
      });
    });
  }


  onClickEliminar(){
    console.log('Eliminar registro');
    this.speechRecognizerService.deleteRegistros({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.speechRecognizerService.getRegistrosAudios().subscribe((result) => {
            this.listaAudios = result.data;
          }
          );
        }
      }
    );

    this.seleccionados = [];
  }

  onClickEditar(){
    this.router.navigate(['gestion-editar',{ idregistro:this.seleccionados[0] }]);
  }

  cellClick(){
    this.router.navigate(['gestion-editar',{ idregistro:this.seleccionados[0] }]);
  }


}
