import { CompileNgModuleMetadata } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Palabra } from '@app/_models/palabras';
import { Registro } from '@app/_models/registro';
import { SpeechrecognizerService } from '@app/_services/speechrecognizer.service';
import {
  CloudData,
  CloudOptions,
  ZoomOnHoverOptions,
  TagCloudComponent,
} from 'angular-tag-cloud-module';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-pantalla-inicial',
  templateUrl: './pantalla-inicial.component.html',
})
export class PantallaInicialComponent implements OnInit {
  listaPalabras: Palabra[] = [];
  listaRegistrosAprobados: Registro[] = [];
  NUM_PALABRAS_CLOUD: number = 50;
  SECONDS_PER_WORD: number = 2;
  SECONDS_RELOAD_WORDS: number = this.NUM_PALABRAS_CLOUD*this.SECONDS_PER_WORD+10; //numwords*2 + 10
  SECONDS_CHECK_AUDIO_INPUTS: number = 3;
  SECONDS_GETK_AUDIOS_APROBADOS:number=300;
  palabrasDetectadasAudio: string = 'inicio';
  showEfecto: boolean = false;
  consumirNuevasEntradas: boolean = true;

  @ViewChild(TagCloudComponent) tagCloudComponent: TagCloudComponent;
  options: CloudOptions = {
    // if width is between 0 and 1 it will be set to the width of the upper element multiplied by the value
    width: 1800,
    // if height is between 0 and 1 it will be set to the height of the upper element multiplied by the value
    height: 740,
    overflow: false,
    delay: this.SECONDS_PER_WORD * 1000,
    randomizeAngle: true,
  };
  zoomOnHoverOptions: ZoomOnHoverOptions = {
    scale: 1.3, // Elements will become 130 % of current zize on hover
    transitionTime: 1.2, // it will take 1.2 seconds until the zoom level defined in scale property has been reached
    delay: 0.8, // Zoom will take affect after 0.8 seconds
  };

  data: CloudData[] = [];

  timerRechargeWords: number;
  timerCheckAudioInputs: number;
  timerCheckAprobadas:number;

  constructor(private speechRecognizerService: SpeechrecognizerService) {}

  ngOnInit(): void {
    this.speechRecognizerService
      .getTopPalabras(this.NUM_PALABRAS_CLOUD)
      .subscribe((result) => {
        console.log(result);
        this.listaPalabras = result.data;
        let wordLang: boolean = Math.random() >= 0.5;
        this.listaPalabras.forEach((palabra) => {
          if (wordLang) {
            this.data.push({
              text: palabra.word_es,
              weight: palabra.puntuacion,
            });
          } else {
            this.data.push({
              text: palabra.word_eu,
              weight: palabra.puntuacion,
            });
          }
          wordLang = !wordLang;
        });
        this.getNewData();
      });

    //Recharging service
    this.timerRechargeWords = setInterval(() => {
      this.getNewData();
    }, this.SECONDS_RELOAD_WORDS * 1000);
    //
    //Check for now audio inputs
    this.timerCheckAudioInputs = setInterval(() => {
      this.checkNewEntries();
    }, this.SECONDS_CHECK_AUDIO_INPUTS * 1000);
    //
    //Check new Frases Aprobadas
    this.timerCheckAprobadas = setInterval(() => {
      this.getAydiosAprobados();
    }, this.SECONDS_GETK_AUDIOS_APROBADOS * 1000);
    //

    this.speechRecognizerService.getAprobados().subscribe((result) => {
      console.log(result);
      this.listaRegistrosAprobados = result.data;
      console.log(
        'Número de aprobados = ' + this.listaRegistrosAprobados.length
      );
      if (this.listaRegistrosAprobados.length <= 0) {
        let myRegistro: Registro = new Registro();
        myRegistro.transcripcion = 'Aún no hay audios aprobados';
        this.listaRegistrosAprobados.push(myRegistro);
      }
    });
  }

  getNewData() {
    console.log('Recharging Data...');
    //this.data=[];
    let datos: CloudData[] = [];
    this.speechRecognizerService
      .getTopPalabras(this.NUM_PALABRAS_CLOUD)
      .subscribe((result) => {
        console.log('Palabras:');
        console.log(result);
        this.listaPalabras = result.data;
        let wordLang: boolean = Math.random() >= 0.5;
        this.listaPalabras.forEach((palabra) => {
          if (wordLang) {
            datos.push({ text: palabra.word_es, weight: palabra.puntuacion });
          } else {
            datos.push({ text: palabra.word_eu, weight: palabra.puntuacion });
          }
          wordLang = !wordLang;
        });
      });
    const changedData$: Observable<CloudData[]> = of(datos);

    changedData$.subscribe((res) => (datos = res));
    this.tagCloudComponent.reDraw();
  }

  checkNewEntries() {
    if (this.consumirNuevasEntradas) {
      console.log('Checking new Entries');
      this.speechRecognizerService.getNewEntries().subscribe((res) => {
        console.log('Datos nuevos');
        console.log(res);
        if (res.data.length > 0) {
          this.consumirNuevasEntradas = false;
          this.palabrasDetectadasAudio = res.data[0].palabras;
          this.showEfecto = true;
          this.changeClass();
        } else {
          this.showEfecto = false;
        }
      });
    }
  }

  getAydiosAprobados(){
    console.log('Comprobando audios...');
    this.speechRecognizerService.getAprobados().subscribe((result) => {
      console.log(result);
      this.listaRegistrosAprobados = result.data;
      console.log(
        'Número de aprobados = ' + this.listaRegistrosAprobados.length
      );
      if (this.listaRegistrosAprobados.length <= 0) {
        let myRegistro: Registro = new Registro();
        myRegistro.transcripcion = 'Aún no hay audios aprobados';
        this.listaRegistrosAprobados.push(myRegistro);
      }
    });
  }

  async changeClass() {
    await this.delay(6);
    this.showEfecto = false;
    this.consumirNuevasEntradas = true;
  }
  delay(seconds: number) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  }

  ngOnDestroy() {
    console.log('Saliendo de pantalla nube');
    if (this.timerRechargeWords) {
      console.log('Cerrando servicio recargar palabras');
      clearInterval(this.timerRechargeWords);
    }
    if (this.timerCheckAudioInputs) {
      console.log('Cerrando servicio obtener inputs usuario');
      clearInterval(this.timerCheckAudioInputs);
    }
    if (this.timerCheckAprobadas) {
      console.log('Cerrando servicio obtener audios aprobados');
      clearInterval(this.timerCheckAprobadas);
    }
    
  }
}
