<div class="row">
  <div class="col-xl-4 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "palabras.titulo_editar" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label> {{ "palabras.palabra_es" | translate }}</label>
              <div class="caja">
                <!--Titulo-->
                <kendo-textbox placeholder="{{ 'palabras.ph_pal_es' | translate }}"
                               [clearButton]="true"
                               [(ngModel)]="word_es">
                  <ng-template kendoTextBoxPrefixTemplate> </ng-template>
                </kendo-textbox>
              </div>
            </div>
            <div class="form-group">
              <label>{{ "palabras.palabra_eus" | translate }}</label>
              <div class="caja">
                <!--Titulo-->
                <kendo-textbox placeholder="{{ 'palabras.ph_pal_eu' | translate }}"
                               [clearButton]="true"
                               [(ngModel)]="word_eu">
                  <ng-template kendoTextBoxPrefixTemplate> </ng-template>
                </kendo-textbox>
              </div>
            </div>

          </div>
          <div class="col-4">
            <div class="form-group">
              <label>{{ "palabras.puntuacion" | translate }}</label>
              <div class="caja">
                <!--Titulo-->
                <kendo-textbox placeholder="{{ 'palabras.ph_pal_eu' | translate }}"
                               [clearButton]="true"
                               [(ngModel)]="myWord.puntuacion">
                  <ng-template kendoTextBoxPrefixTemplate> </ng-template>
                </kendo-textbox>
              </div>

            </div>
            <div class="form-group">
              <label>{{ "palabras.categoria" | translate }}</label>
              <!--Lista Categorias-->
              <div class="caja">
                <kendo-dropdownlist [data]="listaCategorias"
                                    textField="nombre_es"
                                    valueField="id"
                                    [(ngModel)]="selectedCategoria"></kendo-dropdownlist>
              </div>
            </div>
          </div>
          

                      <!--Imagen Palabra-->
                      <label class="image-upload-container btn btn-bwm">
                        <span>Selecciona la imagen</span>
                        <input
                          #imageInput
                          type="file"
                          accept="image/*"
                          (change)="imagePreview($event)"
                        />
                      </label>
              
                      <div *ngIf="filePath && filePath !== ''">
                        <img [src]="filePath" style="height: 200px" />
                      </div>

        </div>

        <div class="form-group">
          <!--Boton Publicar-->
          <button
            type="button"
            class="btn btn-success btn-sm mr-1"
            (click)="btnUpdatPalabra()"
          >
            {{ "botones.actualizar" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="clearfix">
  
  <div class="card">
    <div class="card-header">
      <h3>{{ "palabras.titulo_derivadas" | translate }}</h3>
    </div>
    <!--GRID Derivados-->
    <kendo-grid
      [kendoGridBinding]="listaPalabrasDerivadas"
      [sortable]="true"
      kendoGridSelectBy="id"
      [navigable]="true"
      [filterable]="true"
      (cellClick)="cellClick()"
      [selectedKeys]="seleccionados"
      [pageSize]="15"
      [pageable]="true"
    >
      <ng-template kendoGridToolbarTemplate position="top">
        <button
          kendoButton
          (click)="onClickEditar()"
          class="btn mr-1 btn-success btn-sm mr-1"
        >
          {{ "botones.editar" | translate }}
        </button>
        <button
          kendoButton
          (click)="onClickNuevo()"
          class="btn mr-1 btn-primary btn-sm mr-1"
        >
          {{ "botones.nuevo" | translate }}
        </button>
        <button
          kendoButton
          (click)="onClickEliminar()"
          class="btn mr-1 btn-danger btn-sm mr-1"
          [disabled]="isDeleting"
        >
          {{ "botones.eliminar" | translate }}
        </button>
      </ng-template>
      <kendo-grid-checkbox-column
        width="1%"
        showSelectAll="true"
      ></kendo-grid-checkbox-column>
      <kendo-grid-column
        width="20%"
        [style]="{ 'text-align': 'left' }"
        field="derivado_es"
        title="{{ 'palabras.grid_lbl_palabra_es' | translate }}"
      >
      </kendo-grid-column>

      <kendo-grid-column
        width="20%"
        [style]="{ 'text-align': 'left' }"
        field="derivado_eu"
        title="{{ 'palabras.grid_lbl_palabra_eu' | translate }}"
      >
      </kendo-grid-column>

      <!-- <kendo-grid-column
  width="20%"
  [style]="{ 'text-align': 'left' }"
  field="activa"
  title="{{ 'organizaciones.activo' | translate }}"
>
  <ng-template kendoGridCellTemplate let-dataItem>
    <input
      type="checkbox"
      [checked]="dataItem.organizacionActiva"
      disabled
    />
  </ng-template>
</kendo-grid-column> -->
    </kendo-grid>
  </div>

</div>

<kendo-window
  [minWidth]="200"
  [minHeight]="125"
  title="Derivadas"
  autoFocusedElement="#derivada1"
  *ngIf="openCrearDerivada"
  (close)="btnCloseDialog()"
>
  <kendo-textbox
    placeholder="{{ 'palabras.ph_pal_es' | translate }}"
    id="derivada1"
    [clearButton]="true"
    [(ngModel)]="derivada_es"
  >
    <ng-template kendoTextBoxPrefixTemplate> </ng-template>
  </kendo-textbox>
  <kendo-textbox
    placeholder="{{ 'palabras.ph_pal_eu' | translate }}"
    [clearButton]="true"
    [(ngModel)]="derivada_eu"
  >
    <ng-template kendoTextBoxPrefixTemplate> </ng-template>
  </kendo-textbox>

  <div class="text-right">
    <button type="button" class="k-button" (click)="btnCloseDialog()">
      Cancel
    </button>
    <button
      type="button"
      class="k-button btn-primary m-2"
      (click)="btnGestionarDerivada()"
    >
    {{ "palabras.enviar" | translate }}
    </button>
  </div>
</kendo-window>
