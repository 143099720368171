import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idioma.service';
import {  SpeechrecognizerService } from "@app/_services/speechrecognizer.service";
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Palabra,Derivada, Categoria } from "@app/_models/palabras";

@Component({
  selector: 'app-categorias-editar',
  templateUrl: './categorias-editar.component.html',
})
export class CategoriasEditarComponent implements OnInit {

  myCategoria:Palabra=new Palabra();
  word_es:string='';
  word_eu:string='';
  idCategoria:number;

  public seleccionados: number[] = [];

  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private speechRecognizerService: SpeechrecognizerService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    public route:ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.translate = translate;
    //this.menuService.titulo = this.translate.instant('palabras.cabecera');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idCategoria = this.route.snapshot.params['idcategoria'];
    this.speechRecognizerService.getCategoria(this.idCategoria).subscribe((result)=>{
      console.log(result);
      this.myCategoria.id = result.data[0].id;
      this.word_es = result.data[0].nombre_es;
      this.word_eu = result.data[0].nombre_eu;
    });
  }

  btnUpdateCategoria(){
    if((this.word_es.length<=0 && this.word_eu.length<=0)){
      this.alertService.info(
        this.translateService.instant('palabras.validacion')
      );
      return;
    }
    console.log('Actualizando categoria...');
    this.myCategoria.word_es = this.word_es;
    this.myCategoria.word_eu = this.word_eu;

    this.speechRecognizerService.updateCategoria(this.myCategoria).subscribe((result)=>{
      if(!result.error){
        this.alertService.success(
          this.translateService.instant('palabras.successcategoria')
        );
        this.router.navigate(["categorias"]);
      }
      else{
        this.alertService.error(
          this.translateService.instant('palabras.errorcategoria')
        );
      }
    });

  }

  btnCancelar(){
    this.router.navigate(["categorias"]);
  }

}
