<div class="row">
  <div class="col-xl-4 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "palabras.titulo" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Palabra ES</label>
          <div class="caja">
            <!--Titulo-->
            <kendo-textbox
              placeholder="{{ 'palabras.ph_pal_es' | translate }}"
              [clearButton]="true"
              [(ngModel)]="word_es"
            >
              <ng-template kendoTextBoxPrefixTemplate> </ng-template>
            </kendo-textbox>
          </div>
        </div>

        <div class="form-group">
          <label>Palabra EUS</label>
          <div class="caja">
            <!--Titulo-->
            <kendo-textbox
              placeholder="{{ 'palabras.ph_pal_eu' | translate }}"
              [clearButton]="true"
              [(ngModel)]="word_eu"
            >
              <ng-template kendoTextBoxPrefixTemplate> </ng-template>
            </kendo-textbox>
          </div>
        </div>

        <div class="form-group">
          <label>Categoría</label>
          <!--Lista Categorias-->
          <div class="caja">
            <kendo-dropdownlist
              [data]="listaCategorias"
              textField="nombre_es"
              valueField="id"
              [(ngModel)]="selectedCategoria"
            ></kendo-dropdownlist>
          </div>
        </div>

        <!--Imagen Palabra-->
        <label class="image-upload-container btn btn-bwm">
          <span>Selecciona la imagen</span>
          <input
            #imageInput
            type="file"
            accept="image/*"
            (change)="imagePreview($event)"
          />
        </label>

        <div *ngIf="filePath && filePath !== ''">
          <img [src]="filePath" style="height: 200px" />
        </div>

        <div class="form-group">
          <!--Boton Publicar-->
          <button
            type="button"
            class="btn btn-success btn-sm mr-1"
            (click)="btnCrearPalabra()"
          >
            {{ "botones.guardar" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
