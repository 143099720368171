import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Derivada, Palabra } from '@app/_models/palabras';
import { Registro } from '@app/_models/registro';

const baseUrl = `${environment.apiUrl}/speechrecognizer`;

@Injectable({
  providedIn: 'root',
})
export class SpeechrecognizerService {
  constructor(private http: HttpClient) {}

  public getPalabras(): Observable<any> {
    return this.http.get(baseUrl + '/palabras');
  }

  public getPalabra(idword: number): Observable<any> {
    return this.http.get(baseUrl + '/getpalabra/' + idword);
  }

  public getTopPalabras(nun: number): Observable<any> {
    return this.http.get(baseUrl + '/top/' + nun);
  }
  public getRegistrosAudios(): Observable<any> {
    return this.http.get(baseUrl + '/registros');
  }
  public getCategorias(): Observable<any> {
    return this.http.get(baseUrl + '/categorias');
  }

  public getCategoria(idCategoria: number): Observable<any> {
    return this.http.get(baseUrl + '/getcategoria/' + idCategoria);
  }

  create(palabra: Palabra) {
    return this.http.post<any>(baseUrl + '/create/', JSON.stringify(palabra), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  update(palabra: Palabra) {
    return this.http.post<any>(baseUrl + '/update/', JSON.stringify(palabra), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  public getDerivadas(idPalabra: number): Observable<any> {
    return this.http.get(baseUrl + '/derivadas/' + idPalabra);
  }

  createDerivada(palabra: Derivada) {
    return this.http.post<any>(
      baseUrl + '/createderivada/',
      JSON.stringify(palabra),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  updateDerivada(palabra: Derivada) {
    return this.http.post<any>(
      baseUrl + '/updatederivada/',
      JSON.stringify(palabra),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  deleteDerivadas(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarderivadas', formData, {
      withCredentials: true,
    });
  }

  createCategoria(palabra: Palabra) {
    return this.http.post<any>(
      baseUrl + '/createcategoria/',
      JSON.stringify(palabra),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  updateCategoria(palabra: Palabra) {
    return this.http.post<any>(
      baseUrl + '/updatecategoria/',
      JSON.stringify(palabra),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  deleteCategorias(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarcategorias', formData, {
      withCredentials: true,
    });
  }

  uploadAudio(base64String) {
    return this.http.post<any>(
      baseUrl + '/uploadweb/',
      {
        base64Data: base64String,
      },
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  uploadAudioEUS(base64String) {
    return this.http.post<any>(
      baseUrl + '/uploadwebeus/',
      {
        base64Data: base64String,
      },
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  getRegistro(idRegistro: number): Observable<any> {
    return this.http.get(baseUrl + '/getregistro/' + idRegistro);
  }

  aprobarRegistro(idRegistro: number): Observable<any> {
    return this.http.get(baseUrl + '/aprobar/' + idRegistro);
  }

  getAprobados(): Observable<any> {
    return this.http.get(baseUrl + '/getaprobados/');
  }

  deleteRegistros(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarregistros', formData, {
      withCredentials: true,
    });
  }

  updateRegistro(registro: Registro) {
    return this.http.post<any>(
      baseUrl + '/updateregistro/',
      JSON.stringify(registro),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public getNewEntries(): Observable<any> {
    return this.http.get(baseUrl + '/getinputs/');
  }
}
