import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { AlertService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'zitu-selector-usuarios',
  templateUrl: './zitu-selector-usuarios.component.html',
})
export class ZituSelectorUsuariosComponent implements OnInit {

  listaFiltrados: Usuario[] = [];
  isBusy: boolean = false;

  lang;
  private translate: TranslateService;

  @Input() listaSeleccionados: Usuario[] = [];
  @Output() listaUsuarios = new EventEmitter<Usuario[]>();

  constructor(
    private usuarioService: UsuariosService,
    public router: Router,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  handleFilter(query: string) {
    console.log('filter');
    console.log(query);
    //Query Users
    if (!this.isBusy && query.length > 1) {
      this.isBusy = true;
      this.usuarioService.search(query).subscribe((resultSearch) => {
        this.listaFiltrados = resultSearch.data;
        this.isBusy = false;
      });
    }

    // if (query.length > 1) {
    //   this.listaFiltrados = this.listaUsuarios.filter(
    //     (s) => s.nombre.toLowerCase().indexOf(query.toLowerCase()) !== -1
    //   );
    // } else if (query.length == 0) {
    //   this.listaFiltrados = this.listaUsuarios;
    // }
  }
  removeTag(tag) {
    console.log('Remove');
    console.log(tag);
  }

  valueChange(usuarios: Usuario[]) {
    console.log('valuechange');
    console.log(usuarios);
    this.listaUsuarios.emit(usuarios);
  }
}
