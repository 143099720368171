import { Component } from '@angular/core';
import { MenuService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';


declare var TagCanvas: any;

@Component({
  selector: 'app-tagcanvas2',
  templateUrl: './tagcanvas2.component.html',
})
export class Tagcanvas2Component  {

  constructor(private menuService: MenuService,
    private translate: TranslateService) {

    this.menuService.titulo = this.translate.instant('tutorial').toUpperCase();

  }

  ngOnInit(): void {
    this.cargarCarrusel();
  }

  cargarCarrusel() {

    TagCanvas.Start('resCanvas', 'demoTags', {
      textFont: null,
      textColour: null,
      weight: true,
      textHeight: 25,
      maxSpeed: 0.02,
      minBrightness: 0.2,
      depth: 0.5,
      initial: [0.560, 0.260],
      decel: 0.98,
      reverse: true,
      imageScale: null,
      fadeIn: 1000,
      clickToFront: 600,
      pulsateTo: 0.2,
      pulsateTime: 0.5,
      outlineMethod: 'none',
      outlineColour: 'none',
      lock: 'x',
      shape: 'vcylinder',
      weightGradient: {
        0: 'transparent', 0.33: 'transparent',
        0.66: 'transparent', 1: '#00f'
      },
      wheelZoom: 0,

    });

  }

}
